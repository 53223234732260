import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Flex } from 'components/Flex';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { ShiftStatusBadge } from 'components/ShiftStatusBadge';
import { StarRating } from 'components/StarRating';
import { Tags } from 'components/Tags';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { useDashboardPageContext } from 'pages/DashboardPage/DashboardPage.context';
import { useDashboardPageUnmountContext } from 'pages/DashboardPage/DashboardPageUnmount.context';
import { JaneName } from 'pages/DashboardPage/JaneName';
import { WarningIcon } from 'pages/SchedulePage/Modals/SchedulePublishModal/styled';
import { Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks/useTranslation';
import { MountTransition } from 'routes';
import { queryRefetchService } from 'services/QueryRefetchService';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { parseVerboseDate } from 'utils';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { withMemo } from 'utils/withMemo';
import {
  CallOutStatus,
  CallOutStatusWrapper,
  InProgressHighlight,
  JaneNameWrapper,
  ShiftActionsWrapper,
  ShiftCardIcon,
  ShiftCardSectionA,
  ShiftCardSectionB,
  ShiftCardText,
  ShiftCardWrapper,
  ShiftIdWrapper,
  ShiftInfoWrapper,
} from './ShiftCardSkeleton.styled';
import { IProps } from './ShiftCardSkeleton.types';

import { isAuditTrailFeatureEnabledForCommunitySelector } from 'store/selectors/featureFlagSelectors';
import { OrderStatusEnum } from 'types/graphql-types';
import { RenderShiftActions } from 'components/RenderShiftActions';
import { ContinueCalloutDialog } from 'components/ContinueCalloutDialog';
import {
  RenderShiftActionsProps,
  ShiftAction,
} from 'components/RenderShiftActions/RenderShiftActions.types';
import { toastService } from 'services';
import { AllowOvertimeDialog } from 'components/AllowOvertimeDialog';
import schedulingReadAccessFacilityIdsSelector from 'store/selectors/scheduleSelectors/schedulingReadAccessFacilityIdsSelector';

const ShiftActionComponent: RenderShiftActionsProps['SingleItemComponent'] = ({
  onClick,
  children,
}) => (
  <BKJButton variant="PurpleSolid" width="192px" onClick={onClick}>
    {children}
  </BKJButton>
);

export const ShiftCardSkeleton = withMemo(function (props: IProps): JSX.Element | null {
  const { t, i18n } = useTranslation();
  const {
    id,
    status,
    mainJane,
    dedicated,
    sentToAgencies,
    acceptedBySharedTeamMember,
    position,
    startAt,
    endAt,
    community,
    canSendToAgency,
    isReviewAllowed,
    review,
    recurrence,
    canAssign,
    orderSchedule,
    currentlyActiveCalloutList,
    auditTrailVersion,
    allowOvertime,
  } = props.order;
  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const { values } = useDashboardPageContext();
  const { unmountDashboard } = useDashboardPageUnmountContext();

  const locationsWithScheduling = useSelector(schedulingReadAccessFacilityIdsSelector);
  const isSchedulingEnabled = useMemo(
    () => locationsWithScheduling.includes(Number(community.id)),
    [community.id, locationsWithScheduling],
  );

  useEffect(() => {
    queryRefetchService.addDataQuery('DASHBOARD_PAGE', `${id}`, unmountDashboard);
    queryRefetchService.addDataQuery('SHIFT_CARD', `${id}`, props.fetch);
    return () => {
      queryRefetchService.removeDataQuery('SHIFT_CARD', `${id}`);
      queryRefetchService.removeDataQuery('DASHBOARD_PAGE', `${id}`);
    };
  }, []); // eslint-disable-line

  function handleCardClick() {
    shiftModalRoutingService.open(
      canAssign && !isUserAgencyAndAgencyLocationFilterForcedDisabled
        ? {
            shiftModalShiftId: id,
            agencyCommunityId: values.location.value,
          }
        : {
            shiftModalShiftId: id,
          },
      'DetailView',
    );
  }

  function openRatingFlow(e: MouseEvent) {
    e.stopPropagation();
    shiftModalRoutingService.open({ shiftModalShiftId: id }, 'RateShiftView');
  }

  const openSendToAgenciesFlow = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      shiftModalRoutingService.open({ shiftModalShiftId: id }, 'PreRequestOptionsView');
    },
    [id],
  );

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const isCalloutComplete = useMemo(
    () => currentlyActiveCalloutList?.isCompleted && status.value === OrderStatusEnum.pending,
    [currentlyActiveCalloutList?.isCompleted, status.value],
  );

  const isCalloutPaused = useMemo(
    () =>
      currentlyActiveCalloutList?.waitingForManualApproval &&
      !isCalloutComplete &&
      status.value === OrderStatusEnum.pending,
    [currentlyActiveCalloutList?.waitingForManualApproval, isCalloutComplete, status.value],
  );

  const isAllowOvertimeMenuItem =
    !allowOvertime && status.value === OrderStatusEnum.pending && isSchedulingEnabled;

  const isShiftInfoWrapperVisible =
    !isReadOnly &&
    (!!isReviewAllowed ||
      !!canSendToAgency ||
      !!isCalloutPaused ||
      !!isAllowOvertimeMenuItem ||
      !!review?.rating);

  const [isContinueCallOutConfirmationOpen, setIsContinueCallOutConfirmationOpen] = useState(false);
  const [isAllowOvertimeConfirmationOpen, setIsAllowOvertimeConfirmationOpen] = useState(false);

  const isAuditTrailEnabled = useSelector(
    isAuditTrailFeatureEnabledForCommunitySelector({
      communityId: community.id,
      auditTrailVersion,
    }),
  );

  const shiftActions: ShiftAction[] = useMemo(
    () =>
      [
        !!isCalloutPaused && {
          action: () => setIsContinueCallOutConfirmationOpen(true),
          label: t('dashboard:CONTINUE_CALL_OUT'),
        },
        !!canSendToAgency && {
          action: (e: MouseEvent) => {
            // We shouldn't have to do this... Consider re-writing BKJMenuDropdown to have `isMenuOpen` and`closeMenu()` props
            setTimeout(() => {
              openSendToAgenciesFlow(e);
            }, 250);
          },
          label: t('dashboard:SEND_TO_AGENCIES'),
        },
        !!isAllowOvertimeMenuItem && {
          action: () => setIsAllowOvertimeConfirmationOpen(true),
          label: t('dashboard:ALLOW_OVERTIME'),
        },
      ].filter(Boolean) as ShiftAction[],
    [canSendToAgency, isAllowOvertimeMenuItem, isCalloutPaused, openSendToAgenciesFlow, t],
  );

  return (
    <MountTransition>
      {!!isCalloutPaused && currentlyActiveCalloutList?.id && (
        <ContinueCalloutDialog
          isContinueCallOutConfirmationOpen={isContinueCallOutConfirmationOpen}
          setIsContinueCallOutConfirmationOpen={setIsContinueCallOutConfirmationOpen}
          calloutListId={currentlyActiveCalloutList.id}
          nextGroupName={
            currentlyActiveCalloutList.manualApprovalIndexes?.length
              ? currentlyActiveCalloutList.manualApprovalIndexes[0].groupName
              : ''
          }
          onSuccess={() => {
            toastService.success(t('dashboard:CALL_OUT_SUCCESSFUL_RESUMED'));
            queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${id}`);
            window.scheduleUtils.forceDataUpdate();
          }}
        />
      )}
      <AllowOvertimeDialog
        orderId={id}
        isAllowOvertimeConfirmationOpen={isAllowOvertimeConfirmationOpen}
        setIsAllowOvertimeConfirmationOpen={setIsAllowOvertimeConfirmationOpen}
        onSuccess={() => {
          toastService.success(t('dashboard:ALLOW_OVERTIME_SUCCESS'));
          queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${id}`);
          window.scheduleUtils.forceDataUpdate();
        }}
      />
      <ShiftCardWrapper
        onClick={handleCardClick}
        data-cy={`ShiftCard-${status.value}`}
        className="ShiftCard"
      >
        <ShiftCardSectionA>
          {status.name === 'In Progress' && <InProgressHighlight />}
          <Flex
            justifyContent="center"
            childMarginBottom="8px"
            width="130px"
            minWidth="130px"
            flexDirection="column"
          >
            <ShiftIdWrapper>{`${t('dashboard:SHIFT_NUMBER')}${orderSchedule.id}`}</ShiftIdWrapper>
            <ShiftStatusBadge status={status.value}>
              {getTranslatedResponse(status?.name, 'status', i18n, t)}
            </ShiftStatusBadge>
          </Flex>
          <Flex
            margin="0 28px 0 0"
            minWidth="80px"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
          >
            <JaneAvatar
              size={80}
              status={status.value}
              url={mainJane?.avatarUrls.large}
              pendingSignup={mainJane?.pendingSignup ?? false}
            />
          </Flex>
          <Flex
            flexDirection="column"
            childMarginBottom="8px"
            css="overflow: hidden;"
            width="calc(100% - 238px)"
            maxWidth="calc(100% - 238px)"
          >
            <JaneNameWrapper>
              <JaneName mainJane={mainJane} status={status.name} />
              &nbsp; &nbsp;
              <Tags
                id={id}
                status={status.value}
                isRecurring={!!recurrence}
                isDedicated={dedicated}
                isSentToAgencies={sentToAgencies}
                hasJane={!!mainJane}
                isAgencyJane={!!mainJane?.agencyCommunity}
                isAcceptedBySharedTeamMember={!!acceptedBySharedTeamMember}
              />
            </JaneNameWrapper>
            <ShiftCardText data-cy="ShiftCard-PositionName">{position?.name}</ShiftCardText>
            {isAuditTrailEnabled &&
              (isCalloutComplete ? (
                <CallOutStatusWrapper>
                  <CallOutStatus>{t('dashboard:CALL_OUT_COMPLETE')} &nbsp;</CallOutStatus>
                  <WarningIcon />
                </CallOutStatusWrapper>
              ) : (
                isCalloutPaused && (
                  <CallOutStatusWrapper>
                    <CallOutStatus>{t('dashboard:CALL_OUT_PAUSED')} &nbsp;</CallOutStatus>
                    <BKJIcon iconName="Pause" />
                  </CallOutStatusWrapper>
                )
              ))}
          </Flex>
        </ShiftCardSectionA>
        <ShiftCardSectionB>
          <ShiftInfoWrapper
            isVisible={isShiftInfoWrapperVisible}
            flexDirection="column"
            childMarginBottom="8px"
          >
            <Flex alignItems="center">
              <ShiftCardIcon iconName="ShiftsDate" color="AccentBrand" fontSize={24} />
              <ShiftCardText>{parseVerboseDate([startAt, endAt])}</ShiftCardText>
              {recurrence !== null && (
                <Fragment>
                  &nbsp;
                  <ShiftCardIcon iconName="Recurring" fontSize={24} />
                </Fragment>
              )}
            </Flex>
            <Flex alignItems="center">
              <ShiftCardIcon iconName="ShiftsLocation" color="AccentBrand" fontSize={24} />
              <ShiftCardText>{community?.name}</ShiftCardText>
            </Flex>
          </ShiftInfoWrapper>
          {isShiftInfoWrapperVisible && (
            <ShiftActionsWrapper childMarginBottom="8px" onClick={(e) => e.stopPropagation()}>
              {isReviewAllowed ? (
                <UserGuard
                  userType={[
                    UserPermissionEnum.USER_TYPE_B2B,
                    UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
                    UserPermissionEnum.USER_TYPE_ESSENTIAL_SECONDARY,
                  ]}
                >
                  <BKJButton variant="PurpleSolid" width="192px" onClick={openRatingFlow}>
                    {t('common:ADD_RATING')}
                  </BKJButton>
                </UserGuard>
              ) : review?.rating ? (
                <Flex justifyContent="center" alignItems="center" width="100%">
                  <StarRating rating={review?.rating} />
                </Flex>
              ) : null}
              <UserGuard
                userType={[
                  UserPermissionEnum.USER_TYPE_B2B,
                  UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
                  UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
                ]}
              >
                {!!shiftActions.length && (
                  <RenderShiftActions
                    variant="PurpleSolid"
                    shiftActions={shiftActions}
                    SingleItemComponent={ShiftActionComponent}
                  />
                )}
              </UserGuard>
            </ShiftActionsWrapper>
          )}
        </ShiftCardSectionB>
      </ShiftCardWrapper>
    </MountTransition>
  );
});
