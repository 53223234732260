import { BKJButton, BKJDollarTextInput, BKJSelect } from '@bookjane2/bookjane-design-library';
import {
  ChangeEventType,
  ChangeFunctionType,
} from '@bookjane2/bookjane-design-library/lib/common.types';
import { Flex } from 'components/Flex';
import {
  PriceDrawerFlex,
  ResetButton,
  Text,
} from 'components/PriceFilterInput/PriceFilterInput.styled';
import { IPriceFilterInput } from 'components/PriceFilterInput/PriceFilterInput.types';
import { useTranslation } from 'hooks/useTranslation';
import { useState } from 'react';

export function PriceFilterInput(props: IPriceFilterInput) {
  const { t } = useTranslation();
  const {
    name,
    priceRange: [min, max],
    value,
    onChange,
    className,
  } = props;

  const RenderOptions = (props: { selected: string[]; onChange: ChangeFunctionType }) => {
    const { selected: initialValue, onChange } = props;

    const [value, setValue] = useState(initialValue[0]);

    const handleClear = () => {
      onChange({ target: { value: [], name } });
      setValue('');
    };

    const handleChange = (e: ChangeEventType) => {
      setValue(e.target.value);
    };

    const handleSubmit = () => {
      onChange({ target: { value: value.replace(/[^0-9.-]+/g, ''), name } });
    };

    return (
      <PriceDrawerFlex flexDirection="column" padding="16px">
        <ResetButton variant="PurpleText" onClick={handleClear}>
          {t('common:RESET')}
        </ResetButton>
        <BKJDollarTextInput
          label={t('shifts_calendar:SET_MAX_HOURLY_COST')}
          name={name}
          value={value}
          onChange={handleChange}
          max={1000}
          placeholder={t('common:NONE')}
        />
        <Text>
          {t('shifts_calendar:MARKET_PRICE_RANGE_NOTE')} {min} - {max}.
        </Text>
        <BKJButton variant="PurpleSolid" disabled={!value} onClick={handleSubmit}>
          {t('auth:APPLY')}
        </BKJButton>
      </PriceDrawerFlex>
    );
  };

  return (
    <Flex className={className}>
      <BKJSelect
        name={name}
        type="Single"
        label={
          Number.isNaN(Number.parseFloat(value[0]))
            ? t('shifts_calendar:PRICE')
            : `${t('shifts_calendar:MAX')}. $` + value
        }
        onChange={onChange}
        value={Number.isNaN(Number.parseFloat(value[0])) ? [] : value}
        variant="GreyOutlinedPillClear"
        dropdownPlacement="bottom-start"
        dropdownWidth="358px"
        options={[
          {
            key: 'Price',
            label: t('shifts_calendar:PRICE'),
            value: 'Price',
          },
        ]}
        renderOptions={RenderOptions}
      />
    </Flex>
  );
}
