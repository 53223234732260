import { Flex } from '@bookjane2/bookjane-design-library';
import { IDisplayCustomField } from 'components/ShiftModal/src/shared/ShiftCustomFields/ShiftCustomFields.types';
import { LabelText, ValueText } from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import { shiftModalGeneralTabValueTextMarginLeft } from 'components/ShiftModal/src/views/DetailView/GeneralTab/GeneralTab';
import { MobileLabel } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView.styled';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { shiftModalOrderDetails_fetchOrder } from 'types/graphql-types';

export const ShiftCustomFields: FC<{ order: shiftModalOrderDetails_fetchOrder }> = ({
  order,
}): JSX.Element => {
  const { t } = useTranslation();
  const communityCustomFields = order.community?.customFields || [];
  const communityCustomFieldsMobileDefaultOff = communityCustomFields.map((customField) => ({
    ...customField,
  }));
  const orderCustomFields: IDisplayCustomField[] = order.customFields.map((customField) => {
    let _customField: IDisplayCustomField = { ...customField, id: customField.customFieldId };
    delete _customField.customFieldId;
    return _customField;
  });

  const uniqCustomFields = orderCustomFields
    .concat(communityCustomFieldsMobileDefaultOff)
    .reduce((acc: IDisplayCustomField[], customField) => {
      if (acc.findIndex((accCustomField) => accCustomField.id === customField.id) >= 0) {
        return acc;
      } else {
        acc.push(customField);
        return acc;
      }
    }, []);

  return (
    <Fragment>
      {uniqCustomFields?.map((customField) => {
        const shiftCustomField = order.customFields.find(
          (orderCustomField) => orderCustomField.customFieldId === customField.id,
        );
        const textSegments = shiftCustomField?.value
          ? shiftCustomField?.value.split('\n')
          : [shiftCustomField?.value || '-'];
        return (
          <Flex key={customField.id} flexDirection="column">
            <LabelText>
              {customField.name}
              {customField.mobile && (
                <MobileLabel>&nbsp;({t('schedule:SHOWN_INTERNALLY_ON_MOBILE')})</MobileLabel>
              )}
            </LabelText>
            {textSegments.map((textSegment, i, { length }) => (
              <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                {textSegment}
                {/** Add line break if not first or last text segment */}
                {i > 0 && i < length - 1 && <br />}
              </ValueText>
            ))}
          </Flex>
        );
      })}
    </Fragment>
  );
};
