import { memo, FC } from 'react';
import {
  MainNavigationHeader,
  NavLogoWrapper,
} from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { NavMenuIcon } from 'components/BKJMainNavigation/src/NavMenuIcon';
import { deepEquals } from 'utils/deepEquals';
import { INavHeaderProps } from 'components/BKJMainNavigation/src/NavHeader.types';
import { WhiteLabelLogo } from 'components/WhiteLabelLogo';

const Component: FC<INavHeaderProps> = ({ onMouseDown, isOpen, menuIcon }) => {
  return (
    <MainNavigationHeader onMouseDown={onMouseDown}>
      <NavMenuIcon iconName={menuIcon} color="White" />
      <NavLogoWrapper $isOpen={isOpen}>
        <WhiteLabelLogo />
      </NavLogoWrapper>
    </MainNavigationHeader>
  );
};

export const NavHeader = memo(Component, deepEquals);
