import { ISO8601Type } from 'types/common.types';
import dayjs from 'utils/dayjs';

const dateFormatAliasMap = {
  'YYYY-MM-DD': 'YYYY-MM-DD',
  'MMM D, YYYY': 'MMM D, YYYY',
  MM: 'MM',
  DD: 'DD',
  MMMM: 'MMMM',
};

type DateFormatAliasType = keyof typeof dateFormatAliasMap;

export const formatDate = (date: ISO8601Type, format?: DateFormatAliasType) => {
  const formatString = format ? dateFormatAliasMap[format] : format;
  return dayjs(date).format(formatString);
};
