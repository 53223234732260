import {
  QUERY_b2bDepartmentsNavigationFetchDepartments,
  QUERY_b2bPositionsSettingsPageFetchPositionsByDepartmentId,
} from 'queries';
import {
  b2bDepartmentsNavigationFetchDepartments,
  b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer,
  b2bPositionsSettingsPageFetchPositionsByDepartmentId,
  b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer,
} from 'types/graphql-types';
import {
  IApiPostB2BNewDepartmentPayload,
  IEditDepartmentFormSchema,
  IFetchDepartmentsData,
  INewDepartmentFormSchema,
  IPosition,
} from 'pages/SettingsPage/PositionsSettingsPage/B2BPositionsSettingsPage/B2BPositionsSettingsPage.types';
import uniqBy from 'lodash/uniqBy';
import { UseFormBehaviors_Options } from 'hooks/useFormBehaviors/useFormBehaviors.types';

export const getPositionNameKeyByPositionId = (positionId: string) => `${positionId}_positionName`;
export const getIncludedKeyByPositionId = (positionId: string) => `${positionId}_included`;
export const getIncludedKeys = (keys: string[]) =>
  keys.filter((value) => {
    const regex = new RegExp('.*_included');
    return regex.test(value);
  });
export const getPositionNameKeys = (keys: string[]) =>
  keys.filter((value) => {
    const regex = new RegExp('.*_positionName');
    return regex.test(value);
  });

export const newFormToPayloadTransformer = (
  formValues: INewDepartmentFormSchema,
): IApiPostB2BNewDepartmentPayload => ({
  department_create: {
    name: formValues.name,
  },
  new_positions: formValues.newPositions.filter((position) => !!position),
});

export const editFormToPayloadTransformer = (
  departmentId: number,
  formValues: IEditDepartmentFormSchema,
) => {
  const newPositions = formValues.newPositions.filter((position) => !!position);
  return {
    department_update: {
      id: departmentId,
      name: formValues.name,
      status: 'active',
      positions_attributes: getIncludedKeys(Object.keys(formValues))
        .map((key) => key.split('_')[0])
        .map((positionId) => ({
          id: Number.parseInt(positionId, 10),
          status: formValues[getIncludedKeyByPositionId(positionId)] ? 'active' : 'in_active',
          name: formValues[getPositionNameKeyByPositionId(positionId)],
          error: false,
        })),
    },
    new_positions: newPositions.length ? newPositions : undefined,
  };
};

export const b2bEditDepartmentFormConfig = ({
  name,
  positions,
  onSubmit,
  validations,
}: {
  name: string;
  positions: IPosition[];
  onSubmit: UseFormBehaviors_Options<IEditDepartmentFormSchema>['onSubmit'];
  validations: UseFormBehaviors_Options<IEditDepartmentFormSchema>['validations'];
}): UseFormBehaviors_Options<IEditDepartmentFormSchema> => {
  const selectedPositionIds = positions.map((position) => position.positionId);
  const selectedPositionsInitialState: IEditDepartmentFormSchema = {
    name,
    newPositions: [],
  };
  positions.forEach((position) => {
    selectedPositionsInitialState[getIncludedKeyByPositionId(position.positionId)] =
      selectedPositionIds.includes(position.positionId);
    selectedPositionsInitialState[getPositionNameKeyByPositionId(position.positionId)] =
      position.positionName;
  });

  return {
    initialState: selectedPositionsInitialState,
    // type: 'EDIT',
    validations,
    onSubmit,
  };
};

export const fetchDepartmentsQueryConfig = {
  query: QUERY_b2bDepartmentsNavigationFetchDepartments,
  key: 'B2B_DEPARTMENTS_NAVIGATION_FETCH_DEPARTMENTS',
  responseTransformer: (
    response: b2bDepartmentsNavigationFetchDepartments,
  ): IFetchDepartmentsData => {
    const data = response?.me as b2bDepartmentsNavigationFetchDepartments_me_B2bCustomer;
    return {
      departments:
        uniqBy(
          data?.serviceablePositions
            ?.map((position) => position.department)
            .filter((department) => !!department)
            .map((department) => ({
              value: `${department!.id}`,
              label: department!.name,
              name: 'department',
              key: department!.id,
            })),
          'value',
        ) || [],
    };
  },
};

export const fetchPositionsByDepartmentIdQueryConfig = (departmentId: number) => ({
  query: QUERY_b2bPositionsSettingsPageFetchPositionsByDepartmentId,
  key: `B2B_DEPARTMENTS_SETTINGS_PAGE_FETCH_POSITIONS_BY_ID_${departmentId}`,
  responseTransformer: (response: b2bPositionsSettingsPageFetchPositionsByDepartmentId) => {
    const data =
      response?.me as b2bPositionsSettingsPageFetchPositionsByDepartmentId_me_B2bCustomer;
    return {
      positions: data?.serviceablePositions
        .filter((position) => !!position.department)
        .map((position) => ({
          departmentId: `${position.department!.id}`,
          departmentName: position.department!.name,
          positionId: `${position.id}`,
          positionName: position.name,
        }))
        .filter((position) => position.departmentId === `${departmentId}`),
    };
  },
});
