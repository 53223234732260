/* eslint-disable react/prefer-stateless-function */

import React from 'react';

function assertProps(requiredKeys = []) {
  return (Component) => {
    return class GuardedComponent extends React.Component {
      componentDidMount() {
        requiredKeys.forEach((requiredPropKey) => {
          if (typeof this.props[requiredPropKey] === 'undefined') {
            if (Component.name) {
              const error = new Error(
                `<${Component.name} /> received undefined for the required prop: ${requiredPropKey}`,
              );
              throw error;
            } else {
              const error = new Error(
                `<${Component.type.name} /> received undefined for the required prop: ${requiredPropKey}`,
              );
              throw error;
            }
          }
        });
      }

      render() {
        return <Component {...this.props} />;
      }
    };
  };
}

export default assertProps;
