import i18next from 'i18next';

export const STICKY_INDICES = [0, 1, 2];
export const STATIC_INDICES = [0, 1, 2, 3, 4];
export const TABLE_HEIGHT_OFFSET = 239;
export const FILTER_PANEL_HEIGHT = 52;
export const FOOTER_PANEL_HEIGHT = 65;
export const PAGE_HEADER_HEIGHT = 61;
export const SCHEDULE_HEADER_FILTER_TABS_HEIGHT = 174;
export const ROW_HEIGHT = 62;
export const OVERSCAN_COUNT = 15;
export const CACHE_EXPIRY = [1, 'day'];
export const FILTER_PANEL_DRAWER_HEIGHT = 52;

export const legacyScheduleShiftStatusOptions: {
  key:
    | 'pending'
    | 'accepted'
    | 'assigned'
    | 'in_progress'
    | 'completed'
    | 'unfulfilled'
    | 'cancelled'
    | 'swapped';
  label:
    | 'Pending'
    | 'Accepted'
    | 'Assigned'
    | 'In Progress'
    | 'Completed'
    | 'Unfulfilled'
    | 'Cancelled'
    | 'Swapped';
}[] = [
  {
    key: 'pending',
    label: 'Pending',
  },
  {
    key: 'accepted',
    label: 'Accepted',
  },
  {
    key: 'assigned',
    label: 'Assigned',
  },
  {
    key: 'in_progress',
    label: 'In Progress',
  },
  {
    key: 'completed',
    label: 'Completed',
  },
  {
    key: 'unfulfilled',
    label: 'Unfulfilled',
  },
  {
    key: 'cancelled',
    label: 'Cancelled',
  },
  {
    key: 'swapped',
    label: 'Swapped',
  },
];

interface ITeamMembersSortByOptions {
  label: string;
  key: 'first_name' | 'last_name' | 'ranking';
  default: boolean;
}

export const legacyScheduleTeamMembersSortByOptions = (): ITeamMembersSortByOptions[] => [
  {
    label: i18next.t('schedule:BY_FIRST'),
    key: 'first_name',
    default: true,
  },
  {
    label: i18next.t('schedule:BY_LAST'),
    key: 'last_name',
    default: false,
  },
  {
    label: i18next.t('schedule:BY_SENIORITY'),
    key: 'ranking',
    default: false,
  },
];
export const legacyScheduleTeamMembersSortByDefaultKey: ITeamMembersSortByOptions['key'] =
  'first_name';
