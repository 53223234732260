import dayjs from 'utils/dayjs';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import i18next from 'i18next';
import {
  ISO8601Type,
  ISO8601TupleType,
  isISO8601Type,
  isISO8601Tuple,
  ShiftDateTimeRangeType,
  ShiftDateTimeType,
} from 'types/common.types';

export function parseVerboseDate(arg: ISO8601TupleType): ShiftDateTimeRangeType;
export function parseVerboseDate(arg: ISO8601Type): ShiftDateTimeType;
export function parseVerboseDate(
  arg: ISO8601TupleType | ISO8601Type,
): ShiftDateTimeRangeType | ShiftDateTimeType | string {
  const date = isISO8601Tuple(arg) ? new Date(arg[0]) : new Date(arg);

  const { dayOfWeek, month } = getLocalizedDateFormat(date);

  if (isISO8601Tuple(arg)) {
    return `${dayOfWeek}, ${month}, ${dayjs(date).format('DD')} ${i18next.t('common:AT')} ${dayjs(
      date,
    ).format('hh:mm a')} - ${dayjs(arg[1]).format('hh:mm a')}` as ShiftDateTimeRangeType;
  } else if (isISO8601Type(arg)) {
    return `${month}, ${dayjs(arg).format('DD, YYYY')} at ${dayjs(arg).format(
      'hh:mm a',
    )}` as ShiftDateTimeType;
  } else {
    return '';
  }
}
