import { FC, Fragment, useContext, useState } from 'react';
import { Flex } from 'components/Flex';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { BKJBadge, BKJButton } from '@bookjane2/bookjane-design-library';
import { TimesheetsTabContext } from 'components/ShiftModal/src/views/DetailView/TimesheetsTab/TimesheetsTabForm.context';
import dayjs from 'utils/dayjs';
import { useTranslation } from 'hooks/useTranslation';
import { LabelText, ValueText, Subtitle, Divider, ActualTimeDuration } from '../DetailView.styled';
import { DetailsWrapper, NoShowLabel } from './TimesheetsTab.styled';
import { formatUnpaidBreakTime, formatTime } from './TimesheetsTab.utils';
import { useTimeSheetsTabDataContext } from './TimesheetsTabData.context';
import { TimesheetsTabPendingView } from './TimesheetsTabPendingView';
import { UserGuard } from 'guards/UserGuard';
import { convertTimeToHours } from 'utils/time';
import { USER_TYPE_CAN_APPROVE_TIMESHEETS } from 'components/TimesheetsCard/TimesheetsCard.constants';
import { AutoMargin } from 'components/AutoMargin';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  ClockInStatusEnum,
  ClockOutStatusEnum,
  OrderStatusEnum,
  shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet,
} from 'types/graphql-types';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';

const marginLeft = 16;

const ActualTimeTeamDurationComponent: FC<{
  timeSheet: shiftModalTimeSheetDetails_fetchTimeSheet_timeSheet | null;
}> = ({ timeSheet }) => {
  const { t } = useTranslation();
  if (timeSheet?.clockInStatus?.value === ClockInStatusEnum.not_clocked_in) {
    return <BKJBadge variant="Red">{t('status:NOT_CLOCKED_IN')}</BKJBadge>;
  } else {
    return (
      <Flex>
        {dayjs(timeSheet?.clockIn).format('h:mm a')}&nbsp;-&nbsp;
        {dayjs(timeSheet?.clockOut).format('h:mm a')}&nbsp;&nbsp;
        {timeSheet?.clockInStatus?.value === ClockInStatusEnum.late_clock_in && (
          <BKJBadge variant="Orange">{t('status:LATE')}</BKJBadge>
        )}
      </Flex>
    );
  }
};

const CICODistanceText: FC<{ distance: number }> = ({ distance }: { distance: number }) => {
  return (
    <Fragment>
      {distance < 1 ? `${Math.round(distance * 1000)} meters ` : `${Math.round(distance)} km `}
    </Fragment>
  );
};

const ApproveTimeSection: FC = () => {
  const { t } = useTranslation();
  const { data } = useTimeSheetsTabDataContext();
  const { dayOfWeek, month } = getLocalizedDateFormat(data.fetchTimeSheet.timeSheet?.approvedAt);
  switch (data.fetchTimeSheet.timeSheet?.status.value) {
    case 'approved':
    case 'no_show':
      return (
        <Fragment>
          <Divider />
          <Subtitle>{t('timesheets:APPROVED_TIME')}</Subtitle>
          <Flex flexDirection="column" childMarginBottom="32px">
            <Flex alignItems="center">
              {data.fetchTimeSheet.timeSheet?.autoApproved ? (
                <Fragment>
                  <LabelText>{t('status:AUTO-APPROVED')}: </LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {`${dayOfWeek} ${month} ${dayjs(
                      data.fetchTimeSheet.timeSheet?.approvedAt,
                    ).format(`D [${t('common:AT')}] h:mm a`)}`}
                  </ValueText>
                </Fragment>
              ) : (
                <Fragment>
                  <LabelText>{t('timesheets:APPROVED_BY')}: </LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {data.fetchTimeSheet.timeSheet?.approvedBy},&nbsp;
                    {`${dayOfWeek} ${month} ${dayjs(
                      data.fetchTimeSheet.timeSheet?.approvedAt,
                    ).format(`D [${t('common:AT')}] h:mm a`)}`}
                  </ValueText>
                </Fragment>
              )}
            </Flex>
            <Flex>
              <Flex flexDirection="column" width="240px">
                <LabelText>{t('timesheets:APPROVED_DURATION')}</LabelText>
                <ValueText marginLeft={marginLeft}>
                  {data.fetchTimeSheet.timeSheet?.status.value === 'no_show' ? (
                    <NoShowLabel>{t('status:NO_SHOW')}</NoShowLabel>
                  ) : (
                    <>
                      {dayjs(data.fetchTimeSheet.timeSheet?.approvedStartTime).format('h:mm a')}
                      &nbsp;-&nbsp;
                      {dayjs(data.fetchTimeSheet.timeSheet?.approvedEndTime).format('h:mm a')}
                    </>
                  )}
                </ValueText>
              </Flex>
              {data.fetchTimeSheet.timeSheet?.approvedUnpaidBreakTime && (
                <Flex>
                  <Flex flexDirection="column">
                    <LabelText>{t('common:UNPAID_BREAK_TIME')}</LabelText>
                    <ValueText marginLeft={marginLeft}>
                      {formatUnpaidBreakTime(
                        data.fetchTimeSheet.timeSheet?.approvedUnpaidBreakTime,
                      )}
                    </ValueText>
                  </Flex>
                </Flex>
              )}
            </Flex>
            {data.fetchTimeSheet.timeSheet?.approvedTotalTime && (
              <Flex>
                <Flex flexDirection="column" width="240px">
                  <LabelText>{t('common:TOTAL_DURATION')}</LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {formatTime(
                      (data.fetchTimeSheet.timeSheet?.approvedTotalTime?.rawTime || 0) +
                        convertTimeToHours(data.fetchTimeSheet.timeSheet?.approvedUnpaidBreakTime),
                    )}
                  </ValueText>
                </Flex>
                <Flex>
                  <Flex flexDirection="column">
                    <LabelText>{t('common:TOTAL_PAID_DURATION')}</LabelText>
                    <ValueText marginLeft={marginLeft}>
                      {data.fetchTimeSheet.timeSheet?.approvedTotalTime?.time}
                    </ValueText>
                  </Flex>
                </Flex>
              </Flex>
            )}
            <Flex>
              {data.fetchTimeSheet.timeSheet?.acceptedHourlyRate && (
                <Flex flexDirection="column" width="240px">
                  <LabelText>{t('common:HOURLY_PAY_RATE')}</LabelText>
                  <ValueText marginLeft={marginLeft}>
                    ${data.fetchTimeSheet.timeSheet?.acceptedHourlyRate?.rawCurrency?.toFixed(2)}
                  </ValueText>
                </Flex>
              )}
              {data.fetchTimeSheet.timeSheet?.approvedCustomerCost && (
                <Flex>
                  <Flex flexDirection="column">
                    <LabelText>{t('common:TOTAL_PAY')}</LabelText>
                    <ValueText marginLeft={marginLeft}>
                      $
                      {data.fetchTimeSheet.timeSheet?.approvedCustomerCost?.rawCurrency?.toFixed(2)}
                    </ValueText>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Fragment>
      );

    case 'pending':
      return (
        <UserGuard userType={USER_TYPE_CAN_APPROVE_TIMESHEETS}>
          <Divider />
          <Subtitle>{t('timesheets:APPROVED_TIME')}</Subtitle>
          <TimesheetsTabPendingView />
        </UserGuard>
      );
    default:
      return <Fragment />;
  }
};

export const TimesheetsTabComponent: FC = () => {
  const { t, i18n } = useTranslation();
  const { data } = useTimeSheetsTabDataContext();
  const { data: shiftData } = useShiftModalDataContext();
  const [isNoShowDialogOpen, setIsNoShowDialogOpen] = useState<boolean>(false);
  const { onApprovalSubmit, onMarkAsNoShow, isPending } = useContext(TimesheetsTabContext);

  return (
    <Flex width="100%" height="100%" flexDirection="column" justifyContent="space-between">
      <DetailsWrapper>
        <Flex flexDirection="column">
          <Subtitle>{t('timesheets:SCHEDULED_TIME')}</Subtitle>
          <Flex>
            <Flex flexDirection="column" width="240px">
              <LabelText>{t('timesheets:SCHEDULED_DURATION')}</LabelText>
              <ValueText marginLeft={marginLeft}>
                {dayjs(data.fetchTimeSheet.startTime).format('h:mm a')}&nbsp;-&nbsp;
                {dayjs(data.fetchTimeSheet.endTime).format('h:mm a')}
              </ValueText>
            </Flex>
            <Flex>
              <Flex flexDirection="column">
                <LabelText>{t('common:UNPAID_BREAK_TIME')}</LabelText>
                <ValueText marginLeft={marginLeft}>
                  {formatUnpaidBreakTime(data.fetchTimeSheet.breakTime)}
                </ValueText>
              </Flex>
            </Flex>
          </Flex>
          <Divider />
          <Subtitle>{t('timesheets:ACTUAL_TIME')}</Subtitle>
          <ActualTimeDuration>
            <Flex>
              <Flex flexDirection="column" width="240px">
                <LabelText>{t('timesheets:TEAM_MEMBER_DURATION')}</LabelText>
                <ValueText marginLeft={marginLeft}>
                  <ActualTimeTeamDurationComponent timeSheet={data?.fetchTimeSheet?.timeSheet} />
                </ValueText>
              </Flex>
              <Flex>
                <Flex flexDirection="column">
                  <LabelText>{t('common:UNPAID_BREAK_TIME')}</LabelText>
                  <ValueText marginLeft={marginLeft}>
                    {formatUnpaidBreakTime(
                      data?.fetchTimeSheet?.timeSheet?.unpaidBreakTime as string,
                    )}
                  </ValueText>
                </Flex>
              </Flex>
            </Flex>
          </ActualTimeDuration>
          <Flex>
            <Flex flexDirection="column" width="480px">
              {(data?.fetchTimeSheet?.timeSheet?.clockInStatus?.value ===
                ClockInStatusEnum.clocked_in ||
                data?.fetchTimeSheet?.timeSheet?.clockInStatus?.value ===
                  ClockInStatusEnum.late_clock_in) && (
                <Fragment>
                  <LabelText>{t('timesheets:LOCATION_TRACKING')}</LabelText>
                  <ValueText marginLeft={marginLeft} padding={'2px 0 2px'}>
                    <Flex>
                      {!data?.fetchTimeSheet?.timeSheet?.clockInOffSite && (
                        <Fragment>{t('timesheets:CLOCKED_IN_ON_SITE')}&nbsp;&nbsp;</Fragment>
                      )}
                      {data?.fetchTimeSheet?.timeSheet?.clockInOffSite &&
                        data?.fetchTimeSheet?.timeSheet?.clockInDistance !== null && (
                          <Fragment>
                            {t('timesheets:CLOCKED_IN')} ~
                            <CICODistanceText
                              distance={data?.fetchTimeSheet?.timeSheet.clockInDistance}
                            />
                            {t('common:AWAY')}&nbsp;&nbsp;
                            <BKJBadge variant="Red">{t('status:OFFSITE')}</BKJBadge>
                          </Fragment>
                        )}
                    </Flex>
                  </ValueText>
                </Fragment>
              )}
              <Flex>
                <ValueText marginLeft={marginLeft}>
                  {data?.fetchTimeSheet?.timeSheet?.clockOutStatus?.value ===
                    ClockOutStatusEnum.clocked_out && (
                    <Flex>
                      {!data?.fetchTimeSheet?.timeSheet?.clockOutOffSite && (
                        <Fragment>{t('timesheets:CLOCKED_OUT_ON_SITE')}&nbsp;&nbsp;</Fragment>
                      )}
                      {data?.fetchTimeSheet?.timeSheet?.clockOutOffSite &&
                        data?.fetchTimeSheet?.timeSheet?.clockOutDistance !== null && (
                          <Fragment>
                            {t('timesheets:CLOCKED_OUT')} ~
                            <CICODistanceText
                              distance={data?.fetchTimeSheet?.timeSheet.clockOutDistance}
                            />
                            {t('common:AWAY')}&nbsp;&nbsp;
                            <BKJBadge variant="Red">{t('status:OFFSITE')}</BKJBadge>
                          </Fragment>
                        )}
                    </Flex>
                  )}
                </ValueText>
              </Flex>
            </Flex>
          </Flex>
          <ApproveTimeSection />
        </Flex>
      </DetailsWrapper>
      {shiftData.fetchOrder.status.value === OrderStatusEnum.completed &&
      data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
        <UserGuard userType={USER_TYPE_CAN_APPROVE_TIMESHEETS}>
          <BKJSideModalFooter>
            <AutoMargin />
            <BKJButton
              width="fit-content"
              variant="PurpleOutlined"
              isLoading={isPending}
              disabled={isPending}
              onClick={() => setIsNoShowDialogOpen(true)}
            >
              {t('timesheets:MARK_AS_NO_SHOW')}
            </BKJButton>
            &nbsp;&nbsp;&nbsp;
            <BKJButton
              width={i18n.language === 'en' ? '180px' : 'fit-content'}
              variant="PurpleSolid"
              isLoading={isPending}
              disabled={isPending}
              onClick={onApprovalSubmit}
            >
              {t('timesheets:APPROVE')}
            </BKJButton>
          </BKJSideModalFooter>

          <BKJCenteredDialog
            isOpen={isNoShowDialogOpen}
            variant="Danger"
            title={t('CONFIRM_NO_SHOW_TITLE')}
            text={t('CONFIRM_NO_SHOW_TEXT')}
            cancelLabel={t('auth:CANCEL')}
            actionLabel={t('auth:CONFIRM')}
            onAction={() => {
              setIsNoShowDialogOpen(false);
              onMarkAsNoShow();
            }}
            onCancel={() => setIsNoShowDialogOpen(false)}
            onClose={() => setIsNoShowDialogOpen(false)}
          />
        </UserGuard>
      ) : null}
    </Flex>
  );
};
