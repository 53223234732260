import { Grid } from 'components/Grid';
import styled from 'styled-components';
import { elementDimensions } from 'constants/elementDimensions';
import { PaperContainer } from 'components/PaperContainer';
import { Flex } from 'components/Flex';

const { LogoHeight } = elementDimensions;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${`${LogoHeight}px`};
`;

export const LoginPaperContainer = styled(PaperContainer)`
  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
  }
`;

export const LoginFlex = styled(Flex)`
  height: 100%;
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
    width: 60%;
    margin: 0 auto;
  }
`;

export const FormTitle = styled.h3`
  ${({ theme }) => theme.typography.H3};
  margin-bottom: 14px;
  color: #45424e;
`;

export const FormSection = styled.form`
  position: relative;
  width: 60%;

  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
    width: 100%;
  }
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 64px;
`;

export const JaneImageWrapper = styled.div`
  height: 860px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
    display: none;
  }
`;

export const JaneImage = styled.img`
  width: 100%;
`;

export const LoginSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 64px 0;

  ${({ theme }) => theme.typography.Body}

  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
    width: 100%;
  }
`;

export const BelowLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 28px 0;
`;
