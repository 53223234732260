import { memo, FC } from 'react';
import {
  InternalMenuLink,
  MenuItem,
  MenuItemLabel,
} from 'components/BKJMainNavigation/BKJMainNavigation.styled';
import { IInternalMenuLinkProps } from 'components/BKJMainNavigation/BKJMainNavigation.types';
import { NavMenuIcon } from 'components/BKJMainNavigation/src/NavMenuIcon';
import { deepEquals } from 'utils/deepEquals';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';

const Component: FC<IInternalMenuLinkProps> = (props): JSX.Element => {
  const { frenchFormat } = getLocalizedDateFormat();
  return (
    <InternalMenuLink
      data-cy={props['data-cy']}
      to={props.path}
      $label={props.label}
      $disabled={props.disabled}
      exact={!!props.exact}
      onClick={props.onClick}
      $isOpen={!!props.isOpen}
    >
      <MenuItem>
        <NavMenuIcon iconName={props.icon} color="White" />
        <MenuItemLabel
          $isOpen={props.isOpen}
          $disabled={props.disabled}
          $showPreLine={frenchFormat}
        >
          {props.label}
        </MenuItemLabel>
      </MenuItem>
    </InternalMenuLink>
  );
};

export const InternalNavMenuItem = memo(Component, deepEquals);
