import { EditJaneView } from 'components/JaneModal/views/EditJaneView';
import { JaneDetailsView } from 'components/JaneModal/views/JaneDetailsView';
import dayjs from 'dayjs';
import { UseGQLQueryConfig } from 'hooks/useGQLQuery/useGQLQuery.types';
import i18next from 'i18next';
import { createApolloCache, createApolloClient } from 'providers/ApolloProvider';
import { QUERY_janeModalJaneDetails } from 'queries';
import { ModalRoutingService } from 'services';
import { janeModalJaneDetails } from 'types/graphql-types';

export const JaneModalHeaderMap = {
  JaneDetailsView: 'Team Member Profile',
  EditJaneView: 'Edit Profile',
};

export const JaneModalComponentMap = {
  EditJaneView,
  JaneDetailsView,
};

export const janeModalRoutingService = new ModalRoutingService(
  'janeModal',
  'JaneDetailsView',
  JaneModalComponentMap,
  {
    janeModalJaneId: 'number',
  },
  [],
);
const fetchJaneApolloClient = createApolloClient({ cache: createApolloCache() });
export const fetchJaneConfig = (
  janeId?: string,
): UseGQLQueryConfig<janeModalJaneDetails, janeModalJaneDetails, { id: string | undefined }> => ({
  key: 'FETCH_JANE_DETAIL_BY_ID_QUERY',
  query: QUERY_janeModalJaneDetails,
  client: fetchJaneApolloClient,
  variables: {
    id: janeId,
  },
});
export const getLanguageString = (language: string): string => {
  switch (language) {
    case 'en':
      return `${i18next.t('team_member:ENGLISH')}`;
    case 'fr':
      return `${i18next.t('team_member:FRENCH')}`;
    default:
      return '-';
  }
};
export const humanReadableDate = (date: dayjs.ConfigType | undefined) => {
  const month = dayjs(date).format('MMM').toUpperCase();
  return `${i18next.t(month)} ${dayjs(date).format('DD, YYYY')} ${i18next.t('common:AT')} ${dayjs(
    date,
  ).format('hh:mm A')}`;
};
