import { DetailViewActiveTabType } from './DetailView.types';
import { OrderStatusEnum } from 'types/graphql-types';

export const ORDER_STATUS_DELETE_NOT_ALLOWED: OrderStatusEnum[] = [
  OrderStatusEnum.completed,
  OrderStatusEnum.cancelled,
  OrderStatusEnum.unfulfilled,
  OrderStatusEnum.in_progress,
];

export const PATH_DETAIL_VIEW_TAB_MAP = (pathname: string): DetailViewActiveTabType => {
  switch (pathname) {
    case '/timesheets':
      return 'TimesheetsTab';
    default:
      return 'GeneralTab';
  }
};
