import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { ApiRequestConfigType } from 'requests/index.types';

import { Communities } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { API_GET_customerCommunitiesV2 } from 'requests/GET_customerCommunitiesV2';
import { API_GET_agencyCommunitiesV1 } from 'requests/GET_agencyCommunitiesV1';
import { useSelector } from 'react-redux';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';

type InviteAdminModalType = { communities: Communities[] };

const mapCommunity = (community: Communities) => {
  return {
    id: community.id,
    shift_email: community.shift_emails,
    name: community.name,
  };
};

export const InviteAdminModalDataContext = createContext<InviteAdminModalType>(
  [] as unknown as InviteAdminModalType,
);
export const InviteAdminModalDataProvider: FC = ({ children }) => {
  const [communityIDs, setCommunityIDs] = useState<Communities[]>([]);
  const { apiRequest } = useAPIActions();
  const isUserAgency = useSelector(isUserAgencySelector);
  const [isLoadingCommunities, setIsLoadingCommunities] = useState(false);

  const getCommunitiesRequestOpts = isUserAgency
    ? API_GET_agencyCommunitiesV1
    : API_GET_customerCommunitiesV2;

  const fetchCommunitiesData = useCallback(async () => {
    await apiRequest(getCommunitiesRequestOpts as ApiRequestConfigType, {
      onSuccess: (response) => {
        if (isUserAgency) {
          setCommunityIDs(response?.data.agency_communities.map(mapCommunity));
        } else {
          setCommunityIDs(response?.data.communities.map(mapCommunity));
        }
      },
      onError() {},
    });
  }, [apiRequest, getCommunitiesRequestOpts, isUserAgency]);

  useEffect(() => {
    if (!isLoadingCommunities) {
      setIsLoadingCommunities(true);
      fetchCommunitiesData();
    }
  }, [fetchCommunitiesData, isLoadingCommunities]);

  const contextValues = useMemo(() => {
    return { communities: communityIDs };
  }, [communityIDs]);

  return (
    <InviteAdminModalDataContext.Provider value={contextValues}>
      {children}
    </InviteAdminModalDataContext.Provider>
  );
};

export const useInviteAdminModalDataContext = () =>
  useContext<InviteAdminModalType>(InviteAdminModalDataContext);
