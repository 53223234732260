import React, { useState } from 'react';
import {
  JaneDetailViewComponentMap,
  JaneDetailsViewActiveTabType,
} from 'components/JaneModal/views/JaneDetailsView/JaneDetailsView.types';
import {
  NavigationButton,
  NavigationButtonsWrapper,
  JaneDetailsNavigationWrapper,
} from 'components/JaneModal/views/JaneDetailsView/JaneDetailsView.styled';
import { useTranslation } from 'react-i18next';

const tabs: [string, JaneDetailsViewActiveTabType][] = [
  ['General', 'GeneralTab'],
  ['Reviews', 'ReviewsTab'],
  ['Activity', 'ActivityTab'],
];
export const JaneDetailsView = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<JaneDetailsViewActiveTabType>('GeneralTab');
  const ActiveTabComponent = JaneDetailViewComponentMap[activeTab];

  return (
    <div>
      <JaneDetailsNavigationWrapper>
        <NavigationButtonsWrapper>
          {tabs.map(([label, _enum]) => {
            return (
              <NavigationButton
                key={label}
                onClick={() => activeTab !== _enum && setActiveTab(_enum)}
                isActive={activeTab === _enum}
              >
                {t(`team_member:${label}`)}
              </NavigationButton>
            );
          })}
        </NavigationButtonsWrapper>
      </JaneDetailsNavigationWrapper>
      <ActiveTabComponent />
    </div>
  );
};
