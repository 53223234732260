import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import {
  ContinueCalloutDialogFormProvider,
  useContinueCalloutDialogFormContext,
} from 'components/ContinueCalloutDialog/ContinueCalloutDialog.form.context';
import { ContinueCalloutDialogProps } from 'components/ContinueCalloutDialog/ContinueCalloutDialog.types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const ContinueCalloutDialogView: FC<ContinueCalloutDialogProps> = ({
  isContinueCallOutConfirmationOpen,
  setIsContinueCallOutConfirmationOpen,
  nextGroupName,
}) => {
  const { t } = useTranslation();
  const { handleContinueCallout } = useContinueCalloutDialogFormContext();
  return (
    <BKJCenteredDialog
      isOpen={isContinueCallOutConfirmationOpen}
      variant="Default"
      title={t('dialog:CONTINUE_CALL_OUT_CONFIRMATION')}
      text={t('dialog:CONTINUE_CALL_OUT_APPROVAL_MESSAGE', { nextGroupName: nextGroupName })}
      cancelLabel={t('auth:CANCEL')}
      actionLabel={t('auth:CONFIRM')}
      onAction={() => {
        setIsContinueCallOutConfirmationOpen(false);
        return handleContinueCallout();
      }}
      onCancel={() => setIsContinueCallOutConfirmationOpen(false)}
      onClose={() => setIsContinueCallOutConfirmationOpen(false)}
    />
  );
};

export const ContinueCalloutDialog: FC<ContinueCalloutDialogProps> = (props) => {
  return (
    <ContinueCalloutDialogFormProvider {...props}>
      <ContinueCalloutDialogView {...props} />
    </ContinueCalloutDialogFormProvider>
  );
};
