import { Fragment } from 'react';
import { UnSuccessTeamMemberTableHeader } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/UnSuccessView/TeamMemberTable/UnSuccessTeamMemberTableHeader';
import {
  BKJTableBodyUnsuccess,
  BKJTableWrapperUnsuccess,
  BKJTableRowInfoWrapperUnsuccess,
  BKJTableRowWrapperUnsuccess,
  BKJTableDataUnsuccess,
  TextUnsuccess,
} from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/UnSuccessView/TeamMemberTable/UnsuccessTeamMemberTable.styled';
import { useInviteTeamMembersByCSVModalUIContext } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
export function UnSuccessTeamMemberTable() {
  const { csvErrors } = useInviteTeamMembersByCSVModalUIContext();
  return (
    <Fragment>
      <BKJTableWrapperUnsuccess>
        <BKJTableBodyUnsuccess>
          <UnSuccessTeamMemberTableHeader />
          {csvErrors?.map((item, index) => (
            <BKJTableRowInfoWrapperUnsuccess key={index}>
              <BKJTableRowWrapperUnsuccess>
                <BKJTableDataUnsuccess>
                  <TextUnsuccess>{item.error}</TextUnsuccess>
                </BKJTableDataUnsuccess>
                <BKJTableDataUnsuccess>{item.column}</BKJTableDataUnsuccess>
                <BKJTableDataUnsuccess>{item.row}</BKJTableDataUnsuccess>
              </BKJTableRowWrapperUnsuccess>
            </BKJTableRowInfoWrapperUnsuccess>
          ))}
        </BKJTableBodyUnsuccess>
      </BKJTableWrapperUnsuccess>
    </Fragment>
  );
}
