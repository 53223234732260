import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { Flex } from 'components/Flex';
import { QuerySwitch } from 'components/QuerySwitch';
import { H3 } from 'components/Typography';
import { useTranslation } from 'hooks/useTranslation';
import {
  ShiftDistributionDataContext,
  ShiftDistributionDataProvider,
  useShiftDistributionDataContext,
} from 'pages/ShiftDistributionPage/ShiftDistributionPage.data.context';
import {
  ShiftDistributionFormProvider,
  useShiftDistributionFormContext,
} from 'pages/ShiftDistributionPage/ShiftDistributionPage.form.context';
import {
  Description,
  FormWrapper,
  LastChangedFooter,
  OptionalDescription,
  RadioButtonFormSection,
  RadioButtonWrapper,
  ShiftdistributionContentWrapper,
  TextContent,
  TextContentTitle,
} from 'pages/ShiftDistributionPage/ShiftDistributionPage.styled';
import { ShiftDistributionPageRead } from 'pages/ShiftDistributionPage/ShiftDistributionPageRead';
import ShiftDistributionRules from 'pages/ShiftDistributionPage/ShiftDistributionRules';
import { FC, FormEventHandler, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isUserEssentialSelector from 'store/selectors/userSelectors/isUserEssentialSelector';
import isUserSecondarySelector from 'store/selectors/userSelectors/isUserSecondarySelector';
import dayjs from 'utils/dayjs';

const ShiftDistributionContainer: React.FC = (): JSX.Element => {
  const { values, onChange, onSubmit, isSubmitting, isDisabled } =
    useShiftDistributionFormContext();
  const {
    data: { fetchCommunity },
  } = useShiftDistributionDataContext();

  const { t } = useTranslation();

  // If the vaccination true/false is the same as from the API then disabled Submit button as there is nothing to update
  const isLocalFormVaccinationRequiredValueSameAsRemoteValue =
    `${fetchCommunity?.vaccinationRequired}` === values.vaccinationRequired;

  const isSubmitDisabled = isLocalFormVaccinationRequiredValueSameAsRemoteValue || isDisabled;

  const isFooterVisible =
    !!fetchCommunity?.vaccinationRequiredLastUpdated &&
    !!fetchCommunity?.vaccinationRequiredLastUpdatedBy;

  const isUserEssential = useSelector(isUserEssentialSelector);

  return (
    <ShiftdistributionContentWrapper>
      <Flex margin="0 0 40px 0">
        <H3>{fetchCommunity?.name}</H3>
      </Flex>
      {!isUserEssential && (
        <div>
          <TextContentTitle data-cy="hdr-shiftDistribution-rules">
            {t('location_settings:SHIFT_DISTRIBUTION_RULES')}
          </TextContentTitle>
          <br />
          <ShiftDistributionRules />
        </div>
      )}
      <FormWrapper onSubmit={onSubmit as unknown as FormEventHandler<HTMLFormElement>}>
        <H3 data-cy="hdr-shiftDistribution"> {t('location_settings:COVID19_HEADER')}</H3>
        <br />
        <TextContent data-cy="txt-under-shiftDistribution">
          {t('location_settings:COVID19_DESCRIPTION')}
        </TextContent>

        <RadioButtonFormSection>
          <RadioButtonWrapper>
            <BKJRadioInput
              name="vaccinationRequired"
              value="true"
              onChange={onChange}
              checked={values.vaccinationRequired === 'true'}
              label={t('location_settings:MANDATORY')}
            />
            {values.vaccinationRequired === 'true' && (
              <Description data-cy="txt-under-mandatory">
                {t('location_settings:MANDATORY_DESCRIPTION')}
              </Description>
            )}
          </RadioButtonWrapper>
          <RadioButtonWrapper>
            <BKJRadioInput
              name="vaccinationRequired"
              value="false"
              onChange={onChange}
              checked={values.vaccinationRequired === 'false'}
              label={t('location_settings:OPTIONAL')}
            />
            {values.vaccinationRequired === 'false' && (
              <OptionalDescription data-cy="txt-under-optional">
                {t('location_settings:OPTIONAL_DESCRIPTION')}
              </OptionalDescription>
            )}
          </RadioButtonWrapper>
        </RadioButtonFormSection>
        <BKJButton
          type="submit"
          disabled={isSubmitDisabled}
          isLoading={isSubmitting}
          variant="PurpleSolid"
          width="192px"
        >
          {t('auth:SAVE')}
        </BKJButton>
        {isFooterVisible && (
          <LastChangedFooter>
            {`${t('location_settings:LAST_CHANGED')}: ${dayjs(
              fetchCommunity?.vaccinationRequiredLastUpdated,
            ).format('MMM D, YYYY [at] h:mm a')} ${t('location_settings:BY')} ${
              fetchCommunity?.vaccinationRequiredLastUpdatedBy
            }`}
          </LastChangedFooter>
        )}
      </FormWrapper>
    </ShiftdistributionContentWrapper>
  );
};

const ShiftDistributionFormContainerComponent: FC = () => {
  return (
    <ShiftDistributionFormProvider>
      <ShiftDistributionContainer />
    </ShiftDistributionFormProvider>
  );
};

export const ShiftDistributionPage: FC = () => {
  const isReadOnly = useSelector(isUserSecondarySelector);
  const ShiftDistributionComponent = useMemo(
    () => (isReadOnly ? ShiftDistributionPageRead : ShiftDistributionFormContainerComponent),
    [isReadOnly],
  );
  return (
    <ShiftDistributionDataProvider>
      <QuerySwitch context={ShiftDistributionDataContext} component={ShiftDistributionComponent} />
    </ShiftDistributionDataProvider>
  );
};
