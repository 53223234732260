import { BKJButton, BKJListItem, BKJSelect, BKJTextArea } from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { releaseReasons } from 'components/ShiftModal/src/views/ReleaseShiftView/ReleaseShiftView.constant';
import {
  useReleaseShiftViewContext,
  ReleaseShiftViewProvider,
} from 'components/ShiftModal/src/views/ReleaseShiftView/ReleaseShiftView.context';
import {
  FormGroup,
  FormLabel,
  InnerWrapper,
  ReleaseContent,
  ReleaseShiftTitle,
  ReleaseShiftViewWrapper,
} from 'components/ShiftModal/src/views/ReleaseShiftView/ReleaseShiftView.styled';
import { ReleaseShiftViewWarning } from 'components/ShiftModal/src/views/ReleaseShiftView/ReleaseShiftViewWarning';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment, MouseEventHandler } from 'react';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';

export const ReleaseShiftViewComponent: FC = () => {
  const { t, i18n } = useTranslation();

  const { values, onChange, isDisabled, onSubmit, isSubmitting } = useReleaseShiftViewContext();

  return (
    <Fragment>
      <ReleaseShiftViewWrapper flexDirection="column">
        <ReleaseShiftTitle>{t('schedule:RELEASE_TEAM_MEMBER_CONFIRMATION')}</ReleaseShiftTitle>
        <InnerWrapper>
          <ReleaseShiftViewWarning />
          <FormGroup>
            <FormLabel>{t('schedule:REASON_FOR_RELEASE')}:*</FormLabel>
            <BKJSelect
              options={releaseReasons}
              type="Single"
              width="100%"
              variant="GreyOutlined"
              placeholder={t('schedule:SELECT_A_REASON')}
              value={[values.reason_category]}
              name="reason_category"
              required
              onChange={onChange}
              renderOptions={({ options, selected, onChange }) => {
                return (
                  <Fragment>
                    {releaseReasons.map(({ key, label, value }) => {
                      const translatedLabel = getTranslatedResponse(label, 'status', i18n, t);
                      return (
                        <BKJListItem
                          name="reason_category"
                          selected={selected}
                          key={key}
                          label={translatedLabel}
                          value={value}
                          onChange={onChange}
                        />
                      );
                    })}
                  </Fragment>
                );
              }}
            />
          </FormGroup>
          {values.reason_category === 'other' && (
            <FormGroup>
              <FormLabel>{t('schedule:PLEASE_SPECIFY_REASON')}:*</FormLabel>
              <BKJTextArea
                name="reason_message"
                placeholder={t('schedule:REASON_VISIBLE_TO_FACILITY_AND_TEAM_MEMBER')}
                value={values.reason_message}
                onChange={onChange}
                maxLength={200}
              />
            </FormGroup>
          )}

          <ReleaseContent>{t('schedule:TEAM_MEMBER_WILL_BE_NOTIFIED')}</ReleaseContent>
        </InnerWrapper>
      </ReleaseShiftViewWrapper>
      <BKJSideModalFooter>
        <AutoMargin />
        <BKJButton
          width="180px"
          variant="RedSolid"
          disabled={isDisabled}
          isLoading={isSubmitting}
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
        >
          {t('schedule:RELEASE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};

export const ReleaseShiftView: FC = () => {
  return (
    <ReleaseShiftViewProvider>
      <ReleaseShiftViewComponent />
    </ReleaseShiftViewProvider>
  );
};
