import { BKJ_ENVIRONMENT } from 'config';
import { useEffect } from 'react';
import { useAllFeatureFlagsSelector } from 'store/selectors/featureFlagSelectors/allFeatureFlagsSelector';
import { useUserRoleSelector } from 'store/selectors/userSelectors/userRoleSelector';
import { useUserSelector } from 'store/selectors/userSelectors/userSelector';

export function ChameleonContainer() {
  const features = useAllFeatureFlagsSelector();
  const user_role = useUserRoleSelector();

  const user = useUserSelector();

  useEffect(() => {
    if (user) {
      const { id, created_at, email, first_name, last_name } = user;
      window?.chmln?.identify(id, {
        id,
        email,
        created: created_at,
        name: `${first_name} ${last_name}`,
        role: user_role?.toLowerCase(),
        features,
        environment: BKJ_ENVIRONMENT,
      });
    }
  }, [features, user, user_role]);

  return null;
}
