import { FC, Fragment, useCallback, useContext } from 'react';
import {
  searchTeamMembersForGroup,
  searchTeamMembersForGroup_fetchJanes_edges_node,
  searchTeamMembersForGroup_fetchJanes_edges_node_staticGroups,
} from 'types/graphql-types';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import {
  NoResultsWrapper,
  InfiniteScrollWrapper,
} from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.styled';
import { TeamMemberOption } from 'components/GroupsModal/components/SearchTeamMembersGroups/src/TeamMemberOption';
import { SearchTeamMembersGroupsContext } from 'components/GroupsModal/components/SearchTeamMembersGroups/SearchTeamMembersGroups.context';
import { useGroupsModalFormContext } from 'components/GroupsModal/GroupsModal.form.context';
interface ITeamMembersListProps {
  groupMemberIds: string[];
  data: searchTeamMembersForGroup;
  value: string;
  name: string;
  onSelect: (e: unknown) => void;
  onLoadMore: () => void;
}

export const TeamMembersList: FC<ITeamMembersListProps> = ({
  groupMemberIds,
  data,
  value,
  name,
  onSelect,
  onLoadMore,
}) => {
  const { values: queryContextValues } = useContext(SearchTeamMembersGroupsContext);

  const { deletedMemberIds } = useGroupsModalFormContext();

  const isInStaticGroup = useCallback(
    (staticGroups: searchTeamMembersForGroup_fetchJanes_edges_node_staticGroups[]) =>
      staticGroups
        .map((group) => group.displayId)
        .includes(queryContextValues.groupsFilter.withId[0]),
    [queryContextValues.groupsFilter.withId],
  );
  const isChecked = useCallback(
    (node: searchTeamMembersForGroup_fetchJanes_edges_node) =>
      groupMemberIds.includes(node.id) ||
      (!deletedMemberIds.has(node.id) && isInStaticGroup(node.staticGroups)),
    [deletedMemberIds, groupMemberIds, isInStaticGroup],
  );

  if (!data || (data?.fetchJanes?.edges && data?.fetchJanes?.edges.length === 0))
    return <NoResultsWrapper>No Results</NoResultsWrapper>;

  return (
    <Fragment>
      {data?.fetchJanes?.edges &&
        data.fetchJanes.edges.map(({ node }) => {
          if (!node) return <Fragment />;
          return (
            <TeamMemberOption
              isChecked={isChecked(node)}
              key={node.id}
              node={node as searchTeamMembersForGroup_fetchJanes_edges_node}
              value={value}
              name={name}
              onSelect={onSelect}
            />
          );
        })}
      {!!data?.fetchJanes?.pageInfo.hasNextPage ? (
        <InfiniteScrollWrapper>
          <InfiniteScrollLoading onViewportEnter={onLoadMore} />
        </InfiniteScrollWrapper>
      ) : null}
    </Fragment>
  );
};
