import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { shiftTypeDetailsMapper } from 'components/ActivityTab/ActivityTab.constants';
import { getActivityColorByStatus } from 'components/ActivityTab/getActivityColorByStatus';
import { createContext, FC, useContext, useMemo, useState } from 'react';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import {
  shiftModalOrderDetails_fetchOrder_activities_event,
  shiftModalOrderDetails_fetchOrder_activities_metadata,
  shiftModalOrderDetails_fetchOrder_activities_user_User,
} from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { useTranslation } from 'hooks/useTranslation';

interface IActivityTabRowProps {
  activityDate: string;
  calloutListId: string;
  dotColor: BKJThemeColorType;
  eventType: string;
  eventName: string;
  hasEdits: boolean;
  hasCancellationReason: boolean;
  sentToNrOfAgencies: number;
  showHideDetails: ChangeFunctionType;
  userDetails: shiftModalOrderDetails_fetchOrder_activities_user_User;
  cancellationReason: string | null;
  editDetailList: string[];
  detailsShown: boolean;
  hasDetails: boolean;
  isRowGrey: boolean;
}

export const ActivityTabRowPropsContext = createContext<IActivityTabRowProps>(
  {} as IActivityTabRowProps,
);

export const ActivityTabRowPropsProvider: FC<{
  createdAt: string;
  event: shiftModalOrderDetails_fetchOrder_activities_event;
  userDetails: shiftModalOrderDetails_fetchOrder_activities_user_User;
  index: number;
  metadata: shiftModalOrderDetails_fetchOrder_activities_metadata | null;
}> = ({ children, createdAt, event, userDetails, index, metadata }) => {
  const { t } = useTranslation();
  const { month, frenchFormat } = getLocalizedDateFormat(createdAt);
  const [showDetails, setShowDetails] = useState(false);
  const format = frenchFormat ? '[à] HH:mm' : '[at] h:mm a';
  const todayFormat = frenchFormat ? 'HH:mm' : 'h:mm a';
  const activityDate = dayjs(createdAt).isToday()
    ? dayjs(createdAt).format(todayFormat)
    : `${month} ${dayjs(createdAt).format(`DD, YYYY ${format}`)}`;

  const [calloutListId] = useState(metadata?.calloutListId || '');

  const dotColor = getActivityColorByStatus(event.name);

  let hasEdits = false;
  let hasCancellationReason = false;
  let sentToNrOfAgencies = -1;
  let detailsShown = false;
  let hasDetails = false;
  let eventValue = event.value;
  let eventName = event.name;

  switch (event.name) {
    case 'cancelled': {
      hasCancellationReason = !!metadata?.reasonMessage;
      detailsShown = hasCancellationReason;
      hasDetails = hasCancellationReason;
      break;
    }
    case 'updated': {
      hasEdits = !!metadata?.feedableEdits?.length;
      detailsShown = showDetails;
      hasDetails = hasEdits;
      break;
    }
    case 'agency_assigned': {
      if (!!metadata?.acceptedFor?.name)
        eventValue = `${t('common:ACCEPTED_FOR')} ${metadata?.acceptedFor?.name}`;
      break;
    }
    case 'send_to_agencies':
    case 'sent_to_select_agencies':
    case 'scheduled_send_to_agencies': {
      sentToNrOfAgencies = metadata?.qualifiedAgencyJanesCount || 0;
      break;
    }
  }

  const contextValue = useMemo(
    () => ({
      activityDate,
      calloutListId,
      dotColor,
      eventType: eventValue,
      eventName,
      hasEdits,
      hasCancellationReason,
      sentToNrOfAgencies,
      showHideDetails: () => setShowDetails(!showDetails),
      cancellationReason: metadata?.reasonMessage || null,
      userDetails,
      editDetailList: (
        metadata?.feedableEdits?.map((edit) =>
          edit.fieldType ? shiftTypeDetailsMapper[edit.fieldType](edit) : '',
        ) || []
      ).filter((shiftEdit) => !!shiftEdit),
      detailsShown,
      hasDetails,
      isRowGrey: !!(index % 2),
    }),
    [
      activityDate,
      calloutListId,
      detailsShown,
      dotColor,
      eventName,
      eventValue,
      hasCancellationReason,
      sentToNrOfAgencies,
      hasDetails,
      hasEdits,
      index,
      metadata?.feedableEdits,
      metadata?.reasonMessage,
      showDetails,
      userDetails,
    ],
  );

  return (
    <ActivityTabRowPropsContext.Provider value={contextValue}>
      {children}
    </ActivityTabRowPropsContext.Provider>
  );
};

export const useActivityTabRowPropsContext = (): IActivityTabRowProps =>
  useContext(ActivityTabRowPropsContext);
