import { shiftModalEditViewFormItemVerticalMargin } from 'components/ShiftModal/src/views';
import {
  CustomFieldsFormLabel,
  FormLabel,
} from 'components/ShiftModal/src/views/EditView/EditView.styled';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { Flex } from 'components/Flex';
import { ShiftCustomFieldInput } from 'components/ShiftModal/src/shared/ShiftCustomFields/components/ShiftCustomFieldInput';
import { Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { CustomFieldsContent } from 'components/TimeOffModal/TimeOffModalView.styled';
import { useEditTimeOffViewFormContext } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.form.context';
import { useEditTimeOffViewDataContext } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.data.context';
import { ICustomFields } from 'components/TimeOffModal/TimeOffModal.constants';
import { MobileLabel } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView.styled';

export const EditTimeOffCustomFieldsViewComponent = () => {
  const { t } = useTranslation();
  const { onChange, values } = useEditTimeOffViewFormContext();
  const { timeOffCustomFields } = useEditTimeOffViewDataContext();

  const customFieldsCast = values as unknown as ICustomFields;

  return (
    <CustomFieldsContent>
      {timeOffCustomFields && timeOffCustomFields.length > 0 && (
        <Fragment>
          <SectionDivider
            variant="Thin"
            margin={`${shiftModalEditViewFormItemVerticalMargin}px 0`}
          />
          <CustomFieldsFormLabel>{t('common:CUSTOM_FIELDS')}</CustomFieldsFormLabel>
          {timeOffCustomFields?.map((customField) => {
            return (
              <Flex
                key={customField.name}
                flexDirection="column"
                margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}
              >
                <FormLabel>
                  {customField.name}
                  {customField.mobile && (
                    <MobileLabel>&nbsp;({t('schedule:SHOWN_INTERNALLY_ON_MOBILE')})</MobileLabel>
                  )}
                </FormLabel>
                <ShiftCustomFieldInput
                  key={customField.id}
                  customField={customField}
                  value={customFieldsCast[customField.name]}
                  onChange={onChange}
                />
              </Flex>
            );
          })}
        </Fragment>
      )}
    </CustomFieldsContent>
  );
};
