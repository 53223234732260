import { FC, useContext, useEffect } from 'react';
import { useGQLQuery } from 'hooks';
import { shiftModalDetailsQueryConfig } from 'components/ShiftModal/ShiftModal.constants';
import { createGQLDataContext } from 'utils/createDataContext';
import { useShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { UseGQLQuery } from 'hooks/useGQLQuery/useGQLQuery.types';
import { shiftModalOrderDetails } from 'types/graphql-types';

export const ShiftModalDataContext = createGQLDataContext({});

export const ShiftModalDataProvider: FC = ({ children }) => {
  const { shift_id } = useShiftModalUIContext();
  const value = useGQLQuery<
    shiftModalOrderDetails,
    shiftModalOrderDetails,
    { id: number | undefined }
  >(shiftModalDetailsQueryConfig(shift_id));

  useEffect(() => {
    if (typeof shift_id === 'number') value.fetch();
  }, [shift_id]); // eslint-disable-line

  return <ShiftModalDataContext.Provider value={value}>{children}</ShiftModalDataContext.Provider>;
};

export const useShiftModalDataContext = (): UseGQLQuery<shiftModalOrderDetails> =>
  useContext(ShiftModalDataContext);
