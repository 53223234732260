import { IShiftFormCustomField } from 'components/ShiftModal/src/shared/ShiftCustomFields/ShiftCustomFields.types';
import { SCHEDULING_PUBLISHED_SHIFT_DETAILS } from 'constants/endpoints';
import { METHOD_PUT } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

export type UpdateOrderDetailsParams = {
  description?: string;
  shift_id: number;
  notification_method?: string;
  reaccept_or_decline_threshold?: number;
  start_time?: string;
  end_time?: string;
  unpaid_break_time?: string;
  isOnlyNotesUpdated?: boolean;
  custom_fields?: IShiftFormCustomField[];
  allow_overtime?: boolean;
};

type UpdateOrderRequestBodyType = {
  order: {
    description?: string;
    notification_method?: string;
    reaccept_or_decline_threshold?: number;
    start_time?: string;
    end_time?: string;
    unpaid_break_time?: string;
    custom_fields?: IShiftFormCustomField[];
    allow_overtime?: boolean;
  };
};

export const API_PUT_updateOrderDetails = (
  params: UpdateOrderDetailsParams,
): ApiRequestConfigType => {
  const {
    description = '',
    shift_id,
    notification_method = '',
    reaccept_or_decline_threshold = 1,
    start_time,
    end_time,
    unpaid_break_time,
    isOnlyNotesUpdated = false,
    custom_fields,
    allow_overtime = false,
  } = params;

  const body: UpdateOrderRequestBodyType = {
    order: {
      description,
      notification_method,
      reaccept_or_decline_threshold,
      start_time,
      end_time,
      unpaid_break_time,
      custom_fields,
      allow_overtime,
    },
  };

  if (isOnlyNotesUpdated) {
    delete body.order.reaccept_or_decline_threshold;
    delete body.order.notification_method;
    delete body.order.start_time;
    delete body.order.end_time;
    delete body.order.unpaid_break_time;
  } else if (!notification_method) {
    delete body.order.reaccept_or_decline_threshold;
    body.order.notification_method = 'no_notification';
  } else if (notification_method !== 'reaccept_or_decline')
    delete body.order.reaccept_or_decline_threshold;

  return {
    __typename: 'LegacyAPI',
    url: SCHEDULING_PUBLISHED_SHIFT_DETAILS(shift_id.toString()),
    method: METHOD_PUT,
    body,
  };
};
