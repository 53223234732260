import styled from 'styled-components';
import { Caption } from 'components/Typography';
import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';

export const FlexWrapper = styled(Flex)`
  align-items: start;
  padding: 32px;
  flex-direction: column;
`;
export const ActivityContentWrapper = styled(Flex)`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GreyDivider};
  padding: 0 0 32px;
`;
export const TimeWrapper = styled(Caption)`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  width: 180px;
`;

export const ColorDot = styled.span<{ color: BKJThemeColorType }>`
  font-size: 1.5rem;
  height: 24px;
  display: flex;
  max-height: 24px;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, color }) => theme.colors[color]};
`;
export const TextWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
