import { BKJIcon, BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';

export const SendToAgenciesViewTitle = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 32px;
`;

export const SendToAgenciesText = styled.span`
  font-size: 14px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  line-height: 21px;
`;

export const TotalAvailableJanesText = styled(SendToAgenciesText)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
`;

export const SendToAgenciesBoldText = styled(SendToAgenciesText)`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
`;

export const SendToAgenciesInlineIcon = styled(BKJIcon)`
  margin-right: 6px;
`;

export const InfoSection = styled(Flex)`
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.colors.Grey1};
`;

export const RequestTeamMemberWrapper = styled(Flex)`
  overflow-y: overlay;
`;

export const EstimatedTotalPayoutFooterText = styled.div<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.TextDisabled};
    `}
`;

export const TextButtonWrapper = styled(BKJButton)`
  display: inline;
  width: fit-content;
  font-size: 14px;
  line-height: 22px;
  min-height: auto;
  max-height: auto;
  padding: 0 0;
`;
