import { InviteTeamMembersByCSVModalViewType } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.constants';
import { TeamMemberCSVView } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView';
import { TeamMemberEditRankingView } from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRankingView';
import { useURLParameters } from 'hooks';
import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { ModalRoutingService } from 'services';
import { UnSuccessView } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/UnSuccessView';
import { SuccessView } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/SuccessView';
import { CsvError } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.constants';
import { InviteTeamMemberView } from 'components/InviteTeamMembersByCSVModal/views/InviteTeamMemberView/InviteTeamMemberView';
const InviteTeamMembersByCSVModalComponentMap = {
  InviteByCSV: TeamMemberCSVView,
  EditRanking: TeamMemberEditRankingView,
  UnSuccessFul: UnSuccessView,
  SuccessFul: SuccessView,
  InviteTeamMemberView: InviteTeamMemberView,
};

export const inviteTeaMemberCSVModalRoutingService = new ModalRoutingService(
  'inviteTeamMemberCSV',
  'InviteByCSV',
  InviteTeamMembersByCSVModalComponentMap,
  {},
  ['InviteByCSV', 'EditRanking', 'SuccessFul'],
);

export interface IInviteTeamMembersByCSVModalUI {
  isOpen: boolean;
  locationId: number;
  onClose: () => void;
  inviteTeamMemberCSVActiveModalView: InviteTeamMembersByCSVModalViewType;
  goToViewError: ({ csvErrors }: { csvErrors?: CsvError[] }) => void;
  csvErrors: CsvError[];
}

export const InviteTeamMembersByCSVModalUIContext = createContext<IInviteTeamMembersByCSVModalUI>(
  {} as IInviteTeamMembersByCSVModalUI,
);

export const InviteTeamMembersByCSVModalUIProvider: FC = ({ children }) => {
  const [csvErrors, setCsvErrors] = useState<CsvError[]>([]);
  const [{ locationId, inviteTeamMemberCSVActiveModalView }, { removeParam }] =
    useURLParameters<{
      locationId: string;
      activeModalView: InviteTeamMembersByCSVModalViewType;
    }>();

  const goToViewError = useCallback((params) => {
    inviteTeaMemberCSVModalRoutingService.goToView('UnSuccessFul', params);
    setCsvErrors(params?.csvErrors);
  }, []);
  const handleClose = useCallback(() => {
    inviteTeaMemberCSVModalRoutingService.close();
  }, []);

  useEffect(() => {
    if (
      !Object.keys(InviteTeamMembersByCSVModalComponentMap).includes(
        inviteTeamMemberCSVActiveModalView,
      )
    ) {
      removeParam(['locationId', 'inviteTeamMemberCSVActiveModalView']);
    }
  }, [inviteTeamMemberCSVActiveModalView, removeParam, locationId]);
  return (
    <InviteTeamMembersByCSVModalUIContext.Provider
      value={{
        isOpen: !!inviteTeamMemberCSVActiveModalView,
        locationId: Number.parseInt(locationId),
        goToViewError,
        csvErrors,
        onClose: handleClose,
        inviteTeamMemberCSVActiveModalView:
          inviteTeamMemberCSVActiveModalView as InviteTeamMembersByCSVModalViewType,
      }}
    >
      {children}
    </InviteTeamMembersByCSVModalUIContext.Provider>
  );
};

export const useInviteTeamMembersByCSVModalUIContext = () =>
  useContext<IInviteTeamMembersByCSVModalUI>(InviteTeamMembersByCSVModalUIContext);
