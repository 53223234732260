import {
  defaultEndTime,
  defaultStartTime,
  endOfDay,
  ICustomFields,
  startOfDay,
  timeOffModalRoutingService,
  titleOptions,
} from 'components/TimeOffModal/TimeOffModal.constants';
import {
  VALIDATION_durationMin1Hr,
  VALIDATION_endDate,
  VALIDATION_without_msg,
} from 'components/TimeOffModal/TimeOffModalView.validation.constants';
import { updateTimeOffPayloadTransformer } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.constants';
import { useEditTimeOffViewDataContext } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.data.context';
import { IEditTimeOffViewForm } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.types';
import dayjs from 'utils/dayjs';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { API_PUT_updateTimeOff } from 'requests/PUT_updateTimeOff';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { timeOffModalFetchTimeOffById_fetchTimeOff_jane } from 'types/graphql-types';
import { getHoursMinsSecFromDate } from 'utils/time';
import { combineMsgs } from 'validations/index.utils';
import { useTranslation } from 'hooks/useTranslation';

export type EditTimeOffViewFormContextType = UseFormBehaviors<IEditTimeOffViewForm> & {
  jane: timeOffModalFetchTimeOffById_fetchTimeOff_jane;
};

export const EditTimeOffViewFormContext = createContext<EditTimeOffViewFormContextType>(
  {} as EditTimeOffViewFormContextType,
);

export const EditTimeOffViewFormProvider: FC = ({ children }) => {
  const {
    data: {
      fetchTimeOff: { displayId, customFields, endTime, jane, notes, startTime, title },
    },
    timeOffCustomFields,
  } = useEditTimeOffViewDataContext();

  // Filter out invalid time offs that have been deleted on the org level but still exist in the time off entity
  const validCustomFields = customFields.filter((cf) =>
    timeOffCustomFields.map((field) => field.id).includes(cf.customFieldId),
  );

  const { apiRequest } = useAPIActions();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (data) => {
      const {
        allDay,
        startDate,
        jane,
        endDate,
        startTime,
        endTime,
        title,
        notes,
        customFields,
        ...rest
      } = data;
      const payloadTransformer = updateTimeOffPayloadTransformer({
        allDay,
        startDate,
        endDate,
        startTime,
        endTime,
        title,
        notes,
        customFields: { ...rest },
        timeOffCustomFields,
      });
      const apiRequestConfig = API_PUT_updateTimeOff(payloadTransformer, displayId);
      await apiRequest(apiRequestConfig, {
        onSuccess: (res) => {
          toastService.success(t('success:TIME_OFF_EDITED'));
          window.scheduleUtils.forceDataUpdate();
          timeOffModalRoutingService.close();
        },
        onError: () => {
          toastService.error(t('FAILURE_TOAST_MESSAGE'));
        },
      });
    },
    [timeOffCustomFields, displayId, apiRequest, t],
  );

  const customEntries = validCustomFields.reduce(
    (acc: ICustomFields, cf: { name: string; value: string }) => {
      acc[cf.name] = cf.value;
      return acc;
    },
    {},
  );

  const startTimeHoursMinSec = getHoursMinsSecFromDate(startTime);
  const endTimeHoursMinSec = getHoursMinsSecFromDate(endTime);

  let modifiedStartTime = startTimeHoursMinSec;
  let modifiedEndTime = endTimeHoursMinSec;
  const allDay = startTimeHoursMinSec === startOfDay[0] && endTimeHoursMinSec === endOfDay[0];
  if (allDay) {
    modifiedStartTime = defaultStartTime;
    modifiedEndTime = defaultEndTime;
  }

  let modifiedEndDate = endTime;
  const isEndTimeInvalidDropdownOption = endTimeHoursMinSec === endOfDay[0];
  if (!allDay && isEndTimeInvalidDropdownOption)
    modifiedEndDate = dayjs(modifiedEndDate).add(1, 's').format();

  const initialState = {
    notes,
    title: [titleOptions.find((t) => t.key === title.name)?.value || ''],
    allDay,
    startDate: [startTime],
    startTime: [modifiedStartTime],
    endDate: [modifiedEndDate],
    endTime: allDay
      ? [modifiedEndTime]
      : isEndTimeInvalidDropdownOption
      ? startOfDay
      : [endTimeHoursMinSec],
    customFields: validCustomFields,
    ...customEntries,
  };

  const useFormBehaviorsOptions: UseFormBehaviors_Options<IEditTimeOffViewForm> = {
    initialState: initialState,
    onSubmit: handleSubmit,
    isDirtyCheckEnabled: true,
    type: 'EDIT',
    validations: {
      startDate: VALIDATION_without_msg,
      startTime: VALIDATION_without_msg,
      endDate: VALIDATION_endDate,
      endTime: combineMsgs(VALIDATION_durationMin1Hr, VALIDATION_without_msg),
    } as unknown as UseFormBehaviors_Options<IEditTimeOffViewForm>['validations'],
  };

  const form = useFormBehaviors<IEditTimeOffViewForm>(useFormBehaviorsOptions);
  const value = useMemo(
    () => ({
      ...form,
      jane,
    }),
    [form, jane],
  );

  return (
    <EditTimeOffViewFormContext.Provider value={value}>
      {children}
    </EditTimeOffViewFormContext.Provider>
  );
};

export const useEditTimeOffViewFormContext = (): EditTimeOffViewFormContextType => {
  return useContext(EditTimeOffViewFormContext);
};
