import { BKJSelect, IBKJThemeColor } from '@bookjane2/bookjane-design-library';
import { BKJDatePickerContext } from 'components/BKJDatePicker';
import { getPrettyDayAndDateFromDate } from 'components/BKJDatePicker/src/BKJDatePicker.utils';
import { BKJCalendar } from 'components/BKJDatePicker/src/components/BKJCalendar';
import {
  CalendarInputWrapper,
  RequiredAsterisk,
  ShiftDateCalendarInputDropdownWrapper,
} from 'components/ShiftDateCalendarInput/ShiftDateCalendarInput.styled';
import { normalizeOnChangeValue } from 'components/ShiftDateCalendarInput/ShiftDateCalendarInput.utils';
import { isString } from 'lodash';
import { BKJFormLabel } from 'pages/SettingsPage/SettingsPage.styled';
import { FC } from 'react';
import { isArray } from 'types/common.types';
import {
  IShiftDateCalendarInputProps,
  IShiftDateCalendarRenderGroupProps,
} from './ShiftDateCalendarInput.types';
import { useTranslation } from 'hooks/useTranslation';

const RenderGroup = ({
  name,
  selected,
  onChange,
  dropdownProps,
}: IShiftDateCalendarRenderGroupProps) => {
  const calendarValue = selected[0];
  return (
    <BKJDatePickerContext.Provider
      value={{
        ...dropdownProps,
        type: 'SingleDate',
        name,
        value: calendarValue,
        onChange: onChange,
      }}
    >
      <ShiftDateCalendarInputDropdownWrapper>
        <BKJCalendar context={BKJDatePickerContext} />
      </ShiftDateCalendarInputDropdownWrapper>
    </BKJDatePickerContext.Provider>
  );
};

export const ShiftDateCalendarInput: FC<IShiftDateCalendarInputProps> = ({
  name,
  className,
  label: _label,
  placeholder = 'create_shifts:SELECT_DATE',
  variant = 'GreyOutlined',
  width = '100%',
  dropdownWidth = '360px',
  value,
  onChange,
  iconComponent,
  iconProps,
  dropdownProps,
  error,
  required,
  isEventValueNormalized = false,
}) => {
  const { t } = useTranslation();
  let __value__: string[] = [];

  if (isString(value)) {
    __value__ = [value];
  }
  if (isArray(value)) {
    __value__ = value;
  }

  let label = getPrettyDayAndDateFromDate(__value__[0]);
  if (label === 'Invalid Date') label = t('create_shifts:SELECT_DATE');

  let placeholderColor: keyof IBKJThemeColor | undefined;

  if (label === placeholder) {
    placeholderColor = 'TextDisabled';
  }

  return (
    <CalendarInputWrapper className={className}>
      {_label && (
        <BKJFormLabel>
          {_label}
          {required && <RequiredAsterisk>&nbsp;*</RequiredAsterisk>}
        </BKJFormLabel>
      )}
      <BKJSelect
        required={required}
        error={error}
        label={label}
        placeholder={t(placeholder)}
        type="Single"
        options={[]}
        variant={variant}
        name={name}
        dropdownWidth={dropdownWidth}
        dropdownMinHeight="600px"
        dropdownPlacement="bottom-start"
        value={__value__}
        onChange={(event) =>
          isEventValueNormalized ? onChange(normalizeOnChangeValue(event)) : onChange(event)
        }
        renderOptions={RenderGroup}
        iconComponent={iconComponent}
        iconProps={iconProps}
        width={width}
        dropdownProps={dropdownProps}
        placeholderColor={placeholderColor}
      />
    </CalendarInputWrapper>
  );
};
