import { FC, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { IBookjaneLogoProps } from './BookJaneLogo.types';

export const BookJaneLogo: FC<IBookjaneLogoProps> = (props) => {
  const themeContext = useContext(ThemeContext);
  const { color = themeContext.colors.White, fontSize = 40, ...rest } = props;
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fontSize={fontSize}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.962 7.684a13.68 13.68 0 012.358 3.172c1.889 3.656 3.24 8.24 3.24 14.28 0 7.404-.67 12.2-2.105 15.092-1.625 3.3-4.059 3.7-5.996 3.7a26.819 26.819 0 01-3.718-.364 30.977 30.977 0 00-4.463-.42 29.892 29.892 0 00-4.544.456 25.965 25.965 0 01-3.77.4c-2.835 0-4.655-1.152-5.903-3.736C8.654 37.36 8 32.556 8 25.144c0-8.172 3.016-14.788 5.75-17.268l.053-.047C15.58 6.22 18.031 4 24.233 4c5.796 0 8.23 1.348 10.729 3.684zM17.532 35.34c2.615 2.656 5.427 4.088 6.746 4.088 2.78 0 11.341-6.252 11.341-13.892 0-.897-.05-1.793-.152-2.684-.072-.556-.173-1.028-.313-1.076-2.31-.8-5.266-1.924-7.483-3.496a31.913 31.913 0 01-4.664-4.104c-.137-.14-1.047-.976-1.468-.976-.158 0-.82.596-1.615 1.311-.94.846-2.067 1.86-2.768 2.257-3.706 2.096-4.215 4.192-4.215 8.768 0 4.576 2.88 8.064 4.592 9.804zm7.29-2.802c.254-.05.481-.094.635-.094.666 0 .928.298.997.553.069.255.028.673-.444 1.153a4.188 4.188 0 01-2.17.961 4.175 4.175 0 01-2.167-.96c-.472-.486-.516-.895-.447-1.154.068-.26.334-.553 1.004-.553.156 0 .386.045.64.095.316.062.672.131.974.131.303 0 .66-.07.978-.132zm7.056-8.686a6.714 6.714 0 00-1.855-.296 6.691 6.691 0 00-1.85.296c-.376.099-.673.426-.775.852-.103.426.007.881.286 1.186.279.305.68.41 1.047.273a5.217 5.217 0 011.292-.207c.434.012.865.081 1.285.207.36.117.747.005 1.015-.294.268-.3.377-.74.285-1.156-.092-.416-.37-.744-.73-.86zm-14.152-.296c.624.01 1.245.11 1.847.295h-.012c.359.117.636.444.727.859.091.414-.017.854-.284 1.152-.267.298-.652.41-1.011.293a4.895 4.895 0 00-1.28-.206 5.19 5.19 0 00-1.287.206c-.368.148-.778.047-1.06-.261a1.318 1.318 0 01-.284-1.202c.108-.43.417-.755.8-.84a6.692 6.692 0 011.844-.296z"
        fill={color}
      />
    </svg>
  );
};
