import React, { useCallback, useRef } from 'react';

import JaneEllipses from 'components/JaneModal/JaneMenu/JaneEllipses';
import { Menu, MenuList, MenuWrapper } from 'components/JaneModal/JaneMenu/styled';
import { useClickOutside } from 'hooks';

function JaneMenu({
  disabled,
  setShowDeactivateConfirmModal,
  accessStatusName,
  reactivateTeamMember,
}) {
  const [openMenu, setOpenMenu] = React.useState(false);
  const menuRef = useRef(null);
  const handleDropdownClose = useCallback(() => {
    if (openMenu) setOpenMenu(false);
  }, [openMenu]);
  // Handle clicks outside the menu
  useClickOutside([menuRef && menuRef.current], handleDropdownClose);

  function toggleMenu() {
    if (disabled) return;

    setOpenMenu(!openMenu);
  }
  const handleReactivationClick = () => {
    reactivateTeamMember();
    setOpenMenu(false);
  };
  return (
    <MenuWrapper ref={menuRef}>
      <JaneEllipses disabled={disabled} onClick={toggleMenu} />
      {openMenu && (
        <Menu>
          {accessStatusName === 'blocked' ? (
            <MenuList onClick={handleReactivationClick}>Re-active</MenuList>
          ) : (
            <MenuList onClick={() => setShowDeactivateConfirmModal(true)}>Deactivate</MenuList>
          )}
        </Menu>
      )}
    </MenuWrapper>
  );
}

export default JaneMenu;
