import { shiftDistributionRulesGroupsModalRoutingService } from 'components/ShiftDistributionRulesGroupsModal/ShiftDistributionRulesGroupsModal.ui.context';
import { SHIFT_DISTRIBUTION_RULES } from 'constants/endpoints';
import i18next from 'i18next';
import { FC, createContext, useCallback, useContext, useMemo } from 'react';
import { METHOD_DELETE } from 'requests';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
export interface IViewDetailsActions {
  onDelete: ({ shiftDistributionRuleId }: { shiftDistributionRuleId: string }) => void;
}
export const ViewDetailsActionsContext = createContext({} as IViewDetailsActions);

export const ViewDetailsActionsProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();

  const onDelete = useCallback(
    async ({ shiftDistributionRuleId }) =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_DELETE,
          url: SHIFT_DISTRIBUTION_RULES + `/${shiftDistributionRuleId}`,
        },
        {
          onSuccess: async () => {
            toastService.success(i18next.t('success:RULES_DELETED_SUCCESSFULLY'));
            shiftDistributionRulesGroupsModalRoutingService.goToView('GroupsRules');
          },
          onError: async () => {
            toastService.error(i18next.t('errors:RULES_DELETE_ERROR'));
          },
        },
      ),
    [apiRequest],
  );

  const value = useMemo(() => {
    return {
      onDelete,
    };
  }, [onDelete]);

  return (
    <ViewDetailsActionsContext.Provider value={value}>
      {children}
    </ViewDetailsActionsContext.Provider>
  );
};

export const useViewDetailsActionsContext = () => useContext(ViewDetailsActionsContext);
