import {
  BKJButton,
  BKJPhoneNumberInput,
  BKJTextInput,
  Flex,
} from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useInviteAdminModalFormContext } from 'components/InviteAdminModal/InviteAdminModal.form.context';
import { useInviteAdminModalUIContext } from 'components/InviteAdminModal/InviteAdminModal.ui.context';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';

export const NewAdmin: FC = () => {
  const { fieldErrors, onChange, values, isDisabled } = useInviteAdminModalFormContext();
  const { goToNextView } = useInviteAdminModalUIContext();
  const { t } = useTranslation();
  return (
    <Fragment>
      <SectionDivider margin="0 0 24px 0" variant="Thin" />
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        margin="20px 20px 20px 20px"
        height="100%"
        gap="20px"
      >
        <Flex alignItems="flex-start" gap="10px" width="100%">
          <BKJTextInput
            name="first_name"
            value={values.first_name}
            error={fieldErrors.first_name}
            onChange={onChange}
            required
            label={t('settings:FIRST_NAME')}
            placeholder={t('settings:FIRST_NAME')}
          />
          <BKJTextInput
            name="last_name"
            value={values.last_name}
            error={fieldErrors.last_name}
            required
            onChange={onChange}
            label={t('settings:LAST_NAME')}
            placeholder={t('settings:LAST_NAME')}
          />
        </Flex>
        <BKJTextInput
          name="email"
          value={values.email}
          error={fieldErrors.email}
          required
          type="email"
          onChange={onChange}
          label={t('settings:EMAIL')}
          placeholder={t('settings:EMAIL')}
        />

        <BKJPhoneNumberInput
          name="phone"
          value={values.phone}
          onChange={onChange}
          label={t('settings:PHONE_NUMBER')}
          placeholder={t('settings:PHONE_NUMBER')}
        />
        <Flex height="100%" />
      </Flex>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
        <AutoMargin />

        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={goToNextView}
          type="button"
          disabled={isDisabled}
        >
          {t('auth:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
