import { isEnvDevelopment } from 'config';

const BKJ_DEBUG_MODE = 'BKJDebugMode';
if (isEnvDevelopment) {
  window.toggleDebug = () => {
    const BKJDebugMode = !!localStorage.getItem(BKJ_DEBUG_MODE);
    if (BKJDebugMode) localStorage.removeItem(BKJ_DEBUG_MODE);
    else localStorage.setItem(BKJ_DEBUG_MODE, BKJDebugMode);
    window.location.reload();
  };
}

const isDebugModeEnabled = localStorage.getItem(BKJ_DEBUG_MODE);

export default isDebugModeEnabled;
