export const formatFloatToText = (float: number | null): string => {
  if (!float) return '';
  return String(float)
    .split('.')
    .map((str, i) => {
      if (i === 0) return `${str} hrs`;
      if (i === 1) {
        if (str.length === 1) return `${(60 * Number.parseInt(`${str}0`, 10)) / 100} mins`;
        return `${(60 * Number.parseInt(str, 10)) / 100} mins`;
      }
      return '';
    })
    .join(' ');
};
