import {
  BKJCheckboxListItem,
  BKJIcon,
  BKJIconTooltip,
  BKJImage,
} from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { Flex } from 'components/Flex';
import {
  JaneGridWrapper,
  JaneGridCard,
  JaneGridErrorText,
  JaneGridJaneName,
  JaneGridAgencyName,
  JaneGridSelectButton,
  JaneGridText,
  JaneConflictTooltipWrapper,
  JaneGridGreyText,
  JanePendingSignUp,
  StyledBKJIcon,
  PendingSignUpName,
} from 'components/JaneGridSelect/JaneGridSelect.styled';
import {
  IJaneGridCardComponentProps,
  IJaneGridSelectProps,
} from 'components/JaneGridSelect/JaneGridSelect.types';

import { FC, useCallback, useContext, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { DISPLAY_CONFLICT_DATE_LIMIT } from 'components/JaneGridSelect/JaneGridSelect.constant';
import { requestTeamMembersRecurring_fetchJanes_nodes } from 'types/graphql-types';
import { getPrettyDayAndDateFromDate } from 'components/BKJDatePicker/src/BKJDatePicker.utils';
import { UserGuard } from 'guards/UserGuard';

const ProfilePicLoadingFallbackComponent = () => <BKJIcon iconName="AvatarFill" />;

const JaneGridCardComponent: FC<IJaneGridCardComponentProps> = ({
  edgeCaseType = 'SendToAgencies',
  type = 'Single',
  max = 1,
  name,
  label,
  index,
  getData,
  id,
  value,
  onChange: _onChange,
}) => {
  const { t, i18n } = useTranslation();
  const data = useMemo(
    () => getData(index),
    [getData, index],
  ) as requestTeamMembersRecurring_fetchJanes_nodes;
  const rate = data.positions[0]?.agencyDetails?.openRate;

  const onChange: ChangeFunctionType = useCallback(
    (e: ChangeEventType) => {
      _onChange({
        target: {
          name: e.target.name,
          value: e.target.value,
        },
      });
    },
    [_onChange],
  );

  const conflictingDatesList = data.conflictingDays
    ? [...new Set(data.conflictingDays.map((day) => day.startTime))]
    : [];

  const disabled = !value.includes(id) && value.length === max ? true : false;

  return (
    <JaneGridCard tabIndex={0}>
      <Flex height="96px" width="100%">
        <BKJImage
          height="100%"
          width="100%"
          alt="Avatar"
          src={data.avatarUrls.origin || ''}
          variant="Rectangle"
          LoadingComponent={ProfilePicLoadingFallbackComponent}
          FallbackComponent={ProfilePicLoadingFallbackComponent}
        />
      </Flex>

      <Flex flexDirection="column" padding="8px" flex="1" width="100%">
        <JaneGridJaneName>{`${data.firstName} ${data.lastName}`}</JaneGridJaneName>
        <JaneGridAgencyName>{data.agencyCommunity?.name}</JaneGridAgencyName>
        {!!rate &&
          (() => {
            switch (edgeCaseType) {
              case 'CreateBulkShifts':
                return (
                  <UserGuard userType={['USER_TYPE_ESSENTIAL', 'USER_TYPE_ESSENTIAL_SECONDARY']}>
                    <JaneGridText>{rate}/hr</JaneGridText>
                  </UserGuard>
                );
              case 'SendToAgencies':
                return <JaneGridText>{rate}/hr</JaneGridText>;
              case 'AssignToTeamMember':
                return <Fragment />;
            }
          })()}

        {!!data.customerReviews.count ? (
          <Flex alignItems="center" margin="0 0 4px -4px">
            <BKJIcon iconName="Star" />
            <JaneGridText>
              {data.customerReviews.averageRating}
              &nbsp;
              <JaneGridGreyText>({data.customerReviews.count})</JaneGridGreyText>
            </JaneGridText>
          </Flex>
        ) : (
          <JaneGridAgencyName>{t('agency_dashboard:No_Reviews')}</JaneGridAgencyName>
        )}
      </Flex>
      {data?.pendingSignup && (
        <JanePendingSignUp>
          <StyledBKJIcon iconName="PendingSignup" left="6" top="4" />
          <PendingSignUpName isFrenchFormat={i18n.language === 'fr'}>
            {t('common:PENDING_SIGNUP')}
          </PendingSignUpName>
        </JanePendingSignUp>
      )}

      <JaneGridSelectButton
        alignItems="center"
        height="44px"
        minHeight="44px"
        maxHeight="44px"
        width="100%"
        minWidth="100%"
        padding="0 10px"
      >
        {type === 'Single' && (
          <BKJRadioInput
            {...{
              name,
              value: id,
              checked: value.includes(id),
              onChange,
              label,
              width: '100%',
            }}
          />
        )}
        {type === 'Multiple' && (
          <BKJCheckboxListItem
            {...{
              label,
              selected: value,
              value: id,
              name,
              disabled,
              onChange,
            }}
          />
        )}
        {conflictingDatesList.length > 0 ? (
          <BKJIconTooltip iconName="Error" placement="top">
            <JaneConflictTooltipWrapper flexDirection="column" gap="20px">
              <div>This agency member is unavailable on the following dates:</div>
              <ul>
                {conflictingDatesList.slice(0, 5).map((startTime) => (
                  <li key={startTime}>{getPrettyDayAndDateFromDate(startTime)}</li>
                ))}
                {conflictingDatesList.length - DISPLAY_CONFLICT_DATE_LIMIT > 0 ? (
                  <li>
                    +{conflictingDatesList.length - DISPLAY_CONFLICT_DATE_LIMIT} more conflicts
                  </li>
                ) : null}
              </ul>
              <div>
                If you decide to request this agency member, shifts they are unavailable for will be
                sent out as Open Shifts.
              </div>
            </JaneConflictTooltipWrapper>
          </BKJIconTooltip>
        ) : null}
      </JaneGridSelectButton>
    </JaneGridCard>
  );
};
export const JaneGridSelect: FC<IJaneGridSelectProps> = ({
  edgeCaseType = 'SendToAgencies',
  loadMore,
  paginationType = 'Normal',
  type = 'Single',
  label = 'create_shifts:REQUEST',
  max = 1,
  context,
  name,
  value,
  onChange,
  dataPath,
  pageInfoDataPath = null,
  errorText = 'create_shifts:NO_TEAM_MEMBERS_AVAILABLE',
}) => {
  const { t } = useTranslation();
  const { data, locationName } = useContext(context);

  const janes = get(data, dataPath, []);
  const pageInfo = pageInfoDataPath && get(data, pageInfoDataPath, {});
  const getData = useCallback(
    (index) => {
      return { ...janes[index], locationName };
    },
    [janes, locationName],
  );

  if (janes.length === 0)
    return (
      <Flex
        width="100%"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <JaneGridErrorText>{t(errorText)}</JaneGridErrorText>
      </Flex>
    );

  return (
    <JaneGridWrapper flexWrap="wrap" width="100%">
      {janes.map((jane: { id: string }, index: number) => {
        return (
          <JaneGridCardComponent
            edgeCaseType={edgeCaseType}
            max={max}
            type={type}
            getData={getData}
            id={jane.id}
            index={index}
            label={t(label)}
            key={jane.id}
            name={name}
            value={value}
            onChange={onChange}
          />
        );
      })}
      {paginationType === 'InfiniteScroll' && loadMore && !!pageInfo.hasNextPage ? (
        <Flex justifyContent="center" alignItems="center" width="100%" padding="24px 0px 0px 0px">
          <InfiniteScrollLoading onViewportEnter={loadMore!} />
        </Flex>
      ) : null}
    </JaneGridWrapper>
  );
};
