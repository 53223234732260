import { BKJComboBox } from '@bookjane2/bookjane-design-library';
import { FC } from 'react';
import dayjs from 'utils/dayjs';
import { shiftTimeDropdownOptions } from './ShiftTimeSelectInput.constants';
import { IShiftTimeSelectInputProps } from './ShiftTimeSelectInput.types';

export const ShiftTimeSelectInput: FC<IShiftTimeSelectInputProps> = ({
  name,
  placeholder,
  value,
  onChange,
  label,
  error,
}) => {
  const formattedTime = [dayjs(value[0], ['HH:mm:ss', 'HH:mm']).format('HH:mm:ss')];

  return (
    <BKJComboBox
      error={error}
      label={label}
      placeholder={placeholder}
      name={name}
      value={formattedTime}
      onChange={onChange}
      options={shiftTimeDropdownOptions}
      type="Single"
      action="OpenToggle"
      filter="TimeStartsWith"
    />
  );
};
