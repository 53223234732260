import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { QUERY_customFieldsSettingsFetchCustomFields } from 'queries';
import { customFieldsSettingsFetchCustomFields } from 'types/graphql-types';
import dayjs from 'dayjs';
import {
  CustomFieldTypeLabelMap,
  DATE_FORMAT,
} from 'pages/SettingsPage/CustomFieldsSettingsPage/CustomFieldsSettings.constants';
import { ICustomField } from 'pages/SettingsPage/CustomFieldsSettingsPage/CustomFieldsSettings.types';

export function fetchCustomFieldsQueryConfig(): ISSFBehaviorsOptions {
  return {
    key: 'CUSTOMFIELDS_SETTINGS_PAGE_FETCH_CUSTOMFIELDS',
    query: QUERY_customFieldsSettingsFetchCustomFields,
    schema: {},
    responseTransformer: (data: customFieldsSettingsFetchCustomFields): ICustomField[] =>
      data?.fetchCustomFields.nodes.map((customField) => ({
        id: customField.id,
        name: customField.name,
        mobile: customField.mobile,
        type: CustomFieldTypeLabelMap[customField.fieldType],
        allFacilities: customField.allFacilities,
        facilities: (customField.communities || []).map((community) => community.name),
        lastEdited: {
          date: dayjs(customField.lastEditedAt).format(DATE_FORMAT),
          by: 'TODO: pending lastEditedBy meta data',
        },
      })) || [],
  };
}
