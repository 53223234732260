import styled from 'styled-components';

export const PurpleText = styled.span`
  color: ${({ theme }) => theme.colors.PrimaryDefault};
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  ${({ theme }) => theme.fonts.PoppinsBold};
`;

export const MessageText = styled.span`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  width: 450px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
`;
