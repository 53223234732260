import { IBKJThemeColor } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';
import { zIndexLayers } from 'constants/zIndexLayers';
import { motion } from 'framer-motion';

export const BKJSideModalBackdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${zIndexLayers.sideModalBackdrop};
  will-change: opacity;
`;

export const BKJSideModalDrawer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 556px;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: white;
  z-index: ${zIndexLayers.sideModalDrawer};
  display: flex;
  flex-direction: column;
  max-width: 556px;
  will-change: transform;
`;

export const BKJSideModalHeadingWrapper = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 20px;
  line-height: 28px;
  position: relative;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BKJSideModalCloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  margin: 0 24px;
`;
export const BKJSideModalBackButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  height: 100%;
  margin: 0 24px;
`;

export const BKJSideModalDrawerContent = styled.div<{
  bgColor?: keyof IBKJThemeColor;
  hasPadding?: boolean;
}>`
  background-color: ${({ bgColor, theme }) => bgColor && theme.colors[bgColor]};

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  overflow-y: overlay;

  ${({ hasPadding }) =>
    hasPadding &&
    css`
      padding: 32px 32px 30px;
    `};
`;
