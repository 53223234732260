import { ActivityEventEnum } from './../types/graphql-types';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
import dayjs from 'utils/dayjs';
import {
  OrderStatusEnum,
  shiftModalOrderDetails,
  shiftModalOrderDetails_fetchOrder_location,
} from 'types/graphql-types';
import { formatFloatToText, formatHourToFloat } from 'utils';

export function getShiftModalShiftJaneName(data: shiftModalOrderDetails) {
  if (!data?.fetchOrder?.mainJane) return null;
  return `${data?.fetchOrder?.mainJane?.firstName} ${data?.fetchOrder?.mainJane?.lastName}`;
}

export function getShiftModalShiftDate(data: shiftModalOrderDetails) {
  const { dayOfWeek, month } = getLocalizedDateFormat(data?.fetchOrder?.startAt);

  return `${dayOfWeek}, ${month}, ${dayjs(data?.fetchOrder?.startAt).format('DD, YYYY')}`;
}

export function getShiftModalShiftTimeRange(data: shiftModalOrderDetails) {
  const timeFormat = 'hh:mm a';
  return `${dayjs(data?.fetchOrder?.startAt).format(timeFormat)} - ${dayjs(
    data?.fetchOrder?.endAt,
  ).format(timeFormat)}`;
}

export function getShiftModalShiftTotalDuration(data: shiftModalOrderDetails) {
  if (!data?.fetchOrder?.totalDuration || !data?.fetchOrder?.orderSchedule?.breakTime)
    throw new Error('totalDuration and breakTime cannot be null');
  return `${formatFloatToText(
    data?.fetchOrder?.totalDuration + formatHourToFloat(data?.fetchOrder?.orderSchedule?.breakTime),
  )}`;
}

export function getShiftModalScheduleId(data: shiftModalOrderDetails) {
  if (!data?.fetchOrder?.orderSchedule?.id) throw new Error('Schedule id cannot be null');
  return data?.fetchOrder?.orderSchedule?.id;
}

export function getCancellationReason(data: shiftModalOrderDetails) {
  if (data.fetchOrder?.status.value !== OrderStatusEnum.cancelled) return '';
  return data.fetchOrder.activities.filter(
    (activity) => activity.event.name === ActivityEventEnum.cancelled,
  )[0].message;
}

export const formatLocation = (location: shiftModalOrderDetails_fetchOrder_location) => {
  const { streetNumber, street, city, state, zipCode } = location;
  return streetNumber + ' ' + street + ' ' + city + ' ' + state + ' ' + zipCode;
};
