import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import compact from 'lodash/compact';
import {
  DashboardPageAvailableShiftsPayload,
  DashboardPageAvailableShiftsPayloadTransformer as DPUSPT,
} from 'pages/DashboardAvailableShiftsPage/DashboardAvailableShiftsPage.types';
import { authLink, createApolloClient, httpLink } from 'providers/ApolloProvider';
import { QUERY_dashboardAvailableShiftsPage } from 'queries';
import { SessionService } from 'services';
import { isOrderStatusInProgress, isOrderStatusUnfulfilled } from 'types/common.types';
import {
  dashboardAvailableShiftsPage,
  dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders,
  OrderSortableFieldsEnum,
  SortDirectionEnum,
} from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { isDateTimeBeforeNow } from 'utils/time';

const dashboardAvailableShiftsPagePayloadTransformer: DPUSPT = (values) => {
  const { isUserAgencyAndAgencyLocationFilterForcedDisabled, ...filterValues } = values;

  const variables: DashboardPageAvailableShiftsPayload = {
    currentPage: filterValues.currentPage,
    first: filterValues.first,
    last: filterValues.last,
    after: filterValues.after,
    before: filterValues.before,
    filter: {
      forOrders: {
        withCommunity: undefined,
        withStatus: filterValues.shiftStatus,
        withPositions: filterValues.position_id,
        withJaneType: [...filterValues.internalJaneType, ...filterValues.externalJaneType],
        startBetweenTimeRange: {
          from: dayjs().startOf('day').format(),
          to: dayjs().add(1, 'months').format(),
        },
        withinBounds: undefined,
      },
      forJanes: {
        byEmploymentStatus: filterValues.employmentStatus,
        byFullName: filterValues.byFullName,
      },
      forAgencyCommunities: {
        withId: undefined,
      },
    },
    sortOrders: [
      {
        field: OrderSortableFieldsEnum.startAt,
        direction: SortDirectionEnum.asc,
      },
      {
        field: OrderSortableFieldsEnum.positionName,
        direction: SortDirectionEnum.asc,
      },
    ],
  };
  if (!filterValues?.viewAllFacilities) {
    if (SessionService.assertUserType('B2B'))
      variables.filter.forOrders['withCommunity'] = Number(filterValues.location.value);

    if (
      SessionService.assertUserType('Agency') &&
      !isUserAgencyAndAgencyLocationFilterForcedDisabled
    )
      variables.filter.forAgencyCommunities['withId'] = Number(filterValues.location.value);
  }

  if (values.withinBounds) {
    variables.filter.forOrders.withinBounds = {
      northEast: values.withinBounds.northEast,
      southWest: values.withinBounds.southWest,
    };
  }

  return variables;
};

export function dashboardAvailableShiftsPageResponseTransformer(
  data: dashboardAvailableShiftsPage,
): Nullable<dashboardAvailableShiftsPage> {
  if (!data) return null;
  const newData: dashboardAvailableShiftsPage = JSON.parse(JSON.stringify(data));

  if (!newData) return null;
  if (!newData?.fastGroupOrders) return null;
  if (!newData?.fastGroupOrders?.nodes) return null;

  const inProgressData: dashboardAvailableShiftsPage = newData;
  const notInProgressData: dashboardAvailableShiftsPage = JSON.parse(JSON.stringify(data));

  if (!!inProgressData?.fastGroupOrders?.nodes && inProgressData?.fastGroupOrders?.nodes.length)
    inProgressData?.fastGroupOrders?.nodes.forEach((node) => {
      if (node) {
        const isToday = dayjs(node.group).isSame(dayjs(), 'day');
        if (isToday) {
          const unfulfilledOrders =
            [] as dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders[];
          node?.orders?.forEach(
            (
              record: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders,
              index2: number,
              arr2: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders[],
            ) => {
              if (
                !isOrderStatusInProgress(record.status.value) &&
                isDateTimeBeforeNow(record.endAt)
              ) {
                delete arr2[index2];
              }

              if (isOrderStatusUnfulfilled(record.status.value)) {
                unfulfilledOrders.push(record);
                delete arr2[index2];
              }
            },
          );

          node.orders = [...node.orders, ...unfulfilledOrders];
        }
      }
    });

  if (
    !!notInProgressData?.fastGroupOrders?.nodes &&
    notInProgressData?.fastGroupOrders?.nodes.length
  )
    notInProgressData?.fastGroupOrders?.nodes.forEach((node) => {
      if (node) {
        node?.orders?.forEach(
          (
            record: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders,
            index2: number,
            arr2: dashboardAvailableShiftsPage_fastGroupOrders_nodes_orders[],
          ) => {
            if (isDateTimeBeforeNow(record.endAt) || isOrderStatusInProgress(record.status.value)) {
              delete arr2[index2];
            }
          },
        );
      }
    });

  let result = {
    ...notInProgressData,
    ...inProgressData,
  };
  let filtered = {
    ...result,
    fastGroupOrders: {
      ...result.fastGroupOrders,
      nodes: result.fastGroupOrders.nodes
        .filter((record) => record.orders.length !== 0)
        .map((record) => {
          const { orders: _orders } = record;

          return { ...record, orders: compact(_orders) };
        }),
    },
  } as dashboardAvailableShiftsPage;

  (filtered.fastGroupOrders.nodes || []).forEach((record, index, arr) => {
    if (record.orders.length === 0) {
      delete arr[index];
    }
  });
  if (filtered.fastGroupOrders.nodes)
    filtered.fastGroupOrders.nodes = filtered.fastGroupOrders.nodes.filter(Boolean);
  return filtered;
}

const client = createApolloClient();

export const dashboardAvailableShiftsPageConfig = ({
  location,
  isUserAgencyAndAgencyLocationFilterForcedDisabled,
}: {
  location: ILocationFilterProps['value'];
  isUserAgencyAndAgencyLocationFilterForcedDisabled: boolean;
}): ISSFBehaviorsOptions<
  dashboardAvailableShiftsPage,
  ReturnType<typeof dashboardAvailableShiftsPageResponseTransformer>
> => {
  client.setLink(authLink.concat(httpLink()));
  return {
    key: 'DASHBOARD_AVAILABLE-SHIFTS_PAGE',
    paginationType: 'InfiniteScroll',
    query: QUERY_dashboardAvailableShiftsPage,
    client,
    responseTransformer: dashboardAvailableShiftsPageResponseTransformer,
    nextFetchPolicy: 'network-only',
    pageSize: 20,
    payloadTransformer: (values) =>
      dashboardAvailableShiftsPagePayloadTransformer({
        ...values,
        isUserAgencyAndAgencyLocationFilterForcedDisabled,
      }),
    schema: {
      position_id: {
        initialValue: [],
        isSessionCached: true,
      },
      shiftStatus: {
        initialValue: ['pending'],
        isSessionCached: true,
      },
      location: {
        initialValue: location,

        isGlobalCached: true,
        isPreservedOnReset: true,
        resetFieldsOnChange: [
          {
            field: 'position_id',
            userTypes: ['Agency'],
          },
        ],
      },
      withinBounds: {
        initialValue: undefined,
        isSessionCached: false,
        isPreservedOnReset: false,
      },
      employmentStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      byFullName: {
        initialValue: '',
        isSessionCached: true,
      },
      internalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      externalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      viewAllFacilities: {
        initialValue: false,
        isGlobalCached: true,
        isSessionCached: true,
        isPreservedOnReset: true,
      },
    },
  };
};
