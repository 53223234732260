import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { METHOD_PATCH } from 'requests';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { B2B_JANES, AGENCY_JANES } from 'constants/endpoints';
import { janeModalJaneDetails_fetchJane_secondaryPositions } from 'types/graphql-types';
import { useInviteTeamMembersByCSVModalDataContext } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.data.context';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import { SessionService, toastService } from 'services';
import {
  AdditionalPosition,
  ComboBoxOption,
  DestroyedAndAddPositionArrays,
  DestroyedPosition,
  VALIDATION_position_id,
  VALIDATION_required,
  convertEmploymentStatus,
  monthNameToNumber,
  monthNumberToName,
  monthToDaysMap,
} from 'components/JaneModal/views/EditJaneView/EditJaneView.constants';
import { janeModalRoutingService } from 'components/JaneModal/JaneModal.constants';
import { TeamMemberPageContext } from 'pages/TeamMemberPage/TeamMemberPage.context';
import { useFetchJaneByIdDataContext } from 'components/JaneModal/FetchJaneById.data.context';

export interface IEditJaneViewForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isMobile: boolean;
  locale: string;
  position_id: string;
  community_id: string;
  location: IBKJComboBoxAsyncOption;
  secondaryPositionsIds: string[];
  secondaryPositions: janeModalJaneDetails_fetchJane_secondaryPositions[];
  createdAt: string;
  employmentStatus: string;
  employeeId: string;
  hourlyRate: number | null;
  birthMonth: string;
  birthDay: string;
}

export interface IEditJaneViewFormActions {
  setSelectMonth: (month: string) => void;
  setSelectDay: (day: string) => void;
  dayOptions: ComboBoxOption[];
}
export const EditJaneViewFormContext = createContext(
  {} as UseFormBehaviors<IEditJaneViewForm> & IEditJaneViewFormActions,
);

export const EditJaneViewFormProvider: FC = ({ children }) => {
  const { data, refetch } = useFetchJaneByIdDataContext();
  const { location } = useInviteTeamMembersByCSVModalDataContext();
  const { refetch: refetchData }: { refetch: () => void } = useContext(TeamMemberPageContext);
  const { apiRequest } = useAPIActions();
  const monthName = monthNumberToName[Number(data?.birthMonth)];
  const [selectMonth, setSelectMonth] = useState<string>(monthName ? monthName : '');
  const [selectDay, setSelectDay] = useState<string>(data?.birthDay ? `${data?.birthDay}` : '');
  const [dayOptions, setDayOptions] = useState<ComboBoxOption[]>([]);
  useEffect(() => {
    setSelectDay('');
  }, [selectMonth]);

  const memoizedRefetchData = useMemo(() => {
    return () => {
      refetchData();
    };
  }, [refetchData]);
  useMemo(() => {
    if (selectMonth) {
      // Convert month names to the expected structure with unique 'id'
      const options: ComboBoxOption[] = monthToDaysMap[selectMonth].map((day) => ({
        key: `${selectMonth}_${day}`,
        id: `${selectMonth}_${day}`, // Generate a unique 'id' for each day within the selected month
        value: day,
        label: day,
      }));
      setDayOptions(options);
    } else {
      setDayOptions([]);
    }
  }, [selectMonth]);

  const handleSave = useCallback(
    async ({
      firstName,
      lastName,
      email,
      phone,
      isMobile,
      locale,
      location,
      employmentStatus,
      position_id,
      birthDay,
      birthMonth,
      hourlyRate,
      employeeId,
      secondaryPositionsIds,
    }: IEditJaneViewForm) => {
      const employmentStatusValue = convertEmploymentStatus(employmentStatus[0]);
      const derivedBirthMonth = monthNameToNumber[birthMonth[0]];
      const foundPrimaryPosition = data?.positions.find(
        (position: { id: number; type: string; details: { id: number } }) =>
          position.details.id === data?.primaryPosition?.details.id && position.type === 'primary',
      );
      let handlePrimaryPosition = String(data?.primaryPosition?.details?.id) !== position_id && [
        {
          id: foundPrimaryPosition?.id,
          position_id: foundPrimaryPosition?.details?.id,
          position_type: foundPrimaryPosition?.type,
          _destroy: 1,
        },
        {
          position_id: position_id[0],
          position_type: 'primary',
        },
      ];

      let deleteSecondaryPositions: DestroyedPosition[] = data?.positions.map(
        (position: { id: number; type: string; details: { id: number } }) => ({
          id: position.id,
          position_type: position.type ? position.type : 'additional',
          position_id: position.details?.id || position.id,
          _destroy: data?.secondaryPositions.some((item: { id: number }) => item.id === position.id)
            ? 1
            : 0,
        }),
      );

      let addSecondaryPositions: AdditionalPosition[] = secondaryPositionsIds
        ? secondaryPositionsIds.map((secondaryPositionId) => ({
            position_type: 'additional',
            position_id: secondaryPositionId,
          }))
        : [];
      const handleSecondaryPositions: DestroyedAndAddPositionArrays[] = [
        ...addSecondaryPositions,
        ...deleteSecondaryPositions,
      ];

      return await apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_PATCH,
          url: SessionService.assertUserType('Agency')
            ? AGENCY_JANES(data?.id)
            : B2B_JANES(data?.id),
          body: {
            jane: {
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              locale: locale,
              is_mobile: isMobile,
              employment_status: employmentStatusValue,
              primary_community_id: location?.id,
              birth_day: birthDay[0],
              birth_month: derivedBirthMonth,
              hourly_rate: hourlyRate,
              employee_number: employeeId,
              employee_positions_attributes: handlePrimaryPosition || handleSecondaryPositions,
            },
          },
        },
        {
          onSuccess: async () => {
            if (refetch) refetch();
            memoizedRefetchData();
            toastService.success('Profile successfully saved');
            janeModalRoutingService.goToPrevView();
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: async (e: any) => {
            toastService.error(JSON.stringify(e?.data));
          },
        },
      );
    },
    [
      apiRequest,
      data?.id,
      data?.positions,
      data?.primaryPosition?.details.id,
      data?.secondaryPositions,
      memoizedRefetchData,
      refetch,
    ],
  );
  const form = useFormBehaviors<IEditJaneViewForm>({
    initialState: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone || '',
      isMobile: data?.isMobile,
      locale: data?.locale,
      position_id: `${data?.primaryPosition?.details?.id || ''}`,
      community_id: data?.primaryCommunity?.id || '',
      location: location,
      secondaryPositionsIds:
        data?.secondaryPositions?.map(
          (item: { details: { id: number } }) => `${item.details.id}`,
        ) || [],
      secondaryPositions: data?.secondaryPositions,
      createdAt: data?.createdAt,
      employmentStatus: data?.employmentStatus?.name,
      employeeId: data?.employeeNumber || '',
      hourlyRate: data?.hourlyRate,
      birthMonth: `${selectMonth}`,
      birthDay: `${selectDay}`,
    },
    onSubmit: handleSave,
    validations: {
      firstName: VALIDATION_required,
      lastName: VALIDATION_required,
      email: VALIDATION_required,
      phone: VALIDATION_required,
      position_id: VALIDATION_position_id,
      employmentStatus: VALIDATION_required,
      employeeId: VALIDATION_required,
      createdAt: VALIDATION_required,
      birthMonth: VALIDATION_required,
      birthDay: VALIDATION_required,
    },
  });

  const value = useMemo(() => {
    return {
      ...form,
      isDisabled:
        form.isDisabled ||
        !form.values.firstName ||
        !form.values.lastName ||
        !form.values.phone ||
        !form.values.position_id ||
        !form.values.employmentStatus ||
        !form.values.employeeId ||
        !form.values.createdAt,

      setSelectMonth,
      dayOptions,
      setSelectDay,
    };
  }, [dayOptions, form]);

  return (
    <EditJaneViewFormContext.Provider value={value}>{children}</EditJaneViewFormContext.Provider>
  );
};

export const useEditJaneViewFormContext = () => useContext(EditJaneViewFormContext);
