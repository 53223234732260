import { SSFBarsProvider } from 'components/SSFBars/SSFBars.context';
import { RESEND_TEAM_MEMBERS_INVITES } from 'constants/endpoints';
import { useSSFBehaviors } from 'hooks';
import { teamMemberPageConfig } from 'pages/TeamMemberPage/TeamMemberPage.constants';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { createContext, FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { METHOD_POST } from 'requests';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { GQLAPIContextType, pageContextInitialState } from 'types/common.types';

export const TeamMemberPageContext = createContext<GQLAPIContextType>(
  pageContextInitialState,
) as GQLAPIContextType;

export const TeamMemberPageProvider: FC = ({ children }) => {
  const { locationFilter } = useAuthRouteDataContext();
  const { apiRequest } = useAPIActions();
  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [values, opts] = useSSFBehaviors(
    teamMemberPageConfig({
      location: locationFilter?.initialValue,
      isUserAgencyAndAgencyLocationFilterForcedDisabled,
    }),
  );

  const resendInviteTeamMembers = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_POST,
          url: RESEND_TEAM_MEMBERS_INVITES(values?.location?.id),
        },
        {
          onSuccess: () => {
            toastService.success('Invite has successfully been re-sent!');
          },
          onError: (error) => {
            if (error && error?.data && error?.data?.message)
              toastService.error(`${error.data.message}`);
            else toastService.error('Something went wrong.');
          },
        },
      ),
    [apiRequest, values?.location?.id],
  );

  const value = { values, resendInviteTeamMembers, ...opts };
  return (
    <TeamMemberPageContext.Provider value={value}>
      <SSFBarsProvider>{children}</SSFBarsProvider>
    </TeamMemberPageContext.Provider>
  );
};
