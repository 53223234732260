import styled from 'styled-components';
import { BKJAvatar } from '@bookjane2/bookjane-design-library';

export const TeamMemberToolTip = styled.div`
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30px;
  border-radius: 50%;
  width: 30px;
  cursor: pointer;
  position: absolute;
  &:hover {
    cursor: pointer;
  }

  &:hover::after {
    ${({ theme }) => theme.typography.Caption};
    content: 'Pending mobile app sign up';
    white-space: nowrap;
    word-break: keep-all;
    background-color: ${({ theme }) => theme.colors.Grey7};
    display: flex;
    position: absolute;
    color: white;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 45px 8px 16px;
    border-radius: 8px;
    top: 34px;
    left: 7px;
    pointer-events: none;
    cursor: pointer;
  }
  &:hover::before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.colors.Grey7};
    display: flex;
    height: 20px;
    width: 12px;
    transform: rotate(45deg);
    left: 25px;
    top: 28px;
    cursor: default;
    pointer-events: none;
    cursor: pointer;
  }
`;

export const TeamMemberPageAvatar = styled(BKJAvatar)<{ accessStatusName?: string }>`
  opacity: ${({ accessStatusName }) => accessStatusName === 'blocked' && '50'}%;
`;
