import dayjs from 'dayjs';
import {
  IDraftShiftAssignedResponse,
  IMappedShiftResponse,
  IMappedSchedules,
  IJaneSchedule,
} from 'mappers/scheduling/schedule.rest.types';

const TYPE_DRAFT_ASSIGNED = 'assigned';
export const TYPE_DRAFT_OTHER_POSITION = 'other_position';

function GET_draftShiftsAssignedMapper(
  apiResponseData: IDraftShiftAssignedResponse = {
    jane_schedules: [],
    jane_id: '',
  },
) {
  const { jane_schedules } = apiResponseData;
  const totals = {
    statuses: {
      open: 0,
      pending: 0,
      assigned: 0,
      in_progress: 0,
      accepted: 0,
      completed: 0,
      unfulfilled: 0,
      cancelled: 0,
    },
    sent_to_agencies: 0,
  };
  const data = jane_schedules.reduce((acc, { jane_id, day_schedules, conflicts, warnings }) => {
    let conflictsByTimePeriod: string[] = [];
    let warningsByTimePeriod: string[] = [];
    let numShifts = 0;
    const schedules: IMappedSchedules | IJaneSchedule = {
      ...acc,
      [jane_id]: day_schedules.reduce(
        (_acc, { date, shifts, conflicts: _conflicts, warnings: _warnings }) => {
          conflictsByTimePeriod = conflictsByTimePeriod.concat(_conflicts);
          warningsByTimePeriod = warningsByTimePeriod.concat(_warnings);

          const shiftsByJane = {
            ..._acc,
            [dayjs(date).startOf('day').format()]: shifts.reduce(
              (__acc, shift) => {
                const val = {
                  ...__acc,
                  shifts: {
                    ...__acc.shifts,
                    [shift.id]: { ...shift, _type: TYPE_DRAFT_ASSIGNED },
                  },
                  shifts_ids: [...__acc.shifts_ids, shift.id],
                };
                if (shift.sent_to_agencies) totals.sent_to_agencies += 1;
                totals.statuses[shift.status] += 1;
                numShifts += 1;
                return val;
              },
              {
                shifts: {},
                shifts_ids: [],
                conflicts: _conflicts,
                warnings: _warnings,
              } as IMappedShiftResponse,
            ),
          };
          return { ...shiftsByJane };
        },
        {},
      ),
    };
    schedules[jane_id].hasConflicts = !![...new Set(conflictsByTimePeriod), ...conflicts].length;
    schedules[jane_id].hasWarnings = !![...new Set(warningsByTimePeriod), ...warnings].length;
    schedules[jane_id].conflicts = conflicts;
    schedules[jane_id].warnings = warnings;
    schedules[jane_id].numShifts = numShifts;
    return schedules;
  }, {});
  return { ...data, totals };
}

export default GET_draftShiftsAssignedMapper;
