import { Fragment, FC } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Rating } from 'semantic-ui-react';
import { Flex } from 'components/Flex';
import {
  BKJBadge,
  BKJButton,
  BKJLoadingSpinner,
  BKJThemeColorType,
} from '@bookjane2/bookjane-design-library';
import {
  getLanguageString,
  humanReadableDate,
  janeModalRoutingService,
} from 'components/JaneModal/JaneModal.constants';
import {
  JaneName,
  Text,
  H4,
  ValueText,
  LabelText,
  GeneralJaneWrapper,
  JaneAvatarWrapper,
  ColorDot,
  ContactJaneWrapper,
  MobileAppJaneWrapper,
  LoadingWrapper,
  PendingSignUpText,
  NonApUserTooltip,
  NonAppUserWrapper,
  EllipsisText,
  FieldWrapper,
  SectionWrapper,
  HeadSection,
} from 'components/JaneModal/views/JaneDetailsView/GeneralTab/GeneralTab.styled';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import JaneMenu from 'components/JaneModal/JaneMenu';
import { useFetchJaneByIdDataContext } from 'components/JaneModal/FetchJaneById.data.context';
import { janeModalJaneDetails_fetchJane } from 'types/graphql-types';
import { startCase } from 'lodash';
import { maskStringToPhoneString } from 'utils';
import { TeamMemberJaneAvatar } from 'components/TeamMemberJaneAvatar';
import { useTranslation } from 'react-i18next';

export const shiftModalGeneralTabValueTextMarginLeft = 16;
export const GeneralTab: FC = () => {
  const { t } = useTranslation();
  const {
    data,
    dotColor,
    showDeactivateConfirmModal,
    setShowDeactivateConfirmModal,
    deactivateTeamMember,
    reactivateTeamMember,
    resendInviteTeamMember,
  }: {
    data: janeModalJaneDetails_fetchJane;
    dotColor: BKJThemeColorType;
    showDeactivateConfirmModal: boolean;
    setShowDeactivateConfirmModal: (showDeactivateConfirmModal: boolean) => void;
    deactivateTeamMember: () => void;
    reactivateTeamMember: () => void;
    resendInviteTeamMember: () => void;
  } = useFetchJaneByIdDataContext();

  if (!data)
    return (
      <LoadingWrapper>
        <BKJLoadingSpinner variant="Default" />
      </LoadingWrapper>
    );

  const {
    avatarUrls = { origin: '' },
    accessStatus = { name: '' },
    primaryPosition = { details: { name: '' } },
    communityName = '',
    secondaryPositions = [],
    employmentStatus = { name: '' },
    phone = '',
    email = '',
    location = { fullAddress: '' },
    bio = '',
    shorthandName = '',
    locale = '',
    createdAt = '',
    customerReviews = { averageRating: '', count: '' },
    pendingSignup = false,
    isMobile = false,
    employeeNumber = '',
  } = data;
  const averageRating = Number(customerReviews?.averageRating) || 0;
  return (
    <Fragment>
      <BKJCenteredDialog
        isOpen={showDeactivateConfirmModal}
        variant="Danger"
        title="Deactivate Team Member?"
        text="This will block the team member from accessing the mobile app. You can re-activate a team member at any time. "
        cancelLabel="Cancel"
        actionLabel="Deactivate"
        onAction={deactivateTeamMember}
        onCancel={() => setShowDeactivateConfirmModal(false)}
        onClose={() => setShowDeactivateConfirmModal(false)}
      />
      <HeadSection>
        <JaneAvatarWrapper>
          <TeamMemberJaneAvatar
            url={avatarUrls?.origin}
            status={accessStatus?.name}
            size={112}
            pendingSignup={pendingSignup}
          />
        </JaneAvatarWrapper>
        <Flex justifyContent="space-between" width="100%">
          <Flex flexDirection="column" gap="15px" width="100%">
            <Flex justifyContent="space-between">
              <JaneName>{shorthandName}</JaneName>
              <JaneMenu
                disabled={false}
                setShowDeactivateConfirmModal={setShowDeactivateConfirmModal}
                accessStatusName={accessStatus?.name}
                reactivateTeamMember={reactivateTeamMember}
              />
            </Flex>
            <Flex alignItems="center" margin="0 0 0 4px">
              <ColorDot color={dotColor}>•</ColorDot>
              <Text>
                {accessStatus.name === 'blocked'
                  ? t('team_member:Deactivated')
                  : t(`team_member:${startCase(accessStatus?.name)}`)}
              </Text>
              {pendingSignup && accessStatus?.name === 'approved' && (
                <PendingSignUpText>(Pending mobile app sign up)</PendingSignUpText>
              )}
            </Flex>
            <EllipsisText>{primaryPosition?.details?.name}</EllipsisText>
            {!isMobile && (
              <NonAppUserWrapper>
                <BKJBadge variant="Grey">{t('team_member:Non_App_User')}</BKJBadge>
                <NonApUserTooltip />
              </NonAppUserWrapper>
            )}
            <div>
              <Rating
                disabled
                maxRating={5}
                defaultRating={customerReviews?.count === 0 ? 0 : averageRating}
              />
            </div>
          </Flex>
        </Flex>
      </HeadSection>
      <GeneralJaneWrapper>
        <SectionWrapper>
          <H4>{t('team_member:GENERAL_PROFILE')}</H4>
          <FieldWrapper>
            <LabelText>{t('team_member:PRIMARY_LOCATION')}</LabelText>
            <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
              {communityName}
            </ValueText>
          </FieldWrapper>
          <Flex justifyContent="space-between">
            <FieldWrapper>
              <LabelText>{t('team_member:PRIMARY_POSITION')}</LabelText>
              <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                {primaryPosition?.details?.name}
              </ValueText>
            </FieldWrapper>
            <FieldWrapper>
              <LabelText>{t('team_member:SECONDARY_POSITIONS')}</LabelText>
              {secondaryPositions.length > 0 ? (
                secondaryPositions.map((position, key) => (
                  <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft} key={key}>
                    {position?.details?.name}
                  </ValueText>
                ))
              ) : (
                <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>-</ValueText>
              )}
            </FieldWrapper>
          </Flex>
          <Flex justifyContent="space-between">
            <FieldWrapper>
              <LabelText>{t('team_member:EMPLOYMENT_STATUS')}</LabelText>
              <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                {startCase(employmentStatus?.name)}
              </ValueText>
            </FieldWrapper>
            <FieldWrapper>
              <LabelText>{t('team_member:EMPLOYEE_ID')}</LabelText>
              <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                {employeeNumber || '-'}
              </ValueText>
            </FieldWrapper>
          </Flex>
          <FieldWrapper>
            <LabelText>{t('team_member:BIOGRAPHY')}</LabelText>
            <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>{bio}</ValueText>
          </FieldWrapper>
        </SectionWrapper>
      </GeneralJaneWrapper>
      <ContactJaneWrapper>
        <SectionWrapper>
          <H4>{t('team_member:CONTACT_INFORMATION')}</H4>

          <Flex justifyContent="space-between">
            <FieldWrapper>
              <LabelText>{t('team_member:PHONE_NUMBER')}</LabelText>
              <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                {maskStringToPhoneString(phone)}
              </ValueText>
            </FieldWrapper>
            <FieldWrapper>
              <LabelText>{t('team_member:EMAIL')}</LabelText>
              <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>{email}</ValueText>
            </FieldWrapper>
          </Flex>
          <FieldWrapper>
            <LabelText>{t('team_member:HOME_ADDRESS')}</LabelText>
            <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
              {location?.fullAddress}
            </ValueText>
          </FieldWrapper>
        </SectionWrapper>
      </ContactJaneWrapper>
      <ContactJaneWrapper>
        <SectionWrapper>
          <FieldWrapper>
            <LabelText>{t('team_member:LANGUAGE_PREFERENCE')}</LabelText>
            <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
              {getLanguageString(locale)}
            </ValueText>
          </FieldWrapper>
        </SectionWrapper>
      </ContactJaneWrapper>
      <MobileAppJaneWrapper>
        <SectionWrapper>
          <FieldWrapper>
            <LabelText>{t('team_member:MOBILE_APP_STATUS')}</LabelText>
            <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
              {t('team_member:SIGNED_UP')} {humanReadableDate(createdAt)}
            </ValueText>
          </FieldWrapper>
        </SectionWrapper>
      </MobileAppJaneWrapper>
      <BKJSideModalFooter gap="8px" justifyContent="end">
        {accessStatus?.name === 'blocked' ? (
          <BKJButton
            variant="PurpleOutlined"
            width="180px"
            onClick={() => reactivateTeamMember()}
            type="button"
          >
            {t('team_member:RE_ACTIVATE')}
          </BKJButton>
        ) : (
          <Fragment>
            {pendingSignup && (
              <BKJButton
                variant="PurpleOutlined"
                width="fit-content"
                onClick={() => resendInviteTeamMember()}
                type="button"
              >
                {t('team_member:RESEND_INVITE')}
              </BKJButton>
            )}
            <BKJButton
              variant="PurpleSolid"
              width="180px"
              onClick={() => janeModalRoutingService.goToView('EditJaneView')}
              type="button"
            >
              {t('team_member:EDIT')}
            </BKJButton>
          </Fragment>
        )}
      </BKJSideModalFooter>
    </Fragment>
  );
};
