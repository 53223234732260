import { isArray } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IUseURLParametersHook<T = any> = [
  T,
  {
    removeParam: (arg: Array<string> | string) => void;
    removeAllParams: () => void;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setParam: (params: Record<string, any>) => void;
  },
];

export function useURLParameters<T = Record<string, string>>(): IUseURLParametersHook<
  T & Record<string, string>
> {
  const history = useHistory();
  const _location = useLocation();

  const [search, setSearch] = useState(_location.search);

  useEffect(() => {
    if (search !== _location.search) setSearch(_location.search);
  }, [_location.search, search]);

  const urlQueryParams = useMemo(() => new URLSearchParams(search), [search]);

  const removeParam = useCallback(
    (arg: Array<string> | string): void => {
      if (isArray(arg)) {
        arg.forEach((key) => {
          if (urlQueryParams.has(key)) {
            urlQueryParams.delete(key);
            history.push({ search: urlQueryParams.toString() });
          }
        });
      } else {
        if (urlQueryParams.has(arg)) {
          urlQueryParams.delete(arg);
          history.push({ search: urlQueryParams.toString() });
        }
      }
    },
    [urlQueryParams, history],
  );

  const removeAllParams = useCallback((): void => {
    history.push({ search: '' });
  }, [history]);

  const setParam = useCallback(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: Record<any, string>): void => {
      Object.entries(params).forEach(([key, value]: [string, string]) => {
        if (urlQueryParams.has(key)) {
          urlQueryParams.set(key, value);
        } else urlQueryParams.append(key, value);
      });
      history.push({ search: urlQueryParams.toString() });
    },
    [urlQueryParams, history],
  );

  const result: IUseURLParametersHook = useMemo(() => {
    return [Object.fromEntries(urlQueryParams), { removeParam, removeAllParams, setParam }];
  }, [removeAllParams, removeParam, setParam, urlQueryParams]);

  return result;
}
