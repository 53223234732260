import { useFormBehaviors } from 'hooks';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { useShiftDistributionDataContext } from 'pages/ShiftDistributionPage/ShiftDistributionPage.data.context';
import { vaccinationStatusRequestOptions } from 'pages/ShiftDistributionPage/ShiftDistributionPage.constants';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';

interface ShiftDistributionPageForm {
  vaccinationRequired: string;
}

export const ShiftDistributionFormContext = createContext(
  {} as UseFormBehaviors<ShiftDistributionPageForm>,
);

export const ShiftDistributionFormProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const { apiRequest } = useAPIActions();
  const dataContext = useShiftDistributionDataContext();
  const { data, fetch } = dataContext;

  const location_id = pathname.split('/')[3];

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async ({ vaccinationRequired }) => {
      await apiRequest(
        vaccinationStatusRequestOptions(vaccinationRequired, Number.parseInt(location_id)),
        {
          onSuccess: async () => {
            toastService.success(t('success:COVID19_UPDATED'));
            fetch();
          },
          onError: () => {
            toastService.error(t('errors:FAILED_TO_UPDATE_COVID19'));
          },
        },
      );
    },
    [apiRequest, fetch, location_id, t],
  );

  const form = useFormBehaviors({
    initialState: {
      vaccinationRequired: `${data?.fetchCommunity?.vaccinationRequired}`,
    },
    onSubmit: handleSubmit,
  });

  const value = useMemo(() => form, [form]);

  return (
    <ShiftDistributionFormContext.Provider value={value}>
      {children}
    </ShiftDistributionFormContext.Provider>
  );
};

export const useShiftDistributionFormContext = () => useContext(ShiftDistributionFormContext);
