import { IShiftCustomFieldDropdownInputOption } from 'components/ShiftModal/src/shared/ShiftCustomFields/components/ShiftCustomFieldInput.types';
import { shiftModalOrderDetails_fetchOrder_community_customFields } from 'types/graphql-types';

export const getShiftCustomFieldDropdownInputOptions = (
  customField: shiftModalOrderDetails_fetchOrder_community_customFields,
): IShiftCustomFieldDropdownInputOption[] => {
  let options: IShiftCustomFieldDropdownInputOption[];
  options = (customField.customFieldOptions || []).map((option) => {
    return {
      key: option.value,
      label: option.value,
      value: option.value,
    };
  });
  if (options)
    options.push({
      key: 'none',
      label: 'None',
      value: '',
    });
  return options;
};
