import { createContext, useState } from 'react';
import { API_POST_inviteTeamMembers } from 'requests/POST_inviteTeamMembers';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { IEmailViewContext } from './EmailView.types';

const initialState: IEmailViewContext = {
  flowView: 'invite',
  setFlowView: () => {},
  formValues: {},
  errorIndices: new Set<number>(),
  setErrorIndices: () => {},
  error: '',
  setError: () => {},
  numInvites: 0,
  setNumInvites: () => {},
  onChange: () => {},
  onSubmit: () => {},
};

export const EmailViewContext = createContext<IEmailViewContext>(initialState);

export const EmailViewContextProvider: React.FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const [flowView, setFlowView] = useState<string>('invite');
  const [formValues, setFormValues] = useState({});
  const [numInvites, setNumInvites] = useState<number>(0);
  const [errorIndices, setErrorIndices] = useState<Set<number>>(new Set<number>());
  const [error, setError] = useState<string>('');

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (name: string, value: any) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const onSubmit = async () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { facility: communityId, emails } = formValues as any;
    apiRequest(
      API_POST_inviteTeamMembers({
        communityId: communityId[0],
        inviteType: 'email',
        data: emails,
      }),
      {
        onSuccess: () => {
          console.log('Successfully invited team members.');
          setFlowView('success');
        },
        onError: () => {
          alert('Error inviting team members.');
          setFlowView('fail');
        },
      },
    );
  };

  return (
    <EmailViewContext.Provider
      value={{
        flowView,
        setFlowView,
        formValues,
        errorIndices,
        setErrorIndices,
        error,
        setError,
        numInvites,
        setNumInvites,
        onChange,
        onSubmit,
      }}
    >
      {children}
    </EmailViewContext.Provider>
  );
};
