import { BKJComboBoxAsync, ErrorBoundary, Flex } from '@bookjane2/bookjane-design-library';
import {
  LocationFilterDataContext,
  LocationFilterDataProvider,
  useLocationFilterDataContext,
} from 'components/LocationFilterInput/LocationFilterInput.context';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { usePathname } from 'hooks/usePathname';
import { useSelector } from 'react-redux';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { useTranslation } from 'hooks/useTranslation';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

function LocationFilterInputComponent(props: Omit<ILocationFilterProps, 'options'>) {
  const { value, onChange, name = 'location', disabled } = props;
  const { t } = useTranslation();
  const pathname = usePathname();

  const { data: options = [], values, onChange: onSearchChange } = useLocationFilterDataContext();
  const isLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  let __disabled__ = disabled;

  const isTheCurrentPageAnythingOtherThanTimesheets = pathname !== '/timesheets';
  // This is due to the API not being able to meet SLA
  if (isLocationFilterForcedDisabled && isTheCurrentPageAnythingOtherThanTimesheets)
    __disabled__ = true;

  return (
    <Flex width="318px">
      <BKJComboBoxAsync
        name={name}
        type="Single"
        value={value}
        icon="Location1"
        searchKey="byName"
        onChange={onChange}
        action="OpenToggle"
        disabled={__disabled__}
        options={options}
        searchValue={values.byName}
        onSearchChange={onSearchChange}
        context={LocationFilterDataContext}
        placeholder={t('schedule:SEARCH_LOCATION_PLACEHOLDER')}
      />
    </Flex>
  );
}

export const LocationFilterInput = (props: Omit<ILocationFilterProps, 'options'>) => {
  return (
    <ErrorBoundary
      componentName="LocationFilterInput"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <LocationFilterDataProvider>
        <LocationFilterInputComponent {...props} />
      </LocationFilterDataProvider>
    </ErrorBoundary>
  );
};
