import { Flex } from '@bookjane2/bookjane-design-library';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useFetchAgenciesByAgencyIdsDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/AgencyRequests/FetchAgenciesByAgencyIds.data.context';
import { SelectedAgenciesPay } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/ShiftPay/SelectedAgenciesPay';
import {
  TextButtonWrapper,
  SendToAgenciesBoldText,
  InfoSection,
  SendToAgenciesText,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { useTranslation } from 'hooks/useTranslation';
import { FC, useState } from 'react';
import { formatPlural } from 'utils/formatPlural';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';

export const AgencyRequestsComponent: FC = () => {
  const { t, i18n } = useTranslation();
  const { data } = useShiftModalDataContext();
  const { values } = useSendToAgenciesFormContext();
  const {
    data: { availableJaneCount, selectedAgencies },
  } = useFetchAgenciesByAgencyIdsDataContext();

  const [showMore, setShowMore] = useState(false);
  const onClick = () => setShowMore(!showMore);

  return (
    <Flex flexDirection="column" gap="32px">
      <Flex flexDirection="column" gap="8px">
        <Flex alignItems="center">
          <SendToAgenciesBoldText>{`${getTranslatedResponse(
            data?.fetchOrder?.status.name,
            'status',
            i18n,
            t,
          )} ${t('dashboard:SHIFT')}`}</SendToAgenciesBoldText>
        </Flex>

        <InfoSection flexDirection="column" alignContent="baseline" gap="14px">
          <div>
            <SendToAgenciesBoldText>{t('dashboard:SEND_TO')}:&nbsp;</SendToAgenciesBoldText>
            {selectedAgencies.length > 1 ? (
              showMore ? (
                <SendToAgenciesText>
                  {selectedAgencies.join(', ')}&nbsp;
                  <TextButtonWrapper variant="PurpleText" onClick={onClick}>
                    ${t('shifts_calendar:HIDE')}
                  </TextButtonWrapper>
                </SendToAgenciesText>
              ) : (
                <SendToAgenciesText>
                  {`${selectedAgencies[0]}, ${selectedAgencies[1].slice(0, 2)}...`}&nbsp;
                  <TextButtonWrapper variant="PurpleText" onClick={onClick}>
                    {`+${selectedAgencies.length - 1} ${t('MORE')}`}
                  </TextButtonWrapper>
                </SendToAgenciesText>
              )
            ) : (
              <SendToAgenciesText>{selectedAgencies[0]}</SendToAgenciesText>
            )}
          </div>
          <SendToAgenciesText>{`${availableJaneCount} ${t(
            'shifts_calendar:AVAILABLE_AGENCY_MEMBERS',
          )}`}</SendToAgenciesText>

          <div>
            <SendToAgenciesBoldText>{t('shifts_calendar:SEND_OUT')}:&nbsp;</SendToAgenciesBoldText>
            {values.notification_strategy === 'now' && (
              <SendToAgenciesText>{t('shifts_calendar:IMMEDIATELY')}</SendToAgenciesText>
            )}
            {values.notification_strategy === 'specific_time' && (
              <SendToAgenciesText>
                {`${formatPlural(
                  'hour',
                  Number.parseInt(values.specific_time_selection[0], 10),
                  true,
                )} ${t('shifts_calendar:BEFORE_SHIFT_STARTS')}`}
              </SendToAgenciesText>
            )}
          </div>
        </InfoSection>

        <Flex flexDirection="column" padding="0px 8px 0px 8px">
          <SendToAgenciesText>
            {t('shifts_calendar:SHIFT_AVAILABLE_NOTE_START')}&nbsp;
            <SendToAgenciesBoldText>
              {formatPlural(data?.fetchOrder?.position?.name)}
            </SendToAgenciesBoldText>
            &nbsp;{t('shifts_calendar:SHIFT_AVAILABLE_NOTE_END')}
          </SendToAgenciesText>
        </Flex>
      </Flex>
      <SelectedAgenciesPay />
    </Flex>
  );
};
