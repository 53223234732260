import { ActivityEventEnum } from 'types/graphql-types';
import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { startCase } from 'lodash';
export interface Activity {
  id: number;
  event: ActivityEventEnum;
  created_at: string;
  feedable: {
    shift_ids: number[];
  };
}

export const auditEvents = (events: string, shift: number) => {
  let details;
  switch (events) {
    case 'accepted':
      details = `Accepted Shift #${shift}`;
      break;
    case 'assigned':
      details = `Assigned to Shift #${shift}`;
      break;
    case 'acknowledged':
      details = `Acknowledged Shift #${shift}`;
      break;
    case 'cancelled':
      details = `Shift #${shift} was Cancelled`;
      break;
    case 'requested':
      details = 'published';
      break;
    case 'declined':
      details = `Declined Shift #${shift}`;
      break;
    case 'auto_released':
      details = `Request Automatically Released Shift #${shift}`;
      break;
    default:
      details = `${startCase(events)} Shift #${shift}`;
      break;
  }
  return details;
};

export const getActivityColorByEvent = (events: ActivityEventEnum): BKJThemeColorType => {
  switch (events) {
    case 'pending':
      return 'ShiftPending';
    case 'send_to_agencies':
    case 'sent_to_bookjane':
    case 'accepted_by_bookjane':
    case 'created':
    case 'requested':
      return 'ShiftRequested';
    case 'declined':
      return 'SecondaryPendingHover';
    case 'assigned':
      return 'ShiftAssigned';
    case 'accepted':
      return 'AccentGreen';
    case 'cancelled':
      return 'Grey5';
    case 'customer_released':
    case 'jane_released':
    case 'auto_released':
      return 'ShiftRequested';
    default:
      return 'Grey5';
  }
};
