import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJToastContainer } from '@bookjane2/bookjane-design-library/lib/components/BKJToastMessage/BKJToastContainer';
import { AppWrapper } from 'components/AppWrapper';
import { BKJMainNavigation } from 'components/BKJMainNavigation';
import { GlobalLoading } from 'components/GlobalLoading';
import { GlobalModals } from 'components/GlobalModals';
import { TranslationSwitch } from 'components/TranslationSwitch';
import { ChameleonContainer } from 'containers/ChameleonContainer/ChameleonContainer';
import { GoogleAnalyticsContainer } from 'containers/GoogleAnalyticsContainer';
import { MouseflowContainer } from 'containers/MouseflowContainer/MouseflowContainer';
import { ServerNotReachablePage } from 'pages/ServerNotReachablePage';
import { FC } from 'react';
import { Routes } from 'routes';
import { useIsGlobalLoadingSelector } from 'store/selectors/userSelectors/isGlobalLoadingSelector';
import { useIsJ360ApiDown } from 'store/selectors/userSelectors/isJ360ApiDown';
import { withMemo } from 'utils/withMemo';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useMessaging } from 'hooks/useMessaging';

const J360App: FC = () => {
  const isLoading = useIsGlobalLoadingSelector();
  const isJ360ApiDown = useIsJ360ApiDown();
  const { isWhiteLabelled } = WhiteLabelAccessor;

  useMessaging();
  if (isLoading) return <GlobalLoading />;
  if (isJ360ApiDown) return <ServerNotReachablePage />;
  return (
    <ErrorBoundary componentName="App" showLogo={!isWhiteLabelled()}>
      <AppWrapper>
        <BKJMainNavigation />
        <Routes />
        <GlobalModals />
        <TranslationSwitch />
        <GoogleAnalyticsContainer />
        <ChameleonContainer />
        <MouseflowContainer />
        <BKJToastContainer icon={false} />
      </AppWrapper>
    </ErrorBoundary>
  );
};

export const App = withMemo(J360App);
