import { ActivityTab } from 'components/JaneModal/views/JaneDetailsView/ActivityTab/ActivityTab';
import { GeneralTab } from 'components/JaneModal/views/JaneDetailsView/GeneralTab/GeneralTab';
import { ReviewsTab } from 'components/JaneModal/views/JaneDetailsView/ReviewsTab/ReviewsTab';
export const JaneDetailViewComponentMap = {
  GeneralTab,
  ReviewsTab,
  ActivityTab,
};

export type JaneDetailsViewActiveTabType = keyof typeof JaneDetailViewComponentMap;
