import chalk from 'chalk';
import { getWebVersion } from '../utils/getWebVersion';
import { BKJEnvType, environments } from './environments';

const _BKJ_ENVIRONMENT = (process.env.REACT_APP_BKJ_ENVIRONMENT as BKJEnvType) || 'default';
const _PHRASEAPP_ACCESS_TOKEN = process.env.REACT_APP_PHRASEAPP_ACCESS_TOKEN || '';
const _GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || '';
const _LOGIN_USERNAME = process.env.REACT_APP_LOGIN_USERNAME || '';
const _LOGIN_PASSWORD = process.env.REACT_APP_LOGIN_PASSWORD || '';
const _PUBLIC_URL = process.env.PUBLIC_URL || '';
const _NODE_ENV = process.env.NODE_ENV || '';
const _PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY as string;
const _PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER as string;
const _WHITE_LABEL = process.env.REACT_APP_WHITE_LABEL || '';

const config: Record<string, string> = environments[_BKJ_ENVIRONMENT];

const environment = {
  BKJ_ENVIRONMENT: _BKJ_ENVIRONMENT,
  PHRASEAPP_ACCESS_TOKEN: _PHRASEAPP_ACCESS_TOKEN,
  GOOGLE_API_KEY: _GOOGLE_API_KEY,
  LOGIN_USERNAME: _LOGIN_USERNAME,
  LOGIN_PASSWORD: _LOGIN_PASSWORD,
  PUBLIC_URL: _PUBLIC_URL,
  NODE_ENV: _NODE_ENV,
  WEB_VERSION: getWebVersion(),
  DEFAULT_BASE_URL: config.DEFAULT_BASE_URL,
  UK_BASE_URL: config.UK_BASE_URL,
  SOCKETURL: config.SOCKETURL,
  ADP_REDIRECT_URI: config.ADP_REDIRECT_URI,
  ROCKET_ID: config.ROCKET_ID,
  ADP_PARTNER_CLIENT_ID_USA: config.ADP_PARTNER_CLIENT_ID_USA,
  ADP_PARTNER_CLIENT_ID_CA: config.ADP_PARTNER_CLIENT_ID_CA,
  PUSHER_APP_KEY: _PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER: _PUSHER_APP_CLUSTER,
  BASE_AUTH_URL: config.BASE_AUTH_URL,
  WHITE_LABEL: _WHITE_LABEL,
};

Object.entries(environment).forEach(([key, value]) => {
  if (value === undefined) {
    console.log(chalk.red(`Required environment variable "${key}" was undefined!\n`.repeat(10)));
    process.exit(1);
  }
});

export const BKJ_ENVIRONMENT = environment.BKJ_ENVIRONMENT;
export const PHRASEAPP_ACCESS_TOKEN = environment.PHRASEAPP_ACCESS_TOKEN;
export const GOOGLE_API_KEY = environment.GOOGLE_API_KEY;
export const LOGIN_USERNAME = environment.LOGIN_USERNAME;
export const LOGIN_PASSWORD = environment.LOGIN_PASSWORD;
export const PUBLIC_URL = environment.PUBLIC_URL;
export const WEB_VERSION = environment.WEB_VERSION;
export const DEFAULT_BASE_URL = environment.DEFAULT_BASE_URL;
export const UK_BASE_URL = environment.UK_BASE_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const SOCKETURL = environment.SOCKETURL;
export const ADP_REDIRECT_URI = environment.ADP_REDIRECT_URI;
export const ROCKET_ID = environment.ROCKET_ID;
export const ADP_PARTNER_CLIENT_ID_USA = environment.ADP_PARTNER_CLIENT_ID_USA;
export const ADP_PARTNER_CLIENT_ID_CA = environment.ADP_PARTNER_CLIENT_ID_CA;
export const PUSHER_APP_KEY = environment.PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = environment.PUSHER_APP_CLUSTER;
export const BASE_AUTH_URL = environment.BASE_AUTH_URL;
export const GOOGLE_ANALYTICS_ID = 'UA-72865620-2';
export const WHITE_LABEL = environment.WHITE_LABEL;

const developmentEnvs: BKJEnvType[] = [
  'development',
  'sandbox1',
  'e2etest',
  'sandbox2',
  'sandbox3',
  'sandbox4',
  'sandbox5',
];

export const isNodeEnvProduction: boolean = NODE_ENV === 'production';

export const isEnvPetalProduction: boolean = BKJ_ENVIRONMENT === 'petal';

export const isEnvDevelopment: boolean = developmentEnvs.includes(BKJ_ENVIRONMENT);

export const isLogRocketEnabled: boolean = false; //!!ROCKET_ID && NODE_ENV === 'production' && ['staging', 'master'].includes(BKJ_ENVIRONMENT);
