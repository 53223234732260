import { QuerySwitch } from 'components/QuerySwitch';
import { FC } from 'react';
import { TimesheetsTabComponent } from './TimesheetsTab';
import { TimesheetsTabProvider } from './TimesheetsTabForm.context';
import {
  TimesheetsTabDataContext,
  TimesheetsTabDataProvider,
  useTimeSheetsTabDataContext,
} from './TimesheetsTabData.context';
import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { NoTimesheetsAvailable } from 'components/ShiftModal/src/views/DetailView/TimesheetsTab/TimesheetsTab.styled';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';

const Inner = () => {
  const { t } = useTranslation();
  const { data } = useTimeSheetsTabDataContext();
  const timeSheetExists = !!data.fetchTimeSheet.timeSheet;
  return timeSheetExists ? (
    <TimesheetsTabProvider>
      <QuerySwitch component={TimesheetsTabComponent} context={TimesheetsTabDataContext} />
    </TimesheetsTabProvider>
  ) : (
    <NoTimesheetsAvailable>{t('shifts_calendar:NO_TEAM_SHEETS_AVAILABLE')}</NoTimesheetsAvailable>
  );
};

export const TimesheetsTab: FC = () => (
  <ErrorBoundary componentName="TimesheetsTab" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
    <TimesheetsTabDataProvider>
      <QuerySwitch component={Inner} context={TimesheetsTabDataContext} />
    </TimesheetsTabDataProvider>
  </ErrorBoundary>
);
