import {
  BKJListItem,
  BKJSelect,
  BKJTextArea,
  BKJTextInput,
} from '@bookjane2/bookjane-design-library';
import {
  IShiftCustomFieldDropdownInputRenderGroupProps,
  IShiftCustomFieldInputProps,
} from 'components/ShiftModal/src/shared/ShiftCustomFields/components/ShiftCustomFieldInput.types';
import { getShiftCustomFieldDropdownInputOptions } from 'components/ShiftModal/src/shared/ShiftCustomFields/components/ShiftCustomFieldInput.utils';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment } from 'react';
import { isArray } from 'types/common.types';

const RenderGroup = ({
  name,
  selected,
  options,
  onChange,
}: IShiftCustomFieldDropdownInputRenderGroupProps) => {
  return (
    <Fragment>
      {options.map(({ key, label, value }) => {
        return (
          <BKJListItem
            key={key}
            value={value}
            label={label}
            name={name}
            onChange={onChange}
            selected={selected}
          />
        );
      })}
    </Fragment>
  );
};

export const ShiftCustomFieldInput: FC<IShiftCustomFieldInputProps> = ({
  customField,
  variant = 'GreyOutlined',
  width,
  dropdownWidth,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const { fieldType } = customField;
  switch (fieldType) {
    case 'input': {
      return (
        <BKJTextInput
          name={customField.name}
          onChange={onChange}
          value={value as string}
          placeholder={customField.placeholder || `${t('common:ENTER')} ${customField.name}`}
          maxLength={100}
          autoComplete="off"
        />
      );
    }
    case 'text_area': {
      return (
        <BKJTextArea
          name={customField.name}
          onChange={onChange}
          value={value as string}
          placeholder={customField.placeholder || `${t('common:ENTER')} ${customField.name}`}
          maxLength={1000}
          autoComplete="off"
        />
      );
    }
    case 'dropdown': {
      const customFieldDropdownOptions = getShiftCustomFieldDropdownInputOptions(customField);
      return (
        <BKJSelect
          options={customFieldDropdownOptions}
          placeholder={customField.placeholder || `${t('common:SELECT')} ${customField.name}`}
          label={(isArray(value) && value[0]) || undefined}
          type="Single"
          variant={variant}
          width={width}
          name={customField.name}
          dropdownWidth={dropdownWidth}
          value={(isArray(value) ? value : [value!]) || []}
          onChange={onChange}
          renderOptions={RenderGroup}
          placeholderColor="TextDisabled"
          autoComplete="off"
        />
      );
    }
    default: {
      return (
        <BKJTextInput
          name={customField.name}
          onChange={onChange}
          value={value as string}
          placeholder={customField.placeholder || `${t('common:ENTER')} ${customField.name}`}
          autoComplete="off"
        />
      );
    }
  }
};
