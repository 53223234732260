import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { toastService } from 'services';
import { useGQLMutation } from 'hooks/useGQLMutation.ts';
import {
  MUTATION_manuallyApproveCallout as MUTATION_manuallyApproveCallout_type,
  MUTATION_manuallyApproveCalloutVariables,
} from 'types/graphql-types';
import { MUTATION_manuallyApproveCallout } from 'mutation';
import { UseGQLMutation } from 'hooks/useGQLMutation.ts/useGQLMutation.types';
import { ContinueCalloutDialogProps } from 'components/ContinueCalloutDialog/ContinueCalloutDialog.types';
import { useTranslation } from 'hooks/useTranslation';

interface ContinueCalloutDialogForm
  extends Omit<
    UseGQLMutation<MUTATION_manuallyApproveCallout_type, MUTATION_manuallyApproveCalloutVariables>,
    'mutate'
  > {
  handleContinueCallout: () => void;
}

export const ContinueCalloutDialogFormContext = createContext({} as ContinueCalloutDialogForm);

export const ContinueCalloutDialogFormProvider: FC<ContinueCalloutDialogProps> = ({
  children,
  calloutListId,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { mutate, ...rest } = useGQLMutation<
    MUTATION_manuallyApproveCallout_type,
    MUTATION_manuallyApproveCalloutVariables
  >({
    key: 'MANUALLY_APPROVE_CALLOUT_CONTINUE',
    mutation: MUTATION_manuallyApproveCallout,
    variables: {
      calloutListId,
    },
    refetchQueries: ['shiftModalOrderDetails'],
    onSuccess: (res) => {
      if (res?.approveCallout?.errors && !!res?.approveCallout?.errors?.length)
        return toastService.error(t('errors:UNABLE_TO_CONTINUE_CALL_OUT'));
      return onSuccess();
    },
    onError: (error) => {
      toastService.error(error.message);
    },
  });

  const handleContinueCallout = useCallback(async () => {
    await mutate({ variables: { calloutListId } });
  }, [calloutListId, mutate]);

  const contextValue = useMemo(
    () => ({
      handleContinueCallout,
      ...rest,
    }),
    [handleContinueCallout, rest],
  );

  return (
    <ContinueCalloutDialogFormContext.Provider value={contextValue}>
      {children}
    </ContinueCalloutDialogFormContext.Provider>
  );
};

export const useContinueCalloutDialogFormContext = () =>
  useContext(ContinueCalloutDialogFormContext);
