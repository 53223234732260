import { InviteTeamMembersByCSVModalComponent } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.component';
import { InviteTeamMembersByCSVModalUIProvider } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
import { AuthRouteDataProvider } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC } from 'react';

export const InviteTeamMembersByCSVModal: FC = () => {
  return (
    <AuthRouteDataProvider>
      <InviteTeamMembersByCSVModalUIProvider>
        <InviteTeamMembersByCSVModalComponent />
      </InviteTeamMembersByCSVModalUIProvider>
    </AuthRouteDataProvider>
  );
};
