import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { FC, createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { janeModalJaneDetails_fetchJane_secondaryPositions } from 'types/graphql-types';
import { useInviteTeamMembersByCSVModalDataContext } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.data.context';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import { toastService } from 'services';

import {
  VALIDATION_email,
  VALIDATION_phoneNumber,
  ComboBoxOption,
  VALIDATION_position_id,
  VALIDATION_required,
  convertEmploymentStatus,
  monthToDaysMap,
  InviteTeamMemberFormApiError,
  monthNameToNumber,
} from 'components/InviteTeamMembersByCSVModal/views/InviteTeamMemberView/InviteTeamMemberView.constants';
import { METHOD_POST } from 'requests';
import { INVITE_TEAM_MEMBER } from 'constants/endpoints';
import { inviteTeaMemberCSVModalRoutingService } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
import { TeamMemberPageContext } from 'pages/TeamMemberPage/TeamMemberPage.context';

export interface ITeamMemberViewForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isMobile: boolean;
  locale: string;
  position_id: string;
  community_id: string;
  location: IBKJComboBoxAsyncOption;
  secondaryPositionsIds: string[];
  secondaryPositions: janeModalJaneDetails_fetchJane_secondaryPositions[];
  createdAt: string;
  employmentStatus: string;
  hourlyRate: string;
  birthMonth: string;
  birthDay: string;
  employeeNumber: string;
}
export interface ITeamMemberViewFormActions {
  setSelectMonth: (month: string) => void;
  setSelectDay: (day: string) => void;
  dayOptions: ComboBoxOption[];
}
export const InviteTeamMemberViewFormContext = createContext(
  {} as UseFormBehaviors<ITeamMemberViewForm, InviteTeamMemberFormApiError> &
    ITeamMemberViewFormActions,
);

export const InviteTeamMemberViewFormProvider: FC<{}> = ({ children }) => {
  const { location } = useInviteTeamMembersByCSVModalDataContext();
  const { refetch: refetchData }: { refetch: () => void } = useContext(TeamMemberPageContext);
  const { apiRequest } = useAPIActions();
  const [selectMonth, setSelectMonth] = useState<string>('');
  const [selectDay, setSelectDay] = useState<string>('');
  const [dayOptions, setDayOptions] = useState<ComboBoxOption[]>([]);
  useEffect(() => {
    setSelectDay('');
  }, [selectMonth]);
  const memoizedRefetchData = useMemo(() => {
    return () => {
      refetchData();
    };
  }, [refetchData]);
  useMemo(() => {
    if (selectMonth) {
      // Convert month names to the expected structure with unique 'id'
      const options: ComboBoxOption[] = monthToDaysMap[selectMonth].map((day, index) => ({
        key: `${selectMonth}_${day}`,
        id: `${selectMonth}_${day}`, // Generate a unique 'id' for each day within the selected month
        value: day,
        label: day,
      }));
      setDayOptions(options);
    } else {
      setDayOptions([]);
    }
  }, [selectMonth]);
  const handleSave = useCallback(
    async (payload: ITeamMemberViewForm) => {
      const employmentStatusValue = convertEmploymentStatus(payload?.employmentStatus[0]);
      const derivedBirthMonth = monthNameToNumber[payload?.birthMonth[0]];
      return await apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_POST,
          url: INVITE_TEAM_MEMBER,
          body: {
            invitations: {
              first_name: payload?.firstName,
              last_name: payload?.lastName,
              email: payload?.email,
              phone: payload?.phone,
              is_mobile: payload?.isMobile,
              locale: payload?.locale,
              community_id: payload?.location?.id,
              primary_position_id: payload?.position_id[0],
              secondary_position_ids: payload?.secondaryPositionsIds,
              employment_status: employmentStatusValue,
              hourly_rate: payload?.hourlyRate,
              employee_number: payload?.employeeNumber,
              employee_start_date: payload?.createdAt[0],
              birth_day: payload?.birthDay[0],
              birth_month: derivedBirthMonth,
            },
          },
        },
        {
          onSuccess: async () => {
            memoizedRefetchData();
            inviteTeaMemberCSVModalRoutingService.goToView('SuccessFul');
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: async (e: any) => {
            toastService.error('Something went wrong.');
          },
        },
      );
    },
    [apiRequest, memoizedRefetchData],
  );
  const form = useFormBehaviors<ITeamMemberViewForm, InviteTeamMemberFormApiError>({
    initialState: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      isMobile: false,
      locale: '',
      position_id: '',
      community_id: '',
      location: location,
      secondaryPositionsIds: [],
      secondaryPositions: [],
      createdAt: '',
      employmentStatus: '',
      employeeNumber: '',
      hourlyRate: '',
      birthMonth: selectMonth,
      birthDay: selectDay,
    },
    onSubmit: handleSave,
    validations: {
      firstName: VALIDATION_required,
      lastName: VALIDATION_required,
      email: VALIDATION_email,
      phone: VALIDATION_phoneNumber,
      position_id: VALIDATION_position_id,
      employmentStatus: VALIDATION_required,
      employeeNumber: VALIDATION_required,
      createdAt: VALIDATION_required,
      birthMonth: VALIDATION_required,
      birthDay: VALIDATION_required,
    },
  });
  const value = useMemo(() => {
    return {
      ...form,
      isDisabled:
        form.isDisabled ||
        !form.values.firstName ||
        !form.values.lastName ||
        !form.values.phone ||
        !form.values.position_id ||
        !form.values.employmentStatus ||
        !form.values.employeeNumber ||
        !form.values.createdAt ||
        !form.values.birthMonth ||
        !form.values.birthDay,
      setSelectMonth,
      dayOptions,
      setSelectDay,
    };
  }, [dayOptions, form]);

  return (
    <InviteTeamMemberViewFormContext.Provider value={value}>
      {children}
    </InviteTeamMemberViewFormContext.Provider>
  );
};

export const useInviteTeamMemberViewFormContext = () => useContext(InviteTeamMemberViewFormContext);
