import {
  BKJButton,
  BKJTextArea,
  BKJToggleInput,
  ErrorBoundary,
} from '@bookjane2/bookjane-design-library';
import { useTranslation } from 'hooks/useTranslation';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { ShiftModalComponentMap } from 'components/ShiftModal/ShiftModal.constants';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import {
  shiftModalRoutingService,
  useShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { ShiftCustomFieldInput } from 'components/ShiftModal/src/shared/ShiftCustomFields/components/ShiftCustomFieldInput';
import { NotifyView } from 'components/ShiftModal/src/views';
import {
  ShiftStatusText,
  Span,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import {
  EditViewProvider,
  useEditViewContext,
} from 'components/ShiftModal/src/views/EditView/EditView.context';
import {
  CustomFieldsFormLabel,
  FormLabel,
  PositionText,
  ValueText,
} from 'components/ShiftModal/src/views/EditView/EditView.styled';
import { ShiftTimeSelectInput } from 'components/ShiftTimeSelectInput';
import { ShiftUnpaidBreakTimeSelectInput } from 'components/ShiftUnpaidBreakTimeSelectInput';
import { H3 } from 'components/Typography';
import { FeatureGuard } from 'guards/FeatureGuard';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { MobileLabel } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView.styled';
import { FC, FormEvent, Fragment, MouseEventHandler, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { OrderStatusEnum } from 'types/graphql-types';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import schedulingReadAccessFacilityIdsSelector from 'store/selectors/scheduleSelectors/schedulingReadAccessFacilityIdsSelector';

export const shiftModalEditViewFormItemVerticalMargin = 24;

export const EditView: FC = withMemo(() => {
  const { t, i18n } = useTranslation();
  const { data } = useShiftModalDataContext();

  const communityCustomFields = data.fetchOrder.community.customFields || [];

  const isCustomFieldsEnabled =
    useSelector(FEATURE_SELECTORS_MAP['FEATURE_TYPE_CUSTOM_FIELDS']) &&
    communityCustomFields.length > 0;

  const {
    isDisabled,
    isSubmitting,
    onChange,
    isNotifyFlowRequired,
    onSubmit,
    totalDuration,
    totalPaidDuration,
    values,
  } = useEditViewContext();
  const { value: orderStatusValue } = data.fetchOrder.status;

  const locationsWithScheduling = useSelector(schedulingReadAccessFacilityIdsSelector);
  const isSchedulingEnabled = useMemo(
    () => locationsWithScheduling.includes(Number(data.fetchOrder.community.id)),
    [data.fetchOrder.community.id, locationsWithScheduling],
  );

  const isAllowOvertimeEnabled =
    !values.allow_overtime && orderStatusValue === OrderStatusEnum.pending && isSchedulingEnabled;

  const goToDeleteView = useCallback(() => {
    shiftModalRoutingService.goToView('DeleteView');
  }, []);

  const isAssignedOrAccepted = [OrderStatusEnum.accepted, OrderStatusEnum.assigned].includes(
    orderStatusValue,
  );

  const handleSubmit = useCallback(
    (event: MouseEventHandler<HTMLButtonElement> & { preventDefault: () => void }) => {
      event.preventDefault();
      if (isAssignedOrAccepted && isNotifyFlowRequired)
        return shiftModalRoutingService.goToView('NotifyView');

      return onSubmit(event as unknown as FormEvent<HTMLFormElement>);
    },
    [isAssignedOrAccepted, isNotifyFlowRequired, onSubmit],
  );

  return (
    <ErrorBoundary componentName="EditView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <Flex flexDirection="column" padding="32px" flex="1">
        <H3 color="TextDarkGrey">{t('schedule:EDIT_SHIFT')}</H3>

        <Flex padding="10px 0">
          <ShiftStatusText type={data.fetchOrder!.status.name}>
            <Span>{getTranslatedResponse(data.fetchOrder!.status.name, 'status', i18n, t)}</Span>
          </ShiftStatusText>
        </Flex>

        <Flex flexDirection="column" margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}>
          <PositionText>{t('common:POSITION')}</PositionText>
          <ValueText>{data.fetchOrder!.position.name}</ValueText>
        </Flex>

        <Flex flexDirection="column" margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}>
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <FormLabel>Date</FormLabel>

            <ShiftDateCalendarInput
              name="start_date"
              value={values.start_date}
              onChange={onChange}
            />
          </Flex>
        </Flex>

        <Flex width="100%" margin="0 0 24px">
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <ShiftTimeSelectInput
              label={t('schedule:START_TIME')}
              placeholder={t('schedule:START_TIME')}
              onChange={onChange}
              name="start_time"
              value={values.start_time}
            />
          </Flex>

          <Flex flexDirection="column" width="50%" padding="0 0 0 8px">
            <ShiftTimeSelectInput
              label={t('schedule:END_TIME')}
              placeholder={t('schedule:END_TIME')}
              onChange={onChange}
              name="end_time"
              value={values.end_time}
            />
          </Flex>
        </Flex>

        <Flex width="100%" margin="0 0 24px">
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <ShiftUnpaidBreakTimeSelectInput
              label={t('schedule:UNPAID_BREAK')}
              width="100%"
              placeholder={t('schedule:UNPAID_BREAK')}
              onChange={onChange}
              name="unpaid_break_time"
              value={values.unpaid_break_time}
            />
          </Flex>
        </Flex>

        <Flex width="100%" margin="0 0 24px">
          <Flex flexDirection="column" width="50%" padding="0 8px 0 0">
            <FormLabel>{t('common:TOTAL_DURATION')}</FormLabel>
            <ValueText>{totalDuration}</ValueText>
          </Flex>

          <Flex flexDirection="column" width="50%" padding="0 0 0 8px">
            <FormLabel>{t('common:TOTAL_PAID_DURATION')}</FormLabel>
            <ValueText>{totalPaidDuration}</ValueText>
          </Flex>
        </Flex>

        <FeatureGuard type="FEATURE_TYPE_SCHEDULING">
          <Flex margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`} alignItems="center">
            <ValueText>{t('schedule:ALLOW_OVERTIME')}</ValueText>
            <BKJToggleInput
              variant="Switch"
              name="allow_overtime"
              disabled={!isAllowOvertimeEnabled}
              onChange={onChange}
              checked={values.allow_overtime}
              css="margin-left: 16px;"
            />
          </Flex>
        </FeatureGuard>
        <Flex flexDirection="column">
          <FormLabel>{t('schedule:SHIFT_NOTES')}</FormLabel>
          <BKJTextArea
            name="description"
            placeholder={t('schedule:TYPE_NOTES')}
            value={values.description}
            onChange={onChange}
            maxLength={2000}
          />
        </Flex>
        {isCustomFieldsEnabled && (
          <Fragment>
            <SectionDivider
              variant="Thin"
              margin={`${shiftModalEditViewFormItemVerticalMargin}px 0`}
            />
            <CustomFieldsFormLabel>{t('common:CUSTOM_FIELDS')}</CustomFieldsFormLabel>
            {communityCustomFields?.map((customField) => {
              return (
                <Flex
                  key={customField.id}
                  flexDirection="column"
                  margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}
                >
                  <FormLabel>
                    {customField.name}
                    {customField.mobile && (
                      <MobileLabel>&nbsp;({t('schedule:SHOWN_INTERNALLY_ON_MOBILE')})</MobileLabel>
                    )}
                  </FormLabel>
                  <ShiftCustomFieldInput
                    key={customField.id}
                    customField={customField}
                    value={values[customField.name] as string}
                    onChange={onChange}
                  />
                </Flex>
              );
            })}
          </Fragment>
        )}
      </Flex>

      <BKJSideModalFooter justifyContent="space-between">
        <BKJButton width="auto" variant="RedText" onClick={goToDeleteView}>
          {t('auth:DELETE')}
        </BKJButton>
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          disabled={isDisabled}
          isLoading={isSubmitting}
          type="button"
          onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
        >
          {isDisabled
            ? t('auth:PUBLISH_EDITS')
            : isAssignedOrAccepted && isNotifyFlowRequired
            ? t('auth:NEXT')
            : t('auth:PUBLISH_EDITS')}
        </BKJButton>
      </BKJSideModalFooter>
    </ErrorBoundary>
  );
});

export const EditViewRenderer: FC = withMemo((props) => {
  const { shiftModalActiveModalView } = useShiftModalUIContext();
  const Component = {
    ...ShiftModalComponentMap,
    EditView,
    NotifyView,
  }[shiftModalActiveModalView];
  return (
    <ErrorBoundary
      componentName="EditViewRenderer"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <EditViewProvider>
        <Component {...props} />
      </EditViewProvider>
    </ErrorBoundary>
  );
});
