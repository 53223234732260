import { OffsetElementType } from 'components/AuthPageContentWrapper/AuthPageContextWrapper.types';

export type ElementDimensionValueType = number;

export const NavigationWidth = 80;
export const LogoHeight = 100;
export const DesktopPageHeaderHeight = 70;
export const MobilePageHeaderHeight = 54;
export const SSFTopBarHeight = 52;
export const SSFBottomBarHeight = 60;
export const TabNavigationHeight = 64;
export const OpenSettingsNavigationWidth = 264;
export const ClosedSettingsNavigationWidth = 24;
export const BKJTableHeaderHeight = 65;
export const BKJTableFooterHeight = 64;
export const ShiftStatusSummaryFooterHeight = 65;
export const SetupBanner = 61;

export const elementDimensions: Record<OffsetElementType, ElementDimensionValueType> = {
  NavigationWidth,
  LogoHeight,
  DesktopPageHeaderHeight,
  MobilePageHeaderHeight,
  SSFTopBarHeight,
  SSFBottomBarHeight,
  TabNavigationHeight,
  OpenSettingsNavigationWidth,
  ClosedSettingsNavigationWidth,
  BKJTableHeaderHeight,
  BKJTableFooterHeight,
  ShiftStatusSummaryFooterHeight,
  SetupBanner,
};

export type ElementDimensionsType = keyof typeof elementDimensions;
