import {
  CalendarHeaderDay,
  HeaderCalendarRowWrapper,
} from 'components/ShiftCalendar/ShiftCalendar.styled';
import dayjs from 'utils/dayjs';
import React from 'react';
import { useTranslation } from 'hooks/useTranslation';

export const CalendarHeaderRow: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const startOfWeek = dayjs().startOf('week');
  return (
    <HeaderCalendarRowWrapper>
      {[...Array(7)].map((_, i) => {
        const weekDay = dayjs(startOfWeek).add(i, 'day');
        return (
          <CalendarHeaderDay key={`${weekDay.format()}_header`}>
            {t(weekDay.format('ddd').toUpperCase())}
          </CalendarHeaderDay>
        );
      })}
    </HeaderCalendarRowWrapper>
  );
};
