import styled, { css } from 'styled-components';

export const Title = styled.h3`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;
`;

export const AvailableCountText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 21px;
`;

export const EstimatedTotalPayoutFooterText = styled.div<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    !disabled &&
    css`
      color: ${theme.colors.TextDisabled};
    `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px 0px 32px;
  gap: 24px;

  top: 0;
  position: sticky;
  background: white;
  overflow: hidden;
`;

export const AgencySelectListWrapper = styled.div`
  width: 100%;

  display: flex;
  flex: 1;

  padding: 32px 24px 32px 32px;
  overflow: scroll;
`;
