import { capitalize } from 'lodash';
import { ShiftPrettyStatusType } from 'constants/shiftStatusMap';

const capitalizeEnum = (str: string) =>
  str.replaceAll('_', ' ').split(' ').map(capitalize).join(' ');

export function transformValueToKey(value: string) {
  return !!value
    ? value
        .split(' ')
        .map((word) => word.toUpperCase())
        .join('_')
    : '';
}

export const getTranslatedResponse = (
  status: unknown,
  nameSpace: string,
  i18n: { exists: (key: string) => boolean },
  t: (key: string) => string,
): ShiftPrettyStatusType => {
  const statusEnum = transformValueToKey(status as string);
  if (i18n?.exists(`${nameSpace}:${statusEnum}`)) {
    return t(`${nameSpace}:${statusEnum}`) as ShiftPrettyStatusType;
  }
  return capitalizeEnum(statusEnum) as ShiftPrettyStatusType;
};
