import { BKJButton, BKJIcon, Flex } from '@bookjane2/bookjane-design-library';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  CallOutHeader,
  CallOutWrapper,
  CallOutViewDetailsLink,
  CallOutShiftDistributionLink,
  CallOutTitleSection,
  CallOutBodySection,
  EstimatedTimeSection,
  ProgressStatusSection,
  BKJProgressBar,
  CallOutProgressBarSection,
  CallOutHeaderText,
  CallOutHeaderIcon,
  CallOutHeaderWrapper,
  ProgressBold,
  ProgressText,
  Description,
} from 'components/ShiftModal/src/views/DetailView/GeneralTab/CallOut.styled';
import { WarningIcon } from 'pages/SchedulePage/Modals/SchedulePublishModal/styled';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import dayjs from 'utils/dayjs';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { OrderStatusEnum } from 'types/graphql-types';
import { ContinueCalloutDialog } from 'components/ContinueCalloutDialog';
import { queryRefetchService } from 'services/QueryRefetchService';
import { toastService } from 'services';

export const calculateEstimatedTimeRemaining = (estimatedCompletedAt: string) => {
  const now = dayjs();
  const estimatedCalloutCompleteTimestamp = dayjs(estimatedCompletedAt);

  const totalMins = Math.floor(estimatedCalloutCompleteTimestamp.diff(now, 'minutes'));
  const mins = Math.floor(totalMins % 60);

  const totalHours = Math.floor(estimatedCalloutCompleteTimestamp.diff(now, 'hours'));
  const hours = Math.floor(totalHours % 24);

  const totalDays = Math.floor(estimatedCalloutCompleteTimestamp.diff(now, 'days'));
  const days = Math.floor(totalDays);

  return { mins, hours, days };
};

export function CallOutSection() {
  const { t } = useTranslation();
  const {
    data: { fetchOrder },
    refetch,
  } = useShiftModalDataContext();

  const isCompleted = useMemo(
    () =>
      fetchOrder.currentlyActiveCalloutList?.isCompleted &&
      fetchOrder.status.value === OrderStatusEnum.pending,
    [fetchOrder],
  );

  const [isContinueCallOutConfirmationOpen, setIsContinueCallOutConfirmationOpen] = useState(false);

  const isCalloutPaused = useMemo(
    () =>
      fetchOrder.currentlyActiveCalloutList?.waitingForManualApproval &&
      fetchOrder.status.value === OrderStatusEnum.pending &&
      !isCompleted,
    [
      fetchOrder.currentlyActiveCalloutList?.waitingForManualApproval,
      fetchOrder.status.value,
      isCompleted,
    ],
  );

  const goToAuditTrailView = useCallback(
    (calloutListId: string) =>
      shiftModalRoutingService.goToView('AuditTrailView', {
        calloutListId: calloutListId,
      }),
    [],
  );

  const goToSettingsShiftDistributionView = useCallback(() => {
    window.history.pushState(
      {},
      'BookJane',
      `/settings/locations/${fetchOrder.community.id}/shift-distribution`,
    );
    window.location.reload();
  }, [fetchOrder.community.id]);

  if (!fetchOrder.currentlyActiveCalloutList) {
    return <></>;
  }

  const totalProgress = fetchOrder.currentlyActiveCalloutList.totalJanes;

  const { mins, hours, days } = calculateEstimatedTimeRemaining(
    fetchOrder.currentlyActiveCalloutList.estimatedCompletedAt,
  );

  const nextCalloutGroup =
    !!isCalloutPaused &&
    fetchOrder.currentlyActiveCalloutList &&
    fetchOrder.currentlyActiveCalloutList.manualApprovalIndexes?.length
      ? fetchOrder.currentlyActiveCalloutList.manualApprovalIndexes[0].groupName
      : '';

  return (
    <CallOutWrapper>
      <CallOutTitleSection>
        <Flex flexDirection="row" flex="1">
          <CallOutHeader>
            <CallOutHeaderWrapper>
              <CallOutHeaderText>
                {t('dashboard:CALL_OUT')}{' '}
                {isCompleted
                  ? t('dashboard:COMPLETE')
                  : isCalloutPaused
                  ? t('dashboard:PAUSED')
                  : t('common:IN_PROGRESS')}
                &nbsp;
              </CallOutHeaderText>
              {isCalloutPaused && <BKJIcon iconName="Pause" />}
              <CallOutHeaderIcon>{isCompleted && <WarningIcon />}</CallOutHeaderIcon>
            </CallOutHeaderWrapper>
          </CallOutHeader>
          <CallOutViewDetailsLink
            onClick={() => goToAuditTrailView(fetchOrder.currentlyActiveCalloutList!.id)}
          >
            {t('common:VIEW_DETAILS')}
          </CallOutViewDetailsLink>
        </Flex>
      </CallOutTitleSection>
      <CallOutProgressBarSection>
        <BKJProgressBar
          value={fetchOrder.currentlyActiveCalloutList.totalNotifiedAndSkipped}
          max={totalProgress}
          height="8px;"
        ></BKJProgressBar>
      </CallOutProgressBarSection>
      <CallOutBodySection>
        <Flex flexDirection="row" flex="1">
          <ProgressStatusSection>
            <ProgressBold>{t('dashboard:PROGRESS')}:&nbsp;</ProgressBold>
            <ProgressText>
              {fetchOrder.currentlyActiveCalloutList.totalNotifiedAndSkipped}/{totalProgress}
            </ProgressText>
          </ProgressStatusSection>
          <EstimatedTimeSection>
            <ProgressBold>{t('dashboard:EST_TIME_REMAINING')}:&nbsp;</ProgressBold>
            <ProgressText>
              {days > 0 && <span>{days}d&nbsp;</span>}
              {hours > 0 && <span>{hours}hr&nbsp;</span>}
              {mins > 0 && <span>{mins}m&nbsp;</span>}
              {days <= 0 && hours <= 0 && mins <= 0 && <span>-</span>}
            </ProgressText>
          </EstimatedTimeSection>
        </Flex>
        <Flex>
          <Description>
            {t('dashboard:EST_TIME_REMAINING_BASED_ON')}{' '}
            <CallOutShiftDistributionLink onClick={goToSettingsShiftDistributionView}>
              {t('dashboard:SHIFT_DISTRIBUTION_RULES')}
            </CallOutShiftDistributionLink>{' '}
            {t('dashboard:FOR_THIS_LOCATION')} {t('dashboard:REFRESH_TO_VIEW_UPDATES')}
          </Description>
        </Flex>
        <Flex justifyContent="space-between">
          {nextCalloutGroup && (
            <Flex padding="20px 0 0 0" flexDirection="row">
              {t('dashboard:NEXT_GROUP')}:&nbsp;{nextCalloutGroup}
            </Flex>
          )}
          {isCalloutPaused && (
            <Flex flexDirection="row-reverse" padding="10px 0 0 0">
              <BKJButton
                variant="PurpleSolid"
                width="192px"
                onClick={(e) => setIsContinueCallOutConfirmationOpen(true)}
              >
                {t('dashboard:CONTINUE_CALL_OUT')}
              </BKJButton>
            </Flex>
          )}
        </Flex>
        {!!isCalloutPaused && fetchOrder.currentlyActiveCalloutList && (
          <ContinueCalloutDialog
            isContinueCallOutConfirmationOpen={isContinueCallOutConfirmationOpen}
            setIsContinueCallOutConfirmationOpen={setIsContinueCallOutConfirmationOpen}
            calloutListId={fetchOrder.currentlyActiveCalloutList.id}
            nextGroupName={
              fetchOrder.currentlyActiveCalloutList.manualApprovalIndexes?.length
                ? fetchOrder.currentlyActiveCalloutList.manualApprovalIndexes[0].groupName
                : ''
            }
            onSuccess={() => {
              toastService.success(t('dashboard:CALL_OUT_SUCCESSFUL_RESUMED'));
              queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${fetchOrder.id}`);
              if (refetch) refetch();
            }}
          />
        )}
      </CallOutBodySection>
    </CallOutWrapper>
  );
}

export function CallOutViewComponent() {
  return <CallOutSection />;
}
