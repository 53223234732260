import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';

import { CUSTOMER_POSITIONS } from 'constants/endpoints';

export const API_POST_editRankingCSV = (params: {
  formData: FormData;
  selectedCommunity: number;
  SelectedPosition: string;
}): ApiRequestConfigType => {
  const url = `${CUSTOMER_POSITIONS}/${params.SelectedPosition}/${params.selectedCommunity}/import_csv`;
  return {
    __typename: 'LegacyAPI',
    method: METHOD_POST,
    url,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: params.formData,
  };
};
