import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  font-family: 'Poppins SemiBold';
  color: #961e36 !important;
  display: inline-block;
  font-weight: bold;
  vertical-align: baseline;
  font-size: 12px;
  margin-top: 6px;
  position: relative;
  line-height: 1.33;
`;
