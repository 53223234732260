import { ReviewBox } from './ReviewBox/ReviewBox';
import { LabelText, ValueText } from '../DetailView.styled';
import {
  LabelAddress,
  ReviewTimestampText,
  SeparatingLine,
  TooltipWrapper,
  CallOutWrapper,
} from './GeneralTab.styled';
import { Flex } from 'components/Flex';
import { OrderStatusEnum } from 'types/graphql-types';
import { FC } from 'react';
import { RateShiftViewProvider } from 'components/ShiftModal/src/views/RateShiftView/RateShiftView.context';
import { StarRating } from 'components/StarRating';
import { parseDate } from 'utils';
import { css } from 'styled-components';
import { UserGuard } from 'guards/UserGuard';
import { IGeneralTabProps } from 'components/ShiftModal/src/views/DetailView/GeneralTab/GeneralTab.types';
import { formatTime, formatUnpaidBreakTime } from '../TimesheetsTab/TimesheetsTab.utils';
import { RecurrenceList } from 'components/RecurrenceList';
import { BKJIcon, BKJIconTooltip, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import {
  formatLocation,
  getCancellationReason,
  getShiftModalShiftJaneName,
} from 'mappers/shiftModalOrderDetailsMapper';
import { startCase } from 'lodash';
import { MountTransition } from 'routes';
import { useUserRoleSelector } from 'store/selectors/userSelectors/userRoleSelector';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { ShiftCustomFields } from 'components/ShiftModal/src/views/DetailView/GeneralTab/ShiftCustomFields/ShiftCustomFields';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { useSelector } from 'react-redux';
import { BKJInlineGoogleMapsComponent } from 'components/BKJInlineGoogleMapView';
import { BKJGoogleMapsProvider, createGoogleMapsUrl } from 'components/BKJGoogleMapsProvider';
import { CallOutSection } from 'components/ShiftModal/src/views/DetailView/GeneralTab/CallOut';
import { useTranslation } from 'hooks/useTranslation';
import { isAuditTrailFeatureEnabledForCommunitySelector } from 'store/selectors/featureFlagSelectors';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { DetailViewInnerHeader } from 'components/ShiftModal/src/views/DetailView/DetailTabView';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';

export const shiftModalGeneralTabValueTextMarginLeft = 16;
export const shiftModalGeneralTabFormItemVerticalMargin = 24;
const formatRepeatDays = (weekdays: string[]) => weekdays.map((day) => startCase(day)).join(', ');

export const GeneralTab: FC<IGeneralTabProps> = ({ data }): JSX.Element => {
  const { t, i18n } = useTranslation();
  const format = `DD, YYYY [${t('common:AT')}] hh:mm a`;
  const userType = useUserRoleSelector();
  const isCompletedByAgency =
    data.fetchOrder!.mainJane?.agencyCommunity &&
    data.fetchOrder!.status.value === OrderStatusEnum.completed;

  const showRate =
    isCompletedByAgency || data.fetchOrder!.status.value !== OrderStatusEnum.completed;

  const isCustomFieldsEnabled = useSelector(FEATURE_SELECTORS_MAP['FEATURE_TYPE_CUSTOM_FIELDS']);

  const isShiftOpen = data.fetchOrder?.status?.value === OrderStatusEnum.pending;

  const isAuditTrailEnabled = useSelector(
    isAuditTrailFeatureEnabledForCommunitySelector({
      communityId: data.fetchOrder.community.id,
      auditTrailVersion: data.fetchOrder.auditTrailVersion,
    }),
  );

  const hasCallout = isShiftOpen && !!data.fetchOrder?.currentlyActiveCalloutList;
  const cancellationReason =
    data.fetchOrder!.status.value === OrderStatusEnum.cancelled ? getCancellationReason(data) : '';
  const translateCancellationReason = getTranslatedResponse(cancellationReason, 'status', i18n, t);

  return (
    <ErrorBoundary componentName="GeneralTab" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <Flex flexDirection="column" childMarginBottom="40px" flex="1">
        <MountTransition>
          <DetailViewInnerHeader data={data} />
          <Flex>
            {isAuditTrailEnabled && hasCallout && (
              <CallOutWrapper>
                <Flex flexDirection="column" flex="1">
                  <CallOutSection />
                </Flex>
              </CallOutWrapper>
            )}
          </Flex>
          <Flex>
            {data.fetchOrder!.isReviewAllowed &&
            // Check if mainJane is present as a completed shift may not be assigned
            data.fetchOrder!.mainJane ? (
              <UserGuard
                userType={[
                  'USER_TYPE_B2B',
                  'USER_TYPE_PREMIUM_SECONDARY',
                  'USER_TYPE_ESSENTIAL_SECONDARY',
                ]}
              >
                <Flex flexDirection="column" width="100%" padding="32px 32px 0">
                  <RateShiftViewProvider>
                    <ReviewBox janeName={getShiftModalShiftJaneName(data) as string} />
                  </RateShiftViewProvider>
                </Flex>
              </UserGuard>
            ) : (
              data.fetchOrder!.review && (
                <Flex
                  flexDirection="column"
                  width="100%"
                  padding="32px 32px 40px"
                  css={css`
                    border-bottom: 1px solid #d3d7dc;
                  `}
                >
                  <Flex justifyContent="space-between">
                    <LabelText>{t('dashboard:SUBMITTED_REVIEW')}</LabelText>
                    <ReviewTimestampText>
                      {parseDate(data.fetchOrder!.review.updatedAt, format)}
                    </ReviewTimestampText>
                  </Flex>
                  <Flex padding="12px 0">
                    <StarRating rating={data.fetchOrder!.review.rating as number} />
                  </Flex>
                  <LabelText>{data.fetchOrder!.review.comment}</LabelText>
                </Flex>
              )
            )}
          </Flex>

          <Flex flexDirection="column" padding="32px" childMarginBottom="32px">
            <Flex>
              {data.fetchOrder!.totalDuration && (
                <Flex flexDirection="column" width="240px">
                  <LabelText>{t('common:TOTAL_PAID_DURATION')}</LabelText>
                  <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                    {formatTime(data.fetchOrder!.totalDuration)}
                  </ValueText>
                </Flex>
              )}
              {data.fetchOrder!.orderSchedule.breakTime && (
                <Flex flexDirection="column">
                  <LabelText>{t('common:UNPAID_BREAK_TIME')}</LabelText>
                  <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                    {formatUnpaidBreakTime(data.fetchOrder!.orderSchedule.breakTime)}
                  </ValueText>
                </Flex>
              )}
            </Flex>
            {data.fetchOrder!.recurrence && (
              <>
                <Flex>
                  {data.fetchOrder!.recurrence.frequency && (
                    <Flex flexDirection="column" width="240px">
                      <LabelText>{t('dashboard:FREQUENCY')}</LabelText>
                      <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                        {data.fetchOrder!.recurrence.recurrenceLabel}
                      </ValueText>
                    </Flex>
                  )}
                  {data.fetchOrder!.recurrence.expirationDate && (
                    <Flex flexDirection="column">
                      <LabelText>{t('dashboard:ENDS_ON')}</LabelText>
                      <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                        {parseDate(data.fetchOrder!.recurrence.expirationDate)}
                      </ValueText>
                    </Flex>
                  )}
                </Flex>
                {data.fetchOrder!.recurrence.weekdays &&
                  data.fetchOrder!.recurrence.weekdays.length > 0 && (
                    <Flex>
                      <Flex flexDirection="column">
                        <LabelText>{t('dashboard:REPEAT_DAYS')}</LabelText>
                        <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                          {formatRepeatDays(data.fetchOrder!.recurrence.weekdays)}
                        </ValueText>
                      </Flex>
                    </Flex>
                  )}
              </>
            )}
            {data.fetchOrder!.location && (
              <Flex flexDirection="column">
                <LabelText>
                  {userType === 'Agency' ? t('common:CUSTOMER') : t('common:LOCATION')}
                </LabelText>
                <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                  {data.fetchOrder!.community.name}
                </ValueText>
                <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                  {data.fetchOrder!.location.fullAddress && (
                    <Flex>
                      <Flex flexDirection="column" width="25px">
                        <BKJIcon iconName="Location" color="AccentPurple" height="19px" />
                      </Flex>
                      <Flex flexDirection="column">
                        <a
                          href={createGoogleMapsUrl(data.fetchOrder!.location.fullAddress)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <LabelAddress>{formatLocation(data.fetchOrder!.location)}</LabelAddress>
                        </a>
                      </Flex>
                    </Flex>
                  )}
                </ValueText>
                {data.fetchOrder!.location.longitude &&
                  data.fetchOrder!.location.latitude &&
                  data.fetchOrder!.location.fullAddress && (
                    <UserGuard userType="USER_TYPE_AGENCY">
                      <BKJGoogleMapsProvider>
                        <BKJInlineGoogleMapsComponent
                          height={'132px'}
                          width={'500px'}
                          addressInfo={{
                            longitude: data.fetchOrder!.location.longitude,
                            latitude: data.fetchOrder!.location.latitude,
                            fullAddress: data.fetchOrder!.location.fullAddress,
                          }}
                          zoom={13}
                        />
                      </BKJGoogleMapsProvider>
                    </UserGuard>
                  )}
              </Flex>
            )}
            {showRate &&
              data.fetchOrder!.rate &&
              data.fetchOrder!.rate.rateLabel !== '-' &&
              data.fetchOrder!.estimatedTotalCharge &&
              data.fetchOrder!.estimatedTotalCharge.rateLabel !== '-' &&
              data.fetchOrder!.orderSchedule.timeSheet &&
              data.fetchOrder!.orderSchedule.timeSheet.status && (
                <Flex>
                  <Flex flexDirection="column" width="240px">
                    <Flex>
                      <LabelText>
                        {userType === 'Agency'
                          ? t('dashboard:HOURLY_CHARGE_RATE')
                          : t('dashboard:ESTIMATED_HOURLY_COST')}
                        &nbsp;&nbsp;
                      </LabelText>
                      <BKJIconTooltip placement="bottom" iconName="Info">
                        <TooltipWrapper>{t('GENERAL_TAB_TOOLTIP_TEXT')}</TooltipWrapper>
                      </BKJIconTooltip>
                    </Flex>
                    <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                      {data.fetchOrder!.rate.rateLabel}
                    </ValueText>
                  </Flex>
                  <Flex flexDirection="column">
                    <LabelText>{t('dashboard:ESTIMATED_TOTAL_COST')}</LabelText>
                    <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                      {data.fetchOrder!.estimatedTotalCharge.rateLabel}
                    </ValueText>
                  </Flex>
                </Flex>
              )}
            <Flex flexDirection="column">
              <LabelText>{t('dashboard:OVERTIME')}</LabelText>
              <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                {data.fetchOrder!.allowOvertime
                  ? t('dashboard:ALLOWED')
                  : t('dashboard:NOT_ALLOWED')}
              </ValueText>
            </Flex>
            {data.fetchOrder!.description && (
              <Flex flexDirection="column">
                <LabelText>{t('schedule:SHIFT_NOTES')}</LabelText>
                {data.fetchOrder!.description.split('\n').map((textSegment, i, { length }) => (
                  <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                    {textSegment}
                    {/** Add line break if not first or last text segment */}
                    {i > 0 && i < length - 1 && <br />}
                  </ValueText>
                ))}
              </Flex>
            )}

            {data.fetchOrder!.status.value === OrderStatusEnum.cancelled && (
              <Flex flexDirection="column">
                <LabelText>{t('dashboard:CANCELLATION_REASON')}</LabelText>
                <ValueText marginLeft={shiftModalGeneralTabValueTextMarginLeft}>
                  {translateCancellationReason}
                </ValueText>
              </Flex>
            )}
            {isCustomFieldsEnabled && (
              <>
                <SectionDivider
                  variant="Thin"
                  margin={`0 0 ${shiftModalGeneralTabFormItemVerticalMargin}px 0`}
                />
                <ShiftCustomFields order={data.fetchOrder} />
              </>
            )}

            {data.fetchOrder!.futureShifts.length > 0 && (
              <>
                <SeparatingLine />
                <RecurrenceList shifts={data.fetchOrder!.futureShifts} />
              </>
            )}
          </Flex>
        </MountTransition>
      </Flex>
    </ErrorBoundary>
  );
};
