import { BKJDropdownMenu } from '@bookjane2/bookjane-design-library';
import styled, { css } from 'styled-components';

export const ShiftActionDropdownMenu = styled(BKJDropdownMenu)`
  ${({ variant }) =>
    variant === 'PurpleText' &&
    css`
      font-weight: bold;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
    `}
`;
