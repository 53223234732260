import { BKJIcon, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  WarningWrapper,
  WarningText,
} from 'components/ShiftModal/src/views/ReleaseShiftView/ReleaseShiftView.styled';
import { useTranslation } from 'react-i18next';
import dayjs from 'utils/dayjs';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const ReleaseShiftViewWarningComponent = () => {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();
  const { startAt, mainJane } = data.fetchOrder!;
  const isAgencyJane = !!mainJane?.agencyCommunity;

  const diff = dayjs(startAt).diff(dayjs(), 'hour');
  if (diff > 12 || !isAgencyJane) return null;
  return (
    <WarningWrapper>
      <Flex>
        <BKJIcon iconName="Warning" />
      </Flex>
      <Flex>
        <WarningText>{t('schedule:RELEASE_SHIFT_WITHIN_12_HOURS')}</WarningText>
      </Flex>
    </WarningWrapper>
  );
};

export const ReleaseShiftViewWarning = () => (
  <ErrorBoundary
    componentName="ReleaseShiftViewWarning"
    showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
  >
    <ReleaseShiftViewWarningComponent />
  </ErrorBoundary>
);
