import { SSFBarsProvider } from 'components/SSFBars/SSFBars.context';
import { useSSFBehaviors } from 'hooks';
import { usePathname } from 'hooks/usePathname';
import { dashboardAvailableShiftsPageConfig } from 'pages/DashboardAvailableShiftsPage/DashboardAvailableShiftsPage.constants';
import { dashboardMyUpcomingShiftsPageConfig } from 'pages/DashboardMyUpcomingShiftsPage/DashboardMyUpcomingShiftsPage.constants';
import { DashboardPageRouteType } from 'pages/DashboardPage/AgencyDashboardPage';
import { DashboardPageConfigMapType } from 'pages/DashboardPage/DashboardPage.types';
import { dashboardPastShiftsPageConfig } from 'pages/DashboardPastShiftsPage/DashboardPastShiftsPage.constants';
import { dashboardRecentlyCreatedShiftsPageConfig } from 'pages/DashboardRecentlyCreatedShiftsPage/DashboardRecentlyCreatedShiftsPage.constants';
import { dashboardUpcomingShiftsPageConfig } from 'pages/DashboardUpcomingShiftsPage/DashboardUpcomingShiftsPage.constants';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { GQLAPIContextType, SSFBehaviorsValueType } from 'types/common.types';
import {
  dashboardAvailableShiftsPage,
  dashboardPastShiftsPage,
  dashboardRecentlyCreatedShiftsPage,
  dashboardUpcomingShiftsPage,
} from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';
import { withMemo } from 'utils/withMemo';

export const DashboardPageContext = createGQLDataContext();

const dashboardPageConfigMap: DashboardPageConfigMapType = {
  // B2B config
  '/dashboard/upcoming': dashboardUpcomingShiftsPageConfig,
  '/dashboard/recent': dashboardRecentlyCreatedShiftsPageConfig,
  '/dashboard/past': dashboardPastShiftsPageConfig,

  // Agency config
  '/dashboard/available': dashboardAvailableShiftsPageConfig,
  '/dashboard/myupcoming': dashboardMyUpcomingShiftsPageConfig,
};

function getNumFiltersApplied(values: SSFBehaviorsValueType) {
  let numFiltersApplied = 0;
  if (values.employmentStatus) numFiltersApplied += values.employmentStatus.length;
  if (values.shiftStatus) numFiltersApplied += values.shiftStatus.length;
  if (values.position_id) numFiltersApplied += values.position_id.length;
  if (values.internalJaneType) numFiltersApplied += values.internalJaneType.length;
  if (values.externalJaneType) numFiltersApplied += values.externalJaneType.length;
  if (values.callOutType) numFiltersApplied += values.callOutType.length;
  return numFiltersApplied;
}

const _DashboardPageProvider: FC = ({ children }) => {
  const activeTab = usePathname() as DashboardPageRouteType;
  const { locationFilter } = useAuthRouteDataContext();
  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [values, opts] = useSSFBehaviors(
    dashboardPageConfigMap[activeTab]({
      location: locationFilter.initialValue,
      // agency_id:
      isUserAgencyAndAgencyLocationFilterForcedDisabled,
    }),
  );

  const value = {
    values,
    numFiltersApplied: getNumFiltersApplied(values),
    ...opts,
  };
  return (
    <DashboardPageContext.Provider value={value}>
      <SSFBarsProvider>{children}</SSFBarsProvider>
    </DashboardPageContext.Provider>
  );
};

export const DashboardPageProvider: FC = withMemo(_DashboardPageProvider);

export function useDashboardPageContext<
  T extends
    | dashboardAvailableShiftsPage
    | dashboardPastShiftsPage
    | dashboardRecentlyCreatedShiftsPage
    | dashboardUpcomingShiftsPage,
>(): GQLAPIContextType<T> {
  return useContext(DashboardPageContext);
}
