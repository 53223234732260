import styled, { css } from 'styled-components';
import { Flex } from 'components/Flex';

export const JaneDetailsNavigationWrapper = styled(Flex)`
  flex-direction: column;
  height: fit-content;
  min-height: fit-content;
  position: sticky;
  background-color: white;
  z-index: 1;
  top: 0;
`;

export const NavigationButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dfe3e9;
`;

export const NavigationButton = styled.button<{ isActive: boolean }>`
  position: relative;
  background-color: none;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.Grey7};
  height: 48px;
  min-height: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  transition: all 100ms linear;
  overflow: hidden;
  cursor: pointer;
  flex: 1 1 0px;
  ${({ theme }) => theme.fonts.PoppinsText};

  &:hover {
    ${({ isActive }) =>
      !isActive &&
      css`
        &::after {
          transition: min-width 500ms ease-out, background-color 600ms linear;
          height: 4px;
          min-width: 35%;
          background-color: ${({ theme }) => theme.colors.Grey5};
        }
      `};
  }
  &::after {
    will-change: min-width, background-color;
    transition: min-width 200ms ease-out, background-color 300ms linear;
    content: '';
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    bottom: -2px;
    min-width: 0px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.AccentGreen};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        height: 4px;
        min-width: calc(100% - 25px);
        background-color: ${({ theme }) => theme.colors.AccentGreen};
      }
      cursor: unset;
    `};
`;
