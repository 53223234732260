import { InfiniteScrollLoading } from 'components/InfiniteScrollLoading';
import { AvailabilityEmptyState } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvaibilityEmptyState';
import { AvailabilityAvatar } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailabilityAvatar';
import { AvailableMembersDataContext } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/AvailableMemeberTab.context';
import {
  JaneFacility,
  JaneName,
  JaneWrapper,
  TableAvailabilityRow,
  TableAvailabilityRowWrapper,
  TableColumn,
  TeamMemberWrapper,
  TimeColumn,
} from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab/index.styled';
import { FC, Fragment, useContext } from 'react';
import { availableTeamMembers_fetchOrder_orderAvailabilities_nodes } from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';

const AvailabilityRow: FC<availableTeamMembers_fetchOrder_orderAvailabilities_nodes> = (
  props,
): JSX.Element => {
  const { createdAt, jane } = props;
  const { month, frenchFormat } = getLocalizedDateFormat(createdAt);

  const isToday = dayjs(createdAt).isToday();
  const translatedDate =
    isToday && frenchFormat
      ? dayjs(createdAt).format('HH:mm')
      : isToday
      ? dayjs(createdAt).format('h:mm a')
      : `${month} ${dayjs(createdAt).format(
          frenchFormat ? 'DD, YYYY [à] HH:mm' : 'DD, YYYY [at] h:mm a',
        )}`;
  return (
    <TableAvailabilityRowWrapper>
      <TableAvailabilityRow>
        <TableColumn>
          <TimeColumn>{translatedDate}</TimeColumn>
        </TableColumn>
        <TableColumn>
          <TeamMemberWrapper>
            <div>
              <AvailabilityAvatar {...jane} />
            </div>
            <JaneWrapper>
              <JaneName>{jane?.shorthandName}</JaneName>
              <JaneFacility>{jane?.communityName}</JaneFacility>
            </JaneWrapper>
          </TeamMemberWrapper>
        </TableColumn>
      </TableAvailabilityRow>
    </TableAvailabilityRowWrapper>
  );
};

export const AvailableMemberTabTableBody = () => {
  const { data, loadMore } = useContext(AvailableMembersDataContext);

  if (!data) return null;
  if (!data?.fetchOrder) return null;
  if (!data?.fetchOrder?.orderAvailabilities) return null;
  if (!data?.fetchOrder?.orderAvailabilities?.nodes) return null;
  if (data?.fetchOrder?.orderAvailabilities?.nodes?.length === 0) return <AvailabilityEmptyState />;
  return (
    <Fragment>
      {data.fetchOrder.orderAvailabilities.nodes.map(
        (node: availableTeamMembers_fetchOrder_orderAvailabilities_nodes) => (
          <AvailabilityRow {...node} key={node.id} />
        ),
      )}
      {!!data.fetchOrder.orderAvailabilities.pageInfo.hasNextPage ? (
        <InfiniteScrollLoading onViewportEnter={loadMore} />
      ) : null}
    </Fragment>
  );
};
