import React, { FC } from 'react';
import { BKJButton, BKJIcon } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';

import {
  CSVBorderHeaderStyle,
  H3,
  TeamMemberCSVWrapper,
  LocationHeader,
  ContentName,
  LinkLine,
} from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/SuccessView/SuccessView.styled';

import { inviteTeaMemberCSVModalRoutingService } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
import { TeamMemberCSVViewFormProvider } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.form.context';
export const Component: FC = () => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={CSVBorderHeaderStyle}
    >
      <Flex flexDirection="column" padding="32px 40px 15px" gap="32px">
        <H3>Invite Successful </H3>
        <TeamMemberCSVWrapper>
          <LocationHeader>
            You may now start scheduling. Team members will be notified by email and text message to
            download the BookJane app in order to view and accept shifts.
          </LocationHeader>
        </TeamMemberCSVWrapper>
        <Flex flexDirection="column" gap="8px">
          <Flex alignItems="center" gap="8px">
            <BKJIcon iconName="StepCheck" /> <ContentName>Invited</ContentName>
          </Flex>
          <LinkLine />
          <Flex alignItems="center" gap="8px">
            <BKJIcon iconName="StepCheck" color="Grey6" />
            <ContentName>Start scheduling</ContentName>
          </Flex>
        </Flex>
      </Flex>

      <Flex height="100%"></Flex>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={() => inviteTeaMemberCSVModalRoutingService.close()}
          type="button"
        >
          Done
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};
export const SuccessView: FC = () => {
  return (
    <TeamMemberCSVViewFormProvider>
      <Component />
    </TeamMemberCSVViewFormProvider>
  );
};
