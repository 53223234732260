import { BKJListItem } from '@bookjane2/bookjane-design-library';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { FC, Fragment } from 'react';
import { css } from 'styled-components';
import { UseFormBehaviors_ValidationFnType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { IEditJaneViewForm } from 'components/JaneModal/views/EditJaneView/EditJaneView.form.context';
import { MessageWrapper } from 'components/JaneModal/views/EditJaneView/EditJaneView.styled';
import dayjs from 'dayjs';
export interface ComboBoxOption {
  key: string;
  id: string; // Add an 'id' property
  value: string;
  label: string;
}
export const employmentStatusOptions: {
  key: 'full_time' | 'part_time' | 'casual';
  label: 'Full-Time' | 'Part-Time' | 'Casual';
  value: string;
}[] = [
  {
    key: 'full_time',
    label: 'Full-Time',
    value: 'Full Time',
  },

  {
    key: 'part_time',
    label: 'Part-Time',
    value: 'Part Time',
  },
  {
    key: 'casual',
    label: 'Casual',
    value: 'Casual',
  },
];
export const RenderOptions: FC<IBKJSelectRenderOptionsProps> = ({
  name,
  options,
  selected,
  onChange,
}) => {
  return (
    <Fragment>
      {options.length === 0 ? (
        <MessageWrapper>Select month first</MessageWrapper>
      ) : (
        options.map(({ key, label, value }) => (
          <BKJListItem
            name={name}
            selected={selected}
            key={key}
            label={label}
            value={value}
            onChange={onChange}
            css={css`
              background-color: white;
            `}
          />
        ))
      )}
    </Fragment>
  );
};
export const monthToDaysMap: { [key: string]: string[] } = {};

for (let month = 0; month < 12; month++) {
  const monthName = dayjs().month(month).format('MMMM');
  const daysInMonth = Array.from({ length: dayjs().month(month).daysInMonth() }, (_, i) =>
    (i + 1).toString(),
  );

  monthToDaysMap[monthName] = daysInMonth;
}
export const EmployeeStatus = employmentStatusOptions.map((item, index) => ({
  id: index + 1,
  value: item.value,
  label: item.label,
}));
export const VALIDATION_required = (value: string) => {
  if (value.length === 0 || !value[0]) {
    return 'Mandatory field.';
  }
  return '';
};
export const VALIDATION_position_id: UseFormBehaviors_ValidationFnType<IEditJaneViewForm> = (
  value,
): string => {
  const position_id = value as string[];
  if (position_id.length === 0) return 'Must select at least one Position';
  return '';
};

export const convertEmploymentStatus = (employmentStatus: string) => {
  const convertedEmploymentStatus = employmentStatus.replace(' ', '_').toLowerCase();
  let employmentStatusValue;
  switch (convertedEmploymentStatus) {
    case 'p':
      employmentStatusValue = 'part_time';
      break;
    case 'f':
      employmentStatusValue = 'full_time';
      break;
    case 'c':
      employmentStatusValue = 'casual';
      break;
    default:
      employmentStatusValue = convertedEmploymentStatus;
  }
  return employmentStatusValue;
};

export const monthNameToNumber: { [key: string]: number } = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};
export const monthNumberToName: { [key: number]: string } = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
export interface AdditionalPosition {
  position_type: string;
  position_id: string; // Replace 'string' with the appropriate data type if needed
}

export interface DestroyedPosition {
  id: number;
  position_type: string;
  position_id: number;
  _destroy: number;
}

export type DestroyedAndAddPositionArrays = DestroyedPosition | AdditionalPosition;
