import { fetchJaneConfig } from 'components/JaneModal/JaneModal.constants';
import { getJaneColorByStatus } from 'components/JaneModal/getJaneColorByStatus';
import { AGENCY_JANES, B2B_JANES, RESEND_INVITE, CUSTOMER_TEAMS } from 'constants/endpoints';
import { useGQLQuery } from 'hooks';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SessionService, toastService } from 'services';
import { janeModalJaneDetails } from 'types/graphql-types';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { createGQLDataContext } from 'utils/createDataContext';
import { useTranslation } from 'hooks/useTranslation';
import { METHOD_GET, METHOD_PATCH } from 'requests';

interface IFetchJaneByIdData {
  showDeactivateConfirmModal: boolean;
  setShowDeactivateConfirmModal: (showDeactivateConfirmModal: boolean) => void;
  deactivateTeamMember: () => void;
  reactivateTeamMember: () => void;
  resendInviteTeamMember: () => void;
}

export const FetchJaneByIdByIdDataContext = createGQLDataContext<IFetchJaneByIdData>(
  {} as IFetchJaneByIdData,
);

export const FetchJaneByIdByIdDataProvider: FC<{
  janeId: string;
}> = ({ children, janeId }) => {
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] = useState(false);
  const [activities, setActivities] = useState([]);
  const { t } = useTranslation();
  const { apiRequest } = useAPIActions();
  const {
    data: value,
    fetch,
    isLoading,
    refetch,
  } = useGQLQuery<janeModalJaneDetails, janeModalJaneDetails, { id: string | undefined }>(
    fetchJaneConfig(janeId),
  );
  useEffect(() => {
    if (isLoading) fetch();
  }, [fetch, isLoading, janeId]);
  const data = value?.fetchJane;
  const getActivitiesTeamMember = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_GET,
          url: `${CUSTOMER_TEAMS}/${data?.id}/activities`,
        },
        {
          onSuccess: (response) => {
            setActivities(response?.data?.activities);
          },
        },
      ),
    [apiRequest, data?.id],
  );
  useEffect(() => {
    if (isLoading) getActivitiesTeamMember();
  }, [isLoading, getActivitiesTeamMember]);
  const deactivateTeamMember = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_PATCH,
          url: SessionService.assertUserType('Agency')
            ? AGENCY_JANES(data?.id)
            : B2B_JANES(data?.id),
          body: { access_status: 'blocked' },
        },
        {
          onSuccess: () => {
            toastService.success(t('success:TEAM_MEMBER_DEACTIVATED'));
            setShowDeactivateConfirmModal(false);
            fetch();
          },
        },
      ),
    [apiRequest, data?.id, fetch, t],
  );
  const reactivateTeamMember = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_PATCH,
          url: B2B_JANES(data?.id),
          body: { access_status: 'approved' },
        },
        {
          onSuccess: () => {
            toastService.success(t('success:TEAM_MEMBER_REACTIVATED'));
            fetch();
          },
        },
      ),
    [apiRequest, data?.id, t, fetch],
  );
  const resendInviteTeamMember = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_PATCH,
          url: RESEND_INVITE(data?.id),
        },
        {
          onSuccess: () => {
            toastService.success(t('success:INVITE_SENT'));
            fetch();
          },
        },
      ),
    [apiRequest, data?.id, fetch, t],
  );
  const dotColor = getJaneColorByStatus(data?.accessStatus?.name);
  const contextValues = useMemo(() => {
    return {
      data,
      dotColor,
      refetch,
      showDeactivateConfirmModal,
      deactivateTeamMember,
      setShowDeactivateConfirmModal,
      reactivateTeamMember,
      resendInviteTeamMember,
      isLoading,
      activities,
    };
  }, [
    data,
    deactivateTeamMember,
    dotColor,
    refetch,
    showDeactivateConfirmModal,
    reactivateTeamMember,
    resendInviteTeamMember,
    isLoading,
    activities,
  ]);
  return (
    <FetchJaneByIdByIdDataContext.Provider value={contextValues}>
      {children}
    </FetchJaneByIdByIdDataContext.Provider>
  );
};

export const useFetchJaneByIdDataContext = () => useContext(FetchJaneByIdByIdDataContext);
