import { BKJComboBoxAsync, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import {
  LocationFilterDataContext,
  LocationFilterDataProvider,
  useLocationFilterDataContext,
} from 'components/LocationFilterInput/LocationFilterInput.context';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { usePathname } from 'hooks/usePathname';
import { useSelector } from 'react-redux';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

function LocationFilterInputComponent(props: Omit<ILocationFilterProps, 'options'>) {
  const { value, onChange, name = 'location', disabled } = props;
  const pathname = usePathname();

  const { data: options = [], values, onChange: onSearchChange } = useLocationFilterDataContext();
  const isLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  let __disabled__ = disabled;

  const isTheCurrentPageAnythingOtherThanTimesheets = pathname !== '/timesheets';
  // This is due to the API not being able to meet SLA
  if (isLocationFilterForcedDisabled && isTheCurrentPageAnythingOtherThanTimesheets)
    __disabled__ = true;

  return (
    <BKJComboBoxAsync
      name={name}
      type="Single"
      value={value}
      searchKey="byName"
      onChange={onChange}
      action="OpenToggle"
      disabled={__disabled__}
      options={options}
      searchValue={values.byName}
      onSearchChange={onSearchChange}
      context={LocationFilterDataContext}
      placeholder="Select location"
    />
  );
}

export const TeamLocationFilterInput = (props: Omit<ILocationFilterProps, 'options'>) => {
  return (
    <ErrorBoundary
      componentName="LocationFilterInput"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <LocationFilterDataProvider>
        <LocationFilterInputComponent {...props} />
      </LocationFilterDataProvider>
    </ErrorBoundary>
  );
};
