import { FC, Fragment } from 'react';
import { H3 } from 'components/Typography';
import {
  BKJButton,
  BKJListItem,
  BKJSelect,
  BKJTypography,
  ErrorBoundary,
  Flex,
} from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useEditViewContext } from 'components/ShiftModal/src/views/EditView/EditView.context';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { withMemo } from 'utils/withMemo';
import { AutoMargin } from 'components/AutoMargin';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { useTranslation } from 'hooks/useTranslation';

const hourDropdownOptions = [4, 12, 24, 48, 72].map((dur) => ({
  key: `${dur}`,
  value: `${dur}`,
  label: `${dur} hrs`,
}));

const RenderOptions: FC<IBKJSelectRenderOptionsProps> = withMemo(function _RenderOptions(props) {
  return (
    <Fragment>
      {hourDropdownOptions.map((option) => {
        const { type, ...restProps } = props;
        return <BKJListItem {...restProps} {...option} />;
      })}
    </Fragment>
  );
});

export const NotifyView: FC = () => {
  const { t } = useTranslation();
  const { isSubmitting, values, onChange, onSubmit } = useEditViewContext();
  return (
    <ErrorBoundary componentName="NotifyView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <Flex flexDirection="column" padding="32px" flex="1">
        <H3 color="TextDarkGrey">{t('shifts_calendar:NOTIFY_TEAM_MEMBER_NOTE')}</H3>
        <br />
        <BKJRadioInput
          name="notification_method"
          value="needs_acknowledgement"
          onChange={onChange}
          checked={values.notification_method === 'needs_acknowledgement'}
          label={t('shifts_calendar:TEAM_MEMBER_ACKNOWLEDGE_LABEL')}
        />
        {values.notification_method === 'needs_acknowledgement' && (
          <Flex padding="0 0 0 36px" flexDirection="column">
            <BKJTypography variant="Caption" element="span">
              {t('shifts_calendar:TEAM_MEMBER_ACKNOWLEDGE_NOTE')}
            </BKJTypography>
          </Flex>
        )}
        <BKJRadioInput
          name="notification_method"
          value="reaccept_or_decline"
          onChange={onChange}
          checked={values.notification_method === 'reaccept_or_decline'}
          label={t('shifts_calendar:TEAM_MEMBER_RE_ACCEPT_LABEL')}
        />
        {values.notification_method === 'reaccept_or_decline' && (
          <Flex padding="0 0 0 36px" flexDirection="column">
            <BKJTypography variant="Caption" element="span">
              {t('shifts_calendar:TEAM_MEMBER_RE_ACCEPT_NOTE')}
            </BKJTypography>
            <Flex alignItems="center" margin="10px 0 0 0">
              <BKJSelect
                width="130px"
                type="Single"
                name="reaccept_or_decline_threshold"
                value={values.reaccept_or_decline_threshold}
                variant="GreyOutlined"
                onChange={onChange}
                options={hourDropdownOptions}
                renderOptions={RenderOptions}
              />
              &nbsp; &nbsp;
              <BKJTypography variant="Caption" element="span">
                {t('shifts_calendar:FROM_NOW')}
              </BKJTypography>
            </Flex>
          </Flex>
        )}
        <BKJRadioInput
          name="notification_method"
          value="only_notification"
          onChange={onChange}
          checked={values.notification_method === 'only_notification'}
          label={t('shifts_calendar:NOTIFICATION_ONLY')}
        />
        <BKJRadioInput
          name="notification_method"
          value="no_notification"
          onChange={onChange}
          checked={values.notification_method === 'no_notification'}
          label={t('shifts_calendar:DO_NOT_NOTIFY_TEAM_MEMBER')}
        />
      </Flex>

      <BKJSideModalFooter justifyContent="space-between">
        <AutoMargin />
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          type="button"
          disabled={!values.notification_method}
          isLoading={isSubmitting}
          onClick={onSubmit as ChangeFunctionType}
        >
          {t('auth:SEND')}
        </BKJButton>
      </BKJSideModalFooter>
    </ErrorBoundary>
  );
};
