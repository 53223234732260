import {
  shiftModalRoutingService,
  ShiftModalUIContext,
} from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { composeCustomFieldsForPayload } from 'components/ShiftModal/src/shared/ShiftCustomFields.utils';
import { IShiftFormCustomField } from 'components/ShiftModal/src/shared/ShiftCustomFields/ShiftCustomFields.types';
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useTranslation } from 'hooks/useTranslation';

import { defaultApolloClient } from 'providers/ApolloProvider';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { API_PUT_updateOrderDetails } from 'requests/PUT_updateOrderDetails';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { shiftModalOrderDetails_fetchOrder_community_customFields } from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { calculateDuration, convertTimeToHours } from 'utils/time';

type EditViewContextType = {
  totalDuration: string;
  totalPaidDuration: string;
  isNotifyFlowRequired: boolean;
};

interface StaticEditViewFormType {
  start_date: string[];
  start_time: string[];
  end_time: string[];
  unpaid_break_time: string[];
  description: string;
  allow_overtime: boolean;
  notification_method?:
    | 'needs_acknowledgement'
    | 'reaccept_or_decline'
    | 'only_notification'
    | 'no_notification';
  reaccept_or_decline_threshold: Array<'4' | '12' | '24' | '48' | '72'>;
}

export interface EditViewFormType extends StaticEditViewFormType {
  [key: string]: string | string[] | boolean | undefined;
}

export const EditViewContext = createContext({} as EditViewContextType);

export const EditViewProvider: FC = ({ children }) => {
  const { shift_id } = useContext(ShiftModalUIContext);
  const { data } = useShiftModalDataContext();
  const { apiRequest } = useAPIActions();

  const { t } = useTranslation();

  const start_time = dayjs(new Date(data.fetchOrder!.startAt)).format('HH:mm');
  const end_time = dayjs(new Date(data.fetchOrder!.endAt)).format('HH:mm');

  const initialState: StaticEditViewFormType = useMemo(() => {
    return {
      start_time: [start_time],
      end_time: [end_time],
      unpaid_break_time: [data.fetchOrder!.orderSchedule.breakTime],
      start_date: [dayjs(data.fetchOrder!.startDate).format()],
      description: data?.fetchOrder!.description || '',
      notification_method: undefined,
      reaccept_or_decline_threshold: ['24'],
      allow_overtime: data?.fetchOrder?.allowOvertime,
    };
  }, [data.fetchOrder, end_time, start_time]);

  const communityCustomFields = data.fetchOrder.community.customFields;
  const orderCustomFields = data.fetchOrder.customFields;

  const initialStateWithCustomFields = {
    ...initialState,
    ...communityCustomFields?.reduce((acc, customField) => {
      const orderCustomField = orderCustomFields.find(
        (orderCustomField) => orderCustomField.customFieldId === customField.id,
      );
      const isDropdownAndPrefillDefault =
        customField.fieldType === 'dropdown' && customField.prefillDefault;
      const dropdownDefaultOptionValue = customField.customFieldOptions?.length
        ? customField.customFieldOptions[0]?.value || ''
        : '';
      return {
        ...acc,
        [customField.name]: isDropdownAndPrefillDefault
          ? orderCustomField?.value || dropdownDefaultOptionValue
          : orderCustomField?.value || '',
      };
    }, {}),
  };

  const updateOrderDetails = useCallback(
    async (values) => {
      await apiRequest(
        API_PUT_updateOrderDetails(
          payloadTransformer({ ...values, shift_id, communityCustomFields }),
        ),
        {
          onSuccess: () => {
            toastService.success(t('success:SHIFT_EDITED'));
            defaultApolloClient.refetchQueries({ include: 'all' });
            queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${shift_id}`);
            window.scheduleUtils.forceDataUpdate();
            shiftModalRoutingService.close();
          },
          onError: () => {
            toastService.error(t('errors:SOMETHING_WENT_WRONG'));
          },
        },
      );
    },
    [apiRequest, communityCustomFields, shift_id, t],
  );

  const formUtils = useFormBehaviors<EditViewFormType>({
    initialState: initialStateWithCustomFields,
    validations: {},
    onSubmit: updateOrderDetails,
    isDirtyCheckEnabled: true,
  });

  const _handleChange = useCallback(
    (event) => {
      formUtils.onChange(event);
    },
    [formUtils],
  );

  const totalDuration = calculateDuration(
    formUtils?.values?.start_time[0],
    formUtils?.values?.end_time[0],
    convertTimeToHours(formUtils?.values?.unpaid_break_time[0]),
    'pretty',
    false,
  ) as string;

  const totalPaidDuration = calculateDuration(
    formUtils.values.start_time[0],
    formUtils.values.end_time[0],
    convertTimeToHours(formUtils?.values?.unpaid_break_time[0]),
    'pretty',
    true,
  ) as string;

  const value: EditViewContextType = useMemo(() => {
    return {
      ...formUtils,
      onChange: _handleChange,
      totalDuration,
      totalPaidDuration,
      isNotifyFlowRequired: getIsNotifyFlowRequired(initialState, formUtils.values),
    };
  }, [_handleChange, formUtils, initialState, totalDuration, totalPaidDuration]);

  return <EditViewContext.Provider value={value}>{children}</EditViewContext.Provider>;
};

export const useEditViewContext = (): EditViewContextType & UseFormBehaviors<EditViewFormType> =>
  useContext(EditViewContext) as EditViewContextType & UseFormBehaviors<EditViewFormType>;

function getIsNotifyFlowRequired(initialState: StaticEditViewFormType, values: EditViewFormType) {
  let keysUpdated: string[] = [];
  const keys = Object.keys(initialState) as Array<keyof StaticEditViewFormType>;
  keys.forEach((key) => {
    if (Array.isArray(initialState[key])) {
      const initial = initialState[key] as string[];
      const value = values[key] as string[];
      if (initial.sort().join() !== value.sort().join()) keysUpdated.push(key);
    } else {
      if (initialState[key] !== values[key]) keysUpdated.push(key);
    }
  });
  const isNotifyFlowRequired = keysUpdated.some((key) => {
    const keysThatRequireNotifyFlow = [
      'start_time',
      'end_time',
      'unpaid_break_time',
      'start_date',
      'allow_overtime',
    ];
    return keysThatRequireNotifyFlow.includes(key);
  });

  return isNotifyFlowRequired;
}

function payloadTransformer({
  shift_id,
  communityCustomFields,
  ...values
}: EditViewFormType & { shift_id: number } & {
  communityCustomFields: shiftModalOrderDetails_fetchOrder_community_customFields[];
}): {
  start_time: string;
  end_time: string;
  shift_id: number;
  unpaid_break_time: string;
  description: string;
  notification_method?: string;
  reaccept_or_decline_threshold?: number;
  allow_overtime: boolean;
} {
  const durationMins = calculateDuration(
    values.start_time[0],
    values.end_time[0],
    values.unpaid_break_time[0],
    'raw',
    false,
  );

  const customFields = composeCustomFieldsForPayload(values, communityCustomFields);

  const [hour, minutes] = values.start_time[0].split(':');
  const _start_time = dayjs(values.start_date[0])
    .hour(parseInt(hour))
    .minute(parseInt(minutes))
    .format();

  const _end_time = dayjs(_start_time)
    .add(parseInt(`${durationMins}`), 'minutes')
    .format();

  const payload: {
    start_time: string;
    end_time: string;
    shift_id: number;
    unpaid_break_time: string;
    description: string;
    notification_method?: string;
    reaccept_or_decline_threshold?: number;
    custom_fields?: IShiftFormCustomField[];
    allow_overtime: boolean;
  } = {
    start_time: _start_time,
    end_time: _end_time,
    shift_id,
    unpaid_break_time: values.unpaid_break_time[0],
    description: values.description,
    notification_method: undefined,
    reaccept_or_decline_threshold: undefined,
    allow_overtime: !!values?.allow_overtime,
  };

  if (values.notification_method) payload.notification_method = values!.notification_method;
  if (values.reaccept_or_decline_threshold)
    payload.reaccept_or_decline_threshold = Number.parseInt(
      values!.reaccept_or_decline_threshold[0],
      10,
    );

  payload.custom_fields = customFields;

  return payload;
}
