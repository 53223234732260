import styled from 'styled-components';

export const PositionText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin: 0;
  font-size: 12px;
  line-height: 16px;
`;

export const ValueText = styled.div<{ marginLeft?: number; disabled?: boolean }>`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.TextDisabled : theme.colors.TextDarkGrey};
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0px')};
`;

export const FormLabel = styled.div<{ marginTop?: number }>`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
  text-transform: capitalize;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
`;

export const CustomFieldsFormLabel = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
`;
