import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import compact from 'lodash/compact';
import { QUERY_dashboardUpcomingShiftsPage } from 'queries';
import { SessionService } from 'services';
import { isOrderStatusInProgress, isOrderStatusUnfulfilled } from 'types/common.types';
import {
  dashboardUpcomingShiftsPage,
  dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders,
  OrderSortableFieldsEnum,
  SortDirectionEnum,
} from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { isDateTimeAfterNow, isDateTimeBeforeNow } from 'utils/time';

import {
  DashboardPageUpcomingShiftsPayload,
  DashboardPageUpcomingShiftsPayloadTransformer as DPUSPT,
} from 'pages/DashboardUpcomingShiftsPage/DashboardUpcomingShiftsPage.types';
import { authLink, createApolloClient, httpLink } from 'providers/ApolloProvider';

const dashboardUpcomingShiftsPagePayloadTransformer: DPUSPT = ({
  isUserAgencyAndAgencyLocationFilterForcedDisabled,
  ...filterValues
}) => {
  const variables: DashboardPageUpcomingShiftsPayload = {
    currentPage: filterValues.currentPage,
    first: filterValues.first,
    last: filterValues.last,
    after: filterValues.after,
    before: filterValues.before,
    filter: {
      forOrders: {
        withCommunity: undefined,
        withStatus: filterValues.shiftStatus,
        withPositions: filterValues.position_id,
        withJaneType: [...filterValues.internalJaneType, ...filterValues.externalJaneType],
        startBetweenTimeRange: {
          from: dayjs().startOf('day').format(),
          to: dayjs().add(1, 'months').format(),
        },
        withCalloutStatus: filterValues.callOutType,
      },
      forJanes: {
        byEmploymentStatus: filterValues.employmentStatus,
        byFullName: filterValues.byFullName,
      },
      forAgencyCommunities: {
        withId: undefined,
      },
    },
    sortOrders: [
      {
        field: OrderSortableFieldsEnum.startAt,
        direction: SortDirectionEnum.asc,
      },
      {
        field: OrderSortableFieldsEnum.positionName,
        direction: SortDirectionEnum.asc,
      },
    ],
  };
  if (!filterValues?.viewAllFacilities) {
    if (SessionService.assertUserType('B2B'))
      variables.filter.forOrders['withCommunity'] = Number(filterValues.location.value);

    if (
      SessionService.assertUserType('Agency') &&
      !isUserAgencyAndAgencyLocationFilterForcedDisabled
    )
      variables.filter.forAgencyCommunities['withId'] = Number(filterValues.location.value);
  }
  return variables;
};

export function dashboardUpcomingShiftsPageResponseTransformer(data: dashboardUpcomingShiftsPage) {
  if (!data) return null;
  const newData: dashboardUpcomingShiftsPage = JSON.parse(JSON.stringify(data));

  if (!newData) return null;
  if (!newData?.fastGroupOrders) return null;
  if (!newData?.fastGroupOrders?.nodes) return null;

  const inProgressData: dashboardUpcomingShiftsPage = newData;
  const notInProgressData: dashboardUpcomingShiftsPage = JSON.parse(JSON.stringify(data));

  if (!!inProgressData?.fastGroupOrders?.nodes && inProgressData?.fastGroupOrders?.nodes.length)
    inProgressData?.fastGroupOrders?.nodes.forEach((node) => {
      if (node) {
        const isToday = dayjs(node.group).isSame(dayjs(), 'day');
        if (isToday) {
          const unfulfilledOrders =
            [] as dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders[];
          node?.orders?.forEach(
            (
              record: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders,
              index2: number,
              arr2: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders[],
            ) => {
              if (
                !isOrderStatusInProgress(record.status.value) &&
                isDateTimeBeforeNow(record.endAt)
              ) {
                delete arr2[index2];
              }

              if (isOrderStatusUnfulfilled(record.status.value)) {
                if (isDateTimeAfterNow(record.endAt)) unfulfilledOrders.push(record);
                delete arr2[index2];
              }
            },
          );

          node.orders = [...node.orders, ...unfulfilledOrders];
        }
      }
    });

  if (
    !!notInProgressData?.fastGroupOrders?.nodes &&
    notInProgressData?.fastGroupOrders?.nodes.length
  )
    notInProgressData?.fastGroupOrders?.nodes.forEach((node) => {
      if (node) {
        node?.orders?.forEach(
          (
            record: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders,
            index2: number,
            arr2: dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders[],
          ) => {
            if (isDateTimeBeforeNow(record.endAt) || isOrderStatusInProgress(record.status.value)) {
              delete arr2[index2];
            }
          },
        );
      }
    });

  let result = {
    ...notInProgressData,
    ...inProgressData,
  };
  let filtered = {
    ...result,
    fastGroupOrders: {
      ...result.fastGroupOrders,
      nodes: result.fastGroupOrders.nodes
        .filter((record) => record.orders.length !== 0)
        .map((record) => {
          const { orders: _orders } = record;

          return { ...record, orders: compact(_orders) };
        }),
    },
  } as dashboardUpcomingShiftsPage;

  (filtered.fastGroupOrders.nodes || []).forEach((record, index, arr) => {
    if (record.orders.length === 0) {
      delete arr[index];
    }
  });
  if (filtered.fastGroupOrders.nodes)
    filtered.fastGroupOrders.nodes = filtered.fastGroupOrders.nodes.filter(Boolean);
  return filtered;
}

const client = createApolloClient();

export const dashboardUpcomingShiftsPageConfig = ({
  location,
  isUserAgencyAndAgencyLocationFilterForcedDisabled,
}: {
  location: ILocationFilterProps['value'];
  isUserAgencyAndAgencyLocationFilterForcedDisabled: boolean;
}): ISSFBehaviorsOptions => {
  client.setLink(authLink.concat(httpLink()));
  return {
    key: 'DASHBOARD_UPCOMING-SHIFTS_PAGE',
    paginationType: 'InfiniteScroll',
    nextFetchPolicy: 'network-only',
    client,
    query: QUERY_dashboardUpcomingShiftsPage,
    responseTransformer: dashboardUpcomingShiftsPageResponseTransformer,
    pageSize: 20,
    payloadTransformer: (values) =>
      dashboardUpcomingShiftsPagePayloadTransformer({
        ...values,
        isUserAgencyAndAgencyLocationFilterForcedDisabled,
      }),
    schema: {
      position_id: {
        initialValue: [],
        isSessionCached: true,
      },
      shiftStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      location: {
        initialValue: location,

        isGlobalCached: true,
        isPreservedOnReset: true,
        resetFieldsOnChange: [
          {
            field: 'position_id',
            userTypes: ['Agency'],
          },
        ],
      },
      employmentStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      byFullName: {
        initialValue: '',
        isSessionCached: true,
      },
      internalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      externalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      callOutType: {
        initialValue: [],
        isSessionCached: true,
      },
      viewAllFacilities: {
        initialValue: false,
        isGlobalCached: true,
        isSessionCached: true,
        isPreservedOnReset: true,
      },
    },
  };
};
