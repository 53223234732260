import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { useCanAssignValidationContext } from 'components/ShiftModal/src/views/DetailView/ConfirmAvailabilityModal/CanAssignValidation.validation.context';
import { useDetailTabViewPropsContext } from 'components/ShiftModal/src/views/DetailView/DetailTabView.props.context';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment } from 'react';

export const ConfirmAvailabilityModalComponent: FC = () => {
  const { isConfirmAvailabilityModalOpen, closeConfirmAvailabilityModal } =
    useDetailTabViewPropsContext();

  const { validateAndExecute } = useCanAssignValidationContext();
  const { t } = useTranslation();

  return isConfirmAvailabilityModalOpen ? (
    <BKJCenteredDialog
      isOpen={isConfirmAvailabilityModalOpen}
      variant="Default"
      title={t('agency_dashboard:CONFIRM_AVAILABILITY_MODAL_TITLE')}
      text={t('agency_dashboard:CONFIRM_AVAILABILITY_MODAL_TEXT')}
      cancelLabel={t('auth:CANCEL')}
      actionLabel={t('agency_dashboard:CONTINUE')}
      onAction={validateAndExecute}
      onCancel={closeConfirmAvailabilityModal}
      onClose={closeConfirmAvailabilityModal}
    />
  ) : (
    <Fragment />
  );
};
