import { BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { useTranslation } from 'hooks/useTranslation';
import {
  SendToAgenciesBoldText,
  InfoSection,
  SendToAgenciesText,
} from '../../SendToAgenciesView.styled';

export function ShiftPay({
  estimatedHourlyPay,
  estimatedTotalPay,
}: {
  estimatedHourlyPay: string;
  estimatedTotalPay: string;
}) {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="8px">
      <Flex alignItems="center" gap="8px">
        <SendToAgenciesBoldText>{t('dashboard:ESTIMATED_SHIFT_PAY')}</SendToAgenciesBoldText>
        <BKJIconTooltip iconName="Info" placement="bottom">
          <Flex width="258px">{t('SEND_TO_AGENCIES_SUMMARY_TOOLTIP')}</Flex>
        </BKJIconTooltip>
      </Flex>

      <InfoSection flexDirection="column" childMarginBottom="8px">
        <Flex flexDirection="column" gap="8px">
          <SendToAgenciesBoldText>
            {t('dashboard:HOURLY')}:&nbsp;
            <SendToAgenciesText>{estimatedHourlyPay}</SendToAgenciesText>
          </SendToAgenciesBoldText>

          <SendToAgenciesBoldText>
            {t('dashboard:TOTAL')}:&nbsp;
            <SendToAgenciesText>{estimatedTotalPay}</SendToAgenciesText>
          </SendToAgenciesBoldText>
        </Flex>
      </InfoSection>
    </Flex>
  );
}
