import { BKJButton, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { DeleteButton } from 'components/ShiftModal/src/views/DetailView/DetailTabView.styled';
import {
  NavigationButton,
  NavigationButtonsWrapper,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import {
  DetailViewActiveTabType,
  DetailViewComponentMap,
  IDetailViewComponentProps,
} from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { css } from 'styled-components';

import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { ConfirmAvailabilityModal } from 'components/ShiftModal/src/views/DetailView/ConfirmAvailabilityModal/ConfirmAvailabilityModal';
import { useDetailTabViewPropsContext } from 'components/ShiftModal/src/views/DetailView/DetailTabView.props.context';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { UserGuard } from 'guards/UserGuard';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { useURLParameters } from 'hooks';
import { usePathname } from 'hooks/usePathname';
import { useSelector } from 'react-redux';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import isUserPremiumSelector from 'store/selectors/userSelectors/isUserPremiumSelector';
import { OrderStatusEnum } from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { ORDER_STATUS_DELETE_NOT_ALLOWED, PATH_DETAIL_VIEW_TAB_MAP } from './DetailView.constants';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export function DetailViewFooter() {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();

  const goToDeleteView = useCallback(() => shiftModalRoutingService.goToView('DeleteView'), []);
  const goToEditView = useCallback(() => shiftModalRoutingService.goToView('EditView'), []);
  const goToReleaseShiftView = useCallback(
    () => shiftModalRoutingService.goToView('ReleaseShiftView'),
    [],
  );
  const goToSendToAgenciesView = useCallback(
    () => shiftModalRoutingService.goToView('PreRequestOptionsView'),
    [],
  );

  const today = dayjs();
  const startTime = dayjs(new Date(data.fetchOrder!.startAt));

  const displayDeleteButton: boolean =
    !ORDER_STATUS_DELETE_NOT_ALLOWED.includes(data.fetchOrder!.status.value) &&
    !startTime.isBefore(today);

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [{ agencyCommunityId }] = useURLParameters<{ agencyCommunityId?: string }>();

  const { openConfirmAvailabilityModal } = useDetailTabViewPropsContext();

  if (isReadOnly) return <Fragment />;
  return (
    <BKJSideModalFooter>
      <Flex justifyContent="space-between" width="100%">
        <UserGuard
          userType={[
            'USER_TYPE_B2B',
            'USER_TYPE_PREMIUM_SECONDARY',
            'USER_TYPE_ESSENTIAL_SECONDARY',
          ]}
        >
          {displayDeleteButton && (
            <Flex>
              <DeleteButton variant="RedText" onClick={goToDeleteView}>
                {t('auth:DELETE')}
              </DeleteButton>
            </Flex>
          )}
        </UserGuard>

        <Flex width="100%" justifyContent="flex-end">
          {data.fetchOrder?.isEditable && (
            <BKJButton
              width="180px"
              variant="PurpleOutlined"
              css={css`
                margin-right: 8px;
              `}
              onClick={goToEditView}
            >
              {t('schedule:EDIT')}
            </BKJButton>
          )}
          {data.fetchOrder!.isJaneReleaseAllowed && (
            <BKJButton width="180px" variant="PurpleSolid" onClick={goToReleaseShiftView}>
              {t('agency_dashboard:RELEASE')}
            </BKJButton>
          )}
          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_B2B,
              UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
              UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
            ]}
          >
            {data.fetchOrder!.canSendToAgency && (
              <BKJButton width="185px" variant="PurpleSolid" onClick={goToSendToAgenciesView}>
                {t('dashboard:SEND_TO_AGENCIES')}
              </BKJButton>
            )}
          </UserGuard>
          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_AGENCY,
              UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
              UserPermissionEnum.USER_TYPE_AGENCY_SECONDARY,
            ]}
          >
            {data.fetchOrder?.canAssign &&
              (!isUserAgencyAndAgencyLocationFilterForcedDisabled ? !!agencyCommunityId : true) && (
                <BKJButton
                  width="180px"
                  variant="PurpleSolid"
                  onClick={openConfirmAvailabilityModal}
                >
                  {t('schedule:ASSIGN')}
                </BKJButton>
              )}
          </UserGuard>
        </Flex>
      </Flex>
    </BKJSideModalFooter>
  );
}

const tabs: [string, DetailViewActiveTabType][] = [
  ['General', 'GeneralTab'],
  ['Activity', 'AuditTrailActivityTab'],
  ['Timesheets', 'TimesheetsTab'],
];

export const AuditTrailDetailTabView: FC<IDetailViewComponentProps> = ({ data }) => {
  const { t, i18n } = useTranslation();
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState<DetailViewActiveTabType>(
    PATH_DETAIL_VIEW_TAB_MAP(pathname),
  );
  const ActiveTabComponent = DetailViewComponentMap[activeTab];
  const isUserPremium = useSelector(isUserPremiumSelector);

  return (
    <Fragment>
      <ConfirmAvailabilityModal />
      <ErrorBoundary componentName="DetailTabView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
        <Flex
          flexDirection="column"
          height="fit-content"
          minHeight="fit-content"
          css={css`
            position: sticky;
            background-color: white;
            z-index: 1;
            top: 0;
          `}
        >
          <NavigationButtonsWrapper>
            {tabs
              .filter(([, key]) => {
                if (key === 'TimesheetsTab') {
                  switch (data.fetchOrder!.status.value) {
                    case OrderStatusEnum.completed:
                      // For Premium users, show timesheets tab only if shift was done by agency jane
                      if (isUserPremium) {
                        return (
                          !!data.fetchOrder!.mainJane!.agencyCommunity &&
                          data.fetchOrder!.sentToAgencies
                        );
                      }
                      return true;
                    default:
                      return false;
                  }
                }
                return true;
              })
              .map(([label, _enum]) => {
                const translatedLabel = label.toUpperCase();
                return (
                  <NavigationButton
                    key={label}
                    isDisabled={!!data.fetchOrder!.isAway && label === 'Activity'}
                    onClick={() => activeTab !== _enum && setActiveTab(_enum)}
                    isActive={activeTab === _enum}
                  >
                    {i18n.exists(`common:${translatedLabel}`)
                      ? t(`common:${translatedLabel}`)
                      : label}
                  </NavigationButton>
                );
              })}
          </NavigationButtonsWrapper>
        </Flex>

        <ActiveTabComponent activities={data?.fetchOrder.activities} data={data} />

        {activeTab !== 'TimesheetsTab' && <DetailViewFooter />}
      </ErrorBoundary>
    </Fragment>
  );
};
