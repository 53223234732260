import styled from 'styled-components';
import { Body, Caption } from 'components/Typography';
import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';

export const GeneralJaneWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey3};
  margin: 0 20px;
`;
export const ContactJaneWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey3};
  margin: 0 20px;
`;
export const MobileAppJaneWrapper = styled.div`
  margin: 0 20px;
`;

export const JaneAvatarWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.Grey4};
  border-radius: 50%;
`;

export const JaneName = styled(Body)`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsText}
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
`;
export const Text = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText}
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
export const H3 = styled.div`
  ${({ theme }) => theme.fonts.Poppins};
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
`;
export const LabelText = styled(Caption)`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

export const ValueText = styled(Body)<{ marginLeft?: number; padding?: string }>`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  line-height: 24px;
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0px')};
  padding: ${({ padding }) => (padding ? padding : '0')};
  font-weight: 500;
`;
export const ColorDot = styled.span<{ color: BKJThemeColorType }>`
  font-size: 2rem;
  height: 18px;
  display: flex;
  max-height: 18px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, color }) => theme.colors[color]};
`;
export const LoadingWrapper = styled.div`
  margin: 50%;
`;
export const NonMobileWrapper = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;
export const MessageWrapper = styled(Body)`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 12px;
  padding: 3px;
  font-weight: 500;
`;
