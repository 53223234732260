import dayjs from 'utils/dayjs';
import i18next from 'i18next';

export function getLocalizedDateFormat(date?: Date | string): {
  dayOfWeek: string;
  fullDayNameOfWeek: string;
  month: string;
  frenchFormat: boolean;
} {
  const dayOfWeek = dayjs(date).format('ddd').toUpperCase();
  const fullDayNameOfWeek = dayjs(date).format('dddd').toUpperCase();
  const month = dayjs(date).format('MMM').toUpperCase();
  const translatedDayOfWeek = i18next.t(dayOfWeek);
  const translatedFullDayNameOfWeek = i18next.t(fullDayNameOfWeek);
  const translatedMonth = i18next.t(month);
  const isCurrentLanguageFrench = i18next.language === 'fr';

  return {
    dayOfWeek: translatedDayOfWeek,
    fullDayNameOfWeek: translatedFullDayNameOfWeek,
    month: translatedMonth,
    frenchFormat: isCurrentLanguageFrench,
  };
}
