import { FC, Fragment, useCallback } from 'react';
import { RateShiftTitle, RateShiftViewWrapper } from './RateShiftView.styled';
import { StarReview } from 'components/StarReview/StarReview';
import { BKJButton, BKJTextArea } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useTranslation } from 'hooks/useTranslation';
import { RateShiftViewProvider, useRateShiftViewContext } from './RateShiftView.context';
import { FormErrorBox } from 'components/FormErrorBox/FormErrorBox.styled';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSelector } from 'react-redux';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { AutoMargin } from 'components/AutoMargin';

const RateShiftViewComponent: FC = () => {
  const { t } = useTranslation();
  const { isPending, isDisabled, formValues, onChange, onSubmit, error } =
    useRateShiftViewContext();
  const { fetch } = useShiftModalDataContext();
  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const handleSubmit = useCallback(() => {
    onSubmit(() => {
      fetch();
      shiftModalRoutingService.close();
    });
  }, [fetch, onSubmit]);

  if (isReadOnly) return <Fragment />;
  return (
    <Fragment>
      <RateShiftViewWrapper flexDirection="column">
        <RateShiftTitle data-cy="msg-rate-your-service-experience">
          {t('auth:RATE_YOUR_SERVICE_EXPERIENCE')} :
        </RateShiftTitle>
        <Flex flexDirection="column" childMarginBottom="20px">
          <StarReview
            rating={formValues.rating}
            onChange={(newRating) =>
              (onChange as ChangeFunctionType)({ target: { name: 'rating', value: newRating } })
            }
          />
          <BKJTextArea
            placeholder={t('auth:ADD_A_REVIEW_PLACEHOLDER')}
            name="comment"
            value={formValues.comment}
            onChange={onChange}
          />
        </Flex>

        {error && <FormErrorBox>{t('auth:SOMETHING_WENT_WRONG')}</FormErrorBox>}
      </RateShiftViewWrapper>
      <BKJSideModalFooter>
        <AutoMargin />
        <BKJButton
          width="180px"
          variant="GreenSolid"
          disabled={isDisabled}
          isLoading={isPending}
          onClick={handleSubmit}
        >
          {t('auth:SUBMIT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};

export const RateShiftView: FC = () => {
  return (
    <RateShiftViewProvider>
      <RateShiftViewComponent />
    </RateShiftViewProvider>
  );
};
