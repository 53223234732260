import { shiftModalEditViewFormItemVerticalMargin } from 'components/ShiftModal/src/views';
import {
  CustomFieldsFormLabel,
  FormLabel,
} from 'components/ShiftModal/src/views/EditView/EditView.styled';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { FC } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { Flex } from 'components/Flex';
import { ShiftCustomFieldInput } from 'components/ShiftModal/src/shared/ShiftCustomFields/components/ShiftCustomFieldInput';
import { useCreateTimeOffViewFormContext } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.form.context';
import { Fragment } from 'react';
import { useCreateTimeOffViewDataContext } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.data.context';
import { CustomFieldsContent } from 'components/TimeOffModal/TimeOffModalView.styled';
import { ICustomFields } from 'components/TimeOffModal/TimeOffModal.constants';
import { MobileLabel } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/DetailsView/DetailsView.styled';

const CreateTimeOffCustomFieldsViewContainer: FC = () => {
  const { t } = useTranslation();
  const { timeOffCustomFields } = useCreateTimeOffViewDataContext();
  const { values, onChange } = useCreateTimeOffViewFormContext();

  const customFields: ICustomFields = values.customFields;

  return (
    <CustomFieldsContent>
      {timeOffCustomFields && timeOffCustomFields.length > 0 && (
        <Fragment>
          <SectionDivider
            variant="Thin"
            margin={`${shiftModalEditViewFormItemVerticalMargin}px 0`}
          />
          <CustomFieldsFormLabel>{t('common:CUSTOM_FIELDS')}</CustomFieldsFormLabel>
          {timeOffCustomFields?.map((customField) => {
            return (
              <Flex
                key={customField.id}
                flexDirection="column"
                margin={`0 0 ${shiftModalEditViewFormItemVerticalMargin}px 0`}
              >
                <FormLabel>
                  {customField.name}
                  {customField.mobile && (
                    <MobileLabel>&nbsp;({t('schedule:SHOWN_INTERNALLY_ON_MOBILE')})</MobileLabel>
                  )}
                </FormLabel>

                <ShiftCustomFieldInput
                  key={customField.id}
                  customField={customField}
                  value={customFields[customField.name]}
                  onChange={onChange}
                />
              </Flex>
            );
          })}
        </Fragment>
      )}
    </CustomFieldsContent>
  );
};

export const CreateTimeOffCustomFieldsViewComponent: FC = () => (
  <CreateTimeOffCustomFieldsViewContainer />
);
