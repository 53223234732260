import { Flex } from 'components/Flex';
import {
  GreyText,
  InnerHeaderWrapper,
  MainTitle,
  NavigationButton,
  NavigationButtonsWrapper,
  AgencyAccept,
  Span,
  ShiftStatusText,
  ShiftStatusTextWrapper,
  Text,
  CommunityName,
} from 'components/ShiftModal/src/views/DetailView/DetailView.styled';
import {
  DeleteButton,
  ShiftJaneSection,
} from 'components/ShiftModal/src/views/DetailView/DetailTabView.styled';
import { BKJButton, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import {
  DetailViewActiveTabType,
  DetailViewComponentMap,
  IDetailViewComponentProps,
} from 'components/ShiftModal/src/views/DetailView/DetailView.types';
import { FC, Fragment, useCallback, useState } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'hooks/useTranslation';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Tags } from 'components/Tags';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { ORDER_STATUS_DELETE_NOT_ALLOWED, PATH_DETAIL_VIEW_TAB_MAP } from './DetailView.constants';
import { UserGuard } from 'guards/UserGuard';
import {
  OrderStatusEnum,
  shiftModalOrderDetails,
  shiftModalOrderDetails_fetchOrder_status,
} from 'types/graphql-types';
import {
  getShiftModalShiftDate,
  getShiftModalShiftTimeRange,
  getShiftModalShiftTotalDuration,
} from 'mappers/shiftModalOrderDetailsMapper';
import { useSelector } from 'react-redux';
import dayjs from 'utils/dayjs';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';
import isUserPremiumSelector from 'store/selectors/userSelectors/isUserPremiumSelector';
import { SessionService } from 'services';
import { usePathname } from 'hooks/usePathname';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { ConfirmAvailabilityModal } from 'components/ShiftModal/src/views/DetailView/ConfirmAvailabilityModal/ConfirmAvailabilityModal';
import { useDetailTabViewPropsContext } from 'components/ShiftModal/src/views/DetailView/DetailTabView.props.context';
import { useURLParameters } from 'hooks';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';
import { UserPermissionEnum } from 'guards/UserGuard.types';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export function DetailViewInnerHeader({ data }: { data: shiftModalOrderDetails }) {
  const { t, i18n } = useTranslation();
  const isAcceptedByAgency = !!data.fetchOrder.orderSchedule.mainOrderJane?.assignedBy?.displayId;

  return (
    <InnerHeaderWrapper>
      <MainTitle>{data.fetchOrder!.position.name}</MainTitle>
      <UserGuard userType="USER_TYPE_AGENCY">
        <CommunityName>{data.fetchOrder!.community.name}</CommunityName>
      </UserGuard>
      <ShiftStatusTextWrapper>
        <Span>
          <ShiftStatusText type={data.fetchOrder!.status.name}>
            {getTranslatedResponse(data.fetchOrder?.status.name, 'status', i18n, t)}
            {!!isAcceptedByAgency && <AgencyAccept>&nbsp;(by Agency Admin)</AgencyAccept>}
          </ShiftStatusText>
        </Span>
      </ShiftStatusTextWrapper>
      {!!data.fetchOrder?.mainJane && <ShiftJaneSection data={data} />}
      <Flex>
        <Text>{getShiftModalShiftDate(data)}</Text>
      </Flex>
      <Flex>
        <Text>{getShiftModalShiftTimeRange(data)}</Text>
        &nbsp;
        <GreyText>({getShiftModalShiftTotalDuration(data)})</GreyText>
      </Flex>
      <Flex>
        <Tags
          id={data.fetchOrder!.orderSchedule.id}
          status={data.fetchOrder!.status.value}
          isRecurring={!!data.fetchOrder!.recurrence}
          isDedicated={!!data.fetchOrder!.dedicated}
          isSentToAgencies={data.fetchOrder!.sentToAgencies}
          hasJane={!!data.fetchOrder!.mainJane}
          isAgencyJane={!!data.fetchOrder?.mainJane?.agencyCommunity || false}
          isAcceptedBySharedTeamMember={data.fetchOrder!.acceptedBySharedTeamMember!}
        />
      </Flex>
    </InnerHeaderWrapper>
  );
}

export function DetailViewFooter() {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();

  const goToDeleteView = useCallback(() => shiftModalRoutingService.goToView('DeleteView'), []);
  const goToEditView = useCallback(() => shiftModalRoutingService.goToView('EditView'), []);
  const goToReleaseShiftView = useCallback(
    () => shiftModalRoutingService.goToView('ReleaseShiftView'),
    [],
  );
  const goToSendToAgenciesView = useCallback(
    () => shiftModalRoutingService.goToView('PreRequestOptionsView'),
    [],
  );

  const today = dayjs();
  const startTime = dayjs(new Date(data.fetchOrder!.startAt));

  const displayDeleteButton: boolean =
    !ORDER_STATUS_DELETE_NOT_ALLOWED.includes(data.fetchOrder!.status.value) &&
    !startTime.isBefore(today);

  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );

  const [{ agencyCommunityId }] = useURLParameters<{ agencyCommunityId?: string }>();

  const { openConfirmAvailabilityModal } = useDetailTabViewPropsContext();

  if (isReadOnly) return <Fragment />;

  return (
    <BKJSideModalFooter>
      <Flex justifyContent="space-between" width="100%">
        <UserGuard
          userType={[
            'USER_TYPE_B2B',
            'USER_TYPE_PREMIUM_SECONDARY',
            'USER_TYPE_ESSENTIAL_SECONDARY',
          ]}
        >
          {displayDeleteButton && (
            <Flex>
              <DeleteButton variant="RedText" onClick={goToDeleteView}>
                {t('auth:DELETE')}
              </DeleteButton>
            </Flex>
          )}
        </UserGuard>

        <Flex width="100%" justifyContent="flex-end">
          {data.fetchOrder?.isEditable && (
            <BKJButton
              width="180px"
              variant="PurpleOutlined"
              css={css`
                margin-right: 8px;
              `}
              onClick={goToEditView}
            >
              {t('agency_dashboard:EDIT')}
            </BKJButton>
          )}
          {data.fetchOrder!.isJaneReleaseAllowed && (
            <BKJButton width="180px" variant="PurpleSolid" onClick={goToReleaseShiftView}>
              {t('agency_dashboard:RELEASE')}
            </BKJButton>
          )}
          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_B2B,
              UserPermissionEnum.USER_TYPE_PREMIUM_PRIMARY,
              UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
            ]}
          >
            {data.fetchOrder!.canSendToAgency && (
              <BKJButton width="185px" variant="PurpleSolid" onClick={goToSendToAgenciesView}>
                {t('schedule:SEND_TO_AGENCY')}
              </BKJButton>
            )}
          </UserGuard>
          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_AGENCY,
              UserPermissionEnum.USER_TYPE_AGENCY_PRIMARY,
              UserPermissionEnum.USER_TYPE_AGENCY_SECONDARY,
            ]}
          >
            {data.fetchOrder?.canAssign &&
              (!isUserAgencyAndAgencyLocationFilterForcedDisabled ? !!agencyCommunityId : true) && (
                <BKJButton
                  width="180px"
                  variant="PurpleSolid"
                  onClick={openConfirmAvailabilityModal}
                >
                  {t('schedule:ASSIGN')}
                </BKJButton>
              )}
          </UserGuard>
        </Flex>
      </Flex>
    </BKJSideModalFooter>
  );
}

const tabs: [string, DetailViewActiveTabType][] = [
  ['General', 'GeneralTab'],
  ['Activity', 'ActivityTab'],
  ['Available Members', 'AvailableMembersTab'],
  ['Timesheets', 'TimesheetsTab'],
];

const orderStatusesForAgencyTeamMembersTab = [
  OrderStatusEnum.accepted,
  OrderStatusEnum.completed,
  OrderStatusEnum.pending,
  OrderStatusEnum.unfulfilled,
];

const orderStatusesForB2BTeamMembersTab = [
  OrderStatusEnum.accepted,
  OrderStatusEnum.cancelled,
  OrderStatusEnum.completed,
  OrderStatusEnum.in_progress,
  OrderStatusEnum.pending,
  OrderStatusEnum.requested,
  OrderStatusEnum.unfulfilled,
];

const shouldShowAvailableMembers = (
  isUserAgency: boolean,
  orderStatus: shiftModalOrderDetails_fetchOrder_status,
) => {
  if (isUserAgency) return orderStatusesForAgencyTeamMembersTab.includes(orderStatus.value);

  return orderStatusesForB2BTeamMembersTab.includes(orderStatus.value);
};

export const DetailTabView: FC<IDetailViewComponentProps> = ({ data }) => {
  const pathname = usePathname();
  const [activeTab, setActiveTab] = useState<DetailViewActiveTabType>(
    PATH_DETAIL_VIEW_TAB_MAP(pathname),
  );
  const { t, i18n } = useTranslation();
  const ActiveTabComponent = DetailViewComponentMap[activeTab];
  const isUserAgency = useSelector(isUserAgencySelector);
  const isUserPremium = useSelector(isUserPremiumSelector);
  return (
    <Fragment>
      <ConfirmAvailabilityModal />
      <ErrorBoundary componentName="DetailTabView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
        <Flex
          flexDirection="column"
          height="fit-content"
          minHeight="fit-content"
          css={css`
            position: sticky;
            background-color: white;
            z-index: 1;
            top: 0;
          `}
        >
          <NavigationButtonsWrapper>
            {tabs
              .filter(([, key]) => {
                if (key === 'AvailableMembersTab') {
                  return shouldShowAvailableMembers(isUserAgency, data.fetchOrder!.status);
                } else if (key === 'TimesheetsTab') {
                  switch (data.fetchOrder!.status.value) {
                    case OrderStatusEnum.completed:
                      // For Premium users, show timesheets tab only if shift was done by agency jane
                      if (isUserPremium) {
                        return (
                          !!data.fetchOrder!.mainJane!.agencyCommunity &&
                          data.fetchOrder!.sentToAgencies
                        );
                      }
                      return true;
                    default:
                      return false;
                  }
                }
                return true;
              })
              .map(([label, _enum]) => {
                return (
                  <NavigationButton
                    key={label}
                    isDisabled={!!data.fetchOrder!.isAway && label === 'Activity'}
                    onClick={() => activeTab !== _enum && setActiveTab(_enum)}
                    isActive={activeTab === _enum}
                  >
                    {SessionService.assertUserRole('Premium') && _enum === 'AvailableMembersTab'
                      ? t('common:AUDIT_TRAIL')
                      : getTranslatedResponse(label, 'common', i18n, t)}
                  </NavigationButton>
                );
              })}
          </NavigationButtonsWrapper>
        </Flex>

        <ActiveTabComponent activities={data?.fetchOrder.activities} data={data} />

        {activeTab !== 'TimesheetsTab' && <DetailViewFooter />}
      </ErrorBoundary>
    </Fragment>
  );
};
