import { Flex } from 'components/Flex';
import { Rating } from 'semantic-ui-react';
import { Fragment } from 'react';
import {
  FlexWrapper,
  JaneName,
  LabelText,
  ReviewNumber,
  ReviewText,
  H4,
  Text,
} from 'components/JaneModal/views/JaneDetailsView/ReviewsTab/ReviewsTab.styled';
import {
  janeModalJaneDetails_fetchJane,
  janeModalJaneDetails_fetchJane_customerReviews_reviews,
} from 'types/graphql-types';
import { useFetchJaneByIdDataContext } from 'components/JaneModal/FetchJaneById.data.context';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { ReviewCommentCard } from 'components/JaneModal/views/JaneDetailsView/ReviewsTab/ReviewCommentCard';
import { useTranslation } from 'react-i18next';

export const ReviewsTab = () => {
  const { data }: { data: janeModalJaneDetails_fetchJane } = useFetchJaneByIdDataContext();
  const { shorthandName = '', customerReviews = { averageRating: '', count: '', reviews: [] } } =
    data;
  const { t } = useTranslation();
  const averageRating = Number(customerReviews?.averageRating) || 0;
  const ratingText = averageRating.toFixed(1);
  const renderRating = () => {
    if (customerReviews?.count === 0) {
      return '0.0';
    } else if (typeof averageRating === 'number') {
      return averageRating % 1 === 0 ? ratingText : averageRating;
    }
    return '0.0';
  };
  return (
    <Fragment>
      <FlexWrapper>
        <Flex flexDirection="column" gap="20px">
          <JaneName>{shorthandName}</JaneName>
          <Flex flexDirection="column" gap="8px">
            <LabelText>{t('team_member:OVERALL_RATING')}</LabelText>
            <Flex gap="10px" alignItems="center">
              <ReviewNumber>{renderRating()}</ReviewNumber>
              <Flex flexDirection="column" gap="8px">
                <Rating
                  disabled
                  maxRating={5}
                  defaultRating={customerReviews?.count === 0 ? 0 : averageRating}
                />
                <ReviewText>based on {customerReviews?.count} reviews</ReviewText>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <SectionDivider variant="Thin" />
        <Flex flexDirection="column" gap="32px" width="100%">
          <H4>Reviews</H4>
          {customerReviews?.reviews.length > 0 ? (
            customerReviews?.reviews.map(
              (review: janeModalJaneDetails_fetchJane_customerReviews_reviews) => (
                <ReviewCommentCard review={review} />
              ),
            )
          ) : (
            <Flex height="432px" justifyContent="center" alignItems="center">
              <Text>There are no reviews for this team member.</Text>
            </Flex>
          )}
        </Flex>
      </FlexWrapper>
    </Fragment>
  );
};
