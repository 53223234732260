import { TextButtonWrapper } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useGQLMutation } from 'hooks/useGQLMutation.ts';
import { useTranslation } from 'hooks/useTranslation';
import { MUTATION_assignAgencyTeamMemberAssignOrder } from 'mutation';
import { createContext, FC, useContext } from 'react';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import {
  MUTATION_assignAgencyTeamMemberAssignOrder as MUTATION_assignAgencyTeamMemberAssignOrder_type,
  MUTATION_assignAgencyTeamMemberAssignOrderVariables,
} from 'types/graphql-types';
import { VALIDATION_required } from 'validations';

export const AssignToAgencyTeamMemberFormContext = createContext<
  UseFormBehaviors<{ janeId: string; janeRelayId: string }>
>({} as UseFormBehaviors<{ janeId: string; janeRelayId: string }>);

export const AssignToAgencyTeamMemberFormProvider: FC = ({ children }) => {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();

  const viewShift = () =>
    shiftModalRoutingService.open(
      {
        shiftModalShiftId: data.fetchOrder.id,
      },
      'DetailView',
    );

  const SuccessToastMessage: FC = () => (
    <div>
      {t('ASSIGN_AGENCY_TEAM_MEMBER_SUCCESS_MSG')}&nbsp;
      <TextButtonWrapper variant="PurpleText" onClick={viewShift}>
        {t('schedule:VIEW_SHIFT')}
      </TextButtonWrapper>
    </div>
  );

  const { mutate } = useGQLMutation<
    MUTATION_assignAgencyTeamMemberAssignOrder_type,
    MUTATION_assignAgencyTeamMemberAssignOrderVariables
  >({
    key: 'DELETE_TIME_OFF_VIEW_DELETE_TIME_OFF_BY_ID',
    mutation: MUTATION_assignAgencyTeamMemberAssignOrder,
    refetchQueries: ['scheduleTimeOffs'],
    onSuccess: (res) => {
      console.log(res);
      if (!!res.assignOrder.errors?.length) {
        // TODO: When tyler is done with making a more typed error object, fix this
        switch (res.assignOrder.errors[0]) {
          case 'this order can no longer be assigned':
            shiftModalRoutingService.goToView('ShiftUnavailableView');
            break;
          case "The order is outside the team member's schedule":
          case "The order conflicts with the team member's bookings":
            shiftModalRoutingService.goToView('TeamMemberUnavailableView');
            break;
        }
      } else {
        toastService.success(SuccessToastMessage, { autoClose: false });
        queryRefetchService.refetchDataQueryByKey('SHIFT_CARD', `${data.fetchOrder.id}`);
        window.scheduleUtils.forceDataUpdate();
        shiftModalRoutingService.close();
      }
    },
    onError: (e) => {
      toastService.error(t('FAILURE_TOAST_MESSAGE'));
    },
  });

  const form = useFormBehaviors<{ janeId: string; janeRelayId: string }>({
    initialState: {
      janeId: '',
      janeRelayId: '',
    },
    validations: {
      janeId: VALIDATION_required,
      janeRelayId: VALIDATION_required,
    },
    onSubmit: (formValues) =>
      mutate({
        variables: { orderId: `${data.fetchOrder.relayId}`, janeId: formValues.janeRelayId },
      }),
    isDirtyCheckEnabled: true,
  });

  return (
    <AssignToAgencyTeamMemberFormContext.Provider value={form}>
      {children}
    </AssignToAgencyTeamMemberFormContext.Provider>
  );
};

export const useAssignToAgencyTeamMemberFormContext = (): UseFormBehaviors<{
  janeId: string;
  janeRelayId: string;
}> => useContext(AssignToAgencyTeamMemberFormContext);
