import {
  InviteTeamMembersByCSVModalDataProvider,
  useInviteTeamMembersByCSVModalDataContext,
} from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.data.context';
import { FetchJaneByIdByIdDataProvider } from 'components/JaneModal/FetchJaneById.data.context';
import { JaneModalComponentMap } from 'components/JaneModal/JaneModal.constants';
import {
  JaneModalRoutingProvider,
  useJaneModalRoutingContext,
} from 'components/JaneModal/JaneModal.routing.context';
import { JaneModalTemplate } from 'components/JaneModal/JaneModalTemplate';
import { SelectInputPositionByDepartmentProvider } from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.context';
import { TeamMemberTableRowPropsProvider } from 'pages/TeamMemberPage/components/TeamMemberTable/TeamMemberTableRow/TeamMemberTableRow.props.context';
import { Fragment } from 'react';

const ViewComponent = () => {
  const { janeModalActiveModalView, janeId } = useJaneModalRoutingContext();
  const { location } = useInviteTeamMembersByCSVModalDataContext();
  const Component = JaneModalComponentMap[janeModalActiveModalView] as React.ComponentType;
  if (!Component) return <Fragment />;

  return (
    <SelectInputPositionByDepartmentProvider location={location}>
      <FetchJaneByIdByIdDataProvider janeId={janeId!}>
        <Component />
      </FetchJaneByIdByIdDataProvider>
    </SelectInputPositionByDepartmentProvider>
  );
};

const JaneModalComponent = () => {
  return (
    <JaneModalTemplate>
      <InviteTeamMembersByCSVModalDataProvider>
        <ViewComponent />
      </InviteTeamMembersByCSVModalDataProvider>
    </JaneModalTemplate>
  );
};

export const JaneModal = () => {
  return (
    <JaneModalRoutingProvider>
      <TeamMemberTableRowPropsProvider>
        <JaneModalComponent />
      </TeamMemberTableRowPropsProvider>
    </JaneModalRoutingProvider>
  );
};
