import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components';

const SVG = styled.svg`
  vertical-align: middle;
  margin-left: 14px;
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  min-width: 24px;
`;

function JaneEllipses(props) {
  const themeContext = useContext(ThemeContext);
  return (
    <SVG
      data-testid="menu-additional-options"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={5} cy={12} r={2} fill={themeContext.colors.PrimaryDefault} />
      <circle cx={12} cy={12} r={2} fill={themeContext.colors.PrimaryDefault} />
      <circle cx={19} cy={12} r={2} fill={themeContext.colors.PrimaryDefault} />
    </SVG>
  );
}

export default JaneEllipses;
