import styled from 'styled-components';
import { Body, Caption } from 'components/Typography';
import { Flex } from 'components/Flex';
export const FlexWrapper = styled(Flex)`
  gap: 16px;
  align-items: start;
  padding: 32px;
  flex-direction: column;
`;

export const JaneName = styled(Body)`
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  ${({ theme }) => theme.fonts.PoppinsText}
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
`;
export const H4 = styled.div`
  ${({ theme }) => theme.fonts.Poppins};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;
export const Text = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText}
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
export const LabelText = styled(Caption)`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;
export const ReviewText = styled(LabelText)`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-weight: 500;
`;
export const ReviewCommunityName = styled(LabelText)`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-weight: 500;
`;
export const ReviewNumber = styled(Caption)`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 45px;
  font-weight: 500;
  line-height: 55px;
  letter-spacing: 0.02em;
`;
export const CommentText = styled(Caption)`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 4px;
`;
