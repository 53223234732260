import React, { FC, MouseEventHandler } from 'react';
import { BKJButton, BKJFileInput } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { PositionByDepartmentSelectInput } from 'components/PositionByDepartmentSelectInput';
import {
  CSVBorderHeaderStyle,
  H3,
  TeamMemberCSVWrapper,
  LocationHeader,
  LocationName,
  DownloadHeader,
  UploadHeader,
} from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRankingView.styled';
import { useInviteTeamMembersByCSVModalDataContext } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.data.context';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import {
  TeamMemberEditRankingFormProvider,
  useTeamMemberEditRankingFormContext,
} from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRanking.form.context';
export const EditRankingView: FC = () => {
  const { onChange, values, fieldErrors, onDownload, onSubmit, isDisabled } =
    useTeamMemberEditRankingFormContext();
  const { communities, location } = useInviteTeamMembersByCSVModalDataContext();
  const result = communities?.find((item) => item.id === Number(location?.id));
  const locationName = result?.name;
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={CSVBorderHeaderStyle}
    >
      <Flex flexDirection="column" padding="32px 40px 15px" gap="32px">
        <H3>Import Ranking</H3>
        <TeamMemberCSVWrapper>
          <LocationHeader>Location</LocationHeader>
          <LocationName>{locationName}</LocationName>
        </TeamMemberCSVWrapper>
        <TeamMemberCSVWrapper>
          <LocationHeader>Select Position</LocationHeader>
          <PositionByDepartmentSelectInput
            location={location as IBKJComboBoxAsyncOption}
            dropdownVariant="Square"
            placeholder="Select Position"
            onChange={onChange}
            type="Single"
            name="position_id"
            variant="GreyOutlined"
            value={values?.position_id}
            error={fieldErrors?.position_id}
            width="442px"
            dropdownWidth="442px"
            dropdownProps={{ dropdownListItemVariant: 'List', isSearchable: false }}
          />
        </TeamMemberCSVWrapper>
        <TeamMemberCSVWrapper>
          <DownloadHeader>1. Download Spreadsheet</DownloadHeader>
          <LocationName>Fill in team member data in the Spreadsheet Template.</LocationName>
        </TeamMemberCSVWrapper>
        <BKJButton
          variant="PurpleOutlined"
          width="180px"
          onClick={onDownload}
          type="button"
          disabled={isDisabled}
        >
          Download
        </BKJButton>
        <TeamMemberCSVWrapper>
          <UploadHeader>2. Upload Spreadsheet</UploadHeader>
          <LocationName>
            Select the CSV or XLS file you saved and upload it to invite team members.
          </LocationName>
        </TeamMemberCSVWrapper>
        <div style={{ width: '50%' }}>
          <BKJFileInput
            value={values?.csv}
            name="csv"
            onChange={onChange}
            accept=".csv"
            error={fieldErrors?.csv}
          />
        </div>
      </Flex>

      <Flex height="100%"></Flex>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton
          variant="PurpleOutlined"
          width="180px"
          disabled={isDisabled || !values?.csv}
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          type="button"
        >
          Submit
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};
export const TeamMemberEditRankingView: FC = () => {
  return (
    <TeamMemberEditRankingFormProvider>
      <EditRankingView />
    </TeamMemberEditRankingFormProvider>
  );
};
