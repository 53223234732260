import { IShiftFormCustomField } from 'components/ShiftModal/src/shared/ShiftCustomFields/ShiftCustomFields.types';
import { FormValuesType, isArray } from 'types/common.types';
import { shiftModalOrderDetails_fetchOrder_community_customFields } from 'types/graphql-types';

export function composeCustomFieldsForPayload(
  values: FormValuesType,
  communityCustomFields: shiftModalOrderDetails_fetchOrder_community_customFields[],
): IShiftFormCustomField[] {
  const customFieldsPayload = Object.keys(values).reduce((acc: IShiftFormCustomField[], key) => {
    const communityCustomField = communityCustomFields.find(
      (customField) => customField.name === key,
    );
    const shiftCustomFieldValue = values[key];
    if (
      communityCustomField &&
      shiftCustomFieldValue &&
      typeof shiftCustomFieldValue === 'string'
    ) {
      acc = acc.concat([
        {
          custom_field_id: communityCustomField.id,
          name: communityCustomField.name,
          value: shiftCustomFieldValue,
        },
      ]);
    } else if (communityCustomField && shiftCustomFieldValue && isArray(shiftCustomFieldValue)) {
      if (shiftCustomFieldValue[0]) {
        acc = acc.concat([
          {
            custom_field_id: communityCustomField.id,
            name: communityCustomField.name,
            value: shiftCustomFieldValue[0],
          },
        ]);
      }
    }
    return acc;
  }, []);
  return customFieldsPayload;
}
