import { useGQLQuery } from 'hooks';
import { QUERY_fetchTeamMemberById } from 'queries';
import { FC, useContext } from 'react';
import {
  fetchTeamMemberByIdTeamMembersPage,
  teamMemberPage_fetchJanes_edges_node,
} from 'types/graphql-types';
import { createGQLDataContext } from 'utils/createDataContext';

interface IFetchTeamMemberByIdData {}

export const FetchTeamMemberByIdDataContext = createGQLDataContext<IFetchTeamMemberByIdData>({});

export const FetchTeamMemberByIdDataProvider: FC<{
  teamMemberData: teamMemberPage_fetchJanes_edges_node;
}> = ({ children, teamMemberData }) => {
  const { id } = teamMemberData;
  const fetchTeamMemberRes = useGQLQuery<
    fetchTeamMemberByIdTeamMembersPage,
    fetchTeamMemberByIdTeamMembersPage,
    { teamMemberId: string }
  >({
    key: 'FETCH_TEAM_MEMBER_BY_ID_QUERY',
    query: QUERY_fetchTeamMemberById,
    variables: {
      teamMemberId: id,
    },
  });

  return (
    <FetchTeamMemberByIdDataContext.Provider
      value={{
        ...fetchTeamMemberRes,
        data: fetchTeamMemberRes.called ? fetchTeamMemberRes.data?.fetchJane : teamMemberData,
      }}
    >
      {children}
    </FetchTeamMemberByIdDataContext.Provider>
  );
};

export const useFetchTeamMemberByIdDataContext = () => useContext(FetchTeamMemberByIdDataContext);
