import { useCreateCustomFieldFormContext } from 'components/CustomFieldCreateModal/contexts/CreateCustomField.form.context';
import { customFieldCreateModalRoutingService } from 'components/CustomFieldCreateModal/contexts/CustomFieldCreateModal.ui.context';
import { useFetchCommunitiesDataContext } from 'components/CustomFieldModal/Contexts/FetchCommunities.data.context';
import { CustomFieldFacilitiesFormInitialState } from 'components/CustomFieldModal/CustomFieldModal.constants';
import { ApplyFacilitiesFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { API_POST_customField } from 'requests/POST_customField';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { isArray } from 'types/common.types';
import { VALIDATION_communityIds } from 'validations/VALIDATION_communityIds';

type ApplyFacilitiesFormContextType = UseFormBehaviors<ApplyFacilitiesFormSchema> & {
  handleApplyFacilitiesChange: UseFormBehaviors<ApplyFacilitiesFormSchema>['onChange'];
  handleApplyAllFacilitiesChange: UseFormBehaviors<ApplyFacilitiesFormSchema>['onChange'];
};

export const ApplyFacilitiesFormContext = createContext<ApplyFacilitiesFormContextType>(
  {} as ApplyFacilitiesFormContextType,
);

export const ApplyFacilitiesFormProvider: FC = ({ children }) => {
  const { data: communitiesList } = useFetchCommunitiesDataContext();
  const { apiRequest } = useAPIActions();
  const { values: customFieldInfoParams } = useCreateCustomFieldFormContext();
  const { t } = useTranslation();

  const handleCreateNewCustomField: UseFormBehaviors_Options<ApplyFacilitiesFormSchema>['onSubmit'] =
    useCallback(
      async (customFieldFormParams) => {
        await apiRequest(API_POST_customField(customFieldInfoParams, customFieldFormParams!), {
          onSuccess: () => {
            customFieldCreateModalRoutingService.close();
            queryRefetchService.refetchDataQueryByKey('CUSTOM_FIELD', 'all');
            toastService.success(t('success:CUSTOM_FIELD_SAVED'));
          },
          onError: () => {
            toastService.error(t('FAILURE_TOAST_MESSAGE'));
          },
        });
      },
      [apiRequest, customFieldInfoParams, t],
    );

  const customFieldFacilityFormBehaviorsOpts: UseFormBehaviors_Options<ApplyFacilitiesFormSchema> =
    {
      initialState: CustomFieldFacilitiesFormInitialState,
      onSubmit: handleCreateNewCustomField,
      type: 'CREATE',
      isDirtyCheckEnabled: true,
      validations: {
        communityIds: VALIDATION_communityIds,
      },
    };

  const customFieldFacilitiesForm = useFormBehaviors<ApplyFacilitiesFormSchema>(
    customFieldFacilityFormBehaviorsOpts,
  );

  const { values: formValues, onChange } = customFieldFacilitiesForm;

  const handleApplyFacilitiesChange: UseFormBehaviors<ApplyFacilitiesFormSchema>['onChange'] =
    useCallback(
      (event) => {
        if (!isArray(event)) {
          const {
            target: { value },
          } = event;

          if (formValues.communityIds.includes(`${value}`)) {
            onChange([
              {
                ...event,
                target: {
                  name: 'communityIds',
                  value: formValues.communityIds.filter(
                    (communityId) => communityId !== `${value}`,
                  ),
                },
              },
              {
                ...event,
                target: {
                  name: 'allFacilities',
                  value: false,
                },
              },
            ]);
          } else {
            onChange({
              target: {
                name: 'communityIds',
                value: [...formValues.communityIds, `${value}`],
              },
            });
          }
        }
      },
      [onChange, formValues.communityIds],
    );

  const handleApplyAllFacilitiesChange: UseFormBehaviors<ApplyFacilitiesFormSchema>['onChange'] =
    useCallback(
      (event) => {
        if (!isArray(event)) {
          const {
            target: { value: allFacilitiesSelected },
          } = event;

          const updatedCommunityIds = allFacilitiesSelected
            ? communitiesList?.fetchCommunities.nodes.map((community) => `${community.id}`)
            : [];
          onChange([
            {
              target: {
                name: 'communityIds',
                value: updatedCommunityIds,
              },
            },
            {
              target: {
                name: 'allFacilities',
                value: allFacilitiesSelected,
              },
            },
          ]);
        }
      },
      [communitiesList?.fetchCommunities.nodes, onChange],
    );

  const contextValue = useMemo(
    () => ({
      ...customFieldFacilitiesForm,
      handleApplyFacilitiesChange,
      handleApplyAllFacilitiesChange,
    }),
    [customFieldFacilitiesForm, handleApplyFacilitiesChange, handleApplyAllFacilitiesChange],
  );

  return (
    <ApplyFacilitiesFormContext.Provider value={contextValue}>
      {children}
    </ApplyFacilitiesFormContext.Provider>
  );
};

export const useApplyFacilitiesFormContext = () => useContext(ApplyFacilitiesFormContext);
