import { BKJButton } from '@bookjane2/bookjane-design-library';
import { ShiftJane } from 'components/ShiftModal/src/components/ShiftJane';
import styled from 'styled-components';

export const ShiftJaneSection = styled(ShiftJane)`
  margin-bottom: 12px;
`;

export const DeleteButton = styled(BKJButton)`
  padding: 0;
`;
