import { JaneModalComponentMap } from 'components/JaneModal/JaneModal.constants';
import { IJaneModalRoutingContext, JaneModalViewType } from 'components/JaneModal/JaneModal.types';

import { useURLParameters } from 'hooks';
import { createContext, FC, useContext, useEffect } from 'react';

export const JaneModalRoutingContext = createContext<IJaneModalRoutingContext>({
  isOpen: false,
  janeId: undefined as IJaneModalRoutingContext['janeId'],
  janeModalActiveModalView: 'JaneDetailsView' as JaneModalViewType,
} as IJaneModalRoutingContext);

export const JaneModalRoutingProvider: FC = ({ children }) => {
  const [{ janeId: janeModalJaneId, janeModalActiveModalView }, { removeParam }] =
    useURLParameters<{
      janeModalJaneId?: string;
      janeModalActiveModalView: JaneModalViewType;
    }>();

  const janeId = janeModalJaneId ? janeModalJaneId : undefined;
  useEffect(() => {
    if (!Object.keys(JaneModalComponentMap).includes(janeModalActiveModalView)) {
      removeParam(['janeModalActiveModalView', 'janeModalJaneId']);
    }
    if (!janeId && janeModalActiveModalView) {
      removeParam('janeModalActiveModalView');
    }
    // should also add some logic here to say if the active view is edit but the timeoffid is undefined, remove the param which should shut the modal
  }, [removeParam, janeModalActiveModalView, janeId]);

  return (
    <JaneModalRoutingContext.Provider
      value={{
        isOpen: !!janeModalActiveModalView,
        janeModalActiveModalView,
        janeId,
      }}
    >
      {children}
    </JaneModalRoutingContext.Provider>
  );
};

export const useJaneModalRoutingContext = () => useContext(JaneModalRoutingContext);
