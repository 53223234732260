import { Flex } from 'components/Flex';
import { BKJButton, BKJTextInput, BKJIconTooltip } from '@bookjane2/bookjane-design-library';
import { FC, useState } from 'react';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import {
  groupsModalRoutingService,
  useGroupsDetailsModalUIContext,
} from 'components/GroupsModal/GroupsModal.ui.context';
import { useTranslation } from 'hooks/useTranslation';
import {
  GroupDetailsHeaderBorderStyle,
  GroupsDetailsWrapper,
  H3,
  H4,
  MemberCountLabel,
} from 'components/GroupsModal/GroupsModal.styled';
import { useGroupsModalFormContext } from 'components/GroupsModal/GroupsModal.form.context';
import { MemberTableComponent } from 'components/GroupsModal/views/static/MemberTable.component';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import { css } from 'styled-components';

export const ViewStaticGroupComponent: FC = () => {
  const { t } = useTranslation();
  const {
    values: { name, memberFilter },
    onChange,
    formMembers,
    isSubmitting,
    onDelete,
  } = useGroupsModalFormContext();
  const { groupId } = useGroupsDetailsModalUIContext();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={GroupDetailsHeaderBorderStyle}
    >
      <GroupsDetailsWrapper>
        <H3>{name}</H3>
        <Flex flexDirection="row" padding="0 0 10px 0">
          <H4>{t('groups:STATIC_GROUP')}&nbsp;</H4>
          <BKJIconTooltip iconName="Info" placement="top">
            <Flex width="258px">
              {t('groups:STATIC_GROUP_TOOLTIP_1')}
              <br />
              <br />
              {t('groups:STATIC_GROUP_TOOLTIP_2')}
            </Flex>
          </BKJIconTooltip>
          <MemberCountLabel>
            {formMembers.length} {t('groups:MEMBERS')}
          </MemberCountLabel>
        </Flex>
        <BKJTextInput
          onChange={onChange as ChangeFunctionType}
          name="memberFilter"
          value={memberFilter}
          placeholder={t('groups:SEARCH_MEMBER')}
          leftIconProps={{ iconName: 'Search' }}
          autoComplete="off"
        />
      </GroupsDetailsWrapper>
      <MemberTableComponent memberFilter={memberFilter} readOnly={true} />
      <BKJCenteredDialog
        isOpen={isConfirmDeleteOpen}
        variant="Danger"
        title={t('groups:DELETE_GROUP_TITLE')}
        text={t('groups:DELETE_GROUP_DESCRIPTION')}
        cancelLabel={'Cancel'}
        actionLabel={'Delete'}
        onAction={onDelete}
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onClose={() => setIsConfirmDeleteOpen(false)}
      />
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row">
        <BKJButton
          variant="RedText"
          onClick={() => setIsConfirmDeleteOpen(true)}
          type="button"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          css={css`
            text-decoration: none;
          `}
        >
          {t('groups:DELETE')}
        </BKJButton>
        <BKJButton
          type="button"
          variant="PurpleOutlined"
          width="180px"
          onClick={() => {
            if (groupId) groupsModalRoutingService.goToView('EditGroup', { groupId: groupId });
          }}
        >
          {t('schedule:EDIT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const ViewStaticGroup: FC = () => {
  return <ViewStaticGroupComponent />;
};
