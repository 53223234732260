import {
  BKJButton,
  BKJCheckbox,
  BKJListItem,
  BKJSelect,
  BKJTextArea,
  ErrorBoundary,
} from '@bookjane2/bookjane-design-library';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { useTranslation } from 'hooks/useTranslation';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { QuerySwitch } from 'components/QuerySwitch';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { ShiftTimeSelectInput } from 'components/ShiftTimeSelectInput';
import {
  TimeOffValidationProvider,
  useTimeOffValidationContext,
} from 'components/TimeOffModal/contexts/TimeOffValidation.data.context';
import {
  calendarDropdownProps,
  timeOffModalRoutingService,
  titleOptions,
} from 'components/TimeOffModal/TimeOffModal.constants';
import {
  AllDaySection,
  MainTitle,
  NotesSection,
  TimeOffColumn,
  TimeOffConflictWrapper,
  TimeOffDateTimeWrapper,
  TimeOffJaneSection,
  TimeOffRow,
  TimeOffWrapper,
} from 'components/TimeOffModal/TimeOffModalView.styled';
import { getStartEndDateTime } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.constants';
import {
  CreateTimeOffViewDataContext,
  CreateTimeOffViewDataProvider,
  useCreateTimeOffViewDataContext,
} from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.data.context';
import {
  CreateTimeOffViewFormProvider,
  useCreateTimeOffViewFormContext,
} from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.form.context';
import { CreateTimeOffCustomFieldsViewComponent } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffViewCustomFields';
import { useURLParameters } from 'hooks';
import { FormLabel } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.styled';
import { FC, Fragment, MouseEventHandler, useMemo } from 'react';
import { ITimeOffValidationsQuery } from 'requests/GET_timeOffValidations';
import { css } from 'styled-components';
import { formatPlural } from 'utils/formatPlural';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';

export const CreateTimeOffViewContainer: FC<{}> = () => {
  const { t } = useTranslation();
  const { data } = useCreateTimeOffViewDataContext();
  const {
    hasFieldErrors,
    values: { startDate, startTime, endDate, endTime, allDay, title },
    fieldErrors,
    isSubmitting,
    isDisabled,
    onSubmit,
    onChange,
  } = useCreateTimeOffViewFormContext();

  const { timeOffValidationErrors, timeOffValidationPending } = useTimeOffValidationContext();
  const hasTimeOffConflict =
    !hasFieldErrors &&
    !!timeOffValidationErrors?.error_messages.base.some((error) =>
      error.toLowerCase().includes('conflict'),
    );
  const disableSubmit =
    isDisabled || !!timeOffValidationErrors || isSubmitting || timeOffValidationPending;

  const translatedOptions = titleOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }));
  const RenderOptions: FC<IBKJSelectRenderOptionsProps> = withMemo(function _RenderOptions(props) {
    return (
      <Fragment>
        {translatedOptions.map((option) => {
          const { type, ...restProps } = props;
          return <BKJListItem {...restProps} {...option} />;
        })}
      </Fragment>
    );
  });

  return (
    <Fragment>
      <TimeOffWrapper>
        <MainTitle>{t('schedule:ADD_TIME_OFF')}</MainTitle>
        {!!data?.fetchJane && <TimeOffJaneSection {...data.fetchJane} />}
        <AllDaySection>
          <BKJCheckbox
            value={allDay}
            name="allDay"
            variant={'GreenSolid'}
            onChange={onChange}
            css={css`
              margin-right: 12px;
            `}
          />
          {t('schedule:ALL_DAY')}
        </AllDaySection>

        <Flex flexDirection="column" width="100%" gap="8px">
          <TimeOffDateTimeWrapper>
            <TimeOffRow>
              <TimeOffColumn>
                <FormLabel>{t('schedule:STARTS')}</FormLabel>
                <ShiftDateCalendarInput
                  name="startDate"
                  error={fieldErrors?.startDate || hasTimeOffConflict}
                  value={startDate}
                  onChange={onChange}
                  dropdownProps={calendarDropdownProps}
                />
              </TimeOffColumn>
              <TimeOffColumn>
                {!allDay && (
                  <ShiftTimeSelectInput
                    label={t('schedule:START_TIME')}
                    value={startTime}
                    placeholder={t('schedule:START_TIME')}
                    onChange={onChange}
                    error={fieldErrors?.startTime || hasTimeOffConflict}
                    name="startTime"
                  />
                )}
              </TimeOffColumn>
            </TimeOffRow>
            <TimeOffRow>
              <TimeOffColumn>
                <FormLabel>{t('schedule:ENDS')}</FormLabel>
                <ShiftDateCalendarInput
                  error={fieldErrors?.endDate || hasTimeOffConflict}
                  name="endDate"
                  value={endDate}
                  onChange={onChange}
                  dropdownProps={calendarDropdownProps}
                />
              </TimeOffColumn>
              <TimeOffColumn>
                {!allDay && (
                  <ShiftTimeSelectInput
                    label={t('schedule:END_TIME')}
                    value={endTime}
                    placeholder={t('schedule:END_TIME')}
                    onChange={onChange}
                    name="endTime"
                    error={fieldErrors?.endTime || hasTimeOffConflict}
                  />
                )}
              </TimeOffColumn>
            </TimeOffRow>
          </TimeOffDateTimeWrapper>

          {hasTimeOffConflict && (
            <TimeOffConflictWrapper>
              <div>
                {t('schedule:DATE_AND_TIMES_CONFLICT_WITH')}
                {timeOffValidationErrors.error_messages.base.length}
                {formatPlural('shift', timeOffValidationErrors.error_messages.base.length)} $
                {t('schedule:SCHEDULED_FOR_THIS_TEAM_MEMBER')}
              </div>
              <div>{t('schedule:RELEASE_OR_CANCEL_SHIFTS_NOTE')}</div>
            </TimeOffConflictWrapper>
          )}
        </Flex>

        <Flex width="100%" flexDirection="column">
          <FormLabel>{t('schedule:TITLE')}</FormLabel>
          <BKJSelect
            options={translatedOptions}
            type="Single"
            variant="GreyOutlined"
            dropdownPlacement="bottom-start"
            name="title"
            onChange={onChange}
            value={title}
            renderOptions={RenderOptions}
            required
          />
        </Flex>

        <NotesSection>
          <BKJTextArea
            label={t('schedule:NOTES')}
            name="notes"
            placeholder={t('schedule:NOTES_WILL_ONLY_BE_SHARED_WITH_FACILITY_ADMINISTRATORS')}
            onChange={onChange}
            maxLength={1000}
          />
        </NotesSection>

        <CreateTimeOffCustomFieldsViewComponent />
      </TimeOffWrapper>

      <BKJSideModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <Flex width="100%" justifyContent="flex-end">
            <BKJButton
              width="180px"
              variant="PurpleOutlined"
              css={css`
                margin-right: 8px;
              `}
              onClick={() => timeOffModalRoutingService.close()}
            >
              {t('auth:CANCEL')}
            </BKJButton>
            <BKJButton
              width="180px"
              variant="PurpleSolid"
              disabled={disableSubmit}
              onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            >
              {t('auth:SAVE')}
            </BKJButton>
          </Flex>
        </Flex>
      </BKJSideModalFooter>
    </Fragment>
  );
};

export const CreateTimeOffViewRenderer: FC = withMemo((props) => {
  return (
    <ErrorBoundary
      componentName="CreateTimeOffView"
      showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
    >
      <CreateTimeOffView {...props} />
    </ErrorBoundary>
  );
});

const CreateTimeOffViewValidationComponent: FC = () => {
  const [{ jane_id }] = useURLParameters<{ jane_id: string }>();

  const {
    values: { startDate, startTime, endDate, endTime, allDay },
  } = useCreateTimeOffViewFormContext();

  const [startDateTime, endDateTime] = getStartEndDateTime({
    startDate,
    startTime,
    endDate,
    endTime,
    allDay,
  });

  const timeOffValidationsQuery: ITimeOffValidationsQuery = useMemo(
    () => ({
      time_off: {
        start_time: startDateTime,
        end_time: endDateTime,
        jane_id,
      },
    }),
    [endDateTime, jane_id, startDateTime],
  );

  return (
    <TimeOffValidationProvider timeOffValidationsQuery={timeOffValidationsQuery}>
      <CreateTimeOffViewContainer />
    </TimeOffValidationProvider>
  );
};

const CreateTimeOffViewFormComponent: FC = () => {
  return (
    <CreateTimeOffViewFormProvider>
      <CreateTimeOffViewValidationComponent />
    </CreateTimeOffViewFormProvider>
  );
};

const CreateTimeOffView: FC = () => {
  return (
    <CreateTimeOffViewDataProvider>
      <QuerySwitch
        context={CreateTimeOffViewDataContext}
        component={CreateTimeOffViewFormComponent}
      />
    </CreateTimeOffViewDataProvider>
  );
};
