export const releaseReasons = [
  {
    key: 'Schedule Conflict',
    label: 'Schedule Conflict',
    value: 'conflicts_with_another_shift',
  },
  {
    key: 'Paid Time Off',
    label: 'Paid Time Off',
    value: 'paid_time_off',
  },
  {
    key: 'Unpaid Time Off',
    label: 'Unpaid Time Off',
    value: 'unpaid_time_off',
  },
  {
    key: 'Other',
    label: 'Other',
    value: 'other',
  },
];
