import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  FormFieldCacheConfig,
  FormType,
  GroupedFormsBehaviorsOptionsType,
  GroupedFormsBehaviorsType,
  DirtyFieldsType,
  FormErrorsType,
  GroupedFormsOnSubmitResultType,
} from './useGroupedFormsBehaviors.types';
import { FormValuesType, FieldValuesType, IFieldErrors } from 'types/common.types';
import {
  ChangeEventType,
  ChangeFunctionType,
} from '@bookjane2/bookjane-design-library/lib/common.types';
import { isArray, isBoolean } from 'lodash';
import { validationTimeout } from './useGroupedFormsBehaviors.constants';
import { ssfPersistenceService, SSF_GLOBAL_CACHE_KEY } from 'services/ssfPersistenceService';
import { deepEquals } from 'utils/deepEquals';

export function useGroupedFormsBehaviors(
  options: GroupedFormsBehaviorsOptionsType = {
    initialGroupedFormsState: [{ values: {}, requiredFields: [], dirtyFields: [] }],
    initialState: { values: {}, requiredFields: [], dirtyFields: [] },
    validations: {},
    onSubmit: async () => {
      return {
        payload: {},
      };
    },
    isDirtyCheckEnabled: false,
  },
): GroupedFormsBehaviorsType {
  const {
    initialGroupedFormsState,
    initialState,
    onSubmit = async () => {
      return {
        payload: {},
      };
    },
    isDirtyCheckEnabled = false,
    validations,
    cacheConfig,
  } = options;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [groupedForms, setGroupedForms] = useState<FormType[]>(initialGroupedFormsState);
  const [values, setValues] = useState<FormValuesType>(initialState.values);
  const [dirtyFields, setDirtyFields] = useState<DirtyFieldsType>(initialState.dirtyFields);
  const [fieldErrors, setFieldErrors] = useState<IFieldErrors>({});
  const [groupedFormsFieldErrors, setGroupedFormsFieldErrors] = useState<IFieldErrors[]>([{}]);
  const [groupedFormsFormErrors, setGroupedFormsFormErrors] = useState<FormErrorsType[]>([]);
  const [groupedFormsIsSubmitDisabled, setGroupedFormsIsSubmitDisabled] = useState<boolean[]>([]);
  const [apiErrors, setApiErrors] = useState<Record<string, {}>>({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const mountedRef = useRef<boolean>(false);
  const requiredFieldsRef = useRef<string[]>(initialState.requiredFields);
  const debounceRef = useRef<number | undefined>(undefined);
  const valuesRef = useRef<FormValuesType>(values);
  const dirtyFieldsRef = useRef<DirtyFieldsType>(initialState.dirtyFields);
  const groupedFormsDebounceRefs = useRef<number[] | undefined[]>(
    groupedForms.map(() => undefined),
  );
  let groupedFormsValuesRefs = useRef<FormValuesType[]>(groupedForms.map((form) => form.values));
  const groupedFormsDirtyFieldsRefs = useRef<DirtyFieldsType[]>(
    groupedForms.map((form) => form.dirtyFields),
  );

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      groupedFormsDebounceRefs.current.forEach((debounceRef?: number) =>
        window.clearTimeout(debounceRef),
      );
    };
  }, []);

  useEffect(() => {
    if (mountedRef.current) {
      valuesRef.current = values;
      dirtyFieldsRef.current = dirtyFields;
      groupedFormsValuesRefs.current = groupedForms.map((form) => form.values);
      groupedFormsDebounceRefs.current = groupedForms.map(() => undefined);
      groupedFormsDirtyFieldsRefs.current = groupedForms.map((form) => form.dirtyFields);
    }
  }, [dirtyFields, groupedForms, values]);

  const handleFormItemValidations = useCallback(
    ({ index }: { index: number }): void => {
      let _groupedForms = [...groupedForms];
      let _groupedFormsFieldErrors = [...groupedFormsFieldErrors];
      let _groupedFormsFormErrors = [...groupedFormsFormErrors];
      let _form = _groupedForms[index];
      let _formValues = _groupedForms[index].values;
      const _fieldErrors: IFieldErrors = {};
      let _formErrors: string[] = [];
      const _validations = _form.validations;
      let _dirtyFields = groupedFormsDirtyFieldsRefs.current[index];
      if (_validations)
        Object.entries(_validations).forEach(([key, fn]) => {
          if (_dirtyFields.includes(key)) {
            const result = fn({
              formValues: _formValues,
              value: _formValues[key],
              dirtyFields: _dirtyFields,
            });
            let { valFieldErrors, valFormErrors = [] } = result;
            if (valFieldErrors && valFieldErrors.length) _fieldErrors[key] = valFieldErrors;
            if (valFormErrors[0])
              _formErrors = _formErrors
                .concat(valFormErrors)
                .filter((value, index, array) => array.indexOf(value) === index);
          }
        });

      _groupedFormsFormErrors[index] = _formErrors;
      _groupedFormsFieldErrors[index] = _fieldErrors;

      if (mountedRef.current) {
        setGroupedFormsFormErrors(_groupedFormsFormErrors);
        setGroupedFormsFieldErrors(_groupedFormsFieldErrors);
      }
    },
    [groupedForms, groupedFormsFieldErrors, groupedFormsFormErrors],
  );

  const handleAllFormItemsValidations = useCallback((): void => {
    const _groupedForms = [...groupedForms];
    const _groupedFormsFieldErrors = [...groupedFormsFieldErrors];
    const _groupedFormsFormErrors = [...groupedFormsFormErrors];
    const _groupedFormsIsSubmitDisabled = [...groupedFormsIsSubmitDisabled];
    _groupedForms.forEach((form, index) => {
      _groupedFormsIsSubmitDisabled[index] = false;
      let _form = { ...form };
      let _formValues = _groupedForms[index].values;
      const _fieldErrors: IFieldErrors = {};
      let _formErrors: string[] = [];
      const _validations = _form.validations;
      let _dirtyFields = Object.keys(_formValues);
      if (_validations)
        Object.entries(_validations).forEach(([key, fn]) => {
          if (_dirtyFields.includes(key)) {
            const result = fn({
              formValues: _formValues,
              value: _formValues[key],
              dirtyFields: _dirtyFields,
            });
            let { valFieldErrors, valFormErrors = [] } = result;
            if (valFieldErrors && valFieldErrors.length) _fieldErrors[key] = valFieldErrors;
            if (valFormErrors[0])
              _formErrors = _formErrors
                .concat(valFormErrors)
                .filter((value, index, array) => array.indexOf(value) === index);
          }
        });

      _groupedFormsIsSubmitDisabled[index] = false;
      _groupedFormsFormErrors[index] = _formErrors;
      _groupedFormsFieldErrors[index] = _fieldErrors;
    });

    if (mountedRef.current) {
      setGroupedFormsIsSubmitDisabled(_groupedFormsIsSubmitDisabled);
      setGroupedFormsFormErrors(_groupedFormsFormErrors);
      setGroupedFormsFieldErrors(_groupedFormsFieldErrors);
    }
  }, [groupedForms, groupedFormsFieldErrors, groupedFormsFormErrors, groupedFormsIsSubmitDisabled]);

  const handleFormItemChange = useCallback(
    ({ event, index }) => {
      let _events = event;
      const _groupedForms = [...groupedForms];
      const _groupedFormsIsSubmitDisabled = [...groupedFormsIsSubmitDisabled];
      const _form = _groupedForms[index];
      let _formValues = _form.values;

      if (!isArray(event)) {
        _events = [event];
      }

      _events.forEach((event: ChangeEventType) => {
        _formValues = { ..._formValues, [event.target.name]: event.target.value };
        _groupedFormsIsSubmitDisabled[index] = true;
        _form.values = _formValues;
        if (!_form.dirtyFields.includes(event.target.name))
          _form.dirtyFields.push(event.target.name);
        _groupedForms[index] = _form;
      });

      if (mountedRef.current) {
        setGroupedForms(_groupedForms);
        setGroupedFormsIsSubmitDisabled(_groupedFormsIsSubmitDisabled);
      }
      if (groupedFormsDebounceRefs.current[index])
        window.clearTimeout(groupedFormsDebounceRefs.current[index]);
      const timeout_id = window.setTimeout(() => {
        if (mountedRef.current) handleFormItemValidations({ index });
      }, validationTimeout);
      groupedFormsDebounceRefs.current[index] = timeout_id;
    },
    [groupedForms, groupedFormsIsSubmitDisabled, handleFormItemValidations],
  );

  const handleValidations = useCallback(() => {
    if (validations) {
      const _fieldErrors: IFieldErrors = {};
      Object.entries(validations).forEach(([key, fn]) => {
        if (dirtyFieldsRef.current.includes(key)) {
          const _values = valuesRef.current || {};
          const { valFieldErrors } = fn({
            formValues: _values,
            value: _values[key],
          });
          if (valFieldErrors && valFieldErrors.length > 0) _fieldErrors[key] = valFieldErrors;
        }
      });

      if (mountedRef.current) setFieldErrors(_fieldErrors);
    }
    if (mountedRef.current) setIsSubmitDisabled(false);
    if (mountedRef.current) setIsSubmitting(false);
  }, [validations]);

  const handleCaching = useCallback(
    (event: ChangeEventType) => {
      const { value, name } = event.target;
      const formItemCacheConfig: FormFieldCacheConfig | undefined =
        cacheConfig && cacheConfig[name];
      if (formItemCacheConfig) {
        switch (formItemCacheConfig.cachekey) {
          case SSF_GLOBAL_CACHE_KEY(): {
            ssfPersistenceService.setGlobalCachedSSFValue({
              name,
              value,
            });
            break;
          }
          default: {
            break;
          }
        }
      }
    },
    [cacheConfig],
  );

  const handleChange: ChangeFunctionType = useCallback(
    (event) => {
      let _events = event;
      let _values: FieldValuesType = { ...values };
      let _dirtyFields = [...dirtyFieldsRef.current];
      if (mountedRef.current) setIsSubmitDisabled(true);
      if (!isArray(event)) {
        _events = [event];
      }
      _events.forEach((event: ChangeEventType) => {
        _values = { ..._values, [event.target.name]: event.target.value };
        handleCaching(event);
        if (isDirtyCheckEnabled && !_dirtyFields.includes(event.target.name)) {
          _dirtyFields.push(event.target.name);
        }
      });
      if (mountedRef.current) {
        setValues(_values);
        setDirtyFields(_dirtyFields);
        setFieldErrors({});
      }
      if (debounceRef.current) window.clearTimeout(debounceRef.current);
      const timeout_id = window.setTimeout(() => {
        if (mountedRef.current) handleValidations();
      }, validationTimeout);
      debounceRef.current = timeout_id;
    },
    [handleCaching, handleValidations, isDirtyCheckEnabled, values],
  );

  const isValueDefined = (
    value: string | number | string[] | number[] | boolean | undefined,
  ): boolean => {
    if (isArray(value) && !value.length) {
      return false;
    }
    if (value === false) {
      return true;
    }
    return !!value;
  };

  const doRequiredValuesExist = useCallback((): boolean => {
    if (
      values &&
      requiredFieldsRef.current.length &&
      Object.entries(values).some(
        ([key, value]) => requiredFieldsRef.current.includes(key) && !isValueDefined(value),
      )
    ) {
      return false;
    }

    if (
      groupedForms.length &&
      groupedForms.some((formItem) => {
        const _formItemValues = formItem.values;
        const _formItemRequiredFields = formItem.requiredFields;
        return (
          _formItemValues &&
          _formItemRequiredFields.length &&
          Object.entries(_formItemValues).some(
            ([formItemKey, formItemValue]) =>
              _formItemRequiredFields.includes(formItemKey) && !isValueDefined(formItemValue),
          )
        );
      })
    ) {
      return false;
    }

    return true;
  }, [values, groupedForms]);

  const forceValidateForm = useCallback(() => {
    const _groupedForms = groupedForms.map((form) => {
      return { ...form, dirtyFields: Object.keys(form.values) };
    });
    const _dirtyFields = Object.keys(values);
    setGroupedForms(_groupedForms);
    setDirtyFields(_dirtyFields);

    if (debounceRef.current) window.clearTimeout(debounceRef.current);
    const timeout_id = window.setTimeout(() => {
      if (mountedRef.current) {
        handleValidations();
        handleAllFormItemsValidations();
      }
    }, validationTimeout);
    debounceRef.current = timeout_id;
  }, [groupedForms, values, handleValidations, handleAllFormItemsValidations]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<GroupedFormsOnSubmitResultType> => {
      if (mountedRef.current) setIsSubmitting(true);
      if (mountedRef.current) setIsSubmitDisabled(true);

      const _groupedFormsValues: FormValuesType[] = groupedFormsValuesRefs.current.map(
        (values) => values,
      );
      const _values: FormValuesType = valuesRef.current;
      e.preventDefault();
      try {
        let result = { payload: {} };
        const awaitResult = await onSubmit({
          groupedFormsValues: _groupedFormsValues,
          values: _values,
        });
        result = { ...result, ...awaitResult };
        if (mountedRef.current) setApiErrors({});
        if (mountedRef.current) setIsSubmitting(false);
        return unwrapResult(result);
      } catch (err) {
        if (mountedRef.current) setApiErrors(err as {});
      } finally {
        if (mountedRef.current) setIsSubmitting(false);
        if (mountedRef.current) setIsSubmitDisabled(false);
      }
      return { payload: {} };
    },
    [onSubmit],
  );

  const isFormSubmissionDisabled = useMemo(() => {
    return (
      isSubmitDisabled ||
      !doRequiredValuesExist() ||
      Object.values(fieldErrors).some((errorMessage) => !!errorMessage) ||
      groupedFormsFormErrors.some((formItemFormErrors) => !!formItemFormErrors[0]) ||
      groupedFormsFieldErrors.some((formItemFieldErrors) =>
        Object.values(formItemFieldErrors).some(
          (formItemFieldErrors) => formItemFieldErrors && !!formItemFieldErrors[0],
        ),
      )
    );
  }, [
    isSubmitDisabled,
    doRequiredValuesExist,
    fieldErrors,
    groupedFormsFormErrors,
    groupedFormsFieldErrors,
  ]);

  const addFormItem = (formItem: FormType) => {
    const _groupedForms = [...groupedForms];
    const _groupedFormsFieldErrors = [...groupedFormsFieldErrors];
    const _groupedFormsFormErrors = [...groupedFormsFormErrors];
    _groupedForms.push(formItem);
    _groupedFormsFieldErrors.push({});
    _groupedFormsFormErrors.push([]);
    setGroupedForms(_groupedForms);
    setGroupedFormsFormErrors(_groupedFormsFormErrors);
    setGroupedFormsFieldErrors(_groupedFormsFieldErrors);
  };

  const removeFormItem = ({ id }: { id: string }) => {
    const deleteIndex = groupedForms.findIndex((form) => form?.values?.id === id);
    const _groupedForms = [...groupedForms];
    const _groupedFormsFieldErrors = [...groupedFormsFieldErrors];
    const _groupedFormsFormErrors = [...groupedFormsFormErrors];
    _groupedForms.splice(deleteIndex, 1);
    _groupedFormsFieldErrors.splice(deleteIndex, 1);
    _groupedFormsFormErrors.splice(deleteIndex, 1);
    setGroupedForms(_groupedForms);
    setGroupedFormsFormErrors(_groupedFormsFormErrors);
    setGroupedFormsFieldErrors(_groupedFormsFieldErrors);
  };

  const isFormItemDirty = (index: number) =>
    Object.entries(groupedForms[index].values).some(([key, value]) => {
      const val = isArray(value) ? value[0] : value;
      let initVal = initialGroupedFormsState[0].values[key];
      initVal = isArray(initVal) ? initVal[0] : initVal;
      if (isBoolean(val)) {
        return initVal !== value ? true : false;
      }

      if (key !== 'id' && !!val && !deepEquals(initVal, val)) {
        return true;
      }
      return false;
    });

  const isDirty = groupedForms.some((form, index) => isFormItemDirty(index));

  const resetGroupedForms = () => {
    setGroupedForms(initialGroupedFormsState);
    setGroupedFormsFieldErrors([{}]);
    setGroupedFormsFormErrors([]);
    setGroupedFormsIsSubmitDisabled([]);
    setApiErrors({});
    setIsSubmitDisabled(false);
    groupedFormsDebounceRefs.current = initialGroupedFormsState.map(() => undefined);
    groupedFormsValuesRefs.current = initialGroupedFormsState.map((form) => form.values);
    groupedFormsDirtyFieldsRefs.current = initialGroupedFormsState.map((form) => form.dirtyFields);
  };

  return {
    groupedForms: groupedForms.map((form, index) => {
      return {
        ...form,
        onChange: (e) => handleFormItemChange({ event: e, index }),
      };
    }),
    values,
    apiErrors,
    fieldErrors,
    groupedFormsFormErrors,
    groupedFormsFieldErrors,
    onSubmit: handleSubmit,
    isSubmitting,
    isSubmitDisabled: isFormSubmissionDisabled,
    onChange: handleChange,
    forceValidateForm,
    addFormItem,
    removeFormItem,
    isDirty,
    resetGroupedForms,
  };
}
