import React, { MouseEventHandler } from 'react';
import { BKJButton, BKJFileInput } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';

import {
  CSVBorderHeaderStyle,
  H3,
  TeamMemberCSVWrapper,
  LocationHeader,
  LocationName,
  DownloadHeader,
  UploadHeader,
} from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.styled';

import { FC } from 'react';
import { useInviteTeamMembersByCSVModalDataContext } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.data.context';
import {
  TeamMemberCSVViewFormProvider,
  useTeamMemberCSVViewFormContext,
} from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.form.context';

export const CSVView: FC = () => {
  const { onDownload, values, onChange, fieldErrors, onSubmit } = useTeamMemberCSVViewFormContext();
  const { communities, location } = useInviteTeamMembersByCSVModalDataContext();
  const result = communities?.find((item) => item.id === Number(location?.id));
  const locationName = result?.name;
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={CSVBorderHeaderStyle}
    >
      <Flex flexDirection="column" padding="32px 40px 15px" gap="32px">
        <H3>Import via Spreadsheet</H3>
        <TeamMemberCSVWrapper>
          <LocationHeader>Location</LocationHeader>
          <LocationName>{locationName}</LocationName>
        </TeamMemberCSVWrapper>
        <TeamMemberCSVWrapper>
          <DownloadHeader>1. Download Spreadsheet</DownloadHeader>
          <LocationName>Fill in team member data in the Spreadsheet Template.</LocationName>
        </TeamMemberCSVWrapper>
        <BKJButton variant="PurpleOutlined" width="180px" onClick={onDownload} type="button">
          Download
        </BKJButton>
        <TeamMemberCSVWrapper>
          <UploadHeader>2. Upload Spreadsheet</UploadHeader>
          <LocationName>
            Select the CSV or XLS file you saved and upload it to invite team members.
          </LocationName>
        </TeamMemberCSVWrapper>
        <div style={{ width: '50%' }}>
          <BKJFileInput
            value={values?.csv}
            name="csv"
            onChange={onChange}
            accept=".csv,.xlsx"
            error={fieldErrors?.csv}
          />
        </div>
      </Flex>

      <Flex height="100%"></Flex>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton
          variant="PurpleOutlined"
          width="180px"
          disabled={!values?.csv}
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          type="button"
        >
          Import
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const TeamMemberCSVView: FC = () => {
  return (
    <TeamMemberCSVViewFormProvider>
      <CSVView />
    </TeamMemberCSVViewFormProvider>
  );
};
