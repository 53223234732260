import {
  ApolloClient,
  ApolloProvider as Provider,
  createHttpLink,
  InMemoryCache,
  Reference,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BASE_URL } from 'constants/endpoints';
import isEqual from 'lodash/isEqual';
import unionWith from 'lodash/unionWith';
import { createBookJaneRequestHeaders } from 'utils/createBookJaneRequest';

export const httpLink = () =>
  createHttpLink({
    uri: `${BASE_URL()}/graphql`,
  });

export const authLink = setContext((_, { headers }) => {
  return {
    headers: createBookJaneRequestHeaders(headers),
  };
});

const defaultApolloCache = createApolloCache();

export function createApolloClient(options: { cache?: InMemoryCache } = {}) {
  const { cache = defaultApolloCache } = options;

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink()),
    cache,
  });
  return apolloClient;
}

export function createApolloCache() {
  return new InMemoryCache({
    typePolicies: {
      Order: {
        fields: {
          orderAvailabilities: {
            keyArgs: ['__ref'],
            merge(existing = { nodes: [] }, incoming = { nodes: [] }, { readField }) {
              const nextNodes = [...existing.nodes];
              if (incoming.nodes?.length > 0)
                incoming.nodes.forEach((record: { record: {} }) => {
                  const id = readField('id', record);
                  if (id)
                    if (
                      !nextNodes.some((nodeRef) => {
                        const nodeRefId = readField('id', nodeRef);
                        return nodeRefId === id;
                      })
                    ) {
                      nextNodes.push(record);
                    }
                });
              return { ...existing, ...incoming, nodes: nextNodes };
            },
          },
        },
      },
      Query: {
        fields: {
          fastGroupOrders: {
            keyArgs: ['__ref'],
            merge(existing = { nodes: [] }, incoming = { nodes: [] }, { readField }) {
              const nextNodes = [...existing.nodes];
              if (incoming.nodes?.length > 0)
                incoming.nodes.forEach((record: { group: string; items: Reference[] }) => {
                  if (!nextNodes.some((node) => node.group === record.group)) {
                    nextNodes.push(record);
                  } else {
                    const updateGroupIndex = nextNodes.findIndex(
                      (node) => node.group === record.group,
                    );
                    const updatedOrderList = unionWith(
                      nextNodes[updateGroupIndex].items,
                      record.items,
                      (ref1, ref2) => isEqual(readField('id', ref1), readField('id', ref2)),
                    );
                    nextNodes.splice(updateGroupIndex, 1, {
                      group: record.group,
                      items: updatedOrderList,
                    });
                  }
                });
              return { ...existing, ...incoming, nodes: nextNodes };
            },
          },
          fetchTimeSheets: {
            keyArgs: false,
            merge(existing = { edges: [] }, incoming = { edges: [] }) {
              const nextEdges = [...existing.edges];
              if (incoming.edges?.length > 0)
                incoming.edges.forEach((record: { node: { __ref: string } }) => {
                  if (record && record.node?.__ref)
                    if (
                      !nextEdges.some(
                        (_record: { node: { __ref: string } }) =>
                          record.node.__ref === _record.node.__ref,
                      )
                    )
                      nextEdges.push(record);
                });
              return { ...existing, ...incoming, edges: nextEdges };
            },
          },
          fetchJanes: {
            keyArgs: false,
            merge(existing = { nodes: [] }, incoming = { nodes: [] }) {
              const nextNodes = [...existing.nodes];
              if (incoming.nodes?.length > 0)
                incoming.nodes.forEach((record: { __ref: string }) => {
                  if (record && record.__ref)
                    if (!nextNodes.some(({ __ref }) => record.__ref === __ref))
                      nextNodes.push(record);
                });
              return { ...existing, ...incoming, nodes: nextNodes };
            },
          },
          fetchShiftDistributionRules: {
            keyArgs: false,
            merge(existing = { nodes: [] }, incoming = { nodes: [] }) {
              const nextNodes = [...existing.nodes];
              if (incoming.nodes?.length > 0)
                incoming.nodes.forEach((record: { __ref: string }) => {
                  if (record && record.__ref)
                    if (!nextNodes.some(({ __ref }) => record.__ref === __ref))
                      nextNodes.push(record);
                });
              return { ...existing, ...incoming, nodes: nextNodes };
            },
          },
          requestJanes: {
            keyArgs: false,
            merge(existing = { nodes: [] }, incoming = { nodes: [] }) {
              const nextNodes = [...existing.nodes];
              if (incoming.nodes?.length > 0)
                incoming.nodes.forEach((record: { __ref: string }) => {
                  if (record && record.__ref)
                    if (!nextNodes.some(({ __ref }) => record.__ref === __ref))
                      nextNodes.push(record);
                });
              return { ...existing, ...incoming, nodes: nextNodes };
            },
          },
          fetchTimeOffs: {
            keyArgs: false,
            merge(existing = { nodes: [] }, incoming = { nodes: [] }) {
              const nextNodes = [...existing.nodes];
              if (incoming.nodes?.length > 0)
                incoming.nodes.forEach((record: { __ref: string }) => {
                  if (record && record.__ref)
                    if (!nextNodes.some(({ __ref }) => record.__ref === __ref))
                      nextNodes.push(record);
                });
              return { ...existing, ...incoming, nodes: nextNodes };
            },
          },
          fetchAgencyCommunities: {
            keyArgs: false,
            merge(existing = { nodes: [] }, incoming = { nodes: [] }) {
              const nextNodes = [...existing.nodes];
              if (incoming.nodes?.length > 0)
                incoming.nodes.forEach((record: { __ref: string }) => {
                  if (record && record.__ref)
                    if (!nextNodes.some(({ __ref }) => record.__ref === __ref))
                      nextNodes.push(record);
                });
              return { ...existing, ...incoming, nodes: nextNodes };
            },
          },
        },
      },
    },
  });
}

export const defaultApolloClient = createApolloClient();

export function ApolloProvider({ children }: { children: JSX.Element }) {
  return <Provider client={defaultApolloClient}>{children}</Provider>;
}
