import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJSideModal } from 'components/BKJSideModal';
import { FC, Fragment, useMemo, useState } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import {
  inviteAdminModalRoutingService,
  useInviteAdminModalUIContext,
} from 'components/InviteAdminModal/InviteAdminModal.ui.context';
import { InviteAdminModalComponentMap } from 'components/InviteAdminModal/InviteAdminModal.constants';
import {
  InviteAdminModalDataProvider,
  useInviteAdminModalDataContext,
} from 'components/InviteAdminModal/InviteAdminModal.data.context';
import { InviteAdminModalFormProvider } from 'components/InviteAdminModal/InviteAdminModal.form.context';
import { useTranslation } from 'hooks/useTranslation';

const InviteAdminFormViewComponent: FC<{ view: FC }> = ({ view: Component }) => {
  const data = useInviteAdminModalDataContext();
  if (!data || !data?.communities?.length) return <Fragment />;
  return (
    <InviteAdminModalFormProvider>
      <Component />
    </InviteAdminModalFormProvider>
  );
};

export const InviteAdminModalView: FC = () => {
  const { isOpen, inviteAdminActiveModalView } = useInviteAdminModalUIContext();

  const [isDiscardConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const { t } = useTranslation();

  const Component = useMemo(
    () => InviteAdminModalComponentMap[inviteAdminActiveModalView],
    [inviteAdminActiveModalView],
  );

  const modalHeader = {
    New: t('settings:INVITE_ADMIN'),
    Locations: t('settings:INVITE_ADMIN'),
  };

  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={() => inviteAdminModalRoutingService.close()}
      showBackButton={inviteAdminModalRoutingService.isBackButtonVisible()}
      onBack={inviteAdminModalRoutingService.goToPrevView}
      headerComponent={modalHeader[inviteAdminActiveModalView]}
    >
      <ErrorBoundary
        componentName="InviteAdminModal"
        showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
      >
        <BKJCenteredDialog
          isOpen={isDiscardConfirmationOpen}
          variant="Danger"
          title="Discard changes?"
          data-cy="discard-modal"
          text=""
          cancelLabel="Cancel"
          actionLabel="Discard"
          onAction={() => {
            setIsDeleteConfirmationOpen(false);
            return inviteAdminModalRoutingService.close();
          }}
          onCancel={() => setIsDeleteConfirmationOpen(false)}
          onClose={() => setIsDeleteConfirmationOpen(false)}
        />
        {isOpen && (
          <InviteAdminModalDataProvider>
            <InviteAdminFormViewComponent view={Component} />
          </InviteAdminModalDataProvider>
        )}
      </ErrorBoundary>
    </BKJSideModal>
  );
};

export const InviteAdminModalComponent: FC = () => {
  return <InviteAdminModalView />;
};
