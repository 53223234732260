import { BKJButton } from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useSendToAgenciesPropsContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.props.context';
import { SendToAgenciesViewTitle } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { useURLParameters } from 'hooks';
import { useTranslation } from 'hooks/useTranslation';
import { FC, Fragment, useEffect } from 'react';

export const PostRequestOptionsView: FC = () => {
  const { t, i18n } = useTranslation();
  const { values, onChange } = useSendToAgenciesFormContext();
  const { goToNextView } = useSendToAgenciesPropsContext();

  const [, { removeParam }] = useURLParameters();
  useEffect(() => {
    if (!shiftModalRoutingService.isBackButtonVisible())
      removeParam(['shiftModalShiftId', 'shiftModalActiveModalView']);
  }, [removeParam]);
  const frenchFormat = i18n.language === 'fr';

  const isDeclineBehaviorInternalOnly = values.post_action === 'internal_only';
  const isDeclineBehaviorSendToAgencies = values.post_action === 'send_to_agencies';
  return (
    <Fragment>
      <Flex flexDirection="column" flex="1" padding="40px 32px">
        <SendToAgenciesViewTitle>
          {t('shifts_calendar:POST_REQUEST_OPTIONS')}
        </SendToAgenciesViewTitle>

        <BKJRadioInput
          name="post_action"
          value="internal_only"
          onChange={onChange}
          checked={isDeclineBehaviorInternalOnly}
          label={t('shifts_calendar:KEEP_SHIFT_WITHIN_TEAM_NOTE')}
          isFrenchFormat={frenchFormat}
        />

        <BKJRadioInput
          name="post_action"
          value="send_to_agencies"
          onChange={onChange}
          checked={isDeclineBehaviorSendToAgencies}
          label={t('shifts_calendar:SEND_SHIFT_TO_ALL_MEMBER_NOTE')}
          isFrenchFormat={frenchFormat}
        />
      </Flex>
      <BKJSideModalFooter>
        <AutoMargin />
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          disabled={!values.post_action}
          onClick={goToNextView}
        >
          {t('auth:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
