import { BKJStickyTableHead } from 'components/BKJTable/BKJTable.styled';
import { BKJTableSubHeader } from 'components/BKJTable/BKJTableSubHeader';

import { Fragment } from 'react';

export function UnSuccessTeamMemberTableHeader() {
  return (
    <Fragment>
      <BKJStickyTableHead>
        <BKJTableSubHeader name="error" title="Error" direction="default" />
      </BKJStickyTableHead>
      <BKJStickyTableHead>
        <BKJTableSubHeader name="column" title="Column" direction="default" />
      </BKJStickyTableHead>
      <BKJStickyTableHead>
        <BKJTableSubHeader name="row" title="Row" direction="default" />
      </BKJStickyTableHead>
    </Fragment>
  );
}
