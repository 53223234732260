import { FC, Fragment, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import {
  BKJSelect,
  BKJSelectSearch,
  BKJSelectSearchControls,
  IBKJThemeColor,
} from '@bookjane2/bookjane-design-library';

import { QuerySwitch } from 'components/QuerySwitch';
import { ComponentMapType, SSFBehaviorsValueType } from 'types/common.types';
import { IBKJSelectProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { usePersistentState } from 'hooks';
import {
  ChangeEventType,
  ChangeFunctionType,
} from '@bookjane2/bookjane-design-library/lib/common.types';

import { ISSFBehaviorsQueryResult } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { SessionService } from 'services';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { getSelectPositionByDepartmentOptions } from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.utils';

import {
  SelectInputPositionByDepartmentContext,
  SelectInputPositionByDepartmentProvider,
} from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.context';
import {
  GroupOption,
  NoResultsWrapper,
  PositionOption,
} from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.styled';
import {
  DropdownListItemVariant,
  SelectPositionByDepartmentDataType,
  SelectPositionByDepartmentOptionType,
  SelectPositionByDepartmentRenderGroupProps,
} from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.types';

const RenderGroup = ({
  name = 'positions',
  type = 'Multiple',
  selected,
  onChange: _onChange,
  dropdownProps = {},
}: SelectPositionByDepartmentRenderGroupProps) => {
  const { t, i18n } = useTranslation();
  const {
    isSearchable = true,
    dropdownListItemVariant = 'Checkbox',
  }: { isSearchable?: boolean; dropdownListItemVariant?: DropdownListItemVariant } = dropdownProps;
  const {
    values,
    onChange,
    data,
  }: {
    values: SSFBehaviorsValueType;
    onChange: ChangeFunctionType;
    data: SelectPositionByDepartmentDataType;
    positionSelectInputOnClose: () => void;
  } = useContext(SelectInputPositionByDepartmentContext);
  const allPositions = Object.values(data).reduce(
    (
      acc: SelectPositionByDepartmentOptionType[],
      groupValues: SelectPositionByDepartmentOptionType[],
    ) => {
      return acc.concat(groupValues);
    },
    [],
  );
  const selectedPositionIds = selected.map((value) => Number(value));

  const isAllSelected = selected.length === allPositions.length;

  const selectedGroups = Object.entries(data).reduce((acc: string[], [group, groupValues]) => {
    let isGroupSelected = true;
    groupValues.forEach(({ value }) => {
      if (!selectedPositionIds.includes(value)) {
        isGroupSelected = false;
        return;
      }
    });

    if (isGroupSelected) {
      acc.push(group);
    }
    return acc;
  }, []);

  const onSelectGroup = (group: string) => {
    switch (type) {
      case 'Single':
        break;
      case 'Multiple':
        if (selectedGroups.includes(group)) {
          _onChange({
            target: {
              name: name,
              value: selectedPositionIds.filter(
                (position) => !data[group].map(({ value }) => value).includes(position),
              ),
            },
          });
        } else {
          _onChange({
            target: {
              name: name,
              value: selectedPositionIds.concat(data[group].map(({ value }) => value)),
            },
          });
        }
        break;
      default:
        break;
    }
  };

  const onSelectOption = (e: ChangeEventType) => {
    const { name, value }: { name: string; value: number } = e.target;
    switch (type) {
      case 'Single':
        _onChange({
          target: {
            name,
            value: value,
          },
        });
        break;
      case 'Multiple':
        if (selectedPositionIds.includes(value)) {
          _onChange({
            target: {
              name,
              value: selectedPositionIds.filter((position) => position !== value),
            },
          });
        } else {
          _onChange({
            target: {
              name,
              value: selectedPositionIds.concat(value),
            },
          });
        }
        break;
      default:
        break;
    }
  };

  const dropdownHeaderComponentMap: ComponentMapType = {
    Multiple: () => (
      <BKJSelectSearchControls
        locale={i18n.language}
        numSelected={selected.length}
        isAllSelected={isAllSelected}
        onSelectAll={() => {
          isAllSelected
            ? _onChange({
                target: {
                  name: name,
                  label: [],
                  value: [],
                },
              })
            : _onChange({
                target: {
                  name: name,
                  label: allPositions.map(({ label }) => label),
                  value: allPositions.map(({ value }) => value),
                },
              });
        }}
      />
    ),
    Single: () => null,
  };
  const DepartmentSelectComponent = ({ data }: { data: SelectPositionByDepartmentDataType }) => {
    if (data && Object.keys(data).length <= 0)
      return <NoResultsWrapper>{t('common:NO_RESULTS')}</NoResultsWrapper>;
    return (
      <Fragment>
        {Object.keys(data).map((group: string) => {
          return (
            <Fragment key={group}>
              <GroupOption
                key={group}
                id={group}
                value={group}
                label={
                  i18n.exists(`agency_dashboard:${group}`) ? t(`agency_dashboard:${group}`) : group
                }
                name={group}
                onChange={() => onSelectGroup(group)}
                selected={selectedGroups}
                variant={dropdownListItemVariant}
                type={type}
              />
              {data[group].map(
                ({ label, value, key }: { label: string; value: number; key: string }) => {
                  return (
                    <PositionOption
                      key={key}
                      id={key}
                      data-cy={`SelectInputPositionByDepartmentOption-${label}`}
                      value={value}
                      label={
                        i18n.exists(`agency_dashboard:${label}`)
                          ? t(`agency_dashboard:${label}`)
                          : label
                      }
                      name={name}
                      onChange={onSelectOption}
                      selected={selected}
                      variant={dropdownListItemVariant}
                    />
                  );
                },
              )}
            </Fragment>
          );
        })}
      </Fragment>
    );
  };
  const DropdownHeaderComponent = dropdownHeaderComponentMap[type];
  return (
    <Fragment>
      <DropdownHeaderComponent />
      {isSearchable && (
        <BKJSelectSearch
          onChange={onChange}
          name="byName"
          value={values.byName}
          placeholder={t('common:SEARCH')}
        />
      )}
      <QuerySwitch
        context={SelectInputPositionByDepartmentContext}
        component={DepartmentSelectComponent}
      />
    </Fragment>
  );
};

const SelectInputPositionByDepartmentComponent: FC<
  {
    onChange: IBKJSelectProps['onChange'];
    onClose?: IBKJSelectProps['onClose'];
    value: IBKJSelectProps['value'];
    location: ILocationFilterProps['value'];
    variant?: IBKJSelectProps['variant'];
    type?: IBKJSelectProps['type'];
  } & Omit<IBKJSelectProps, 'options' | 'renderOptions' | 'variant' | 'type'>
> = (props) => {
  const { t } = useTranslation();
  const {
    name = 'positions',
    placeholder = t('common:POSITION'),
    variant = 'GreyOutlinedPillClear',
    type = 'Multiple',
    width,
    error,
    dropdownVariant,
    dropdownProps,
    value,
    onChange,
    iconComponent,
    iconProps,
    location,
  } = props;
  const {
    data,
    onClose,
    onReset,
  }: {
    data: SelectPositionByDepartmentDataType;
    onReset: ISSFBehaviorsQueryResult['onReset'];
    onClose: () => void;
    values: Record<string, unknown>;
  } = useContext(SelectInputPositionByDepartmentContext);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setOptions(data);
    }
  }, [data]); // eslint-disable-line

  const [options, setOptions] = usePersistentState(
    data,
    'SelectInputPositionByDepartmentComponent',
  );

  const _options = getSelectPositionByDepartmentOptions(options);

  const label = useRef<string | undefined>(undefined);

  if (Object.keys(data)?.length && value?.length) {
    const firstSelectedOption = _options.find((option) => option.value === Number(value[0]))?.label;
    if (firstSelectedOption) {
      label.current = `${firstSelectedOption}${
        value.length > 1 ? `... +${value.length - 1} ${t('MORE')}` : ''
      }`;
    }
  }

  let placeholderColor: keyof IBKJThemeColor | undefined;
  if (!label.current && variant === 'GreyOutlined') {
    placeholderColor = 'TextDisabled';
  }

  useEffect(() => {
    // this is because agency users have different positions for each location
    // check for location since its an optional prop and resetting on location change is not desired behavior in those cases
    if (!!location && SessionService.assertUserType('Agency')) {
      onReset && onReset();
    }
  }, [location]); // eslint-disable-line

  if (!Object.keys(options).length) return null;
  return (
    <BKJSelect
      options={[]}
      placeholder={placeholder}
      type={type}
      label={label.current}
      variant={variant}
      width={width}
      name={name}
      error={error}
      dropdownWidth="485px"
      dropdownVariant={dropdownVariant}
      dropdownProps={dropdownProps}
      dropdownPlacement="bottom-start"
      value={value}
      onChange={onChange}
      onClose={onClose}
      renderOptions={RenderGroup}
      iconComponent={iconComponent}
      iconProps={iconProps}
      placeholderColor={placeholderColor}
    />
  );
};

export const SelectInputPositionByDepartment: FC<
  {
    onChange: IBKJSelectProps['onChange'];
    value: IBKJSelectProps['value'];
    location: ILocationFilterProps['value'];
    variant?: IBKJSelectProps['variant'];
    type?: IBKJSelectProps['type'];
  } & Omit<IBKJSelectProps, 'options' | 'renderOptions' | 'variant' | 'type'>
> = ({ location, ...props }) => {
  return (
    <SelectInputPositionByDepartmentProvider location={location}>
      <SelectInputPositionByDepartmentComponent {...props} location={location} />
    </SelectInputPositionByDepartmentProvider>
  );
};
