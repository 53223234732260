import {
  BKJButton,
  BKJCheckbox,
  BKJSelect,
  BKJTextArea,
  ErrorBoundary,
  Flex,
} from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { QuerySwitch } from 'components/QuerySwitch';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { ShiftTimeSelectInput } from 'components/ShiftTimeSelectInput';
import { RenderOptions } from 'components/TimeOffModal';
import {
  TimeOffValidationProvider,
  useTimeOffValidationContext,
} from 'components/TimeOffModal/contexts/TimeOffValidation.data.context';
import {
  calendarDropdownProps,
  timeOffModalRoutingService,
  titleOptions,
} from 'components/TimeOffModal/TimeOffModal.constants';
import {
  AllDaySection,
  MainTitle,
  NotesSection,
  TimeOffColumn,
  TimeOffConflictWrapper,
  TimeOffDateTimeWrapper,
  TimeOffJaneSection,
  TimeOffRow,
  TimeOffWrapper,
} from 'components/TimeOffModal/TimeOffModalView.styled';
import { getStartEndDateTime } from 'components/TimeOffModal/views/CreateTimeOffView/CreateTimeOffView.constants';
import {
  EditTimeOffViewDataContext,
  EditTimeOffViewDataProvider,
  useEditTimeOffViewDataContext,
} from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.data.context';
import {
  EditTimeOffViewFormProvider,
  useEditTimeOffViewFormContext,
} from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.form.context';
import { DeleteButton } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffView.styled';
import { EditTimeOffCustomFieldsViewComponent } from 'components/TimeOffModal/views/EditTimeOffView/EditTimeOffViewCustomFields';
import { useTranslation } from 'hooks/useTranslation';
import { FormLabel } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftCard/CreateShiftCard.styled';
import { FC, Fragment, MouseEventHandler, useMemo } from 'react';
import { ITimeOffValidationsQuery } from 'requests/GET_timeOffValidations';
import { css } from 'styled-components';
import { formatPlural } from 'utils/formatPlural';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';

export const EditTimeOffViewContainer = () => {
  const { t } = useTranslation();
  const {
    hasFieldErrors,
    values: { endDate, endTime, notes, startDate, startTime, title, allDay },
    jane,
    isDisabled,
    isSubmitting,
    onChange,
    fieldErrors,
    onSubmit,
  } = useEditTimeOffViewFormContext();

  const { timeOffValidationErrors, timeOffValidationPending } = useTimeOffValidationContext();
  const hasTimeOffConflict =
    !hasFieldErrors &&
    !!timeOffValidationErrors?.error_messages.base.some((error) =>
      error.toLowerCase().includes('conflict'),
    );
  const disableSubmit =
    isDisabled || !!timeOffValidationErrors || isSubmitting || timeOffValidationPending;
  const translatedOptions = titleOptions.map((option) => ({
    ...option,
    label: t(option.label),
  }));
  return (
    <Fragment>
      <TimeOffWrapper>
        <MainTitle>{t('schedule:EDIT_TIME_OFF')}</MainTitle>
        <TimeOffJaneSection {...jane} />
        <AllDaySection>
          <BKJCheckbox
            value={allDay}
            name="allDay"
            variant={'GreenSolid'}
            onChange={onChange}
            css={css`
              margin-right: 12px;
            `}
          />
          {t('schedule:ALL_DAY')}
        </AllDaySection>
        <Flex flexDirection="column" width="100%" gap="8px">
          <TimeOffDateTimeWrapper>
            <TimeOffRow>
              <TimeOffColumn>
                <FormLabel>{t('schedule:STARTS')}</FormLabel>
                <ShiftDateCalendarInput
                  name="startDate"
                  error={fieldErrors?.startDate || hasTimeOffConflict}
                  value={startDate}
                  onChange={onChange}
                  dropdownProps={calendarDropdownProps}
                />
              </TimeOffColumn>
              <TimeOffColumn>
                {!allDay && (
                  <ShiftTimeSelectInput
                    label={t('schedule:START_TIME')}
                    value={startTime}
                    placeholder={t('schedule:START_TIME')}
                    onChange={onChange}
                    error={fieldErrors?.startTime || hasTimeOffConflict}
                    name="startTime"
                  />
                )}
              </TimeOffColumn>
            </TimeOffRow>
            <TimeOffRow>
              <TimeOffColumn>
                <FormLabel>{t('schedule:ENDS')}</FormLabel>
                <ShiftDateCalendarInput
                  error={fieldErrors?.endDate || hasTimeOffConflict}
                  name="endDate"
                  value={endDate}
                  onChange={onChange}
                  dropdownProps={calendarDropdownProps}
                />
              </TimeOffColumn>
              <TimeOffColumn>
                {!allDay && (
                  <ShiftTimeSelectInput
                    label={t('schedule:END_TIME')}
                    value={endTime}
                    placeholder={t('schedule:END_TIME')}
                    onChange={onChange}
                    name="endTime"
                    error={fieldErrors?.endTime || hasTimeOffConflict}
                  />
                )}
              </TimeOffColumn>
            </TimeOffRow>
          </TimeOffDateTimeWrapper>

          {hasTimeOffConflict && (
            <TimeOffConflictWrapper>
              <div>
                {`${t('schedule:DATE_AND_TIMES_CONFLICT_WITH')} ${
                  timeOffValidationErrors.error_messages.base.length
                } ${formatPlural(
                  t('schedule:SHIFT'),
                  timeOffValidationErrors.error_messages.base.length,
                )} ${t('schedule:SCHEDULED_FOR_THIS_TEAM_MEMBER')}`}
              </div>
              <div>{t('schedule:RELEASE_OR_CANCEL_SHIFTS_NOTE')}</div>
            </TimeOffConflictWrapper>
          )}
        </Flex>

        <Flex width="100%" flexDirection="column">
          <FormLabel>{t('schedule:TITLE')}</FormLabel>
          <BKJSelect
            options={translatedOptions}
            type="Single"
            variant="GreyOutlined"
            dropdownPlacement="bottom-start"
            name="title"
            onChange={onChange}
            value={title}
            renderOptions={RenderOptions}
            required
          />
        </Flex>

        <NotesSection>
          <BKJTextArea
            label={t('schedule:NOTES')}
            name="notes"
            placeholder={t('schedule:NOTES_WILL_ONLY_BE_SHARED_WITH_FACILITY_ADMINISTRATORS')}
            onChange={onChange}
            maxLength={1000}
            value={notes}
          />
        </NotesSection>

        <EditTimeOffCustomFieldsViewComponent />
      </TimeOffWrapper>

      <BKJSideModalFooter>
        <Flex justifyContent="space-between" width="100%">
          <Flex>
            <DeleteButton
              variant="RedText"
              onClick={() => timeOffModalRoutingService.goToView('DeleteTimeOffView')}
            >
              {t('auth:DELETE')}
            </DeleteButton>
          </Flex>
          <Flex width="100%" justifyContent="flex-end">
            <BKJButton
              width="180px"
              variant="PurpleOutlined"
              css={css`
                margin-right: 8px;
              `}
              onClick={() => timeOffModalRoutingService.close()}
            >
              {t('auth:CANCEL')}
            </BKJButton>
            <BKJButton
              width="180px"
              variant="PurpleSolid"
              disabled={disableSubmit}
              onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            >
              {t('auth:SAVE')}
            </BKJButton>
          </Flex>
        </Flex>
      </BKJSideModalFooter>
    </Fragment>
  );
};

const EditTimeOffViewValidationComponent: FC = () => {
  const {
    data: {
      fetchTimeOff: { displayId },
    },
  } = useEditTimeOffViewDataContext();

  const {
    values: { startDate, startTime, endDate, endTime, allDay },
    jane,
  } = useEditTimeOffViewFormContext();

  const [startDateTime, endDateTime] = getStartEndDateTime({
    startDate,
    startTime,
    endDate,
    endTime,
    allDay,
  });

  const timeOffValidationsQuery: ITimeOffValidationsQuery = useMemo(
    () => ({
      time_off: {
        id: `${displayId}`,
        start_time: startDateTime,
        end_time: endDateTime,
        jane_id: jane?.id,
      },
    }),
    [displayId, endDateTime, jane?.id, startDateTime],
  );

  return (
    <TimeOffValidationProvider timeOffValidationsQuery={timeOffValidationsQuery}>
      <EditTimeOffViewContainer />
    </TimeOffValidationProvider>
  );
};

const EditTimeOffViewFormComponent: FC = () => {
  return (
    <EditTimeOffViewFormProvider>
      <EditTimeOffViewValidationComponent />
    </EditTimeOffViewFormProvider>
  );
};

export const EditTimeOffView: FC = () => {
  return (
    <EditTimeOffViewDataProvider>
      <QuerySwitch context={EditTimeOffViewDataContext} component={EditTimeOffViewFormComponent} />
    </EditTimeOffViewDataProvider>
  );
};

export const EditTimeOffViewRenderer: FC = withMemo((props) => {
  return (
    <ErrorBoundary componentName="EditTimeOffView" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <EditTimeOffView {...props} />
    </ErrorBoundary>
  );
});
