import styled from 'styled-components';

export const LocationHeader = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
`;
export const ContentName = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const TeamMemberCSVWrapper = styled.div``;
export const H3 = styled.div`
  ${({ theme }) => theme.fonts.Poppins};
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  word-break: break-all;
`;

export const CSVBorderHeaderStyle = 'solid 1px #8b96a350';
export const LinkLine = styled.div`
  width: 1px;
  height: 16px;
  position: relative;
  left: 11px;
  background-color: ${({ theme }) => theme.colors.Grey5}; ;
`;
