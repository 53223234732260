import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { createContext, useState } from 'react';
import { API_POST_inviteTeamMembers } from 'requests/POST_inviteTeamMembers';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { ISMSViewContext } from './SMSView.types';

const initialState: ISMSViewContext = {
  flowView: 'invite',
  setFlowView: () => {},
  formValues: {},
  errorIndices: new Set<number>(),
  setErrorIndices: () => {},
  error: '',
  setError: () => {},
  numInvites: 0,
  setNumInvites: () => {},
  onChange: () => {},
  onSubmit: () => {},
};

export const SMSViewContext = createContext<ISMSViewContext>(initialState);

export const SMSViewContextProvider: React.FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const [flowView, setFlowView] = useState<string>('invite');
  const [formValues, setFormValues] = useState({ location: undefined });
  const [numInvites, setNumInvites] = useState<number>(0);
  const [errorIndices, setErrorIndices] = useState<Set<number>>(new Set<number>());
  const [error, setError] = useState<string>('');

  const onChange = (name: string, value: string) => {
    setFormValues({ ...formValues, [name]: value });
  };

  const onSubmit = async () => {
    const { location: communityId, phoneNumbers } = formValues as unknown as {
      phoneNumbers: string[];
      location: ILocationFilterProps['value'];
    };
    apiRequest(
      API_POST_inviteTeamMembers({
        communityId: `${communityId!.value}`,
        inviteType: 'phone',
        data: phoneNumbers,
      }),
      {
        onSuccess: () => {
          console.log('Successfully invited team members.');
          setFlowView('success');
        },
        onError: () => {
          alert('Error inviting team members.');
          setFlowView('fail');
        },
      },
    );
  };

  return (
    <SMSViewContext.Provider
      value={{
        flowView,
        setFlowView,
        formValues,
        errorIndices,
        setErrorIndices,
        error,
        setError,
        numInvites,
        setNumInvites,
        onChange,
        onSubmit,
      }}
    >
      {children}
    </SMSViewContext.Provider>
  );
};
