import { Flex } from '@bookjane2/bookjane-design-library';
import { useTranslation } from 'hooks/useTranslation';
import { BKJStickyTableHead } from 'components/BKJTable/BKJTable.styled';
import { BKJTableSubHeader } from 'components/BKJTable/BKJTableSubHeader';
import { css } from 'styled-components';

const AuditTrailHeader = () => {
  const { t } = useTranslation();
  return (
    <Flex>
      <BKJStickyTableHead
        css={css`
          width: 165px;
        `}
      >
        <BKJTableSubHeader
          name="callout_time"
          title={t('dashboard:CALL_OUT_TIME')}
          direction="default"
          css={css`
            width: 165px;
            padding-right: 0px;
            padding-left: 31px !important;
          `}
        />
      </BKJStickyTableHead>
      <BKJStickyTableHead>
        <BKJTableSubHeader
          name="jane"
          title={t('common:TEAM_MEMBER')}
          direction="default"
          css={css`
            text-align: left;
            padding-left: 0px;
            margin-left: 0px;
          `}
        />
      </BKJStickyTableHead>
      <BKJStickyTableHead>
        <BKJTableSubHeader
          css={css`
            padding-left: 35px;
          `}
          name="callout_status"
          title={t('dashboard:CALL_OUT_STATUSES')}
          direction="default"
        />
      </BKJStickyTableHead>
    </Flex>
  );
};

export default AuditTrailHeader;
