import { isEmpty, isPlainObject, transform } from 'lodash';
import { isArray } from 'types/common.types';

type CleanOptions = {
  cleanKeys?: string[];
  cleanValues?: string[];
  emptyArrays?: boolean;
  emptyObjects?: boolean;
  emptyStrings?: boolean;
  NaNValues?: boolean;
  nullValues?: boolean;
  undefinedValues?: boolean;
};

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepClean(object: unknown | any, opts?: CleanOptions): any {
  const options = { ...opts };
  if (!options.cleanKeys) options.cleanKeys = [];
  if (!options.cleanValues) options.cleanValues = [];
  if (!options.emptyArrays) options.emptyArrays = false;
  if (!options.emptyObjects) options.emptyObjects = false;
  if (!options.emptyStrings) options.emptyStrings = false;
  if (!options.NaNValues) options.NaNValues = false;
  if (!options.nullValues) options.nullValues = false;
  if (!options.undefinedValues) options.undefinedValues = false;

  const {
    NaNValues,
    cleanKeys,
    cleanValues,
    emptyArrays,
    emptyObjects,
    emptyStrings,
    nullValues,
    undefinedValues,
  } = options;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return transform(object, (result: Record<string, any>, value: any, key: string) => {
    if (cleanKeys.includes(key)) return;
    if (isArray(value) || isPlainObject(value)) {
      value = deepClean(value, {
        NaNValues,
        cleanKeys,
        cleanValues,
        emptyArrays,
        emptyObjects,
        emptyStrings,
        nullValues,
        undefinedValues,
      });
    }
    if (cleanValues.includes(value)) return;
    if (emptyObjects && isPlainObject(value) && isEmpty(value)) return;
    if (emptyArrays && isArray(value) && !value.length) return;
    if (emptyStrings && value === '') return;
    if (NaNValues && Number.isNaN(value)) return;
    if (nullValues && value === null) return;
    if (undefinedValues && value === undefined) return;
    if (isArray(result)) return result.push(value);
    result[key] = value;
  });
}
