import { ShiftModalUIProvider } from 'components/ShiftModal/ShiftModal.ui.context';
import { ShiftModalDataProvider } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { ShiftModalComponent } from 'components/ShiftModal/ShiftModal';

export const ShiftModal = () => {
  return (
    <ShiftModalUIProvider>
      <ShiftModalDataProvider>
        <ShiftModalComponent />
      </ShiftModalDataProvider>
    </ShiftModalUIProvider>
  );
};
