import { BKJButton, Flex } from '@bookjane2/bookjane-design-library';
import { AutoMargin } from 'components/AutoMargin';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useEditCustomFieldFormContext } from 'components/CustomFieldEditModal/contexts/EditCustomField.form.context';
import { Description, Heading } from 'components/CustomFieldModal/CustomFieldModal.styled';
import { FC, Fragment } from 'react';

export const DeleteCustomFieldView: FC = () => {
  const { onDelete } = useEditCustomFieldFormContext();
  return (
    <Fragment>
      <Flex flexDirection="column" gap="32px" padding="32px" flex="1">
        <Heading>Delete custom field?</Heading>
        <Description>
          This action will remove the field from all shifts for the entire organization. All
          previously filled fields will remain on past shifts until edited.
        </Description>
      </Flex>
      <BKJSideModalFooter>
        <AutoMargin />
        <BKJButton variant="RedSolid" width="180px" onClick={onDelete}>
          Delete
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
