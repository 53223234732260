import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import {
  DashboardPagePastShiftsPayloadTransformer,
  DashboardPagePastShiftsPayload,
} from 'pages/DashboardPastShiftsPage/DashboardPastShiftsPage.types';
import { createApolloClient } from 'providers/ApolloProvider';
import { QUERY_dashboardPastShiftsPage } from 'queries';
import { SessionService } from 'services';
import { isOrderStatusInProgress } from 'types/common.types';
import { dashboardPastShiftsPage } from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { isDateTimeAfterNow } from 'utils/time';

const payloadTransformer: DashboardPagePastShiftsPayloadTransformer = ({
  isUserAgencyAndAgencyLocationFilterForcedDisabled,
  ...filterValues
}) => {
  const payload: DashboardPagePastShiftsPayload = {
    currentPage: filterValues.currentPage,
    first: filterValues.first,
    last: filterValues.last,
    after: filterValues.after,
    before: filterValues.before,
    filter: {
      forOrders: {
        withCommunity: undefined,
        withStatus: filterValues.shiftStatus,
        withPositions: filterValues.position_id,
        withJaneType: [...filterValues.internalJaneType, ...filterValues.externalJaneType],
        startBetweenTimeRange: {
          from: dayjs().subtract(1, 'months').format(),
          to: dayjs().format(),
        },
        withCalloutStatus: filterValues.callOutType,
      },
      forJanes: {
        byEmploymentStatus: filterValues.employmentStatus,
        byFullName: filterValues.byFullName,
      },
      forAgencyCommunities: {
        withId: undefined,
      },
    },
    sortOrders: [
      {
        field: 'startAt',
        direction: 'desc',
      },
    ],
  };
  if (!filterValues.viewAllFacilities) {
    if (SessionService.assertUserType('B2B'))
      payload.filter.forOrders['withCommunity'] = Number(filterValues.location.value);

    if (
      SessionService.assertUserType('Agency') &&
      !isUserAgencyAndAgencyLocationFilterForcedDisabled
    )
      payload.filter.forAgencyCommunities['withId'] = Number(filterValues.location.value);
  }
  return payload;
};

const dashboardPastShiftsPageApolloClient = createApolloClient();

export const dashboardPastShiftsPageConfig = ({
  location,
  isUserAgencyAndAgencyLocationFilterForcedDisabled,
}: {
  location: ILocationFilterProps['value'];
  isUserAgencyAndAgencyLocationFilterForcedDisabled: boolean;
}): ISSFBehaviorsOptions => {
  return {
    key: 'DASHBOARD_PAST-SHIFTS_PAGE',
    paginationType: 'InfiniteScroll',
    query: QUERY_dashboardPastShiftsPage,
    pageSize: 20,
    client: dashboardPastShiftsPageApolloClient,
    nextFetchPolicy: 'network-only',
    responseTransformer: (data: dashboardPastShiftsPage) => {
      if (!data) return data;
      let newData: dashboardPastShiftsPage = JSON.parse(JSON.stringify(data));
      newData?.fastGroupOrders?.nodes?.forEach((node, index1: number) => {
        node.orders.forEach((record, index2: number) => {
          if (isOrderStatusInProgress(record.status.value) || isDateTimeAfterNow(record.endAt)) {
            delete newData.fastGroupOrders.nodes[index1].orders[index2];
          }
        });
        if (!!newData.fastGroupOrders.nodes[index1]?.orders) {
          newData.fastGroupOrders.nodes[index1].orders =
            newData?.fastGroupOrders?.nodes[index1].orders.filter(Boolean);
        }
      });
      return newData as dashboardPastShiftsPage;
    },
    payloadTransformer: (variables) =>
      payloadTransformer({ ...variables, isUserAgencyAndAgencyLocationFilterForcedDisabled }),
    schema: {
      position_id: {
        initialValue: [],
        isSessionCached: true,
      },
      shiftStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      location: {
        initialValue: location,
        isGlobalCached: true,
        isPreservedOnReset: true,
        resetFieldsOnChange: [
          {
            field: 'position_id',
            userTypes: ['Agency'],
          },
        ],
      },
      employmentStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      byFullName: {
        initialValue: '',
        isSessionCached: true,
      },
      internalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      externalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      callOutType: {
        initialValue: [],
        isSessionCached: true,
      },
      viewAllFacilities: {
        initialValue: false,
        isSessionCached: true,
        isGlobalCached: true,
        isPreservedOnReset: true,
      },
    },
  };
};
