import { Flex } from 'components/Flex';
import { useFetchJaneByIdDataContext } from 'components/JaneModal/FetchJaneById.data.context';
import { JaneName } from 'components/JaneModal/views/JaneDetailsView/ReviewsTab/ReviewsTab.styled';
import {
  ColorDot,
  TimeWrapper,
  FlexWrapper,
  ActivityContentWrapper,
  TextWrapper,
} from 'components/JaneModal/views/JaneDetailsView/ActivityTab/ActivityTab.styled';
import { Fragment } from 'react';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import { humanReadableDate } from 'components/JaneModal/JaneModal.constants';
import { Text } from 'components/JaneModal/views/JaneDetailsView/GeneralTab/GeneralTab.styled';
import {
  Activity,
  auditEvents,
  getActivityColorByEvent,
} from 'components/JaneModal/views/JaneDetailsView/ActivityTab/ActivityTab.types';
export const ActivityTab = () => {
  const { data, activities } = useFetchJaneByIdDataContext();
  const { shorthandName = '' } = data;
  return (
    <Fragment>
      <FlexWrapper>
        <Flex flexDirection="column" width="100%">
          <JaneName>{shorthandName}</JaneName>
          <SectionDivider variant="Thin" />
        </Flex>
        <Flex flexDirection="column" width="100%" gap="32px">
          {activities?.length > 0 ? (
            activities.map((item: Activity) => (
              <ActivityContentWrapper key={item.id}>
                <TimeWrapper>{humanReadableDate(item?.created_at)}</TimeWrapper>
                <Flex gap="6px" width="100%">
                  <ColorDot color={getActivityColorByEvent(item?.event)}>•</ColorDot>
                  <TextWrapper>
                    {auditEvents(item?.event, item?.feedable?.shift_ids[0])}
                  </TextWrapper>
                </Flex>
              </ActivityContentWrapper>
            ))
          ) : (
            <Flex height="581px" justifyContent="center" alignItems="center">
              <Text>There is no activity for this team member yet.</Text>
            </Flex>
          )}
        </Flex>
      </FlexWrapper>
    </Fragment>
  );
};
