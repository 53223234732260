import { BKJListItem } from '@bookjane2/bookjane-design-library';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';
import { FC, Fragment } from 'react';
import { css } from 'styled-components';
import { UseFormBehaviors_ValidationFnType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { MessageWrapper } from 'components/JaneModal/views/EditJaneView/EditJaneView.styled';
import { FormValidationErrorMessageType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { isString } from 'types/common.types';
import { ITeamMemberViewForm } from 'components/InviteTeamMembersByCSVModal/views/InviteTeamMemberView/InviteTeamMemberView.form.context';
export interface ComboBoxOption {
  key: string;
  id: string;
  value: string;
  label: string;
}
export const employmentStatusOptions: {
  key: 'full_time' | 'part_time' | 'casual';
  label: 'Full-Time' | 'Part-Time' | 'Casual';
  value: string;
}[] = [
  {
    key: 'full_time',
    label: 'Full-Time',
    value: 'Full Time',
  },

  {
    key: 'part_time',
    label: 'Part-Time',
    value: 'Part Time',
  },
  {
    key: 'casual',
    label: 'Casual',
    value: 'Casual',
  },
];
export const RenderOptions: FC<IBKJSelectRenderOptionsProps> = ({
  name,
  options,
  selected,
  onChange,
}) => {
  return (
    <Fragment>
      {options.length === 0 ? (
        <MessageWrapper>Select month first</MessageWrapper>
      ) : (
        options.map(({ key, label, value }) => (
          <BKJListItem
            name={name}
            selected={selected}
            key={key}
            label={label}
            value={value}
            onChange={onChange}
            css={css`
              background-color: white !important;
            `}
          />
        ))
      )}
    </Fragment>
  );
};
export const monthToDaysMap: { [key: string]: string[] } = {};
const currentYear = new Date().getFullYear();

const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

for (let month = 0; month < 12; month++) {
  const monthName = new Date(currentYear, month, 1).toLocaleString('default', { month: 'long' });

  if (month === 1) {
    // Handle February
    monthToDaysMap[monthName] = isLeapYear(currentYear)
      ? Array.from({ length: 29 }, (_, i) => (i + 1).toString())
      : Array.from({ length: 28 }, (_, i) => (i + 1).toString());
  } else if ([3, 5, 8, 10].includes(month)) {
    // April, June, September, November have 30 days
    monthToDaysMap[monthName] = Array.from({ length: 30 }, (_, i) => (i + 1).toString());
  } else {
    // Other months have 31 days
    monthToDaysMap[monthName] = Array.from({ length: 31 }, (_, i) => (i + 1).toString());
  }
}
export const EmployeeStatus = employmentStatusOptions.map((item, index) => ({
  id: index + 1,
  value: item.value,
  label: item.label,
}));
export const VALIDATION_required = (value: string) => {
  if (value.length === 0 || !value[0]) {
    return 'Mandatory field.';
  }
  return '';
};
export const VALIDATION_position_id: UseFormBehaviors_ValidationFnType<ITeamMemberViewForm> = (
  value,
): string => {
  const position_id = value as string[];
  if (position_id.length === 0) return 'Mandatory field.';
  return '';
};

export const convertEmploymentStatus = (employmentStatus: string) => {
  const convertedEmploymentStatus = employmentStatus.replace(' ', '_').toLowerCase();
  let employmentStatusValue;
  switch (convertedEmploymentStatus) {
    case 'p':
      employmentStatusValue = 'part_time';
      break;
    case 'f':
      employmentStatusValue = 'full_time';
      break;
    case 'c':
      employmentStatusValue = 'casual';
      break;
    default:
      employmentStatusValue = convertedEmploymentStatus;
  }
  return employmentStatusValue;
};

function validateEmail(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const VALIDATION_email = (value: unknown): FormValidationErrorMessageType => {
  const messages: FormValidationErrorMessageType = [];

  if (isString(value)) {
    if (value.length === 0) return messages.concat('Mandatory field.');

    if (!validateEmail(value)) messages.push('Please make sure email is in the correct format.');
  }
  return messages;
};

export const VALIDATION_phoneNumber = (value: unknown): FormValidationErrorMessageType => {
  const messages: FormValidationErrorMessageType = [];

  if (isString(value)) {
    if (value.length === 0) return messages.concat('Mandatory field.');
    // Remove non-digit characters from the phone number
    const digitsOnly = value.replace(/\D/g, '');
    if (!/^\d{8,12}$/.test(digitsOnly)) {
      messages.push('Please make sure the phone number is between 8 and 12 digits.');
    }
  }

  return messages;
};

interface ApiError {
  phone: string;
  email: string;
}

export interface InviteTeamMemberFormApiError {
  data?: ApiError[];
}

export const monthNameToNumber: { [key: string]: number } = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};
