import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import {
  AdministratorSettingsGeneralFormFieldsForForm as GASFFields,
  AdministratorSettingsGeneralFormApiError as GASFApiError,
} from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { createContext, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { API_PUT_updateCustomerSecondaryDetails } from 'requests/PUT_updateCustomerSecondaryDetails';
import { API_PUT_updateAgencySecondaryDetails } from 'requests/PUT_updateAgencySecondaryDetails';
import { VALIDATION_email, VALIDATION_required } from 'validations';
import {
  AdministratorSettingsDataProvider,
  useAdministratorSettingsDataContext,
} from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import { useTranslation } from 'hooks/useTranslation';
const GeneralPageFormAdministratorSettingsContext = createContext<
  UseFormBehaviors<GASFFields, GASFApiError>
>({} as UseFormBehaviors<GASFFields, GASFApiError>);

export const GeneralPageFormAdministratorSettingsProvider: FC = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { secondary_users } = useAdministratorSettingsDataContext();
  const data = secondary_users?.find((item) => item.id === id)!;
  const { t } = useTranslation();

  const data2: GASFFields = {
    access_status: [data?.access_status],
    email: data?.email,
    first_name: data?.first_name,
    last_name: data?.last_name,
    phone: data?.phone,
    title: data?.title,
    communities: data?.communities,
  };
  const { apiRequest } = useAPIActions();

  const form = useFormBehaviors<GASFFields, GASFApiError>({
    initialState: data2,
    onSubmit: useUserTypeSwitch({
      Agency: (formValues: GASFFields) => {
        return apiRequest(
          API_PUT_updateAgencySecondaryDetails(id, {
            access_status: formValues.access_status[0],
            email: formValues.email,
            first_name: formValues.first_name,
            last_name: formValues.last_name,
            phone: formValues.phone,
            title: formValues.title,
            communities: formValues.communities,
          }),
          {
            onError: (error) => {
              if (error && error?.data && error?.data?.message)
                toastService.error(`${error.data.message}`);
              else toastService.error(t('settings:Something_went_wrong'));
            },
            onSuccess: () => {
              toastService.success(t('settings:Successfully_updated'));
            },
          },
        );
      },
      B2B: (formValues: GASFFields) => {
        return apiRequest(
          API_PUT_updateCustomerSecondaryDetails(id, {
            access_status: formValues.access_status[0],
            email: formValues.email,
            first_name: formValues.first_name,
            last_name: formValues.last_name,
            phone: formValues.phone,
            title: formValues.title,
            communities: formValues.communities,
          }),
          {
            onError: (error) => {
              if (error && error?.data && error?.data?.message)
                toastService.error(`${error.data.message}`);
              else toastService.error(t('settings:Something_went_wrong'));
            },
            onSuccess: () => {
              toastService.success(t('settings:Successfully_updated'));
            },
          },
        );
      },
    }),
    validations: useUserTypeSwitch({
      Agency: {
        access_status: VALIDATION_required,
        first_name: VALIDATION_required,
        last_name: VALIDATION_required,
        email: VALIDATION_email,
      },
      B2B: {
        access_status: VALIDATION_required,
        first_name: VALIDATION_required,
        last_name: VALIDATION_required,
        email: VALIDATION_email,
      },
    }),
  });
  return (
    <AdministratorSettingsDataProvider>
      <GeneralPageFormAdministratorSettingsContext.Provider value={form}>
        {children}
      </GeneralPageFormAdministratorSettingsContext.Provider>
    </AdministratorSettingsDataProvider>
  );
};

export const useGeneralPageFormAdministratorSettingsContext = () =>
  useContext<UseFormBehaviors<GASFFields, GASFApiError>>(
    GeneralPageFormAdministratorSettingsContext,
  );
