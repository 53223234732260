import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { AccessStatusEnum } from 'types/graphql-types';

export const getJaneColorByStatus = (status: AccessStatusEnum): BKJThemeColorType => {
  switch (status) {
    case 'approved':
      return 'SecondaryAcceptedDefault';
    default:
      return 'Grey6';
  }
};
