import { AddEditRankingFormSchema } from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRanking.constants';
import { DOWNLOAD_INVITE_SPREADSHEET } from 'constants/endpoints';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviorsEventType,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { API_POST_inviteBySpreadsheet } from 'requests/POST_inviteBySpreadsheet';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { FC, createContext, useCallback, useContext, useMemo } from 'react';
import { toastService } from 'services';
import {
  AddTeamMemberCSVViewSchema,
  CsvError,
} from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.constants';
import {
  inviteTeaMemberCSVModalRoutingService,
  useInviteTeamMembersByCSVModalUIContext,
} from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
import i18next from 'i18next';
import { METHOD_GET } from 'requests';
import { saveResponseAsCSV } from 'utils/saveResponseAsCSV';

type TeamMemberCSVViewFormContextType = UseFormBehaviors<AddEditRankingFormSchema> & {
  onDownload: () => {};
};

const VALIDATION_CSV = (file: File | undefined) => {
  if (!file) {
    return i18next.t('team_member:FILE_IS_REQUIRED');
  }

  const allowedFileTypes = ['csv', 'xlsx']; // Add your allowed file types here
  const fileNameParts = file.name.split('.');
  const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  if (!allowedFileTypes.includes(fileExtension)) {
    return i18next.t('team_member:FILE_NOT_CORRECT_FORMAT_UPLOAD_XLSX_FILE_AND_TRY_AGAIN');
  }

  return '';
};

const TeamMemberCSVViewInitialState = {
  csv: undefined,
  position_id: [''],
};

export const TeamMemberCSVViewFormContext = createContext<TeamMemberCSVViewFormContextType>(
  {} as TeamMemberCSVViewFormContextType,
);

export const TeamMemberCSVViewFormProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const { goToViewError } = useInviteTeamMembersByCSVModalUIContext();
  const handleSubmit = useCallback(
    async (values) => {
      const formData = new FormData();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { csv } = values as any;
      formData.append('csv_file', csv);
      let collectedErrors: CsvError[] = [];
      apiRequest(API_POST_inviteBySpreadsheet({ formData }), {
        onSuccess: () => {
          toastService.success('Successfully invited team member');
          inviteTeaMemberCSVModalRoutingService.goToView('SuccessFul');
        },
        onError: (error) => {
          const errors = error?.data;
          if (errors && Array.isArray(errors)) {
            errors.forEach((errorObj) => {
              collectedErrors.push(errorObj);
            });
          }
          goToViewError({ csvErrors: collectedErrors });
        },
      });
    },

    [apiRequest, goToViewError],
  );

  const addCSVRuleFormBehaviorsOptions: UseFormBehaviors_Options<AddTeamMemberCSVViewSchema> = {
    initialState: { ...TeamMemberCSVViewInitialState },
    isDirtyCheckEnabled: true,
    type: 'CREATE',
    onSubmit: handleSubmit,
    validations: {
      csv: VALIDATION_CSV,
    },
  };

  const addEditRankingRuleForm = useFormBehaviors<AddTeamMemberCSVViewSchema>(
    addCSVRuleFormBehaviorsOptions,
  );
  const onDownload = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_GET,
          responseType: 'blob',
          url: DOWNLOAD_INVITE_SPREADSHEET,
        },
        {
          onSuccess: (response) => {
            saveResponseAsCSV(response, `TeamMemberInviteSpreadsheetTemplate-${Date.now()}.xlsx`);
          },
          onError: () => {
            toastService.error('Something went wrong.');
          },
        },
      ),
    [apiRequest],
  );
  const value = useMemo(
    () => ({
      ...addEditRankingRuleForm,
      onChange: (e: UseFormBehaviorsEventType | UseFormBehaviorsEventType[]) => {
        if (Array.isArray(e)) {
          // If e is an array of events, iterate through them
          e.forEach(({ target: { value = new File([], ''), name = 'csv', ...rest } }) => {
            addEditRankingRuleForm.onChange({ target: { value, name, ...rest } });
          });
        } else {
          const {
            target: { value = new File([], ''), name = 'csv', ...rest },
          } = e;
          addEditRankingRuleForm.onChange({
            target: { name, value, ...rest },
          });
        }
      },
      onDownload,
    }),
    [addEditRankingRuleForm, onDownload],
  );
  return (
    <TeamMemberCSVViewFormContext.Provider value={value}>
      {children}
    </TeamMemberCSVViewFormContext.Provider>
  );
};

export const useTeamMemberCSVViewFormContext = (): TeamMemberCSVViewFormContextType =>
  useContext(TeamMemberCSVViewFormContext);
