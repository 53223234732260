import dayjs from 'utils/dayjs';
import i18next from 'i18next';
import { ISO8601Type, ISO8601TupleType } from 'types/common.types';

export const daysOfTheWeek: Array<string> = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export const getCalendarMonthRange = (date: ISO8601Type): ISO8601TupleType => {
  const firstDayOfMonth = dayjs(date).startOf('d').date(1).format();
  const isFirstDayOfMonthSunday = dayjs(firstDayOfMonth).weekday() === 0;
  let startDate = firstDayOfMonth;
  if (!isFirstDayOfMonthSunday) {
    startDate = dayjs(firstDayOfMonth).weekday(7).weekday(-7).format();
  }

  const lastDayOfMonth = dayjs(date).endOf('M').add(1, 'd').format();
  const isLastDayOfMonthSaturday = dayjs(lastDayOfMonth).weekday() === 6;
  let endDate = lastDayOfMonth;
  if (!isLastDayOfMonthSaturday) {
    const numDaysToAddToRange = 7 - dayjs(lastDayOfMonth).weekday();
    endDate = dayjs(lastDayOfMonth).add(numDaysToAddToRange, 'd').format();
  }

  return [startDate, endDate];
};

export const getOneMonthRangeFromDate = (date: ISO8601Type): ISO8601TupleType => {
  const startDate = dayjs(date).startOf('d').format();
  const endDate = dayjs(startDate).endOf('d').add(1, 'M').subtract(1, 'd').format();
  return [startDate, endDate];
};

export const getOneWeekRangeFromDate = (date: ISO8601Type): ISO8601TupleType => {
  const startDate = dayjs(date).format();
  const endDate = dayjs(startDate).endOf('d').add(1, 'w').subtract(1, 'd').format();
  return [startDate, endDate];
};

export const getSingleDateRangeFromDate = (date: ISO8601Type): ISO8601TupleType => {
  const startDate = dayjs(date).startOf('d').format();
  const endDate = dayjs(date).endOf('d').format();
  return [startDate, endDate];
};

export const getCurrentMonthCalendarRange = (): ISO8601TupleType =>
  getCalendarMonthRange(dayjs().format());

export const getOneMonthRangeFromToday = (): ISO8601TupleType => {
  const startDate = dayjs().format();
  return getOneMonthRangeFromDate(startDate);
};

export const getOneWeekRangeFromToday = (): ISO8601TupleType => {
  const startDate = dayjs().format();
  return getOneWeekRangeFromDate(startDate);
};

export const getSingleDateRangeFromToday = (): ISO8601TupleType => {
  const startDate = dayjs().format();
  return getSingleDateRangeFromDate(startDate);
};

export const getDaysArrayBetweenTwoDates = ([
  startDate,
  endDate,
]: ISO8601TupleType): Array<ISO8601Type> => {
  const numOfDaysForMonth = dayjs(endDate).diff(startDate, 'd');
  return [...new Array(numOfDaysForMonth)].map((_, daysToAdd) =>
    dayjs(startDate).add(daysToAdd, 'd').format(),
  );
};

export const getFortyTwoDaysArrayFromDate = (date: ISO8601Type) => {
  return [...new Array(42)].map((_, daysToAdd) => dayjs(date).add(daysToAdd, 'd').format());
};

export const getPreviousMonthCalendarRange = (date: ISO8601Type): ISO8601TupleType => {
  const dateDayOfMonth = dayjs(date).date();
  const isDateFirstDayOfMonth = dateDayOfMonth === 1;
  if (isDateFirstDayOfMonth) {
    return getCalendarMonthRange(dayjs(date).subtract(1, 'M').format());
  }
  return getCalendarMonthRange(dayjs(date).date(1).format());
};

export const getNextMonthCalendarRange = (date: ISO8601Type): ISO8601TupleType => {
  const dateDayOfMonth = dayjs(date).date();
  const isDateFirstDayOfMonth = dateDayOfMonth === 1;
  if (isDateFirstDayOfMonth) {
    return getCalendarMonthRange(dayjs(date).add(1, 'M').format());
  }
  return getCalendarMonthRange(dayjs(date).add(2, 'M').format());
};

export const getOneMonthRangeFromPreviousDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const previousDay = dayjs(date).subtract(1, 'd').format();
  return getOneMonthRangeFromDate(previousDay);
};

export const getOneWeekRangeFromPreviousDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const previousDay = dayjs(date).subtract(1, 'd').format();
  return getOneWeekRangeFromDate(previousDay);
};

export const getSingleDateRangeFromPreviousDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const previousDay = dayjs(date).subtract(1, 'd').format();
  return getSingleDateRangeFromDate(previousDay);
};

export const getOneMonthRangeFromNextDay = ({ date }: { date: ISO8601Type }): ISO8601TupleType => {
  const nextDay = dayjs(date).add(1, 'd').format();
  return getOneMonthRangeFromDate(nextDay);
};

export const getOneWeekRangeFromNextDay = ({ date }: { date: ISO8601Type }): ISO8601TupleType => {
  const nextDay = dayjs(date).add(1, 'd').format();
  return getOneWeekRangeFromDate(nextDay);
};

export const getSingleDateRangeFromNextDay = ({
  date,
}: {
  date: ISO8601Type;
}): ISO8601TupleType => {
  const nextDay = dayjs(date).add(1, 'd').format();
  return getSingleDateRangeFromDate(nextDay);
};

export const getPrettyMonthAndYear = (startDate: ISO8601Type): string => {
  const startDateDayOfMonth = dayjs(startDate).date();
  const isStartDateFirstDay = startDateDayOfMonth === 1;

  const monthKey = dayjs(startDate)
    .add(isStartDateFirstDay ? 0 : 1, 'M')
    .format('MMMM')
    .toUpperCase();

  const translatedMonth = i18next.t(`translations:${monthKey}`);
  const year = dayjs(startDate).format('YYYY');

  return `${translatedMonth}, ${year}`;
};

export const getPrettyRange = ([startDate, endDate]: ISO8601TupleType): string => {
  const currentYear = dayjs().format('YYYY');
  const startDateYear = dayjs(startDate).format('YYYY');
  const endDateYear = dayjs(endDate).format('YYYY');
  const isRangeInCurrentYear = startDateYear === currentYear && endDateYear === currentYear;
  const startDateMonth = dayjs(startDate).format('MMM').toUpperCase();
  const translatedStartDateMonth = i18next.t(startDateMonth);
  const endDateMonth = dayjs(endDate).format('MMM').toUpperCase();
  const translatedEndDateMonth = i18next.t(endDateMonth);
  return `${translatedStartDateMonth} ${dayjs(startDate).format(
    'D',
  )} - ${translatedEndDateMonth} ${dayjs(endDate).format('D')}${
    isRangeInCurrentYear ? '' : `, ${endDateYear}`
  }`;
};

export const getPrettySingleDateFromRange = ([startDate, _]: ISO8601TupleType): string => {
  const month = dayjs(startDate).format('MMM').toUpperCase();
  const translatedDateMonth = i18next.t(month);
  return `${translatedDateMonth}, ${dayjs(startDate).format('D, YYYY')}`;
};

export const getPrettyDateFromDate = (date: ISO8601Type): string => {
  return dayjs(date).format('MMM D, YYYY');
};

export const getPrettyDayAndDateFromDate = (date: ISO8601Type, omitYear = true): string => {
  const isWithinCurrentYear = dayjs(date).isSame(dayjs(), 'year');

  const dayOfWeek = dayjs(date).format('ddd').toUpperCase();
  const month = dayjs(date).format('MMM').toUpperCase();
  const TranslatedDayOfWeek = i18next.t(`translations:${dayOfWeek}`);
  const TranslatedMonth = i18next.t(`translations:${month}`);
  if (omitYear && isWithinCurrentYear) {
    return `${TranslatedDayOfWeek}, ${TranslatedMonth} ${dayjs(date).format('D')}`;
  }

  return `${TranslatedDayOfWeek}, ${TranslatedMonth} ${dayjs(date).format('D, YYYY')}`;
};

export const isDateInCurrentMonth = (date: ISO8601Type): boolean => {
  return dayjs().month() === dayjs(date).month();
};

export const getFirstDayOfMonthInsideDateRange = (dateRange: ISO8601TupleType): ISO8601Type => {
  const startDate = dateRange[0];
  const startDateDayOfMonth = dayjs(startDate).date();
  if (startDateDayOfMonth === 1) {
    return startDate;
  } else {
    return dayjs(startDate).add(1, 'M').date(1).format();
  }
};

export const getFirstDayOfMonthFromDate = (date: ISO8601Type): ISO8601Type => {
  const startDateDayOfMonth = dayjs(date).date();
  if (startDateDayOfMonth === 1) {
    return dayjs(date).startOf('d').format();
  } else {
    return dayjs(date).add(1, 'M').date(1).format();
  }
};

export const getFirstDayOfThisMonth = () =>
  getFirstDayOfMonthFromDate(dayjs().startOf('M').format());
