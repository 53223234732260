import dayjs from 'utils/dayjs';
import { IDayCellConfig, IGetDayCellsArgs } from './MonthView.types';
import {
  getOneMonthRangeFromDate,
  getFortyTwoDaysArrayFromDate,
  getFirstDayOfMonthFromDate,
  getOneWeekRangeFromDate,
  getSingleDateRangeFromDate,
} from '../../../../../BKJDatePicker.utils';
import {
  isArray,
  isISO8601Tuple,
  isISO8601Type,
  ISO8601TupleType,
  ISO8601Type,
} from 'types/common.types';

const getMonthRangeDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  hoveredDate,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isISO8601Tuple(value)) {
    throw new Error('value of Month Range Calendar must be of type ISO8601TupleType');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const _value = getOneMonthRangeFromDate(day);
    const handleDayClick = (): void => {
      const e = {
        target: {
          name,
          value: _value,
        },
      };
      return onChange(e);
    };
    return {
      label: dayjs(_value[0]).date(),
      onClick: handleDayClick,
      isSelected: dayjs(value[0]).isSame(day, 'd') || dayjs(value[1]).isSame(day, 'd'),
      isHoveredOnCalendar: !!hoveredDate,
      isHovered: hoveredDate === _value[0],
      isDateInHoverRange:
        hoveredDate.length > 0 &&
        dayjs(_value[0]).isBetween(
          hoveredDate,
          getOneMonthRangeFromDate(hoveredDate)[1],
          null,
          '[]',
        ),
      isBetweenSelectedRange: dayjs(day).isBetween(value[0], value[1]),
      isDateToday: dayjs().isSame(day, 'd'),
      isDateInViewMonth:
        dayjs(day).month() === dayjs(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])).month(),
      id: day,
    };
  });
};

const getWeekRangeDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  hoveredDate,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isISO8601Tuple(value)) {
    throw new Error('value of Week Range Calendar must be of type ISO8601TupleType');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const _value = getOneWeekRangeFromDate(day);
    const handleDayClick = (): void => {
      const e = {
        target: {
          name,
          value: _value,
        },
      };
      return onChange(e);
    };
    return {
      label: dayjs(_value[0]).date(),
      onClick: handleDayClick,
      isSelected: dayjs(value[0]).isSame(day, 'd') || dayjs(value[1]).isSame(day, 'd'),
      isHoveredOnCalendar: !!hoveredDate,
      isHovered: hoveredDate === _value[0],
      isDateInHoverRange:
        hoveredDate.length > 0 &&
        dayjs(_value[0]).isBetween(
          hoveredDate,
          getOneWeekRangeFromDate(hoveredDate)[1],
          null,
          '[]',
        ),
      isBetweenSelectedRange: dayjs(day).isBetween(value[0], value[1]),
      isDateToday: dayjs().isSame(day, 'd'),
      isDateInViewMonth:
        dayjs(day).month() === dayjs(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])).month(),
      id: day,
    };
  });
};

const getSingleDateRangeDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isISO8601Tuple(value)) {
    throw new Error('value of Single Date Range Calendar must be of type ISO8601TupleType');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const _value = getSingleDateRangeFromDate(day);
    const handleDayClick = (): void => {
      const e = {
        target: {
          name,
          value: _value,
        },
      };
      return onChange(e);
    };
    return {
      label: dayjs(_value[0]).date(),
      onClick: handleDayClick,
      isSelected: dayjs(value[0]).isSame(day, 'd'),
      isDateToday: dayjs().isSame(day, 'd'),
      isDateInViewMonth:
        dayjs(day).month() === dayjs(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])).month(),
      id: day,
    };
  });
};

const getSingleDateDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  onChange,
  disabledTo,
  disabledFrom,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (value && !isISO8601Type(value)) {
    throw new Error('value of Single Date Calendar must be of type ISO8601Type');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);

  return daysArray.map((day: ISO8601Type) => {
    let isDisabled = false;
    if (disabledTo) {
      isDisabled = dayjs(day).isBefore(disabledTo);
    }
    if (disabledFrom) {
      isDisabled = isDisabled || dayjs(day).isAfter(disabledFrom);
    }

    const handleDayClick = (): void => {
      if (isDisabled) return;
      const e = {
        target: {
          name,
          value: day,
        },
      };
      return onChange(e);
    };
    return {
      label: dayjs(day).date(),
      name: name,
      value: value,
      onClick: handleDayClick,
      isSelected: dayjs(value).isSame(day, 'd'),
      isDateToday: dayjs().isSame(day, 'd'),
      isDisabled: isDisabled,
      isDateInViewMonth:
        dayjs(day).month() === dayjs(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])).month(),
      id: day,
    };
  });
};

const getMultipleDatesDayCells = ({
  value,
  name,
  dateRangeInMonthView,
  onChange,
}: IGetDayCellsArgs): IDayCellConfig[] => {
  if (!isArray(value)) {
    throw new Error('value of Multiple Dates Calendar must be an array of ISO8601Type dates');
  }
  const daysArray = getFortyTwoDaysArrayFromDate(dateRangeInMonthView[0]);
  return daysArray.map((day: ISO8601Type) => {
    const isDisabled = dayjs().isAfter(day, 'd');
    const handleDayClick = (): void => {
      if (isDisabled) return;
      let valueArray = Array.from(value);
      const valueIndex = valueArray.indexOf(day);
      if (valueIndex > -1) {
        valueArray.splice(valueIndex, 1);
      } else {
        valueArray.push(day);
      }
      const e = {
        target: {
          name,
          value: valueArray,
        },
      };
      return onChange(e);
    };
    return {
      label: dayjs(day).date(),
      onClick: handleDayClick,
      isSelected: value.some((dateValue: ISO8601Type) => dayjs(dateValue).isSame(day, 'd')),
      isDateToday: dayjs().isSame(day, 'd'),
      isDisabled: isDisabled,
      isDateInViewMonth:
        dayjs(day).month() === dayjs(getFirstDayOfMonthFromDate(dateRangeInMonthView[0])).month(),
      id: day,
    };
  });
};

export const getDayCellsConfigArray = (args: IGetDayCellsArgs): Array<IDayCellConfig> => {
  const { type } = args;
  switch (type) {
    case 'MonthRange': {
      return getMonthRangeDayCells(args);
    }
    case 'WeekRange': {
      return getWeekRangeDayCells(args);
    }
    case 'SingleDateRange': {
      return getSingleDateRangeDayCells(args);
    }
    case 'SingleDate': {
      return getSingleDateDayCells(args);
    }
    case 'MultipleDates': {
      return getMultipleDatesDayCells(args);
    }
    default:
      return [];
  }
};

export const getYearInView = (dateRangeInMonthView: ISO8601TupleType): number =>
  dayjs(dateRangeInMonthView[0]).year();
