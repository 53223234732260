import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { AllowOvertimeDialogProps } from 'components/AllowOvertimeDialog/AllowOvertimeDialog.types';
import { SCHEDULING_PUBLISHED_SHIFT_DETAILS } from 'constants/endpoints';
import { METHOD_PATCH } from 'requests';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { toastService } from 'services';
import { useTranslation } from 'hooks/useTranslation';
import { ApiRequestConfigType } from 'requests/index.types';

interface AllowOvertimeDialogForm {
  handleAllowOvertime: () => void;
}

const API_PATCH_publishedShiftAllowOvertime = (shift_id: number): ApiRequestConfigType => ({
  __typename: 'LegacyAPI',
  url: SCHEDULING_PUBLISHED_SHIFT_DETAILS(`${shift_id}`),
  method: METHOD_PATCH,
  body: {
    order: {
      allow_overtime: true,
    },
  },
});

export const AllowOvertimeDialogFormContext = createContext({} as AllowOvertimeDialogForm);

export const AllowOvertimeDialogFormProvider: FC<AllowOvertimeDialogProps> = ({
  children,
  orderId,
  onSuccess,
}) => {
  const { apiRequest } = useAPIActions();
  const { t } = useTranslation();

  const handleAllowOvertime = useCallback(
    async () =>
      await apiRequest(API_PATCH_publishedShiftAllowOvertime(orderId), {
        onSuccess,
        onError: () => toastService.error(t('FAILURE_TOAST_MESSAGE')),
      }),
    [apiRequest, onSuccess, orderId, t],
  );

  const contextValue = useMemo(
    () => ({
      handleAllowOvertime,
    }),
    [handleAllowOvertime],
  );

  return (
    <AllowOvertimeDialogFormContext.Provider value={contextValue}>
      {children}
    </AllowOvertimeDialogFormContext.Provider>
  );
};

export const useAllowOvertimeDialogFormContext = () => useContext(AllowOvertimeDialogFormContext);
