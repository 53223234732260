import { BKJHorizontalRule } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const SectionDivider = styled(BKJHorizontalRule)`
  margin: 0px 0px 0px 0px;
`;

export const SendToAgenciesSummaryViewTitle = styled.div`
  ${({ theme }) => theme.fonts.PoppinsBold}
  font-size: 28px;
  line-height: 34px;
`;
