import { CustomFieldInfoFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { filter, includes, uniq } from 'lodash';
import pluralize from 'pluralize';

const VALIDATION_dropdownOptions = (
  value: CustomFieldInfoFormSchema[keyof CustomFieldInfoFormSchema],
  rest: CustomFieldInfoFormSchema,
): string | string[] => {
  const dropdownOptions = value as string[];
  if (rest.type[0] !== 'Single-Select Dropdown') return '';
  const data = dropdownOptions.map((str) => str.toLowerCase());
  let errs: string[] = [];
  const hasDuplicates = uniq(data).length !== data.length;
  const duplicates = uniq(filter(data, (val, i, iteratee) => includes(iteratee, val, i + 1)));

  if (data.length === 0) errs.push('At least 1 option is required');
  if (data.length > 200)
    errs.push(`Remove ${data.length - 200} ${pluralize('option', data.length - 200)}`);
  if (hasDuplicates)
    [`Remove duplicate values`, ...duplicates.map((str) => `\t• ${str}`)].forEach((item) =>
      errs.push(item),
    );
  if (errs.length) return errs;
  return '';
};

export { VALIDATION_dropdownOptions };
