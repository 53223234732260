import {
  SelectPositionByDepartmentDataType,
  SelectPositionByDepartmentOptionType,
} from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.types';

export const getSelectPositionByDepartmentOptions = (data: SelectPositionByDepartmentDataType) => {
  return Object.values(data).reduce(
    (
      acc: SelectPositionByDepartmentOptionType[],
      groupValues: SelectPositionByDepartmentOptionType[],
    ) => {
      acc = acc.concat(
        groupValues.map(({ value, label, key }) => {
          return {
            key: key,
            value: value,
            label: label,
          };
        }),
      );
      return acc;
    },
    [],
  );
};
