import assertProps from 'utils/assertProps';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorWrapper } from 'components/ErrorLabel/styled';

const ErrorLabel = ({ isVisible, message, pointed, ...rest }) => {
  if (!isVisible) return null;
  return (
    <ErrorWrapper pointed={pointed} {...rest}>
      {message.split('\n').map((str) => (
        <div>{str}</div>
      ))}
    </ErrorWrapper>
  );
};

ErrorLabel.defaultProps = {
  pointed: false,
};

ErrorLabel.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  pointed: PropTypes.bool,
};

export default assertProps(['isVisible'])(ErrorLabel);
