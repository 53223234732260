import { BKJButton, BKJTextInput, Flex } from '@bookjane2/bookjane-design-library';
import { ChangeFunctionType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useApplyFacilitiesFormContext } from 'components/CustomFieldCreateModal/contexts/ApplyFacilities.form.context';
import { useFetchCommunitiesDataContext } from 'components/CustomFieldModal/Contexts/FetchCommunities.data.context';
import { useFetchCommunitiesCountDataContext } from 'components/CustomFieldModal/Contexts/FetchCommunitiesCount.data.context';
import {
  CUSTOM_FIELDS_MODAL_FACILITIES_EMPTY_SEARCH,
  SEARCH_FACILITY_INITIAL_STATE,
} from 'components/CustomFieldModal/CustomFieldModal.constants';
import {
  FacilitiesSelectionCountLabel,
  Heading,
  NoResultsFoundWrapper,
} from 'components/CustomFieldModal/CustomFieldModal.styled';
import { FacilitiesSelectionTable } from 'components/CustomFieldModal/Views/ApplyEditFacilitiesToCustomField/FacilitiesSelectionTable';
import { FC, Fragment, MouseEventHandler } from 'react';
import { useTranslation } from 'hooks/useTranslation';

export const ApplyFacilitiesView: FC = () => {
  const {
    data: fetchCommunities,
    values: fetchCommunitiesValues,
    onChange,
    isLoading,
  } = useFetchCommunitiesDataContext();
  const fetchCommunitiesCount = useFetchCommunitiesCountDataContext();
  const {
    values,
    onSubmit,
    isDisabled,
    handleApplyAllFacilitiesChange,
    handleApplyFacilitiesChange,
  } = useApplyFacilitiesFormContext();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Flex flexDirection="column" gap="32px" padding="32px" flex="1">
        <Heading>{t('settings:SELECT_LOCATIONS_TO_APPLY')}:</Heading>
        <BKJTextInput
          onChange={onChange as ChangeFunctionType}
          name="byName"
          value={fetchCommunitiesValues?.byName}
          placeholder={t('settings:SEARCH_LOCATION')}
          leftIconProps={{ iconName: 'Search' }}
          rightIconProps={
            fetchCommunitiesValues?.byName === '' ? undefined : { iconName: 'CloseBold' }
          }
          onClick={() => onChange(SEARCH_FACILITY_INITIAL_STATE)}
          autoComplete="off"
        />
      </Flex>
      {!!fetchCommunities?.fetchCommunities.nodes?.length || isLoading ? (
        <FacilitiesSelectionTable
          values={values}
          handleApplyAllFacilitiesChange={handleApplyAllFacilitiesChange}
          handleApplyFacilitiesChange={handleApplyFacilitiesChange}
        />
      ) : (
        <NoResultsFoundWrapper alignItems="center" justifyContent="center">
          {t(CUSTOM_FIELDS_MODAL_FACILITIES_EMPTY_SEARCH)}
        </NoResultsFoundWrapper>
      )}
      <BKJSideModalFooter justifyContent="space-between">
        <FacilitiesSelectionCountLabel>
          {`${t('common:SELECTED')}: ${values.communityIds.length}/${fetchCommunitiesCount}`}
        </FacilitiesSelectionCountLabel>
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          disabled={isDisabled}
          onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
        >
          {t('auth:SAVE')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
