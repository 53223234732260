import { Flex } from 'components/Flex';
import { Rating } from 'semantic-ui-react';
import { SectionDivider } from 'components/ShiftModal/ShiftModal.styled';
import {
  LabelText,
  ReviewCommunityName,
  CommentText,
} from 'components/JaneModal/views/JaneDetailsView/ReviewsTab/ReviewsTab.styled';
import { JaneAvatarWrapper } from 'components/JaneModal/views/EditJaneView/EditJaneView.styled';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { humanReadableDate } from 'components/JaneModal/JaneModal.constants';
import { janeModalJaneDetails_fetchJane_customerReviews_reviews } from 'types/graphql-types';
export const ReviewCommentCard = ({
  review,
}: {
  review: janeModalJaneDetails_fetchJane_customerReviews_reviews;
}) => {
  return (
    <Flex flexDirection="column" gap="16px">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="10px" alignItems="center" padding="0 0 0 6px">
          <JaneAvatarWrapper>
            <JaneAvatar
              url={review?.jane?.avatarUrls?.small}
              status={'accepted'}
              size={35}
              pendingSignup={false}
            />
          </JaneAvatarWrapper>
          <Flex flexDirection="column" gap="8px">
            <LabelText>{review?.jane?.shorthandName}</LabelText>
            <ReviewCommunityName>{review?.jane?.communityName}</ReviewCommunityName>
          </Flex>
        </Flex>
        <ReviewCommunityName>{humanReadableDate(review?.createdAt)}</ReviewCommunityName>
      </Flex>
      {review?.comment && <CommentText>{review?.comment}</CommentText>}
      <Flex gap="15px" padding="0 0 0 6px">
        <Rating disabled maxRating={5} defaultRating={review ? review?.rating || 0 : 0} />
      </Flex>
      <SectionDivider variant="Thin" />
    </Flex>
  );
};
