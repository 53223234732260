import { BKJAvatar } from '@bookjane2/bookjane-design-library';

import styled, { css } from 'styled-components';
import { AccessStatusEnum } from 'types/graphql-types';

interface IProps {
  status: string;
  size?: number;
  width?: string;
  height?: string;
  showPendingSignUp: boolean;
}

export const StyledBKJAvatar = styled(BKJAvatar)<IProps>`
  opacity: ${({ status }) => (status === AccessStatusEnum.blocked ? '0.5' : '1')};
  ${({ size }) => {
    return css`
      min-width: calc(${size}px / 2);
      min-height: calc(${size}px / 2);
    `;
  }};
  /* Asset Provided By Design is Not Centered */
  g {
    path {
      transform: translate(0.5px, -0.5px);
    }
  }
`;
