import { BKJLoadingSpinner, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJSideModal } from 'components/BKJSideModal';
import { FC, useMemo, useState } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { BKJCenteredDialog } from 'components/BKJCenteredDialog';
import {
  inviteTeaMemberCSVModalRoutingService,
  useInviteTeamMembersByCSVModalUIContext,
} from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
import { InviteTeamMembersByCSVModalComponentMap } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.constants';
import {
  InviteTeamMembersByCSVModalDataProvider,
  useInviteTeamMembersByCSVModalDataContext,
} from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.data.context';
import { SelectInputPositionByDepartmentProvider } from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.context';

const InviteTeamMembersByCSVFormViewComponent: FC<{ view: FC }> = ({ view: Component }) => {
  const { location, communities } = useInviteTeamMembersByCSVModalDataContext();
  if (!communities || !communities?.length) return <BKJLoadingSpinner variant="Default" />;
  return (
    <SelectInputPositionByDepartmentProvider location={location}>
      <Component />
    </SelectInputPositionByDepartmentProvider>
  );
};

export const InviteTeamMembersByCSVModalView: FC = () => {
  const { isOpen, inviteTeamMemberCSVActiveModalView } = useInviteTeamMembersByCSVModalUIContext();

  const [isDiscardConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  const Component = useMemo(
    () => InviteTeamMembersByCSVModalComponentMap[inviteTeamMemberCSVActiveModalView],
    [inviteTeamMemberCSVActiveModalView],
  );

  const modalHeader = {
    InviteByCSV: 'Invite Team Members',
    SuccessFul: 'Invite Team Members',
    EditRanking: 'Edit Ranking',
    UnSuccessFul: 'Invite Team Members',
    InviteTeamMemberView: 'Invite Team Member',
  };

  return (
    <BKJSideModal
      isOpen={isOpen}
      onClose={() => setIsDeleteConfirmationOpen(true)}
      showBackButton={inviteTeaMemberCSVModalRoutingService.isBackButtonVisible()}
      onBack={inviteTeaMemberCSVModalRoutingService.goToPrevView}
      headerComponent={modalHeader[inviteTeamMemberCSVActiveModalView]}
    >
      <ErrorBoundary
        componentName="InviteTeamMembersByCSVModal"
        showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
      >
        <BKJCenteredDialog
          isOpen={isDiscardConfirmationOpen}
          variant="Danger"
          title="Discard changes?"
          data-cy="discard-modal"
          text=""
          cancelLabel="Cancel"
          actionLabel="Discard"
          onAction={() => {
            setIsDeleteConfirmationOpen(false);
            return inviteTeaMemberCSVModalRoutingService.close();
          }}
          onCancel={() => setIsDeleteConfirmationOpen(false)}
          onClose={() => setIsDeleteConfirmationOpen(false)}
        />
        {isOpen && (
          <InviteTeamMembersByCSVModalDataProvider>
            <InviteTeamMembersByCSVFormViewComponent view={Component} />
          </InviteTeamMembersByCSVModalDataProvider>
        )}
      </ErrorBoundary>
    </BKJSideModal>
  );
};

export const InviteTeamMembersByCSVModalComponent: FC = () => {
  return <InviteTeamMembersByCSVModalView />;
};
