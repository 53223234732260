import styled, { css } from 'styled-components';
import { BKJButton, BKJHorizontalRule, BKJIcon } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';

export const CreateShiftCardWrapper = styled(Flex)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.White};
  ${({ theme }) => theme.borders.DefaultBorder};
  ${({ theme }) => theme.shadows.Default};
  min-height: 145px;
  padding: 16px 16px 0px 16px;
  min-width: 362px;
`;

export const DeleteIcon = styled(BKJIcon)`
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
`;

export const Duration = styled.span<{ isError: boolean }>`
  margin-top: 8px;
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ${({ theme }) => theme.common.Ellipsis};
  color: ${({ theme }) => theme.colors.TextSubtleGrey};
  font-size: 14px;
  line-height: 24px;
  ${({ isError }) =>
    isError &&
    css`
      color: ${({ theme }) => theme.colors.ErrorDefault};
    `};
`;

export const IconWithMarginRight = styled(BKJIcon)`
  margin-right: 10px;
`;

export const SectionA = styled(Flex)`
  position: relative;
  height: 100%;
`;

export const SectionDivider = styled(BKJHorizontalRule)`
  margin: 16px auto 0px;
  width: calc(100% - 8px);
`;

export const FormLabel = styled.span`
  ${({ theme }) => theme.fonts.PoppinsTextMedium};
  ${({ theme }) => theme.common.Ellipsis};
  line-height: 21px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  margin-bottom: 4px;
`;

export const SectionB = styled(Flex)`
  min-height: 57px;
  align-items: center;
`;

export const LeftSideActions = styled(Flex)``;

export const RightSideActions = styled(Flex)``;

export const ShiftCardButton = styled(BKJButton)`
  background-color: ${({ theme }) => theme.colors.White};
  width: fit-content;
  padding: 0;
  min-height: fit-content;
  opacity: 1;
  transition: 'opacity 500ms ease-in';

  &:not(:first-child) {
    margin-left: 30px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const HideOnMobile = styled.span`
  @media (max-width: ${({ theme }) => theme.breakpoints.Tablet}) {
    display: none;
  } ;
`;

export const Drawer = styled.div`
  height: 200px;
  overflow: auto;
`;

export const CreateShiftCardErrorsWrapper = styled.div`
  min-height: 30px;
  margin-bottom: 20px;
  border: 1px solid transparent;
`;

export const CreateShiftCardError = styled.label`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  display: flex;
  margin-top: 8px;
  margin-left: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.ErrorDefault};
`;
