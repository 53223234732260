import { ShiftCardSkeleton } from 'components/ShiftCardSkeleton';
import { FC, Fragment } from 'react';
import {
  shiftCardFetchOrder_fetchOrder,
  dashboardPastShiftsPage_fastGroupOrders_nodes_orders,
  dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders,
  dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders,
} from 'types/graphql-types';
import { withMemo } from 'utils/withMemo';
import { IGroupOrdersProps } from './GroupOrdersComponent.types';
import { useGroupOrdersContext } from './useGroupOrdersContext';

export const GroupOrdersComponent: FC<IGroupOrdersProps> = withMemo((props: IGroupOrdersProps) => {
  const { order, fetch } =
    useGroupOrdersContext<
      Required<
        | shiftCardFetchOrder_fetchOrder
        | dashboardPastShiftsPage_fastGroupOrders_nodes_orders
        | dashboardRecentlyCreatedShiftsPage_fastGroupOrders_nodes_orders
        | dashboardUpcomingShiftsPage_fastGroupOrders_nodes_orders
      >
    >(props);
  if (!order?.id) return <Fragment />;
  return <ShiftCardSkeleton order={order} fetch={fetch} />;
});
