import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from 'store/reducers/auth/authReducer';
import { apiReducer } from 'store/reducers/api/apiSlice';
import { scheduleReducer } from 'store/reducers/scheduleSlice/scheduleSlice';
import heartbeatReducer from 'store/reducers/heartbeat/heartbeatSlice';

export const globalStore = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false, immutableCheck: false }),
  reducer: {
    api: apiReducer,
    heartbeat: heartbeatReducer,
    schedule: scheduleReducer,
    auth: authReducer,
  },
});
