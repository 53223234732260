import { FC, useCallback, useRef, useState } from 'react';
import {
  HamburgerMenu,
  MobilePageHeaderWrapper,
} from 'components/MobilePageHeader/MobilePageHeader.styled';
import { IMobilePageHeaderProps } from 'components/MobilePageHeader/MobilePageHeader.types';
import { NavHeader } from 'components/BKJMainNavigation/src/NavHeader';
import { NavMenuItems } from 'components/BKJMainNavigation/src/NavMenuItems';
import { NavFooter } from 'components/BKJMainNavigation/src/NavFooter';
import { MobileNavigationWrapper } from 'components/BKJMainNavigation/BKJMainNavigation.styled';

export const MobilePageHeader: FC<IMobilePageHeaderProps> = ({
  title,
  children,
  ...rest
}): JSX.Element => {
  const disabled = useRef(false);

  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const onMenuClosed = useCallback(() => setOpen(false), []);
  const onMenuToggled = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <MobilePageHeaderWrapper {...rest}>
      <HamburgerMenu
        onMouseDown={onMenuToggled}
        iconName="HamburgerTablet"
        color="Grey7"
        $isOpen={isOpen}
      />
      <MobileNavigationWrapper $isOpen={isOpen} ref={ref}>
        <NavHeader onMouseDown={onMenuToggled} isOpen={isOpen} menuIcon="CloseBold" />
        <NavMenuItems disabled={disabled} isOpen={isOpen} onClose={onMenuClosed} />
        <NavFooter disabled={disabled} isOpen={isOpen} onClose={onMenuClosed} />
      </MobileNavigationWrapper>
      {title}
      {children}
    </MobilePageHeaderWrapper>
  );
};
