import { FC } from 'react';
import {
  IBKJSideModalProps,
  BKJSideModalHeaderComponentType,
} from 'components/BKJSideModal/BKJSideModal.types';
import { Portal } from 'components/Portal/Portal';
import {
  BKJSideModalBackdrop,
  BKJSideModalDrawer,
  BKJSideModalHeadingWrapper,
  BKJSideModalDrawerContent,
  BKJSideModalBackButton,
  BKJSideModalCloseButton,
} from 'components/BKJSideModal/BKJSideModal.styled';
import { getJ360ElementNode } from 'utils/getJ360ElementNode';
import { isReactComponent, isReactElement, isString } from 'types/common.types';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { QuerySwitch } from 'components/QuerySwitch';
import { Flex } from 'components/Flex';
import { AnimatePresence } from 'framer-motion';
import { MODAL_ROOT_ID } from 'constants/elements';
import { useTranslation } from 'hooks/useTranslation';

let targetElement = getJ360ElementNode(MODAL_ROOT_ID);

export const BKJSideModal: FC<IBKJSideModalProps> = (props) => {
  const {
    isOpen,
    onClose,
    showBackButton,
    onBack,
    children,
    headerComponent,
    bgColor,
    context,
    hasPadding = false,
    ...rest
  } = props;

  if (!targetElement) {
    targetElement = document.createElement('div');
    targetElement.setAttribute('id', MODAL_ROOT_ID);
    document.body.appendChild(targetElement);
  }
  const HeaderComponent = headerComponent as BKJSideModalHeaderComponentType;
  const { t } = useTranslation();
  return (
    <AnimatePresence initial key="BKJSideModal">
      {isOpen && (
        <Portal targetElement={targetElement}>
          <BKJSideModalDrawer
            {...rest}
            key="BKJSideModalDrawer"
            initial={{ x: 556 }}
            animate={{ x: 0 }}
            exit={{ x: 556 }}
            transition={{
              type: 'tween',
              ease: 'easeOut',
              duration: 0.3,
            }}
          >
            <BKJSideModalHeadingWrapper>
              {showBackButton && (
                <BKJSideModalBackButton onClick={onBack}>
                  <BKJIcon iconName="ChevronLeft" color="TextDarkGrey" />
                  &nbsp;{t('auth:BACK')}
                </BKJSideModalBackButton>
              )}
              <Flex
                width="100%"
                height="100%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding="0 118px"
                data-cy="hdr-new-custom-modal"
              >
                {isString(HeaderComponent) && HeaderComponent}
                {isReactComponent(HeaderComponent) && <HeaderComponent closeModal={onClose} />}
                {isReactElement(HeaderComponent) && HeaderComponent}
              </Flex>
              <BKJSideModalCloseButton onClick={onClose}>
                <BKJIcon
                  data-cy="btn-close-customfield"
                  iconName="CloseBold"
                  color="TextDarkGrey"
                />
              </BKJSideModalCloseButton>
            </BKJSideModalHeadingWrapper>
            <BKJSideModalDrawerContent bgColor={bgColor} hasPadding={hasPadding}>
              {!!context ? <QuerySwitch context={context} component={children} /> : children}
            </BKJSideModalDrawerContent>
          </BKJSideModalDrawer>
          <BKJSideModalBackdrop
            key="BKJSideModalBackdrop"
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'tween',
              ease: 'linear',
              duration: 0.3,
            }}
          />
        </Portal>
      )}
    </AnimatePresence>
  );
};
