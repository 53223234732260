import { CUSTOMER_AVAILABILITY } from 'constants/endpoints';
import { AvailabilityLocationSettingsPageFormFields } from 'pages/LocationSettingsPage/AvailabilityLocationSettingsPageForm.types';
import { METHOD_POST } from 'requests';
import { ApiRequestConfigType } from 'requests/index.types';
import dayjs from 'utils/dayjs';

export const API_POST_updateCustomerAvailability = (
  idArr: string[],
  params: AvailabilityLocationSettingsPageFormFields,
  isTieringEnabled: boolean,
): ApiRequestConfigType[] => {
  const payload = {
    community_availability: {
      start_at: dayjs(params.start_at).format('YYYY-MM-DD'),
      timespan: Number.parseInt(params.timespan[0]),
      deadline: Number.parseInt(params.deadline),
      extra_settings: {} as Record<string, unknown>,
      non_mobile: params.non_mobile,
    },
  };

  if (isTieringEnabled) {
    payload.community_availability.extra_settings['critical'] = params['critical'];
    payload.community_availability.extra_settings['high_priority'] = params['high_priority'];
    payload.community_availability.extra_settings['vacation_loa'] = params['vacation_loa'];
    payload.community_availability.extra_settings['shift_exchange'] = params['shift_exchange'];
  } else {
    payload.community_availability.extra_settings['waiting_hours'] = Number.parseInt(
      params['waiting_hours'],
    );
  }

  return idArr.map((id) => {
    return {
      method: METHOD_POST,
      url: CUSTOMER_AVAILABILITY(id),
      __typename: 'LegacyAPI',
      body: payload,
    };
  });
};
