import * as React from 'react';

export function SadJaneIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M63.935 15.667a24.758 24.758 0 00-4.341-5.874C55.003 5.493 50.568 3 39.862 3c-11.422 0-16.013 4.176-19.28 7.167C15.524 14.747 10 27.01 10 42.045c0 13.695 1.198 22.56 3.781 27.92 1.759 4.456 6.194 7.26 10.971 6.903a48.758 48.758 0 006.91-.717c2.77-.499 5.57-.78 8.371-.857 2.755.062 5.493.327 8.2.779 2.257.39 4.545.623 6.832.685 4.778.359 9.244-2.399 11.064-6.84 2.63-5.36 3.86-14.193 3.86-27.889a55.582 55.582 0 00-6.054-26.362z"
        fill="#4E4785"
      />
      <path
        d="M39.953 68.422c-2.427 0-7.593-2.633-12.417-7.556-3.16-3.225-8.45-9.66-8.45-18.105 0-8.444 1.292-12.854 7.75-16.188 6.457-3.334 7.375-6.575 8.06-6.575.685 0 5.182 5.329 10.146 9.052 4.964 3.724 10.053 5.282 14.908 6.793.264.094.435.982.591 1.995.187 1.651.28 3.303.28 4.97 0 14.07-15.763 25.614-20.867 25.614z"
        fill="#fff"
      />
      <path
        d="M36.96 59.434c.637 0 1.96-.328 2.956-.328s2.319.328 2.957.328c1.213 0 1.711-.437 1.851-.826.125-.39 0-1.028-.824-1.854a8.459 8.459 0 00-3.968-1.496 8.521 8.521 0 00-3.969 1.496c-.855.763-.933 1.386-.809 1.854.11.452.591.826 1.805.826zM29.156 44.135c1.307 0 2.583-.124 3.812-.53 1.23-.405 1.868-1.526 1.54-2.695-.326-1.168-1.524-1.87-2.691-1.542-.872.28-1.712.53-2.677.53a9.42 9.42 0 01-2.676-.39c-1.167-.327-2.381.374-2.708 1.542-.327 1.169.405 2.493 1.54 2.711 1.152.203 2.553.374 3.86.374zM50.819 44.135c-1.307 0-2.583-.125-3.813-.53-1.229-.405-1.867-1.527-1.54-2.695.327-1.169 1.525-1.87 2.692-1.543.871.28 1.712.53 2.676.53a9.42 9.42 0 002.677-.39c1.167-.327 2.38.374 2.708 1.543.326 1.168-.405 2.493-1.541 2.71a21.61 21.61 0 01-3.86.375z"
        fill="#4E4785"
      />
    </svg>
  );
}
