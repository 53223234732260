import {
  FC,
  MouseEventHandler,
  // MouseEventHandler,
  useContext,
} from 'react';
import {
  LabelText,
  LoadingWrapper,
  NonMobileWrapper,
  Text,
  H3,
} from 'components/JaneModal/views/EditJaneView/EditJaneView.styled';
import {
  BKJTextInput,
  BKJButton,
  Flex,
  BKJComboBox,
  BKJLoadingSpinner,
  BKJCheckbox,
  BKJIconTooltip,
  BKJPillDropdown,
  BKJSelect,
  BKJPhoneNumberInput,
} from '@bookjane2/bookjane-design-library';
import { ShiftDateCalendarInput } from 'components/ShiftDateCalendarInput';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { useFetchJaneByIdDataContext } from 'components/JaneModal/FetchJaneById.data.context';
import { startCase } from 'lodash';
import { BKJRadioInput } from 'components/BKJRadioInput/BKJRadioInput';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import { TeamLocationFilterInput } from 'components/JaneModal/views/EditJaneView/TeamLocationFilterInput';
import { getPositionByDepartmentSelectOptions } from 'components/PositionByDepartmentSelectInput/PositionByDepartmentSelectInput.utils';
import { SelectInputPositionByDepartment } from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment';
import {
  EmployeeStatus,
  RenderOptions,
  monthToDaysMap,
} from 'components/JaneModal/views/EditJaneView/EditJaneView.constants';
import { janeModalRoutingService } from 'components/JaneModal/JaneModal.constants';
import {
  InviteTeamMemberViewFormProvider,
  useInviteTeamMemberViewFormContext,
} from 'components/InviteTeamMembersByCSVModal/views/InviteTeamMemberView/InviteTeamMemberView.form.context';
import { SelectInputPositionByDepartmentContext } from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.context';
import { SelectPositionByDepartmentDataType } from 'components/JaneModal/views/EditJaneView/SelectInputPositionByDepartment/SelectInputPositionByDepartment.types';
import { TeamMemberPageProvider } from 'pages/TeamMemberPage/TeamMemberPage.context';

const Component: FC = () => {
  const {
    data,
  }: {
    data: SelectPositionByDepartmentDataType;
  } = useContext(SelectInputPositionByDepartmentContext);
  const _options = getPositionByDepartmentSelectOptions(data);
  const {
    values,
    onChange,
    fieldErrors,
    apiErrors,
    isDisabled,
    setSelectMonth,
    dayOptions,
    onSubmit,
  } = useInviteTeamMemberViewFormContext();
  const secondaryPositionsDropdown = _options.map((item) => ({
    id: `${item?.key}`,
    value: `${item?.value}`,
    label: item?.label,
  }));

  const employStatus = startCase(values?.employmentStatus);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectMonth(e.target.value);
    if (values.birthDay) {
      values.birthDay = '';
    }
  }
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      padding="32px 39px 0"
      borderTop="1px solid rgba(139, 150, 163, 0.33)"
    >
      <Flex flexDirection="column" gap="32px" padding="0 0 50px" width="100%">
        <H3>Invite Team Member</H3>
        <Text>
          Enter team member’s profile information and select Invite. Users will be sent an
          invitation via email and text message to download the BookJane app and setup their
          accounts.
        </Text>
        <BKJTextInput
          label="First Name*"
          onChange={onChange}
          value={values?.firstName}
          name={'firstName'}
          autoComplete="off"
          error={fieldErrors.firstName}
        />

        <BKJTextInput
          label="Last Name*"
          onChange={onChange}
          value={values?.lastName}
          name={'lastName'}
          autoComplete="off"
          error={fieldErrors.lastName}
        />

        <Flex flexDirection="column" gap="8px">
          <BKJTextInput
            label="Email*"
            onChange={onChange}
            value={values?.email}
            name={'email'}
            autoComplete="off"
            error={fieldErrors.email || apiErrors?.data?.[1]?.email}
          />
        </Flex>
        <Flex flexDirection="column">
          <BKJPhoneNumberInput
            label="Mobile Phone*"
            onChange={onChange}
            value={values?.phone}
            name={'phone'}
            autoComplete="off"
            error={fieldErrors.phone || apiErrors?.data?.[0]?.phone}
          />

          <Flex alignItems="center" gap="10px" style={{ marginTop: '10px', marginLeft: '16px' }}>
            <BKJCheckbox
              variant="PurpleSolid"
              value={false}
              name={'isMobile'}
              onChange={onChange}
            />
            <NonMobileWrapper>Flag as Non-Mobile User</NonMobileWrapper>
            <BKJIconTooltip iconName="Info" placement="right">
              Non-mobile users will be <br /> notified of any new shifts with <br /> an automated
              voice call
            </BKJIconTooltip>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <LabelText>Select a language preference*</LabelText>
          <BKJRadioInput
            name="locale"
            value="en"
            onChange={onChange}
            checked={true}
            label="English"
          />
          <BKJRadioInput
            name="locale"
            value="fr"
            onChange={onChange}
            checked={false}
            label="French"
          />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <LabelText style={{ fontWeight: 'normal', fontFamily: 'Poppins Medium' }}>
            Primary location*
          </LabelText>
          <TeamLocationFilterInput
            value={values?.location}
            onChange={onChange}
            name="location"
            width="470px"
          />
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <LabelText style={{ fontWeight: 'normal', fontFamily: 'Poppins Medium' }}>
            Primary Position*
          </LabelText>

          <SelectInputPositionByDepartment
            location={values?.location as IBKJComboBoxAsyncOption}
            dropdownVariant="Square"
            placeholder="Select Position"
            onChange={onChange}
            type="Single"
            name="position_id"
            variant="GreyOutlined"
            value={[values?.position_id]}
            error={fieldErrors?.position_id}
            width="100%"
            dropdownProps={{
              dropdownListItemVariant: 'List',
              isSearchable: false,
            }}
          />
        </Flex>
        <Flex flexDirection="column">
          <LabelText style={{ fontWeight: 'normal', fontFamily: 'Poppins Medium' }}>
            Secondary Position
          </LabelText>
          <BKJPillDropdown
            onChange={onChange}
            name="secondaryPositionsIds"
            value={values?.secondaryPositionsIds}
            options={secondaryPositionsDropdown}
            validations={[]}
            error={fieldErrors.secondaryPositionsIds}
            placeholder="Select Position(s)"
            max={20}
          />
        </Flex>

        <BKJComboBox
          label="Employment Status*"
          placeholder="Select Status"
          name="employmentStatus"
          value={[employStatus]}
          onChange={onChange}
          options={EmployeeStatus}
          type="Single"
          action="OpenToggle"
          filter="TimeStartsWith"
          error={fieldErrors?.employmentStatus}
        />

        <BKJTextInput
          label="Hourly Rate"
          onChange={onChange}
          value={values?.hourlyRate}
          name={'hourlyRate'}
          autoComplete="off"
        />

        <BKJTextInput
          label="Employment ID*"
          onChange={onChange}
          value={values?.employeeNumber}
          name={'employeeNumber'}
          autoComplete="off"
          error={fieldErrors?.employeeNumber}
        />
        <Flex flexDirection="column" gap="8px">
          <LabelText>Employee Start Date*</LabelText>
          <ShiftDateCalendarInput
            name="createdAt"
            value={values?.createdAt}
            onChange={onChange}
            width="100%"
            error={fieldErrors?.createdAt}
          />
        </Flex>
        <Flex gap="16px">
          <Flex flexDirection="column" width="100%">
            <LabelText>Birth Month*</LabelText>
            <BKJSelect
              placeholder="Select Month"
              name="birthMonth"
              options={Object.keys(monthToDaysMap).map((month, index) => ({
                key: index,
                id: index,
                value: month,
                label: month,
              }))}
              onChange={(e) => {
                handleChange(e);
                onChange(e);
              }}
              type="Single"
              variant="GreyOutlined"
              value={[`${values?.birthMonth}`]}
              renderOptions={RenderOptions}
              placeholderColor="TextDisabled"
              error={fieldErrors.birthMonth}
            />
          </Flex>
          <Flex flexDirection="column" width="100%">
            <LabelText>Birth Day*</LabelText>
            <BKJSelect
              placeholder="Day"
              name="birthDay"
              options={dayOptions}
              onChange={(e) => {
                onChange(e);
              }}
              type="Single"
              variant="GreyOutlined"
              value={[`${values?.birthDay}`]}
              renderOptions={RenderOptions}
              placeholderColor="TextDisabled"
              error={fieldErrors.birthDay}
            />
          </Flex>
        </Flex>
      </Flex>

      <BKJSideModalFooter>
        <Flex width="100%" justifyContent="end">
          <BKJButton
            variant="PurpleOutlined"
            width="140px"
            onClick={() => janeModalRoutingService.goToPrevView()}
            type="button"
          >
            cancel
          </BKJButton>
          <BKJButton
            variant="PurpleSolid"
            disabled={isDisabled}
            width="140px"
            onClick={onSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            type="button"
            style={{ marginLeft: '4%' }}
          >
            Invite
          </BKJButton>
        </Flex>
      </BKJSideModalFooter>
    </Flex>
  );
};

export const InviteTeamMemberDefaultFormComponent: FC = () => {
  const { isLoading } = useFetchJaneByIdDataContext();
  if (isLoading)
    return (
      <LoadingWrapper>
        <BKJLoadingSpinner variant="Default" />
      </LoadingWrapper>
    );
  return (
    <TeamMemberPageProvider>
      <InviteTeamMemberViewFormProvider>
        <Component />
      </InviteTeamMemberViewFormProvider>
    </TeamMemberPageProvider>
  );
};
export const InviteTeamMemberView: FC = () => {
  return <InviteTeamMemberDefaultFormComponent />;
};
