import {
  customFieldsPayloadConverter,
  endOfDay,
  ICustomFields,
  startOfDay,
} from 'components/TimeOffModal/TimeOffModal.constants';
import { createDateWithHourAndMinute } from 'components/TimeOffModal/TimeOffModalView.validation.constants';
import dayjs from 'utils/dayjs';
import {
  API_PUT_updateTimeOffParamsType,
  CustomFieldEntity,
} from 'requests/PUT_updateTimeOff.types';
import { fetchTimeOffCustomFields_fetchCustomFields_nodes } from 'types/graphql-types';
import { getHoursMinsSecFromDate } from 'utils/time';

export const updateTimeOffPayloadTransformer = (payload: {
  allDay: boolean;
  startDate: string[];
  endDate: string[];
  startTime: string[];
  endTime: string[];
  title: string[];
  notes: string;
  customFields: ICustomFields;
  timeOffCustomFields: fetchTimeOffCustomFields_fetchCustomFields_nodes[];
}): API_PUT_updateTimeOffParamsType => {
  const {
    allDay,
    startDate,
    endDate,
    startTime,
    endTime,
    title,
    notes,
    customFields,
    timeOffCustomFields,
  } = payload;

  const from = allDay
    ? createDateWithHourAndMinute(startDate, startOfDay)
    : createDateWithHourAndMinute(startDate, startTime);

  let to = allDay
    ? createDateWithHourAndMinute(endDate, endOfDay)
    : createDateWithHourAndMinute(endDate, endTime);

  if (getHoursMinsSecFromDate(to) === startOfDay[0]) to = dayjs(to).subtract(1, 's').format();

  const custom_fields = customFieldsPayloadConverter(
    customFields,
    timeOffCustomFields,
  ) as CustomFieldEntity[];

  return {
    time_off: {
      start_time: from,
      end_time: to,
      title: title[0],
      notes,
      custom_fields,
    },
  };
};
