import styled, { css } from 'styled-components';

export const CallOutHeader = styled.div`
  justify-content: flex-start;
`;

export const CallOutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CallOutViewDetailsLink = styled.div`
  ${({ theme }) => theme.typography.TextLink};
  cursor: pointer;
  color: #4e4785;
  justify-content: flex-end;
  flex-grow: 1;
  text-align: end;
  margin-top: auto;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: underline;
`;

export const CallOutTitleSection = styled.div`
  padding-bottom: 24px;
`;

export const CallOutBodySection = styled.div`
  padding: 8px 0 24px 0;
`;
export const ProgressStatusSection = styled.div`
  justify-content: flex-start;
  display: flex;
`;

export const EstimatedTimeSection = styled.div`
  justify-content: flex-end;
  text-align: end;
  flex-grow: 1;
  display: flex;
`;

export const BKJProgressBar = styled.progress<{ height?: string }>`
  width: 100%;
  ${({ height }) => {
    if (height)
      return css`
        height: ${height};
      `;
  }}

  -webkit-appearance: none;
  &::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.colors.Grey2};
  }
  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.SecondaryPendingHover};
  }
`;

export const CallOutProgressBarSection = styled.div`
  width: 100%;
`;

export const CallOutHeaderWrapper = styled.h4`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
`;

export const CallOutHeaderText = styled.div`
  ${({ theme }) => theme.typography.H4};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  display: flex;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
`;

export const CallOutHeaderIcon = styled.div`
  display: flex;
  margin-top: 4px;
`;

export const ProgressBold = styled.div`
  ${({ theme }) => theme.fonts.PoppinsTextBold};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 24px;
`;

export const ProgressText = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-size: 14px;
  line-height: 24px;
`;

export const Description = styled.div`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 24px 0 0 0;
`;

export const CallOutShiftDistributionLink = styled.a`
  ${({ theme }) => theme.fonts.PoppinsText};
  color: ${({ theme }) => theme.colors.TextDarkGrey};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
`;
