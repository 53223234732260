import { Flex } from '@bookjane2/bookjane-design-library';
import styled from 'styled-components';

export const BKJSideModalFooterWrapper = styled(Flex)`
  position: sticky;
  background-color: ${({ theme }) => theme.colors.White};
  bottom: 0px;
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  border-top: solid 1px #8b96a350;
  padding: 0 24px;
`;
