import { BKJToggleInput } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import { useFetchAvailableTeamMembersCountDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/OpenShift/FetchAvailableTeamMembersCount.data.context';
import { OpenPay } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/ShiftPay/OpenPay';
import {
  InfoSection,
  SendToAgenciesBoldText,
  SendToAgenciesText,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { UseFormBehaviorsEventType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPlural } from 'utils/formatPlural';
import { formatPossession } from 'utils/formatPossession';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';

function SummaryDetailsInfo() {
  const { t } = useTranslation();
  const { data: fetchOrderData } = useShiftModalDataContext();
  const { values } = useSendToAgenciesFormContext();
  const { data: availableTeamMembersCountData } = useFetchAvailableTeamMembersCountDataContext();

  return (
    <Fragment>
      {!!availableTeamMembersCountData?.fetchJanes?.totalCount && (
        <Fragment>
          {values.isDedicated ? (
            <SendToAgenciesText>
              {availableTeamMembersCountData?.fetchJanes?.totalCount}&nbsp;
              {t('dashboard:AVAILABLE_TEAM_MEMBERS_IN')}&nbsp;
              <SendToAgenciesBoldText>
                {`${formatPossession(fetchOrderData?.fetchOrder?.position?.name)} ${t(
                  'dashboard:TEAM',
                )}`}
              </SendToAgenciesBoldText>
            </SendToAgenciesText>
          ) : (
            <SendToAgenciesText>
              {`${availableTeamMembersCountData?.fetchJanes?.totalCount} ${t(
                'dashboard:AVAILABLE_AGENCY_MEMBERS',
              )}`}
            </SendToAgenciesText>
          )}
        </Fragment>
      )}

      <SendToAgenciesBoldText>
        {`${t('dashboard:SEND_OUT')} :`}&nbsp;
        {values.notification_strategy === 'now' && (
          <SendToAgenciesText>{t('dashboard:IMMEDIATELY')}</SendToAgenciesText>
        )}
        {values.notification_strategy === 'specific_time' && (
          <SendToAgenciesText>
            {`${formatPlural(
              'hour',
              Number.parseInt(values.specific_time_selection[0], 10),
              true,
            )} ${t('dashboard:BEFORE_SHIFT_STARTS')}`}
          </SendToAgenciesText>
        )}
      </SendToAgenciesBoldText>
    </Fragment>
  );
}

export function OpenShiftComponent() {
  const { t, i18n } = useTranslation();
  const { data } = useShiftModalDataContext();
  const { values, onChange: onChangeForm } = useSendToAgenciesFormContext();
  const { onChange: onChangeFetchAvailableTeamMembersCount } =
    useFetchAvailableTeamMembersCountDataContext();

  const onChange = (e: UseFormBehaviorsEventType) => {
    onChangeForm(e);
    onChangeFetchAvailableTeamMembersCount(e);
  };

  return (
    <Flex flexDirection="column" gap="32px">
      <Flex flexDirection="column" gap="8px">
        <Flex alignItems="center">
          <SendToAgenciesBoldText>{`${getTranslatedResponse(
            data?.fetchOrder?.status.name,
            'status',
            i18n,
            t,
          )}
          ${t('dashboard:SHIFT')}`}</SendToAgenciesBoldText>
        </Flex>

        <InfoSection flexDirection="column" alignContent="baseline" gap="14px">
          {data?.fetchOrder?.dedicatedPool ? (
            <Flex alignItems="center">
              <SendToAgenciesBoldText>{t('dashboard:SEND_TO')}: </SendToAgenciesBoldText>&nbsp;
              <SendToAgenciesText>{t('dashboard:ONLY_DEDICATED_TEAM_MEMBERS')}</SendToAgenciesText>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <BKJToggleInput
                variant="Switch"
                name="isDedicated"
                checked={values.isDedicated}
                onChange={onChange}
              />
            </Flex>
          ) : null}
          <SummaryDetailsInfo />
        </InfoSection>

        <Flex flexDirection="column" padding="0px 8px 0px 8px">
          <SendToAgenciesText>
            {t('dashboard:THIS_SHIFT_WILL_BECOME_AVAILABLE_TO')}&nbsp;
            <SendToAgenciesBoldText>
              {formatPlural(data?.fetchOrder?.position?.name)}
            </SendToAgenciesBoldText>
            &nbsp;{t('dashboard:IN_THE_CARE_NETWORK_TO_ACCEPT')}
          </SendToAgenciesText>
        </Flex>
      </Flex>
      <OpenPay />
    </Flex>
  );
}
