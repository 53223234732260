import i18next from 'i18next';
import dayjs from 'utils/dayjs';

export const formatDetailTimeOffStartTimeEndTime = (dateTime: string) => {
  const dayName = dayjs(dateTime).format('ddd');
  const translatedDayName = i18next.t(`translations:${dayName.toUpperCase()}`);

  const monthName = dayjs(dateTime).format('MMM');
  const translatedMonthName = i18next.t(`translations:${monthName.toUpperCase()}`);

  const dateYear = dayjs(dateTime).format('DD, YYYY');
  const timeLabel = dayjs(dateTime).format('h:mm a');

  const atLabel = i18next.t('common:AT');

  return `${translatedDayName}, ${translatedMonthName} ${dateYear} ${atLabel} ${timeLabel}`;
};
