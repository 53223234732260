import { ISO8601Type } from 'types/common.types';
import { shiftsListPage_fetchOrders_edges_node_orderSchedule } from 'types/graphql-types';
import dayjs from 'utils/dayjs';
import { convertTimeToHours, formatInterval } from 'utils/time';
import i18next from 'i18next';
export const calculateBreakTime = (order: shiftsListPage_fetchOrders_edges_node_orderSchedule) => {
  return formatInterval(convertTimeToHours(order?.breakTime) * 60);
};
export const calculateDuration = (totalDuration: number | null) => {
  return `${String(totalDuration)
    .split('.')
    .map((str, i) => {
      if (i === 0) return `${str} hrs`;
      if (i === 1) {
        if (str.length === 1) return `${(60 * Number.parseInt(`${str}0`, 10)) / 100} mins`;
        return `${(60 * Number.parseInt(str, 10)) / 100} mins`;
      }
      return '';
    })
    .join(' ')}`;
};

export const formatDateForShiftTableRow = (
  date: ISO8601Type,
  showDayOfWeek: boolean,
  showTime: boolean,
) => {
  // Parse the day of the week
  let day = '';
  switch (dayjs(date).day()) {
    case 0:
      day = i18next.t('SUN');
      break;
    case 1:
      day = i18next.t('MON');
      break;
    case 2:
      day = i18next.t('TUE');
      break;
    case 3:
      day = i18next.t('WED');
      break;
    case 4:
      day = i18next.t('THU');
      break;
    case 5:
      day = i18next.t('FRI');
      break;
    case 6:
      day = i18next.t('SAT');
      break;
  }
  const dateFormat = showTime ? `D, YYYY [${i18next.t('common:AT')}] h:mm a` : `D, YYYY`;
  const month = dayjs(date).format('MMM').toUpperCase();
  const TranslatedMonth = i18next.t(month);
  return showDayOfWeek
    ? `${day}, ${TranslatedMonth}, ${dayjs(date).format(dateFormat)}`
    : `${TranslatedMonth}, ${dayjs(date).format(dateFormat)}`;
};

export const getHoursAndMinutesFromDate = (date: ISO8601Type) => dayjs(date).format('hh:mm a');
