import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { TeamMembersModule } from '@bookjane2/phoenix/lib/modules/TeamMembersModule';
import { PageWrapper } from 'components/PageWrapper';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { withMemo } from 'utils/withMemo';

const LegacyTeamMembersPage: FC = withMemo(() => {
  return (
    <PageWrapper>
      <ErrorBoundary
        componentName="TeamMembersModule"
        showLogo={!WhiteLabelAccessor.isWhiteLabelled()}
      >
        <TeamMembersModule />
      </ErrorBoundary>
    </PageWrapper>
  );
});

export default LegacyTeamMembersPage;
