import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { BKJTableWrapper } from 'components/BKJTable/BKJTable.styled';
import {
  BoldText,
  Text,
} from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.styled';
import { Flex } from 'components/Flex';
import AuditTrailHeader from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailHeader';
import { AuditTrailTableView } from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailTableView';
import { FEATURE_SELECTORS_MAP } from 'guards/FeatureGuard.constants';
import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'hooks/useTranslation';
import { css } from 'styled-components';
import {
  AuditTrailDataProvider,
  useAuditTrailDataContext,
} from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.data.context';
import { GET_callOutListCSV } from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.constants';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { saveResponseAsCSV } from 'utils/saveResponseAsCSV';
import dayjs from 'utils/dayjs';
import { useShiftModalUIContext } from 'components/ShiftModal/ShiftModal.ui.context';
import { shiftModalOrderDetailsCalloutList_node_CalloutList } from 'types/graphql-types';

export function DetailViewFooter() {
  const { t } = useTranslation();
  const isReadOnly = useSelector(
    FEATURE_SELECTORS_MAP['FEATURE_TYPE_YMCA_READ_ONLY_SECONDARY_USER'],
  );

  const {
    data,
    estimatedTimeRemaining: { mins, hours, days },
  } = useAuditTrailDataContext();
  const { shift_id } = useShiftModalUIContext();

  const callout_list = `${data?.node?.id}`;

  const requestOptions = GET_callOutListCSV({
    callout_list,
    data: 'csv',
  });

  const { apiRequest } = useAPIActions();

  const exportCallOutListCSV = useCallback(() => {
    apiRequest(requestOptions, {
      onSuccess: (response) => {
        saveResponseAsCSV(
          response,
          `${t('dashboard:AUDIT_TRAIL_SHIFT_ID')} #${shift_id} - ${t(
            'dashboard:EXPORTED',
          )} ${dayjs().format('MMM D YYYY')}.csv`,
        );
      },
    });
  }, [apiRequest, requestOptions, shift_id, t]);

  if (isReadOnly || !data) return <Fragment />;

  const { totalNotifiedAndSkipped, totalJanes } =
    data.node as shiftModalOrderDetailsCalloutList_node_CalloutList;

  return (
    <BKJSideModalFooter>
      <Flex justifyContent="space-between" width="100%">
        <Flex flexDirection="column" width="100%">
          <Text>
            <BoldText>{t('dashboard:CALL_OUT_PROGRESS')}: </BoldText>
            {totalNotifiedAndSkipped}/{totalJanes}
          </Text>
          <Text>
            <BoldText>{t('dashboard:EST_TIME_REMAINING')}: </BoldText>
            {days > 0 && <span>{days}d&nbsp;</span>}
            {hours > 0 && <span>{hours}hr&nbsp;</span>}
            {mins > 0 && <span>{mins}m&nbsp;</span>}
            {days <= 0 && hours <= 0 && mins <= 0 && <span>-</span>}
          </Text>
        </Flex>
        <Flex width="100%" justifyContent="flex-end">
          <BKJButton
            width="180px"
            variant="PurpleOutlined"
            css={css`
              margin-right: 8px;
            `}
            onClick={exportCallOutListCSV}
          >
            {t('common:EXPORT_CSV')}
          </BKJButton>
        </Flex>
      </Flex>
    </BKJSideModalFooter>
  );
}
export const AuditTrailTabView = () => {
  return (
    <AuditTrailDataProvider>
      <BKJTableWrapper>
        <Flex flexDirection="column">
          <AuditTrailHeader />
          <AuditTrailTableView />
        </Flex>
      </BKJTableWrapper>
      <DetailViewFooter />
    </AuditTrailDataProvider>
  );
};
