import {
  CustomFieldInfoFormSchema,
  CustomFieldType,
} from 'components/CustomFieldModal/CustomFieldModal.types';
import { UseFormBehaviors_ValidationFnType } from 'hooks/useFormBehaviors/useFormBehaviors.types';

export const VALIDATION_placeholder: UseFormBehaviors_ValidationFnType<CustomFieldInfoFormSchema> =
  (value, rest): string => {
    const fieldName = value as string;
    if (
      !(['Single-Line Text', 'Paragraph Text'] as CustomFieldType[]).includes(
        rest!.type[0] as CustomFieldType,
      )
    )
      return '';
    if (fieldName.length > 50) return 'You’ve reached max of 50 characters';
    return '';
  };
