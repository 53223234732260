import styled from 'styled-components';

export const NavigationButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  min-height: 64px;
  max-height: 64px;
  height: 64px;
  background-color: ${({ theme }) => theme.colors.Grey3};
`;
