import {
  AGENCY_SECONDARY_USERS,
  CUSTOMER_COMMUNITIES,
  CUSTOMER_POSITIONS,
  CUSTOMER_SECONDARY_USERS,
  SCHEDULING_DRAFTS_ASSIGNED,
  SCHEDULING_DRAFTS_UNASSIGNED,
  SCHEDULING_DRAFT_SHIFTS,
  SCHEDULING_DRAFT_SHIFTS_W_SHIFT_ID,
  SCHEDULING_DRAFT_SHIFTS_CLEAR,
  SCHEDULING_HOURS,
  SCHEDULING_JANES,
  SCHEDULING_PUBLISHED_SHIFTS,
  SCHEDULING_SETTINGS,
  SCHEDULING_POSITION_SETTINGS,
  SCHEDULING_SUMMARY,
  SCHEDULING_PUBLISH_SHIFTS,
  SCHEDULING_TEMPLATES,
  SCHEDULING_TEMPLATES_W_TEMPLATE_ID,
  SCHEDULING_TEMPLATES_W_TEMPLATE_ID_APPLY,
  SCHEDULING_PUBLISHED_SHIFT_DETAILS,
  SCHEDULING_TEMPLATES_CHECK_NAME,
  SCHEDULING_TEMPLATES_APPLIED,
  CUSTOMER_TEAMS,
  SCHEDULING_ACTIONABLE_JANES,
  SCHEDULING_META,
  INVITE_BY_SPREADSHEET,
  SEND_TO_AGENCIES,
  SCHEDULING_RELEASE_PUBLISH_SHIFT,
  SCHEDULING_CANCEL_PUBLISH_SHIFT,
  SCHEDULING_PUBLISHED_SHIFT_AVAILABILITY,
} from 'constants/endpoints';
import assertKeys from 'utils/assertKeys';
import GET_schedulingDraftShiftsAssignedMapper from 'mappers/scheduling/GET_draftShiftsAssignedMapper';
import GET_schedulingDraftShiftsUnassignedMapper from 'mappers/scheduling/GET_draftShiftsUnassignedMapper';
import GET_publishedShiftsMapper from 'mappers/scheduling/GET_publishedShiftsMapper';
import GET_schedulingHourMapper from 'mappers/scheduling/GET_schedulingHourMapper';
import GET_schedulingSummaryMapper from 'mappers/scheduling/GET_schedulingSummaryMapper';
import GET_customerPositionsMapper from 'mappers/scheduling/GET_customerPositionsMapper';
import { SCHEDULE_SEARCH_MEMBER_JANES_PER_PAGE } from 'pages/SchedulePage/ScheduleFiltersAndTemplatesPanel/ScheduleSearchMember/ScheduleSearchMember.constants';
import { API_NAMESPACE_SCHEDULING_SEARCH_MEMBERS } from 'constants/apiNamespaces';
import { CONFLICTS_AND_WARNINGS_TAB } from 'pages/SchedulePage/ScheduleTabNavigation/ConflictsAndWarningsTab';
import { SEARCHED_MEMBERS_TAB } from 'pages/SchedulePage/ScheduleTabNavigation/SearchedMembersTab';

const GET = 'GET';
const POST = 'POST';
const PUT = 'PUT';
const DELETE = 'DELETE';
const PATCH = 'PATCH';

export const API_GET_customerCommunitiesV2 = {
  method: GET,
  url: CUSTOMER_COMMUNITIES,
};

export const API_GET_customerPositionsV2 = {
  method: GET,
  url: CUSTOMER_POSITIONS,
  responseTransformer: GET_customerPositionsMapper,
};

export const API_GET_schedulingSettings = ({ facility_id }) => ({
  method: GET,
  url: SCHEDULING_SETTINGS(facility_id),
});

export const API_GET_schedulingPositionSettings = ({ facility_id }) => ({
  method: GET,
  url: SCHEDULING_POSITION_SETTINGS(facility_id),
});

export const API_GET_scheduleSummary = (params) => {
  const { namespace, positionCodes, janeIds, facilityId, startTime, endTime } = params;

  assertKeys(params, ['facilityId', 'startTime', 'endTime']);

  const query = {
    filter: {
      with_facility: {
        id: facilityId,
        type: 'Community',
      },
      with_positions_or_janes: {
        position_codes: positionCodes,
        jane_ids: janeIds,
      },
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
    },
  };

  if (positionCodes) query.filter.with_positions_or_janes.position_codes = positionCodes;
  if (janeIds) query.filter.with_positions_or_janes.jane_ids = janeIds;

  return {
    namespace,
    url: SCHEDULING_SUMMARY,
    method: GET,
    query,
    responseTransformer: GET_schedulingSummaryMapper,
  };
};

export const API_GET_scheduleHours = (params) => {
  const {
    positionCode,
    facilityId,
    startTime,
    endTime,
    janeIds,
    statuses,
    namespace,
    employment_statuses,
    group_ids,
  } = params;
  assertKeys(params, ['facilityId', 'positionCode', 'startTime', 'endTime']);

  const query = {
    current_facility_id: facilityId,
    current_facility_type: 'Community',
    filter_shifts: {
      with_states: statuses,
      with_employment_statuses_or_unassigned: employment_statuses,
      with_group_ids: group_ids,
      with_positions_or_janes: {
        jane_ids: janeIds,
      },
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
    },
    current_position_code: positionCode,
  };

  return {
    url: SCHEDULING_HOURS,
    method: GET,
    query,
    responseTransformer: GET_schedulingHourMapper,
    namespace,
  };
};

export const API_PATCH_schedulingSettings = (params) => {
  const { schedulingSettings, selectedFacilities } = params;
  const body = {
    scheduling_settings: {
      ...schedulingSettings,
      unpaid_breaks_attributes: schedulingSettings.unpaid_breaks,
    },
  };

  delete body.scheduling_settings.unpaid_breaks;

  if (selectedFacilities.length > 1)
    body.scheduling_settings.apply_to = [...selectedFacilities].filter((facilityId) => {
      return facilityId !== schedulingSettings.facility.id.toString();
    });

  return {
    method: PATCH,
    url: SCHEDULING_SETTINGS(schedulingSettings.facility.id),
    body,
  };
};

export const API_GET_schedulingJanes = (params) => {
  const {
    facilityId,
    positionCode,
    per_page,
    page,
    sort_by,
    jane_ids,
    employment_statuses,
    group_ids,
    no_pagination,
    by_name,
    namespace,
  } = params;

  assertKeys(params, ['facilityId', 'sort_by']);
  const query = {
    filter: {
      with_facility: {
        type: 'Community',
        id: facilityId,
      },
      with_employment_statuses: employment_statuses,
      with_group_ids: group_ids,
      by_name,
      with_position_code: positionCode,
      with_jane_ids: jane_ids,
    },
    sort_by,
  };

  if (no_pagination) query.no_pagination = no_pagination;
  if (per_page) query.per_page = per_page;
  if (page) query.page = page;

  return {
    url: SCHEDULING_JANES,
    method: GET,
    query,
    namespace,
  };
};

export const API_GET_schedulingSearchMembers = (params) => {
  const { page, by_name, facilityId } = params;

  assertKeys(params, ['page', 'by_name', 'facilityId']);
  const query = {
    filter: {
      by_name,
      with_facility: {
        id: facilityId,
        type: 'Community',
      },
    },
    per_page: SCHEDULE_SEARCH_MEMBER_JANES_PER_PAGE,
    page,
  };

  return {
    url: SCHEDULING_JANES,
    method: GET,
    query,
    namespace: API_NAMESPACE_SCHEDULING_SEARCH_MEMBERS,
  };
};

export const API_GET_searchSchedulingJanes = (params) => {
  const { facilityId, positionCode, per_page, page, sort_by, by_name } = params;

  assertKeys(params, ['facilityId']);
  const query = {
    filter: {
      with_facility: {
        type: 'Community',
        id: facilityId,
      },
    },
    sort_by,
  };

  if (per_page) query.per_page = per_page;
  if (page) query.page = page;
  if (sort_by) query.sort_by = sort_by;
  if (positionCode) query.filter.with_position_code = positionCode;
  if (by_name) query.filter.by_name = by_name;

  return {
    url: SCHEDULING_JANES,
    method: GET,
    query,
  };
};

export const API_GET_draftShiftsAssigned = (params) => {
  const { positionCode, facilityId, startTime, endTime } = params;
  assertKeys(params, ['facilityId', 'positionCode', 'startTime', 'endTime']);
  const query = {
    include_conflicts_and_warnings: true,
    filter: {
      with_facility: {
        id: facilityId,
        type: 'Community',
      },
      with_position_code: positionCode,
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
    },
  };
  return {
    url: SCHEDULING_DRAFTS_ASSIGNED,
    method: GET,
    query,
    responseTransformer: GET_schedulingDraftShiftsAssignedMapper,
  };
};

export const API_GET_draftShiftsUnassigned = (params) => {
  const { positionCode, facilityId, startTime, endTime } = params;

  assertKeys(params, ['facilityId', 'positionCode', 'startTime', 'endTime']);
  const query = {
    filter: {
      with_facility: {
        id: facilityId,
        type: 'Community',
      },
      with_position_code: positionCode,
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
    },
  };
  return {
    url: SCHEDULING_DRAFTS_UNASSIGNED,
    method: GET,
    query,
    responseTransformer: GET_schedulingDraftShiftsUnassignedMapper,
  };
};

export const API_POST_draftShifts = (params) => {
  const {
    start_time,
    end_time,
    position_code,
    facility_id,
    facility_type,
    allow_overtime,
    unpaid_break_time,
    description,
    jane_ids: janeIds = [],
    custom_fields,
  } = params;

  assertKeys(params, [
    'start_time',
    'end_time',
    'position_code',
    'facility_id',
    'facility_type',
    'allow_overtime',
    'unpaid_break_time',
    'description',
  ]);

  const body = {
    draft_shift: {
      start_time,
      end_time,
      position_code,
      facility_id,
      facility_type,
      allow_overtime,
      unpaid_break_time,
      description,
      quantity: 1,
      jane_ids: janeIds,
      custom_fields,
    },
  };
  return {
    url: SCHEDULING_DRAFT_SHIFTS,
    method: POST,
    body,
  };
};

export const API_PATCH_draftShifts = (params) => {
  const {
    id,
    start_time,
    end_time,
    position_code,
    facility_id,
    facility_type,
    allow_overtime,
    unpaid_break_time,
    description,
    jane_ids: janeIds = [],
    custom_fields,
  } = params;

  assertKeys(params, [
    'id',
    'start_time',
    'end_time',
    'position_code',
    'facility_id',
    'facility_type',
    'allow_overtime',
    'unpaid_break_time',
    'description',
  ]);

  const body = {
    draft_shift: {
      start_time,
      end_time,
      position_code,
      facility_id,
      facility_type,
      allow_overtime,
      unpaid_break_time,
      description,
      quantity: 1,
      jane_ids: janeIds,
      custom_fields,
    },
  };
  return {
    url: SCHEDULING_DRAFT_SHIFTS_W_SHIFT_ID(id),
    method: PATCH,
    body,
  };
};

export const API_DELETE_draftShift = (params) => {
  assertKeys(params, ['shift_id']);
  return {
    url: SCHEDULING_DRAFT_SHIFTS_W_SHIFT_ID(params.shift_id),
    method: DELETE,
  };
};

export const API_PUT_agencySecondaryAdmins = (params) => {
  const { id } = params;
  return {
    method: PUT,
    url: `${AGENCY_SECONDARY_USERS}/${id}`,
    body: {
      agency_user: params,
    },
  };
};

export const API_POST_agencySecondaryAdmins = (params) => {
  return {
    method: POST,
    url: `${AGENCY_SECONDARY_USERS}`,
    body: {
      agency_user: params,
    },
  };
};

export const API_GET_agencySecondaryAdmins = {
  method: GET,
  url: AGENCY_SECONDARY_USERS,
};

export const API_PUT_customerSecondaryAdmins = (params) => {
  const { id } = params;
  return {
    method: PUT,
    url: `${CUSTOMER_SECONDARY_USERS}/${id}`,
    body: {
      secondary_user: params,
    },
  };
};

export const API_POST_customerSecondaryAdmins = (params) => {
  return {
    method: POST,
    url: `${CUSTOMER_SECONDARY_USERS}/invite`,
    body: {
      secondary_user: params,
    },
  };
};

export const API_GET_customerSecondaryAdmins = {
  method: GET,
  url: CUSTOMER_SECONDARY_USERS,
};

export const API_GET_publishedShifts = (params) => {
  const {
    positionCode,
    facilityId,
    startTime,
    endTime,
    statuses,
    isShowAgencyShiftsOnly,
    current_position_code,
  } = params;
  assertKeys(params, ['facilityId', 'positionCode', 'startTime', 'endTime']);
  const query = {
    current_facility_id: facilityId,
    current_facility_type: 'Community',
    filter_shifts: {
      with_position_code: positionCode,
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
      with_states: statuses,
      sent_to_agencies: isShowAgencyShiftsOnly,
    },
    current_position_code: [CONFLICTS_AND_WARNINGS_TAB, SEARCHED_MEMBERS_TAB].includes(
      current_position_code,
    )
      ? undefined
      : current_position_code,
  };
  return {
    url: SCHEDULING_PUBLISHED_SHIFTS,
    method: GET,
    query,
    responseTransformer: GET_publishedShiftsMapper,
  };
};

export const API_POST_publishedShifts = (params) => {
  const { positionCodes = [], janeIds = [], facilityId, startTime, endTime } = params;

  assertKeys(params, ['facilityId', 'startTime', 'endTime']);

  const body = {
    publish: {
      with_facility: {
        id: facilityId,
        type: 'Community',
      },
      with_positions_or_janes: {
        position_codes: positionCodes,
        jane_ids: janeIds,
      },
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
    },
  };

  return {
    url: SCHEDULING_PUBLISH_SHIFTS,
    method: POST,
    body,
  };
};

export const API_PUT_publishedShifts = (params) => {
  const {
    description = '',
    shift_id,
    notification_method = '',
    reaccept_or_decline_threshold = 1,
    start_time,
    end_time,
    unpaid_break_time,
    allow_overtime,
    isOnlyNotesUpdated = false,
  } = params;

  const body = {
    order: {
      description,
      notification_method,
      reaccept_or_decline_threshold,
      start_time,
      end_time,
      allow_overtime,
      unpaid_break_time,
    },
  };

  if (isOnlyNotesUpdated) {
    delete body.order.reaccept_or_decline_threshold;
    delete body.order.notification_method;
    delete body.order.start_time;
    delete body.order.end_time;
    delete body.order.unpaid_break_time;
    delete body.order.allow_overtime;
  } else if (!notification_method) {
    delete body.order.reaccept_or_decline_threshold;
    body.order.notification_method = 'no_notification';
  } else if (notification_method !== 'reaccept_or_decline')
    delete body.order.reaccept_or_decline_threshold;

  return {
    url: SCHEDULING_PUBLISHED_SHIFT_DETAILS(shift_id),
    method: PUT,
    body,
  };
};

export const API_GET_publishShiftDetails = (params) => {
  assertKeys(params, ['shift_id']);
  const { shift_id, facility_id, namespace } = params;
  const opts = {
    url: SCHEDULING_PUBLISHED_SHIFT_DETAILS(shift_id),
    method: GET,
    namespace,
  };

  if (facility_id)
    opts.query = {
      current_facility_id: facility_id,
      current_facility_type: 'Community',
    };

  return opts;
};

export const API_GET_publishedShiftTeamMemberAvailability = ({
  shift_id,
  data = '',
  namespace,
}) => {
  if (!shift_id)
    throw new Error('API_GET_publishedShiftTeamMemberAvailability: shift_id was undefined');

  const query = {};

  if (data) query.data = data;

  return {
    url: SCHEDULING_PUBLISHED_SHIFT_AVAILABILITY(shift_id),
    method: GET,
    namespace,
    query,
  };
};

export const API_POST_releasePublishedShifts = (params) => {
  const { reason_message = '', reason_category = '', shift_id } = params;

  assertKeys(params, ['shift_id', 'reason_message', 'reason_category']);

  const body = {
    release: {
      reason_message,
      reason_category,
    },
  };

  if (reason_category !== 'other') delete body.release.reason_message;

  return {
    url: SCHEDULING_RELEASE_PUBLISH_SHIFT(shift_id),
    method: POST,
    body,
  };
};

export const API_POST_cancelPublishedShifts = (params) => {
  const { reason_message = '', reason_category = '', cancel_recurring = false, shift_id } = params;

  assertKeys(params, ['shift_id', 'reason_category']);

  const body = {
    cancel: {
      reason_message,
      reason_category,
      cancel_recurring,
    },
  };

  if (reason_category !== 'other') delete body.cancel.reason_message;

  return {
    url: SCHEDULING_CANCEL_PUBLISH_SHIFT(shift_id),
    method: POST,
    body,
  };
};

export const API_GET_schedulingTemplates = (params) => {
  const { facilityId, positionCode, weekRanges } = params;
  assertKeys(params, ['facilityId', 'positionCode']);

  const query = {
    filter: {
      with_facility: {
        type: 'Community',
        id: facilityId,
      },
      with_number_of_weeks: weekRanges || [1, 2],
      with_position_code: positionCode,
    },
  };

  return {
    url: SCHEDULING_TEMPLATES,
    method: GET,
    query,
  };
};

export const API_GET_schedulingTemplatesCheckName = (params) => {
  const { name } = params;
  assertKeys(params, ['name']);

  const query = {
    name,
  };

  return {
    url: SCHEDULING_TEMPLATES_CHECK_NAME,
    method: GET,
    query,
  };
};

export const API_GET_schedulingTemplatesApplied = (params) => {
  const { facilityId, positionCode, startTime, endTime } = params;
  assertKeys(params, ['facilityId', 'positionCode', 'startTime', 'endTime']);

  const query = {
    filter: {
      with_facility: {
        type: 'Community',
        id: facilityId,
      },
      with_position_code: positionCode,
      with_scheduled_draft_shifts: {
        start_time: startTime,
        end_time: endTime,
      },
    },
  };

  return {
    url: SCHEDULING_TEMPLATES_APPLIED,
    method: GET,
    query,
  };
};

export const API_POST_schedulingTemplate = (params) => {
  const { name, shared, positionCode, facilityId, startTime, endTime } = params;

  assertKeys(params, ['name', 'shared', 'positionCode', 'facilityId', 'startTime', 'endTime']);

  const body = {
    template: {
      name,
      shared,
    },
    shift_filters: {
      with_position_code: positionCode,
      with_facility: {
        id: facilityId,
        type: 'Community',
      },
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
    },
  };

  return {
    url: SCHEDULING_TEMPLATES,
    method: POST,
    body,
  };
};

export const API_POST_schedulingTemplate_apply = (params) => {
  const { templateId, startTime, endTime } = params;
  assertKeys(params, ['templateId', 'startTime', 'endTime']);

  const body = {
    for_schedule: {
      start_date: startTime,
      end_date: endTime,
    },
  };

  return {
    url: SCHEDULING_TEMPLATES_W_TEMPLATE_ID_APPLY(templateId),
    method: POST,
    body,
  };
};

export const API_PATCH_schedulingTemplate = (params) => {
  const { id, name } = params;

  assertKeys(params, ['id', 'name']);

  const body = {
    template: {
      name,
    },
  };

  return {
    url: SCHEDULING_TEMPLATES_W_TEMPLATE_ID(id),
    method: PATCH,
    body,
  };
};

export const API_DELETE_schedulingTemplate = (params) => {
  const { id } = params;
  assertKeys(params, ['id']);
  return {
    url: SCHEDULING_TEMPLATES_W_TEMPLATE_ID(id),
    method: DELETE,
  };
};

export const API_GET_availableTeamMembers = (params) => {
  const { page, per_page, by_name, order_id, sending_to_agencies, with_pool } = params;

  assertKeys(params, ['page', 'per_page', 'by_name', 'order_id']);

  const query = {
    page,
    per_page,
    order_id,
    filter: {
      with_pool,
      search_by_full_name: by_name,
      suitable_for_client: {
        sending_to_agencies,
      },
    },
  };

  return {
    method: GET,
    url: CUSTOMER_TEAMS,
    query,
    queryStringOpts: {
      arrayFormat: 'indices',
      parseArrays: 'false',
      encodeValuesOnly: true,
    },
  };
};

export const API_GET_actionableJanes = (params) => {
  const { namespace, facilityId, positionCodes, jane_ids, startTime, endTime } = params;
  assertKeys(params, ['facilityId']);
  const query = {
    filter_draft_shift: {
      with_facility: {
        type: 'Community',
        id: facilityId,
      },
      with_schedule: {
        start_time: startTime,
        end_time: endTime,
      },
      with_positions_or_janes: {
        jane_ids,
        position_codes: positionCodes,
      },
    },
  };
  return {
    namespace,
    method: GET,
    url: SCHEDULING_ACTIONABLE_JANES,
    query,
  };
};

export const API_GET_schedulingMeta = {
  method: GET,
  url: SCHEDULING_META,
};

export const API_POST_inviteBySpreadsheet = (params) => {
  const { formData } = params;
  assertKeys(params, ['formData']);
  return {
    url: INVITE_BY_SPREADSHEET,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: formData,
  };
};

export const API_DELETE_draftShiftsClear = (params) => {
  const { namespace, facilityId, positionCode, startTime, endTime, templateId } = params;

  assertKeys(params, ['facilityId']);

  const clear_draft_shifts = {
    with_facility: {
      id: facilityId,
      type: 'Community',
    },
  };

  if (positionCode) clear_draft_shifts.with_position_code = [positionCode];

  if (startTime && endTime) {
    clear_draft_shifts.with_schedule = {
      start_time: startTime,
      end_time: endTime,
    };
  }

  if (templateId >= 0) {
    clear_draft_shifts.from_template = [templateId];
  }

  return {
    namespace,
    url: SCHEDULING_DRAFT_SHIFTS_CLEAR,
    method: DELETE,
    body: { clear_draft_shifts },
  };
};
export const API_POST_sendToAgencies = (params) => {
  assertKeys(params, ['shift_id']);
  const payload = { ...params };
  delete payload.shift_id;
  return {
    method: POST,
    url: SEND_TO_AGENCIES(params.shift_id),
    query: payload,
  };
};
