import { useInviteTeamMembersByCSVModalDataContext } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.data.context';
import { AddEditRankingFormSchema } from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRanking.constants';
import { CUSTOMER_POSITIONS } from 'constants/endpoints';
import { useFormBehaviors } from 'hooks';
import {
  UseFormBehaviors,
  UseFormBehaviorsEventType,
  UseFormBehaviors_Options,
} from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { FC, createContext, useCallback, useContext, useMemo } from 'react';
import { toastService } from 'services';
import i18next from 'i18next';
import { API_POST_editRankingCSV } from 'requests/POST_EditRankingCSV';
import {
  inviteTeaMemberCSVModalRoutingService,
  useInviteTeamMembersByCSVModalUIContext,
} from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
import { CsvError } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.constants';
import { METHOD_GET } from 'requests';
import { saveResponseAsCSV } from 'utils/saveResponseAsCSV';

type TeamMemberEditRankingFormContextType = UseFormBehaviors<AddEditRankingFormSchema> & {
  onDownload: () => {};
};

const VALIDATION_position_id = (value: string[]) => {
  if (value.length === 0 || !value[0]) {
    return i18next.t('team_member:POSITION_IS_REQUIRED');
  }
  return '';
};

const VALIDATION_CSV = (file: File | undefined) => {
  if (!file) {
    return i18next.t('team_member:FILE_IS_REQUIRED');
  }
  const allowedFileTypes = ['csv']; // Add your allowed file types here
  const fileNameParts = file.name.split('.');
  const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  if (!allowedFileTypes.includes(fileExtension)) {
    return i18next.t('team_member:FILE_NOT_CORRECT_FORMAT_UPLOAD_CSV_FILE_AND_TRY_AGAIN');
  }
  return '';
};

const TeamMemberEditRankingInitialState = {
  csv: undefined,
  position_id: [''],
};

export const TeamMemberEditRankingFormContext = createContext<TeamMemberEditRankingFormContextType>(
  {} as TeamMemberEditRankingFormContextType,
);

export const TeamMemberEditRankingFormProvider: FC = ({ children }) => {
  const { apiRequest } = useAPIActions();
  const { location } = useInviteTeamMembersByCSVModalDataContext();
  const { goToViewError } = useInviteTeamMembersByCSVModalUIContext();
  const selectedCommunity = Number(location.id);
  const handleSubmit = async (values: AddEditRankingFormSchema) => {
    const SelectedPosition = values?.position_id[0];
    const formData = new FormData();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { csv } = values as any;
    formData.append('csv_file', csv);
    let collectedErrors: CsvError[] = [];
    apiRequest(API_POST_editRankingCSV({ formData, SelectedPosition, selectedCommunity }), {
      onSuccess: () => {
        toastService.success('Ranking successfully imported.');
        inviteTeaMemberCSVModalRoutingService.close();
      },
      onError: (error) => {
        const errors = error?.data;
        if (errors && Array.isArray(errors)) {
          errors.forEach((errorObj) => {
            collectedErrors.push(errorObj);
          });
        }
        goToViewError({ csvErrors: collectedErrors });
      },
    });
  };

  const addEditRankingRuleFormBehaviorsOptions: UseFormBehaviors_Options<AddEditRankingFormSchema> =
    {
      initialState: { ...TeamMemberEditRankingInitialState },
      isDirtyCheckEnabled: true,
      type: 'CREATE',
      onSubmit: handleSubmit,
      validations: {
        position_id: VALIDATION_position_id,
        csv: VALIDATION_CSV,
      },
    };

  const addEditRankingRuleForm = useFormBehaviors<AddEditRankingFormSchema>(
    addEditRankingRuleFormBehaviorsOptions,
  );
  const selectedPosition = addEditRankingRuleForm?.values?.position_id[0];
  const onDownload = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_GET,
          responseType: 'blob',
          url: `${CUSTOMER_POSITIONS}/${selectedPosition}/${selectedCommunity}/export_csv.csv`,
        },
        {
          onSuccess: (response) => {
            saveResponseAsCSV(response, `seniority_ranking-${Date.now()}.csv`);
          },
          onError: () => {
            toastService.error('Something went wrong.');
          },
        },
      ),
    [apiRequest, selectedCommunity, selectedPosition],
  );

  const value = useMemo(
    () => ({
      ...addEditRankingRuleForm,
      onChange: (e: UseFormBehaviorsEventType | UseFormBehaviorsEventType[]) => {
        if (Array.isArray(e)) {
          // If e is an array of events, iterate through them
          e.forEach(({ target: { value = new File([], ''), name = 'csv', ...rest } }) => {
            addEditRankingRuleForm.onChange({ target: { value, name, ...rest } });
          });
        } else {
          const {
            target: { value = new File([], ''), name = 'csv', ...rest },
          } = e;
          addEditRankingRuleForm.onChange({
            target: { name, value, ...rest },
          });
        }
      },
      onDownload,
      isDisabled: VALIDATION_position_id(addEditRankingRuleForm.values.position_id) !== '',
    }),
    [addEditRankingRuleForm, onDownload],
  );
  return (
    <TeamMemberEditRankingFormContext.Provider value={value}>
      {children}
    </TeamMemberEditRankingFormContext.Provider>
  );
};

export const useTeamMemberEditRankingFormContext = (): TeamMemberEditRankingFormContextType =>
  useContext(TeamMemberEditRankingFormContext);
