import { FC, memo } from 'react';
import { IBKJDatePickerProps } from './BKJDatePicker.types';
import {
  SingleMonthPicker,
  MonthRangePicker,
  WeekRangePicker,
  SingleDateRangePicker,
} from './components';
import { deepEquals } from 'utils/deepEquals';
import { BKJDatePickerContext } from './BKJDatePicker.context';

export const BKJDatePicker: FC<IBKJDatePickerProps> = memo(
  ({ type, value, name, onChange, variant }): JSX.Element | null => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const DatePickerComponentMap: any = {
      SingleDateRange: SingleDateRangePicker,
      WeekRange: WeekRangePicker,
      MonthRange: MonthRangePicker,
      SingleMonth: SingleMonthPicker,
    };

    const DatePickerComponent = DatePickerComponentMap[type];

    return (
      <BKJDatePickerContext.Provider
        value={{
          variant,
          type,
          name,
          value,
          onChange,
        }}
      >
        <DatePickerComponent />
      </BKJDatePickerContext.Provider>
    );
  },
  deepEquals,
);
