import { ChangeEventType } from '@bookjane2/bookjane-design-library/lib/common.types';
import { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_PATCH_approveTimeSheet, API_PATCH_noShowTimeSheet } from 'requests/PATCH_TimeSheet';
import { toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { apiDataSelector } from 'store/reducers/api/apiSelector';
import { createGQLDataContext } from 'utils/createDataContext';
import dayjs from 'utils/dayjs';
import { useTimeSheetsTabDataContext } from './TimesheetsTabData.context';
import { useTranslation } from 'hooks/useTranslation';

export const TimesheetsTabContext = createGQLDataContext({});

export const TimesheetsTabProvider: FC = ({ children }) => {
  const { data, fetch } = useTimeSheetsTabDataContext();
  const { apiRequest } = useAPIActions();
  const [approvalFormValues, setApprovalFormValues] = useState({
    startTime: [dayjs(data?.fetchTimeSheet?.timeSheet?.clockIn).format('HH:mm')],
    endTime: [dayjs(data?.fetchTimeSheet?.timeSheet?.clockOut).format('HH:mm')],
    unpaidBreak: [data?.fetchTimeSheet?.timeSheet?.unpaidBreakTime || '00:00'],
  });

  const { t } = useTranslation();

  const handleFormChange = (event: ChangeEventType) => {
    setApprovalFormValues({
      ...approvalFormValues,
      [event?.target.name]: event?.target.value,
    });
  };

  const requestOptions = () =>
    API_PATCH_approveTimeSheet({
      id: data!.fetchTimeSheet.timeSheet!.id,
      endTime: approvalFormValues.endTime[0],
      startTime: approvalFormValues.startTime[0],
      unpaidBreak: approvalFormValues.unpaidBreak[0],
    });

  const requestOptionsNoShow = () =>
    API_PATCH_noShowTimeSheet({
      id: data!.fetchTimeSheet.timeSheet!.id,
    });

  const onApprovalSubmit = async () => {
    apiRequest(requestOptions(), {
      onSuccess: async () => {
        toastService.success(t('success:TIMESHEET_APPROVED'));
        queryRefetchService.refetchDataQueryByKey('TIMESHEETS_CARD', data?.fetchTimeSheet.id!);
        window.scheduleUtils.forceDataUpdate();
        await fetch();
      },
      onError: () => {
        toastService.error(t('errors:ERROR_APPROVING_TIMESHEET'));
      },
    });
  };

  const onMarkAsNoShow = async () => {
    apiRequest(requestOptionsNoShow(), {
      onSuccess: async () => {
        toastService.success(t('success:TIMESHEET_NO_SHOW'));
        queryRefetchService.refetchDataQueryByKey('TIMESHEETS_CARD', data?.fetchTimeSheet.id!);
        window.scheduleUtils.forceDataUpdate();
        await fetch();
      },
      onError: () => {
        toastService.error(t('errors:ERROR_UPDATING_TIMESHEET'));
      },
    });
  };

  const request = useSelector((state) => apiDataSelector(state, requestOptions()));

  return (
    <TimesheetsTabContext.Provider
      value={{
        ...request,
        onApprovalSubmit,
        onMarkAsNoShow,
        values: approvalFormValues,
        onChange: handleFormChange,
      }}
    >
      {children}
    </TimesheetsTabContext.Provider>
  );
};

export const useTimesheetsTabFormContext = () => useContext(TimesheetsTabContext);
