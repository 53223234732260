import React, { FC } from 'react';
import { BKJButton } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { Flex } from 'components/Flex';

import {
  CSVBorderHeaderStyle,
  H3,
  TeamMemberCSVWrapper,
  LocationHeader,
} from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRankingView.styled';
// import {
//   TeamMemberEditRankingFormProvider,
//   // useTeamMemberEditRankingFormContext,
// } from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRanking.form.context';
import { UnSuccessTeamMemberTable } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/UnSuccessView/TeamMemberTable/UnSuccessTeamMemberTable';
import { inviteTeaMemberCSVModalRoutingService } from 'components/InviteTeamMembersByCSVModal/InviteTeamMembersByCSVModal.ui.context';
import { TeamMemberCSVViewFormProvider } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView.form.context';
export const Component: FC = () => {
  // const { onSubmit } = useTeamMemberEditRankingFormContext();

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      flexGrow={1}
      borderTop={CSVBorderHeaderStyle}
    >
      <Flex flexDirection="column" padding="32px 40px 15px" gap="32px">
        <H3>Import Unsuccessful </H3>
        <TeamMemberCSVWrapper>
          <LocationHeader>Please correct the following errors and upload again.</LocationHeader>
        </TeamMemberCSVWrapper>
      </Flex>
      <UnSuccessTeamMemberTable />

      <Flex height="100%"></Flex>
      <BKJSideModalFooter justifyContent="space-between" flexDirection="row-reverse">
        <BKJButton
          variant="PurpleSolid"
          width="180px"
          onClick={() => inviteTeaMemberCSVModalRoutingService.goToPrevView()}
          // disabled={isDisabled || isSubmitting}
          type="button"
        >
          Go Back
        </BKJButton>
      </BKJSideModalFooter>
    </Flex>
  );
};
export const UnSuccessView: FC = () => {
  return (
    <TeamMemberCSVViewFormProvider>
      <Component />
    </TeamMemberCSVViewFormProvider>
  );
};
