import { BKJButton, BKJTextInput, Flex } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { JaneGridSelect } from 'components/JaneGridSelect';
import { QuerySwitch } from 'components/QuerySwitch';
import { useAssignToAgencyTeamMemberFormContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/AssignToAgencyTeamMemberView.form.context';
import {
  FetchAgencyTeamMembersDataContext,
  useFetchAgencyTeamMembersDataContext,
} from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/FetchAgencyTeamMembers.data.context';
import {
  FetchAgencyTeamMembersCountDataProvider,
  useFetchAgencyTeamMembersCountDataContext,
} from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/contexts/FetchAgencyTeamMembersCount.data.context';
import { useSendToAgencyTeamMemberViewPropsContext } from 'components/ShiftModal/src/views/AssignToAgencyTeamMemberView/views/SelectAgencyTeamMemberView/SendToAgencyTeamMemberView.props.context';
import { RequestTeamMemberWrapper } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { MainTitle } from 'components/TimeOffModal/TimeOffModalView.styled';
import { TotalAvailableJanesText } from 'pages/CreateShiftsPage/BulkCreateShiftView/CreateShiftModal/src/views/CreateShiftModal.styled';
import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';

function RenderJanes() {
  const { onChange, values } = useAssignToAgencyTeamMemberFormContext();
  const { loadMore } = useFetchAgencyTeamMembersDataContext();
  const { t } = useTranslation();

  return (
    <JaneGridSelect
      edgeCaseType="AssignToTeamMember"
      paginationType="InfiniteScroll"
      type="Single"
      label={t('common:SELECT')}
      name="janeId"
      dataPath="requestJanes.nodes"
      value={[values.janeId]}
      context={FetchAgencyTeamMembersDataContext}
      onChange={onChange}
      loadMore={loadMore}
      pageInfoDataPath="requestJanes.pageInfo"
      errorText={t('errors:SELECT_AGENCY_TEAM_MEMBER_ERROR_MSG')}
    />
  );
}

const AssignToAgencyTeamMemberHeader: FC = () => {
  const { t } = useTranslation();
  const { data } = useFetchAgencyTeamMembersCountDataContext();

  return (
    <Flex justifyContent="space-between" alignItems="baseline">
      <MainTitle>{t('agency_dashboard:ASSIGN_TEAM_MEMBER')}</MainTitle>
      {data.totalCount && (
        <TotalAvailableJanesText>
          {data.totalCount} {t('common:AVAILABLE')}
        </TotalAvailableJanesText>
      )}
    </Flex>
  );
};

export const SelectAgencyTeamMemberViewComponent: FC = () => {
  const { t } = useTranslation();
  const { values: formValues, isDisabled } = useAssignToAgencyTeamMemberFormContext();
  const { values, onChange } = useFetchAgencyTeamMembersDataContext();
  const { goToSummaryView } = useSendToAgencyTeamMemberViewPropsContext();

  return (
    <Fragment>
      <Flex flexDirection="column" padding="40px 32px 32px 32px" gap="24px">
        <FetchAgencyTeamMembersCountDataProvider>
          <AssignToAgencyTeamMemberHeader />
        </FetchAgencyTeamMembersCountDataProvider>

        <BKJTextInput
          leftIconProps={{ iconName: 'Search' }}
          name="byFullName"
          placeholder={t('common:SEARCH_MEMBER')}
          value={values.byFullName}
          onChange={onChange}
        />
      </Flex>
      <RequestTeamMemberWrapper
        flexDirection="column"
        padding="24px 32px"
        flex="1"
        height="100%"
        width="100%"
      >
        <QuerySwitch component={RenderJanes} context={FetchAgencyTeamMembersDataContext} />
      </RequestTeamMemberWrapper>
      <BKJSideModalFooter justifyContent="space-between">
        <div>{`${t('common:SELECTED')}: ${formValues.janeId ? 1 : 0}/1`}</div>
        <BKJButton
          width="180px"
          variant="PurpleSolid"
          onClick={goToSummaryView}
          disabled={isDisabled}
        >
          {t('auth:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
};
