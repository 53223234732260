import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { ApiRequestConfigType } from 'requests/index.types';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import { Communities } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { API_GET_customerCommunitiesV2 } from 'requests/GET_customerCommunitiesV2';
import { API_GET_agencyCommunitiesV1 } from 'requests/GET_agencyCommunitiesV1';
import { useSelector } from 'react-redux';
import isUserAgencySelector from 'store/selectors/userSelectors/isUserAgencySelector';
import { useAuthRouteDataContext } from 'providers/AuthRouteDataProvider/AuthRouteDataProvider.context';
import { useSSFBehaviors } from 'hooks';
import { teamMemberPageConfig } from 'pages/TeamMemberPage/TeamMemberPage.constants';
import { isUserAgencyAndAgencyLocationFilterForcedDisabledSelector } from 'store/selectors/featureFlagSelectors';

type InviteTeamMembersByCSVModalType = {
  communities: Communities[];
  location: IBKJComboBoxAsyncOption;
};

const mapCommunity = (community: Communities) => {
  return {
    id: community.id,
    shift_email: community.shift_emails,
    name: community.name,
  };
};

export const InviteTeamMembersByCSVModalDataContext =
  createContext<InviteTeamMembersByCSVModalType>([] as unknown as InviteTeamMembersByCSVModalType);
export const InviteTeamMembersByCSVModalDataProvider: FC = ({ children }) => {
  const { locationFilter } = useAuthRouteDataContext();
  const isUserAgencyAndAgencyLocationFilterForcedDisabled = useSelector(
    isUserAgencyAndAgencyLocationFilterForcedDisabledSelector,
  );
  const [values] = useSSFBehaviors(
    teamMemberPageConfig({
      location: locationFilter?.initialValue,
      isUserAgencyAndAgencyLocationFilterForcedDisabled,
    }),
  );
  const value = values?.location;
  const [communityIDs, setCommunityIDs] = useState<Communities[]>([]);
  const { apiRequest } = useAPIActions();
  const isUserAgency = useSelector(isUserAgencySelector);
  const [isLoadingCommunities, setIsLoadingCommunities] = useState(false);

  const getCommunitiesRequestOpts = isUserAgency
    ? API_GET_agencyCommunitiesV1
    : API_GET_customerCommunitiesV2;

  const fetchCommunitiesData = useCallback(async () => {
    await apiRequest(getCommunitiesRequestOpts as ApiRequestConfigType, {
      onSuccess: (response) => {
        if (isUserAgency) {
          setCommunityIDs(response?.data.agency_communities.map(mapCommunity));
        } else {
          setCommunityIDs(response?.data.communities.map(mapCommunity));
        }
      },
      onError() {},
    });
  }, [apiRequest, getCommunitiesRequestOpts, isUserAgency]);

  useEffect(() => {
    if (!isLoadingCommunities) {
      setIsLoadingCommunities(true);
      fetchCommunitiesData();
    }
  }, [fetchCommunitiesData, isLoadingCommunities]);

  const contextValues = useMemo(() => {
    return { communities: communityIDs, location: value };
  }, [communityIDs, value]);

  return (
    <InviteTeamMembersByCSVModalDataContext.Provider value={contextValues}>
      {children}
    </InviteTeamMembersByCSVModalDataContext.Provider>
  );
};

export const useInviteTeamMembersByCSVModalDataContext = () =>
  useContext<InviteTeamMembersByCSVModalType>(InviteTeamMembersByCSVModalDataContext);
