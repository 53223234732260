import styled from 'styled-components';

export const Menu = styled.ul`
  width: 180px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.White};
  box-shadow: 0 0 2px 2px rgb(34 36 38 / 15%);
  margin: 0;
  padding: 5px;
  position: absolute;
  right: 6px;
  z-index: 100;
  top: 30px;
`;
export const MenuWrapper = styled.div`
  position: relative;
`;
export const MenuList = styled.li`
  height: 35px;
  list-style: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.Grey7};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.fonts.PoppinsText};
  cursor: pointer;
`;
