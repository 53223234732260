import { ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { BKJSideModal } from 'components/BKJSideModal/BKJSideModal';
import {
  JaneModalHeaderMap,
  janeModalRoutingService,
} from 'components/JaneModal/JaneModal.constants';
import { useJaneModalRoutingContext } from 'components/JaneModal/JaneModal.routing.context';
import { FC } from 'react';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

export const JaneModalTemplate: FC = ({ children }) => {
  const { isOpen, janeModalActiveModalView } = useJaneModalRoutingContext();

  return (
    <BKJSideModal
      data-testid="J360JaneModal"
      isOpen={isOpen}
      onClose={janeModalRoutingService.close}
      onBack={janeModalRoutingService.goToPrevView}
      headerComponent={JaneModalHeaderMap[janeModalActiveModalView]}
      showBackButton={janeModalRoutingService.isBackButtonVisible()}
    >
      <ErrorBoundary componentName="JaneModal" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
        {children}
      </ErrorBoundary>
    </BKJSideModal>
  );
};
