import { Fragment } from 'react';
import { BKJButton, BKJHorizontalRule, BKJTextInput } from '@bookjane2/bookjane-design-library';
import backgroundSVG from 'assets/background-left.svg';
import { AppLink } from 'components/AppLink/AppLink';
import { PageWrapper } from 'components/PageWrapper';
import { PublicPageContentWrapper } from 'components/PublicPageContentWrapper';
import { useFormBehaviors } from 'hooks';
import {
  BelowLoginForm,
  FormBody,
  FormSection,
  FormTitle,
  JaneImageWrapper,
  LoginFlex,
  LoginPaperContainer,
  LogoWrapper,
} from 'pages/LoginPage/LoginPage.styled';
import { useTranslation } from 'hooks/useTranslation';
import { useUserActions } from 'store/reducers/auth/authReducer';
import { css } from 'styled-components';
import { VALIDATION_email, VALIDATION_password } from 'validations';
import { WhiteLabelMainLogo } from 'components/WhiteLabelMainLogo';
import { WhiteLabelSplashLogo } from 'components/WhiteLabelSplashLogo';
import { authResponseHandler } from 'pages/LoginPage/LoginPage.constants';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';

const initialState = {
  username: '',
  password: '',
};

export interface LoginFormSchema {
  username: string;
  password: string;
}
interface APIErrors {
  type: string;
  message: string;
}

export function isResponseSuccessful(responseType: { type: string }) {
  const split = responseType?.type?.split('/');
  const actionStatus = split[split.length - 1];
  return actionStatus === 'fulfilled';
}

function LoginPage() {
  const { userLogin } = useUserActions();
  const { t } = useTranslation();
  const { isPetalProvider } = WhiteLabelAccessor;

  const { onSubmit, apiErrors, isSubmitting, isDisabled, onChange, values, fieldErrors } =
    useFormBehaviors<Required<LoginFormSchema>, APIErrors>({
      isDirtyCheckEnabled: true,
      initialState,
      validations: {
        username: VALIDATION_email,
        password: VALIDATION_password,
      },
      onSubmit: async ({ username, password }) => {
        const trimmedUsername = username.trim();
        const response = await userLogin({ username: trimmedUsername, password });
        return authResponseHandler(response);
      },
    });

  const translatedError =
    apiErrors?.type === 'NotFound' ? t('errors:NOT_FOUND') : apiErrors?.message;

  return (
    <PageWrapper background={backgroundSVG} $minWidth="100%" $maxWidth="100%" $width="100%">
      <LogoWrapper>
        <WhiteLabelMainLogo width="200px" />
      </LogoWrapper>
      <PublicPageContentWrapper gridTemplateColumns="12">
        <LoginPaperContainer gridColumn="2/12">
          <JaneImageWrapper>
            <WhiteLabelSplashLogo />
          </JaneImageWrapper>
          <LoginFlex justifyContent="center">
            <FormSection onSubmit={onSubmit}>
              <FormBody>
                <FormTitle>{t('auth:LOGIN')}</FormTitle>
                <BKJTextInput
                  label={t('auth:EMAIL')}
                  value={values.username}
                  placeholder={t('auth:ENTER_YOUR_EMAIL_ADDRESS')}
                  type="username"
                  name="username"
                  error={fieldErrors.username}
                  required
                  onChange={onChange}
                  autoComplete="off"
                  css={css`
                    margin: 28px 0;
                  `}
                />
                <BKJTextInput
                  label={t('auth:PASSWORD')}
                  placeholder={t('auth:ENTER_YOUR_PASSWORD')}
                  value={values.password}
                  error={fieldErrors.password}
                  type="password"
                  name="password"
                  required
                  onChange={onChange}
                  autoComplete="off"
                  css={css`
                    margin-bottom: 28px;
                  `}
                />
                {apiErrors && <span>{translatedError}</span>}
                <BKJButton
                  variant="PurpleSolid"
                  type="submit"
                  disabled={isDisabled}
                  isLoading={isSubmitting}
                  css={css`
                    margin-top: 28px;
                    width: 255px;
                  `}
                >
                  {t('auth:LOGIN')}
                </BKJButton>
              </FormBody>
              <BelowLoginForm>
                <AppLink to="/forgot">
                  <BKJButton width="255px" variant="PurpleNoDecorations" data-cy="forgot_password">
                    {t('auth:FORGOT_PASSWORD')}
                  </BKJButton>
                </AppLink>
                {!isPetalProvider && (
                  <Fragment>
                    <BKJHorizontalRule variant="Thin" />
                    {t('auth:NOT_REGISTER')}
                    <AppLink to="/register">
                      <BKJButton
                        variant="GreenSolid"
                        css={css`
                          margin-top: 28px;
                          width: 255px;
                        `}
                      >
                        {t('auth:SIGN_UP')}
                      </BKJButton>
                    </AppLink>
                  </Fragment>
                )}
              </BelowLoginForm>
            </FormSection>
          </LoginFlex>
        </LoginPaperContainer>
      </PublicPageContentWrapper>
    </PageWrapper>
  );
}

export default LoginPage;
