import styled from 'styled-components';

export const BKJTableBodyUnsuccess = styled.div`
  position: relative;
  display: grid;
  border-collapse: collapse;
  grid-template-columns: minmax(188px, 1fr) minmax(151px, 1fr) minmax(151px, 1fr);
`;
export const BKJTableWrapperUnsuccess = styled.div`
  /* overflow: overlay; */
  height: 100%;
  max-width: 100%;
  padding: 0px 40px;
`;
export const BKJTableRowInfoWrapperUnsuccess = styled.div`
  display: contents;

  background-color: ${({ theme }) => theme.colors.White};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.Grey1};
  }
`;
export const BKJTableRowWrapperUnsuccess = styled.div`
  display: contents;
  background-color: inherit;
`;

export const BKJTableDataUnsuccess = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  height: 72px;
  background-color: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Grey4};
`;

export const TextUnsuccess = styled.span`
  ${({ theme }) => theme.fonts.PoppinsText}
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.ErrorDefault};
`;
