import { BKJButton, BKJButtonIconTextInput } from '@bookjane2/bookjane-design-library';
import { BKJSideModalFooter } from 'components/BKJSideModalFooter';
import { BKJToggleInput } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { JaneGridSelect } from 'components/JaneGridSelect';
import { QuerySwitch } from 'components/QuerySwitch';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import {
  EstimatedTotalPayoutFooterText,
  InfoSection,
  RequestTeamMemberWrapper,
  SendToAgenciesBoldText,
  SendToAgenciesText,
  SendToAgenciesViewTitle,
  TotalAvailableJanesText,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesView.styled';
import { FC, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { AutoMargin } from 'components/AutoMargin';
import { RequestTeamMemberFilterBar } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberFilterBar';
import { PriceFilter } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/RequestTeamMemberView.styled';
import { useSendToAgenciesPropsContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.props.context';
import { useSendToAgenciesFormContext } from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgencies.form.context';
import {
  FetchTeamMembersDataContext,
  useFetchTeamMembersDataContext,
} from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/contexts/FetchTeamMembers.data.context';
import { useFetchMinMaxPricesByPositionIdDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/contexts/FetchMinMaxPricesByPositionId.data.context';
import { useFetchTeamMemberByJaneIdDataContext } from 'components/ShiftModal/src/views/SendToAgenciesView/RequestTeamMemberView/contexts/FetchTeamMemberByJaneId.data.context';
import { UseFormBehaviorsEventType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import {
  parsePrice,
  priceFormatter,
} from 'components/ShiftModal/src/views/SendToAgenciesView/SendToAgenciesSummaryView/SendToAgenciesSummaryView.constants';

function RenderJanes() {
  const { onChange: onChangeForm, values } = useSendToAgenciesFormContext();
  const { loadMore } = useFetchTeamMembersDataContext();
  const { onChange: onChangeFetchTeamMemberByJaneId } = useFetchTeamMemberByJaneIdDataContext();

  const onChange = (e: UseFormBehaviorsEventType) => {
    onChangeForm(e);
    onChangeFetchTeamMemberByJaneId(e);
  };

  return (
    <JaneGridSelect
      edgeCaseType="SendToAgencies"
      paginationType="InfiniteScroll"
      type="Single"
      name="jane_id"
      dataPath="fetchJanes.nodes"
      value={[values.jane_id]}
      context={FetchTeamMembersDataContext}
      onChange={onChange}
      loadMore={loadMore}
      pageInfoDataPath="fetchJanes.pageInfo"
    />
  );
}

function RenderDedicatedToggle() {
  const { t } = useTranslation();
  const { onChange: onChangeForm, values } = useSendToAgenciesFormContext();
  const { onChange } = useFetchTeamMembersDataContext();

  const toggleDedicated = useCallback(
    (onToggle) => {
      onChangeForm([onToggle, { target: { name: 'jane_id', value: '' } }]);
      onChange(onToggle);
    },
    [onChangeForm, onChange],
  );
  return (
    <InfoSection gap="8px" alignItems="center">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <SendToAgenciesBoldText>
          {t('shifts_calendar:SEND_TO')}:&nbsp;
          <SendToAgenciesText>{t('dashboard:ONLY_DEDICATED_TEAM_MEMBERS')}</SendToAgenciesText>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </SendToAgenciesBoldText>
        <BKJToggleInput
          name="isDedicated"
          variant="Switch"
          checked={values.isDedicated}
          onChange={toggleDedicated}
        />
      </Flex>
    </InfoSection>
  );
}

const RenderSSFControls: FC = () => {
  const { t } = useTranslation();
  const { data } = useFetchMinMaxPricesByPositionIdDataContext();
  const { values, onChange } = useFetchTeamMembersDataContext();

  const { min, max } = data;

  const [isOpen, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <Flex flexDirection="column">
      <BKJButtonIconTextInput
        placeholder={t('common:SEARCH_MEMBER')}
        buttonLabel={t('common:FILTERS')}
        name="byFullName"
        iconName="Search"
        buttonIcon="Filters"
        value={values.byFullName}
        onChange={onChange}
        onButtonClick={handleClick}
        autoComplete="off"
        label=""
        required={false}
        error={''}
        disabled={false}
      />
      <RequestTeamMemberFilterBar isOpen={isOpen}>
        {/* Commenting out the Agency Filter. Scoped out for J360 V1. */}
        {/* <AgencyFilterInput
          dropdownVariant="Square"
          placeholder="Agency"
          type="Multiple"
          variant="GreyOutlinedPillClear"
          name="byAgency"
          onChange={onChange}
          value={values.byAgency}
        /> */}
        <PriceFilter
          priceRange={[min, max]}
          name="byRateLessThanOrEqualTo"
          value={values.byRateLessThanOrEqualTo}
          onChange={onChange}
        />
      </RequestTeamMemberFilterBar>
    </Flex>
  );
};

export function RequestTeamMemberViewComponent() {
  const { t } = useTranslation();
  const { data } = useShiftModalDataContext();
  const { values } = useSendToAgenciesFormContext();
  const { goToNextView } = useSendToAgenciesPropsContext();
  const { data: requestTeamMemberCountData } = useFetchTeamMembersDataContext();

  const { data: fetchTeamMemberByJaneIdData } = useFetchTeamMemberByJaneIdDataContext();

  const estimatedPayout =
    fetchTeamMemberByJaneIdData?.fetchJane.positions[0].agencyDetails?.openRate || '';

  const estimatedTotalPayout = estimatedPayout
    ? priceFormatter.format(data.fetchOrder!.totalDuration! * parsePrice(estimatedPayout))
    : '';

  return (
    <Fragment>
      <Flex
        flexDirection="column"
        padding="40px 32px 0px 32px"
        gap="24px"
        css="position: sticky; top: 0; background: white;"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <SendToAgenciesViewTitle css="margin-bottom: 0;">
            {t('shifts_calendar:REQUEST_AGENCY_MEMBER')}
          </SendToAgenciesViewTitle>
          {requestTeamMemberCountData && (
            <TotalAvailableJanesText>
              {`${requestTeamMemberCountData.fetchJanes.totalCount} ${t('common:AVAILABLE')}`}
            </TotalAvailableJanesText>
          )}
        </Flex>

        {data.fetchOrder!.dedicatedPool && <RenderDedicatedToggle />}

        <RenderSSFControls />
      </Flex>

      <RequestTeamMemberWrapper
        flexDirection="column"
        padding="24px 32px"
        flex="1"
        height="100%"
        width="100%"
      >
        <QuerySwitch component={RenderJanes} context={FetchTeamMembersDataContext} />
      </RequestTeamMemberWrapper>
      <BKJSideModalFooter>
        <EstimatedTotalPayoutFooterText disabled={!estimatedTotalPayout}>
          {`${t('shifts_calendar:ESTIMATED_PAYOUT')}: ${
            !!estimatedTotalPayout ? estimatedTotalPayout : '$0.00'
          }`}
        </EstimatedTotalPayoutFooterText>
        <AutoMargin />
        <BKJButton
          width="180px"
          variant="PurpleSolid"
          onClick={goToNextView}
          disabled={!values.jane_id}
        >
          {t('auth:NEXT')}
        </BKJButton>
      </BKJSideModalFooter>
    </Fragment>
  );
}
