import { Flex } from 'components/Flex';
import { useShiftModalDataContext } from 'components/ShiftModal/ShiftModalProvider.data.context';
import { ShiftTimeSelectInput } from 'components/ShiftTimeSelectInput';
import { ShiftUnpaidBreakTimeSelectInput } from 'components/ShiftUnpaidBreakTimeSelectInput';
import { formatCurrencyCAD } from 'format/formatCurrencyCAD';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import dayjs from 'utils/dayjs';
import { formatInterval, calculateShiftDurationInMinutes } from 'utils/time';
import { LabelText, ValueText } from '../DetailView.styled';
import { formatUnpaidBreakTime } from './TimesheetsTab.utils';
import { useTimeSheetsTabDataContext } from './TimesheetsTabData.context';
import { useTimesheetsTabFormContext } from './TimesheetsTabForm.context';

const marginLeft = 16;

export const TimesheetsTabPendingView: FC = () => {
  const { t } = useTranslation();
  const { onChange, values } = useTimesheetsTabFormContext();
  const { data } = useTimeSheetsTabDataContext();
  const { data: fetchOrderData } = useShiftModalDataContext();

  const totalPaidDurationInMinutes = useMemo(
    () =>
      calculateShiftDurationInMinutes({
        startDate: fetchOrderData.fetchOrder!.startDate,
        startAt: values.startTime,
        endAt: values.endTime,
        unpaidBreakTime: values.unpaidBreak,
        isPaidDuration: true,
      }),
    [fetchOrderData.fetchOrder, values.endTime, values.startTime, values.unpaidBreak],
  );

  const totalDurationInMinutes = useMemo(
    () =>
      calculateShiftDurationInMinutes({
        startDate: fetchOrderData.fetchOrder!.startDate,
        startAt: values.startTime,
        endAt: values.endTime,
        isPaidDuration: false,
      }),
    [fetchOrderData.fetchOrder, values.endTime, values.startTime],
  );

  return (
    <Flex flexDirection="column" childMarginBottom="32px">
      <Flex childMarginRight="16px">
        <Flex flexDirection="column" width="240px">
          {data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
            <ShiftTimeSelectInput
              label={t('schedule:START_TIME')}
              placeholder={t('schedule:START_TIME')}
              onChange={onChange}
              name="startTime"
              value={values.startTime}
            />
          ) : (
            <ValueText marginLeft={marginLeft}>
              {dayjs(data.fetchTimeSheet.startTime).format('h:mm a')}
            </ValueText>
          )}
        </Flex>
        <Flex flexDirection="column" width="240px">
          {data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
            <ShiftTimeSelectInput
              label={t('schedule:END_TIME')}
              placeholder={t('schedule:END_TIME')}
              onChange={onChange}
              name="endTime"
              value={values.endTime}
            />
          ) : (
            <ValueText marginLeft={marginLeft}>
              {dayjs(data.fetchTimeSheet.endTime).format('h:mm a')}
            </ValueText>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" width="240px">
        {data.fetchTimeSheet.timeSheet?.status.value === 'pending' ? (
          <ShiftUnpaidBreakTimeSelectInput
            label={t('schedule:UNPAID_BREAK')}
            width="100%"
            placeholder={t('schedule:UNPAID_BREAK')}
            onChange={onChange}
            name="unpaidBreak"
            value={values.unpaidBreak}
          />
        ) : (
          <Fragment>
            <LabelText>{t('schedule:UNPAID_BREAK')}</LabelText>
            <ValueText marginLeft={marginLeft}>
              {formatUnpaidBreakTime(data?.fetchTimeSheet?.timeSheet?.unpaidBreakTime as string)}
            </ValueText>
          </Fragment>
        )}
      </Flex>
      <Flex>
        {values.startTime && values.endTime && (
          <Flex flexDirection="column" width="240px">
            <LabelText>{t('common:TOTAL_DURATION')}</LabelText>
            <ValueText marginLeft={marginLeft}>{formatInterval(totalDurationInMinutes)}</ValueText>
          </Flex>
        )}
        {values.startTime && values.endTime && values.unpaidBreak && (
          <Flex flexDirection="column" width="240px">
            <LabelText>{t('common:TOTAL_PAID_DURATION')}</LabelText>
            <ValueText marginLeft={marginLeft}>
              {data.fetchTimeSheet.timeSheet?.approvedTotalTime?.time}
            </ValueText>
          </Flex>
        )}
      </Flex>
      <Flex>
        <Flex flexDirection="column" width="240px">
          <LabelText>{t('common:HOURLY_PAY_RATE')}</LabelText>
          <ValueText marginLeft={marginLeft}>
            {formatCurrencyCAD(
              data?.fetchTimeSheet?.timeSheet?.acceptedHourlyRate?.rawCurrency || 0,
            )}
          </ValueText>
        </Flex>
        {values.startTime && values.endTime && (
          <Flex flexDirection="column" width="240px">
            <LabelText>{t('common:TOTAL_PAY')}</LabelText>
            <ValueText marginLeft={marginLeft}>
              {formatCurrencyCAD(
                (totalPaidDurationInMinutes / 60) *
                  (data?.fetchTimeSheet?.timeSheet?.acceptedHourlyRate?.rawCurrency || 0),
              )}
            </ValueText>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
