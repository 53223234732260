import {
  BKJButton,
  BKJListItem,
  BKJPhoneNumberInput,
  BKJSelect,
  BKJTextInput,
} from '@bookjane2/bookjane-design-library';
import { BKJFieldSet, BKJForm } from 'components/FormWrapper';
import { Flex } from '@bookjane2/bookjane-design-library';
import { IBKJSelectRenderOptionsProps } from '@bookjane2/bookjane-design-library/lib/components/BKJSelect/BKJSelect.types';

import { FC, Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { H3 } from 'components/Typography';
import {
  GeneralPageFormAdministratorSettingsProvider,
  useGeneralPageFormAdministratorSettingsContext,
} from 'pages/SettingsPage/AdministratorSettingsPage/GeneralPageFormAdministratorSettings.form.context';
import { useParams } from 'react-router-dom';
import {
  AdministratorSettingsDataProvider,
  useAdministratorSettingsDataContext,
} from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import { UseFormBehaviorsEventType } from 'hooks/useFormBehaviors/useFormBehaviors.types';
export interface SettingsAdministratorPageMatchParams {
  id: string;
}
export const RenderOptions: FC<IBKJSelectRenderOptionsProps> = ({
  name,
  options,
  selected,
  onChange,
}) => {
  return (
    <Fragment>
      {options.map(({ key, label, value }) => {
        return (
          <BKJListItem
            name={name}
            selected={selected}
            key={key}
            label={label}
            value={value}
            onChange={onChange}
          />
        );
      })}
    </Fragment>
  );
};
const Component: FC = () => {
  const { t } = useTranslation();
  const { onSubmit, apiErrors, isSubmitting, fieldErrors, isDisabled, onChange, values } =
    useGeneralPageFormAdministratorSettingsContext();

  return (
    <Fragment>
      <BKJForm
        data-cy="formSettingsValidation"
        onSubmit={onSubmit}
        width="100%"
        maxWidth="100%"
        gap="22px"
      >
        <H3>{`${values.first_name} ${values.last_name}`}</H3>
        <Flex
          alignItems="flex-start"
          justifyContent="center"
          width="fit-content"
          padding="10px"
          style={{
            backgroundColor: '#E9EEF4',
            borderRadius: '6px',
          }}
        >
          {values.access_status[0] === 'approved'
            ? t('settings:APPROVED')
            : t('settings:PENDIND_INVITE')}
        </Flex>
        <BKJFieldSet width="121px" maxWidth="121px" gap="8px">
          <Flex gap="2px" style={{ fontSize: '14px', color: '#45424E' }}>
            {t('settings:STATUS')} <div style={{ color: '#AC1D36' }}>*</div>
          </Flex>
          <BKJSelect
            name="access_status"
            options={[
              {
                key: 'active',
                value: 'approved',
                label: t('Active'),
              },
              {
                key: 'inactive',
                value: 'not_approved',
                label: t('Inactive'),
              },
            ]}
            required
            variant="GreyOutlined"
            placeholder={values.access_status[0]}
            type="Single"
            renderOptions={RenderOptions}
            value={values.access_status}
            error={fieldErrors.access_status}
            onChange={(e: UseFormBehaviorsEventType | UseFormBehaviorsEventType[]) => onChange(e)}
          />
        </BKJFieldSet>

        <Flex alignItems="flex-start" gap="8px" width="100%">
          <BKJTextInput
            name="first_name"
            value={values.first_name}
            error={fieldErrors.first_name}
            onChange={onChange}
            label={t('settings:FIRST_NAME')}
            placeholder={t('settings:FIRST_NAME')}
          />
          <BKJTextInput
            name="last_name"
            value={values.last_name}
            error={fieldErrors.last_name}
            onChange={onChange}
            label={t('settings:LAST_NAME')}
            placeholder={t('settings:LAST_NAME')}
          />
        </Flex>

        <BKJTextInput
          // data-cy="txt-new-facilityname"
          label={t('settings:JOB_TITLE')}
          placeholder={t('settings:JOB_TITLE')}
          value={values.title ? values.title : ''}
          error={fieldErrors.title}
          name="title"
          onChange={onChange}
        />
        <BKJTextInput
          name="email"
          value={values.email}
          error={fieldErrors.email}
          type="email"
          onChange={onChange}
          label={t('settings:EMAIL')}
          placeholder={t('settings:EMAIL')}
        />
        <BKJPhoneNumberInput
          name="phone"
          value={values.phone ? values.phone : ''}
          error={fieldErrors.phone}
          onChange={onChange}
          label={t('settings:PHONE_NUMBER')}
          placeholder={t('settings:PHONE_NUMBER')}
        />

        {apiErrors && <span>{apiErrors.message}</span>}
        <BKJButton
          type="submit"
          variant="PurpleSolid"
          width="192px"
          disabled={isDisabled}
          isLoading={isSubmitting}
          data-cy="btn-save"
        >
          {t('auth:SAVE')}
        </BKJButton>
      </BKJForm>
    </Fragment>
  );
};
export const GeneralAdministratorSettingPageFormView: FC = () => {
  const data = useAdministratorSettingsDataContext();

  if (!data || !data?.secondary_users) return <Fragment />;
  return (
    <GeneralPageFormAdministratorSettingsProvider>
      <Component />
    </GeneralPageFormAdministratorSettingsProvider>
  );
};

export const GeneralAdministratorSettingPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <AdministratorSettingsDataProvider key={id}>
      <GeneralAdministratorSettingPageFormView />
    </AdministratorSettingsDataProvider>
  );
};
