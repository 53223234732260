import { WEB_VERSION } from 'config';
import dayjs from 'dayjs';
import { SessionService } from 'services/SessionService';
import {
  initialFacilityViewState,
  initialCopyParamsState,
  initialScheduleState,
  initialContextMenuState,
  initialCreateShiftState,
} from 'store/reducers/scheduleSlice/index.initialState';
import { CACHE_EXPIRY } from 'store/reducers/scheduleSlice/index.constants';

export function generateViewKey(facility_id) {
  return `view_${facility_id}`;
}

export function persistScheduleState(state) {
  const { id: user_id } = SessionService.getUser();

  const cachedState = {
    ...state,
    copyParams: initialCopyParamsState,
    contextMenu: initialContextMenuState,
    createShiftPopup: initialCreateShiftState,
  };
  if (!user_id) return state;
  localStorage.setItem(
    `${user_id}_SCHEDULE_STATE_${WEB_VERSION}`,
    JSON.stringify({
      state: cachedState,
      expiry: dayjs().add(CACHE_EXPIRY[0], CACHE_EXPIRY[1]).startOf('day').format(),
    }),
  );
  return state;
}

export function getCachedScheduleState(isOutputRawJSON = false) {
  const user = SessionService.getUser();

  if (!user) return undefined;
  const { id: user_id } = user;

  let isCacheValid = true;

  const cachedScheduleStates = Object.keys(localStorage).filter((key) =>
    key.includes('SCHEDULE_STATE'),
  );

  cachedScheduleStates.forEach((key) => {
    const user_key_from_key = key.split('_')[0];
    if (`${user_key_from_key}` !== `${user_id}`) {
      localStorage.removeItem(key);
      isCacheValid = false;
    }
  });

  const stringifiedState = localStorage.getItem(`${user_id}_SCHEDULE_STATE_${WEB_VERSION}`);

  if (stringifiedState && isOutputRawJSON)
    return [stringifiedState, `${user_id}_SCHEDULE_STATE_${WEB_VERSION}`];
  if (!stringifiedState) return undefined;

  const { state, expiry } = JSON.parse(stringifiedState);

  const now = dayjs();
  isCacheValid = now.isBefore(dayjs(expiry));

  if (!isCacheValid) {
    localStorage.removeItem(`${user_id}_SCHEDULE_STATE_${WEB_VERSION}`);
    return undefined;
  }
  return { ...state, copyParams: initialCopyParamsState };
}

export function getPrevFacilityViewState(state) {
  return {
    facilityViewState: state[generateViewKey(state.selectedFacilityId)] || initialFacilityViewState,
    selectedFacilityId: state.selectedFacilityId,
  };
}

export function getInitialScheduleState(cachedScheduleState = undefined) {
  const obj = JSON.parse(
    JSON.stringify({
      ...initialScheduleState,
      ...cachedScheduleState,
    }),
  );
  obj.shiftSideModal.isSideModalOpen = false;
  return cachedScheduleState ? obj : initialScheduleState;
}

/**
 * Function that merges prepared payload to previous facility view state and returns a new facility view state state.
 * @param {Object}  state       Schedule Slice State
 * @param {Object}  payload     Payload prepared by Action calling this Function
 *
 * @return {Object} Passes mutated State to persistScheduleState which stores the new State to LocalStorage and returns it
 */

export function mergePayloadWithFacilityViewState(state, { payload }, persist = true) {
  const { selectedFacilityId, facilityViewState } = getPrevFacilityViewState(state);
  const nextFacilityViewState = Object.entries(payload).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return persist
    ? persistScheduleState({
        ...state,
        [generateViewKey(selectedFacilityId)]: {
          ...facilityViewState,
          ...nextFacilityViewState,
        },
      })
    : {
        ...state,
        [generateViewKey(selectedFacilityId)]: {
          ...facilityViewState,
          ...nextFacilityViewState,
        },
      };
}

export function mergePayloadWithState(state, { payload }, persist = true) {
  return persist ? persistScheduleState({ ...state, ...payload }) : { ...state, ...payload };
}
