import {
  ChangeEventType,
  ChangeFunctionType,
  isBKJChangeEventType,
} from '@bookjane2/bookjane-design-library/lib/common.types';
import { BKJThemeButtonVariantType } from '@bookjane2/bookjane-design-library/lib/components/BKJButton/BKJButton.types';
import { B2B_JANES, AGENCY_JANES, RESEND_INVITE } from 'constants/endpoints';
import { useTranslation } from 'hooks/useTranslation';
import i18next from 'i18next';
import { isArray } from 'lodash';
import { useFetchTeamMemberByIdDataContext } from 'pages/TeamMemberPage/components/TeamMemberTable/TeamMemberTableRow/FetchTeamMemberById.data.context';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { METHOD_PATCH } from 'requests';
import { SessionService, toastService } from 'services';
import { queryRefetchService } from 'services/QueryRefetchService';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { AccessStatusEnum } from 'types/graphql-types';

export type AccessStatusButtonMap = Record<
  AccessStatusEnum,
  {
    variant: BKJThemeButtonVariantType;
    onClick: ChangeFunctionType;
    label: string | JSX.Element;
  } | null
>;

interface ITeamMemberTableRowProps {
  accessStatusButtonMap: AccessStatusButtonMap;
  showDeactivateConfirmModal: boolean;
  setShowDeactivateConfirmModal: (showDeactivateConfirmModal: boolean) => void;
  deactivateTeamMember: () => void;
}

export const TeamMemberTableRowPropsContext = createContext<ITeamMemberTableRowProps>(
  {} as ITeamMemberTableRowProps,
);

export const TeamMemberTableRowPropsProvider: FC = ({ children }) => {
  const [showDeactivateConfirmModal, setShowDeactivateConfirmModal] = useState(false);

  const { data, fetch } = useFetchTeamMemberByIdDataContext();

  const { t } = useTranslation();

  const { apiRequest } = useAPIActions();

  const deactivateTeamMember = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_PATCH,
          url: SessionService.assertUserType('Agency')
            ? AGENCY_JANES(data?.id)
            : B2B_JANES(data?.id),
          body: { access_status: 'blocked' },
        },
        {
          onSuccess: () => {
            toastService.success(t('success:TEAM_MEMBER_DEACTIVATED'));
            setShowDeactivateConfirmModal(false);
            fetch();
          },
        },
      ),
    [apiRequest, data?.id, fetch, t],
  );

  const reactivateTeamMember = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_PATCH,
          url: B2B_JANES(data?.id),
          body: { access_status: 'approved' },
        },
        {
          onSuccess: () => {
            toastService.success(t('success:TEAM_MEMBER_REACTIVATED'));
            fetch();
          },
        },
      ),
    [apiRequest, data?.id, t, fetch],
  );

  const resendInviteTeamMember = useCallback(
    () =>
      apiRequest(
        {
          __typename: 'LegacyAPI',
          method: METHOD_PATCH,
          url: RESEND_INVITE(data?.invitation?.id),
        },
        {
          onSuccess: () => {
            toastService.success(t('success:INVITE_SENT'));
            fetch();
          },
        },
      ),
    [apiRequest, data?.invitation?.id, fetch, t],
  );

  useEffect(() => {
    queryRefetchService.addDataQuery('TEAM_MEMBERS_SETTINGS_PAGE_TABLE_ROW', `${data?.id}`, fetch);

    return () =>
      queryRefetchService.removeDataQuery('TEAM_MEMBERS_SETTINGS_PAGE_TABLE_ROW', `${data?.id}`);
  }, [data?.id, fetch]);

  const contextValue = useMemo(
    () => ({
      accessStatusButtonMap: {
        approved: {
          variant: 'PurpleText' as BKJThemeButtonVariantType,
          onClick: (event: ChangeEventType) => {
            if (!isArray(event) && isBKJChangeEventType(event)) {
              event.stopPropagation();
              setShowDeactivateConfirmModal(true);
            }
          },
          label: i18next.t('team_member:DEACTIVATE'),
        },
        blocked: {
          variant: 'PurpleText' as BKJThemeButtonVariantType,
          onClick: (event: ChangeEventType) => {
            if (!isArray(event) && isBKJChangeEventType(event)) {
              event.stopPropagation();
              reactivateTeamMember();
            }
          },
          label: i18next.t('team_member:RE_ACTIVATE'),
        },
        incomplete: {
          variant: 'PurpleText' as BKJThemeButtonVariantType,
          onClick: (event: ChangeEventType) => {
            if (!isArray(event) && isBKJChangeEventType(event)) {
              event.stopPropagation();
              window.alert('TODO: Open "Edit team member modal"');
            }
          },
          label: i18next.t('team_member:COMPLETE_PROFILE'),
        },
        pending_signup: {
          variant: 'PurpleText' as BKJThemeButtonVariantType,
          onClick: (event: ChangeEventType) => {
            if (!isArray(event) && isBKJChangeEventType(event)) {
              event.stopPropagation();
              resendInviteTeamMember();
            }
          },
          label: i18next.t('team_member:RESEND_INVITE'),
        },
        on_hold: null,
        rejected: null,
        not_approved: null,
      },
      showDeactivateConfirmModal,
      setShowDeactivateConfirmModal,
      deactivateTeamMember,
    }),
    [
      deactivateTeamMember,
      reactivateTeamMember,
      resendInviteTeamMember,
      showDeactivateConfirmModal,
    ],
  );

  return (
    <TeamMemberTableRowPropsContext.Provider value={contextValue}>
      {children}
    </TeamMemberTableRowPropsContext.Provider>
  );
};

export const useTeamMemberTableRowPropsContext = () => useContext(TeamMemberTableRowPropsContext);
