import { BKJBadge, ErrorBoundary } from '@bookjane2/bookjane-design-library';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { Flex } from 'components/Flex';
import { IShiftJane } from 'components/ShiftModal/src/components/ShiftJane/ShiftJane.types';
import { UserGuard } from 'guards/UserGuard';
import { getShiftModalShiftJaneName } from 'mappers/shiftModalOrderDetailsMapper';
import { css } from 'styled-components';
import { WhiteLabelAccessor } from 'utils/whiteLabelAccessor';
import { Jane, JaneName, JaneFacility, ShiftJaneWrapper } from './ShiftJane.styled';

export function ShiftJane(props: IShiftJane) {
  const { data, className } = props;
  return (
    <ErrorBoundary componentName="ShiftJane" showLogo={!WhiteLabelAccessor.isWhiteLabelled()}>
      <ShiftJaneWrapper className={className}>
        <Jane justifyContent="space-between" alignItems="center" width="100%">
          <Flex alignItems="center">
            <JaneAvatar
              url={data.fetchOrder!.mainJane!.avatarUrls.small}
              status={data.fetchOrder!.status.value}
              css={css`
                margin-right: 13px;
              `}
              size={30}
              pendingSignup={data.fetchOrder!.mainJane!.pendingSignup}
            />
            <Flex flexDirection="column">
              <JaneName>{getShiftModalShiftJaneName(data)}</JaneName>
              <JaneFacility>{data.fetchOrder!.mainJane!.communityName}</JaneFacility>
            </Flex>
          </Flex>
          <UserGuard userType={['USER_TYPE_PREMIUM', 'USER_TYPE_PREMIUM_SECONDARY']}>
            {!!data.fetchOrder!.mainJane!.agencyCommunity && !data.fetchOrder!.sentToAgencies && (
              <BKJBadge variant="Grey">Agency</BKJBadge>
            )}
          </UserGuard>
        </Jane>
      </ShiftJaneWrapper>
    </ErrorBoundary>
  );
}
