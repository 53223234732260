import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { DashboardPageRecentlyCreatedShiftsPayloadTransformer as DRCSPT } from 'pages/DashboardRecentlyCreatedShiftsPage/DashboardRecentlyCreatedShiftsPage.types';
import { QUERY_dashboardRecentlyCreatedShiftsPage } from 'queries';
import { SessionService } from 'services';
import { OrderSortableFieldsEnum, SortDirectionEnum } from 'types/graphql-types';
import dayjs from 'utils/dayjs';

const dashboardRecentlyCreatedShiftsPayloadTransformer: DRCSPT = (filterValues) => {
  const variables = {
    currentPage: filterValues.currentPage,
    first: filterValues.first,
    last: filterValues.last,
    after: filterValues.after,
    before: filterValues.before,
    filter: {
      forOrders: {
        withCommunity: undefined as number | undefined,
        withStatus: filterValues.shiftStatus,
        withPositions: filterValues.position_id,
        withJaneType: [...filterValues.internalJaneType, ...filterValues.externalJaneType],
        createdBetween: {
          from: dayjs().subtract(1, 'months').format(),
        },
        withCalloutStatus: filterValues.callOutType,
      },
      forJanes: {
        byEmploymentStatus: filterValues.employmentStatus,
        byFullName: filterValues.byFullName,
      },
      forAgencyCommunities: {
        withId: undefined as number | undefined,
      },
    },
    groupBy: {
      field: OrderSortableFieldsEnum.createdAt,
      sort: SortDirectionEnum.desc,
    },
    sortOrders: [
      {
        field: OrderSortableFieldsEnum.createdAt,
        direction: SortDirectionEnum.desc,
      },
    ],
  };
  if (!filterValues.viewAllFacilities) {
    if (SessionService.assertUserType('B2B'))
      variables.filter.forOrders['withCommunity'] = Number(filterValues.location.value);

    if (SessionService.assertUserType('Agency'))
      variables.filter.forAgencyCommunities['withId'] = Number(filterValues.location.value);
  }
  return variables;
};

export const dashboardRecentlyCreatedShiftsPageConfig = ({
  location,
}: {
  location: ILocationFilterProps['value'];
}): ISSFBehaviorsOptions => {
  return {
    key: 'DASHBOARD_RECENTLY-CREATED-SHIFTS_PAGE',
    paginationType: 'InfiniteScroll',
    query: QUERY_dashboardRecentlyCreatedShiftsPage,
    pageSize: 20,
    payloadTransformer: dashboardRecentlyCreatedShiftsPayloadTransformer,
    nextFetchPolicy: 'network-only',
    schema: {
      position_id: {
        initialValue: [],
        isSessionCached: true,
      },
      shiftStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      location: {
        initialValue: location,
        isGlobalCached: true,
        isPreservedOnReset: true,
        resetFieldsOnChange: [
          {
            field: 'position_id',
            userTypes: ['Agency'],
          },
        ],
      },
      employmentStatus: {
        initialValue: [],
        isSessionCached: true,
      },
      byFullName: {
        initialValue: '',
        isSessionCached: true,
      },
      internalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      externalJaneType: {
        initialValue: [],
        isSessionCached: true,
      },
      callOutType: {
        initialValue: [],
        isSessionCached: true,
      },
      viewAllFacilities: {
        initialValue: false,
        isSessionCached: true,
        isGlobalCached: true,
        isPreservedOnReset: true,
      },
    },
  };
};
