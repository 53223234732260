import { FC, Fragment, useRef, useState, useMemo } from 'react';
import { BKJMenuItem } from '@bookjane2/bookjane-design-library';
import { RenderShiftActionsProps } from 'components/RenderShiftActions/RenderShiftActions.types';
import { ShiftActionDropdownMenu } from 'components/RenderShiftActions/RenderShiftActions.styled';
import { usePopper } from 'react-popper';
import { mergeRefs } from 'utils';

const RenderShiftActionsComponent: FC<RenderShiftActionsProps> = ({
  shiftActions,
  variant,
  SingleItemComponent,
}: RenderShiftActionsProps) => {
  const type = shiftActions.length === 1 ? 'Single' : shiftActions.length > 1 ? 'Multiple' : 'None';
  switch (type) {
    case 'Single':
      return (
        <SingleItemComponent
          onClick={(e) => {
            e.stopPropagation();
            shiftActions[0].action(e);
          }}
        >
          {shiftActions[0].label}
        </SingleItemComponent>
      );
    case 'Multiple':
      return (
        <ShiftActionDropdownMenu width="100%" variant={variant} label={shiftActions[0].label}>
          {shiftActions.map(({ action, label }, i) => {
            return (
              <BKJMenuItem borderRadius="20px 20px 0 0" width="200px" onClick={action} key={i}>
                {label}
              </BKJMenuItem>
            );
          })}
        </ShiftActionDropdownMenu>
      );
    default:
      return <Fragment />;
  }
};

export const RenderShiftActions: FC<RenderShiftActionsProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dropdownRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dropdownElement, setDropdownElement] = useState<any>();

  const { styles, attributes } = usePopper(dropdownElement, dropdownElement, {});

  const popperStyles = useMemo(
    () => ({
      ...styles['popper'],
      maxHeight: 'auto',
      height: 'auto',
      minHeight: 'auto',
    }),
    [styles],
  );

  const popperProps = useMemo(
    () => ({
      style: popperStyles,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(attributes['popper'] as any),
    }),
    [attributes, popperStyles],
  );

  return (
    <RenderShiftActionsComponent
      innerRef={mergeRefs([dropdownRef, setDropdownElement])}
      {...popperProps}
      {...props}
    />
  );
};
