import { shiftModalOrderDetails } from 'types/graphql-types';
import { ActivityTab } from 'components/ActivityTab/ActivityTab';
import { AuditTrailActivityTab } from 'components/AuditTrailActivityTab/AuditTrailActivityTab';
import { AvailableMembersTab } from 'components/ShiftModal/src/views/DetailView/AvailableMembersTab';
import { GeneralTab } from 'components/ShiftModal/src/views/DetailView/GeneralTab/GeneralTab';
import { TimesheetsTab } from 'components/ShiftModal/src/views/DetailView/TimesheetsTab';

export const DetailViewComponentMap = {
  GeneralTab,
  ActivityTab,
  AvailableMembersTab,
  TimesheetsTab,
  AuditTrailActivityTab,
};

export type DetailViewActiveTabType = keyof typeof DetailViewComponentMap;

export interface IDetailViewComponentProps {
  data: shiftModalOrderDetails;
  setActiveSubView: (activeSubView: DetailViewActiveTabType) => void;
  activeSubView: DetailViewActiveTabType;
}
