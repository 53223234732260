import { IBKJComboBoxOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBox/BKJComboBox.types';
import { IBKJComboBoxAsyncOption } from '@bookjane2/bookjane-design-library/lib/components/BKJComboBoxAsync/BKJComboBoxAsync.types';
import { EmploymentStatusesDropdownOptionEnum } from 'components/GroupsModal/GroupsModal.form.types';
import { PositionTypeEnum } from 'types/graphql-types';
// import { castEnumToDropdownItems } from 'utils/castEnumsToDropdownItems';
import i18next from 'i18next';

const numberToDropDownWithLabel = ({ value, label }: { value: number; label: string }) =>
  ({
    id: `${value}`,
    key: `${value}`,
    value: `${value}`,
    label: `${value} ${label}${value !== 1 ? 's' : ''}`,
    type: 'Standard',
  } as IBKJComboBoxAsyncOption);

const toHoursDropdownOption = (value: number) => numberToDropDownWithLabel({ value, label: 'hr' });

const toMinsDropdownOption = (value: number) => numberToDropDownWithLabel({ value, label: 'min' });

export const criteriaHoursDropdownOptionsPeriod = [...Array(169).keys()].map(toHoursDropdownOption);
export const criteriaHoursDropdownOptionsDay = [...Array(24).keys()].map(toHoursDropdownOption);
export const criteriaMinsDropdownOptions = [0, 15, 30, 45].map(toMinsDropdownOption);

export const isEmptyOrZero = (value: string[]) => value.length === 0 || `${value[0]}` === '0';

// const castEnumToPillSelectOptions = (enumType: Record<string, string>, name: string) =>
//   castEnumToDropdownItems(enumType, name).map((opt) => ({ ...opt, id: opt.key }));

export const employmentStatuses = (): Omit<
  IBKJComboBoxOption<string, string, string | number>,
  'index'
>[] => [
  {
    id: EmploymentStatusesDropdownOptionEnum.casual,
    label: i18next.t('groups:CASUAL'),
    value: EmploymentStatusesDropdownOptionEnum.casual,
    name: 'employmentStatuses',
    key: EmploymentStatusesDropdownOptionEnum.casual,
  },
  {
    id: EmploymentStatusesDropdownOptionEnum.full_time,
    label: i18next.t('groups:FULL_TIME'),
    value: EmploymentStatusesDropdownOptionEnum.full_time,
    name: 'employmentStatuses',
    key: EmploymentStatusesDropdownOptionEnum.full_time,
  },
  {
    id: EmploymentStatusesDropdownOptionEnum.part_time,
    label: i18next.t('groups:PART_TIME'),
    value: EmploymentStatusesDropdownOptionEnum.part_time,
    name: 'employmentStatuses',
    key: EmploymentStatusesDropdownOptionEnum.part_time,
  },
];

export const positionTypes = (): Omit<
  IBKJComboBoxOption<string, string, string | number>,
  'index'
>[] => [
  {
    id: PositionTypeEnum.primary,
    label: i18next.t('groups:PRIMARY'),
    value: PositionTypeEnum.primary,
    name: 'positionTypes',
    key: PositionTypeEnum.primary,
  },
  {
    id: PositionTypeEnum.additional,
    label: i18next.t('groups:SECONDARY'),
    value: PositionTypeEnum.additional,
    name: 'positionTypes',
    key: PositionTypeEnum.additional,
  },
];
