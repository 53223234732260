import { BKJThemeColorType } from '@bookjane2/bookjane-design-library';
import { ActivityEventEnum } from 'types/graphql-types';

export const getActivityColorByStatus = (status: ActivityEventEnum): BKJThemeColorType => {
  switch (status) {
    case 'pending':
      return 'ShiftPending';
    case 'swap_expired':
    case 'swapped':
    case 'swap_initiated':
      return 'ShiftSwapped';
    case 'send_to_agencies':
    case 'sent_to_bookjane':
    case 'accepted_by_bookjane':
    case 'created':
    case 'requested':
      return 'ShiftRequested';
    case 'declined':
      return 'SecondaryPendingHover';
    case 'assigned':
      return 'ShiftAssigned';
    case 'accepted':
      return 'ShiftAccepted';
    case 'cancelled':
      return 'ShiftCancelled';
    case 'customer_released':
    case 'jane_released':
    case 'auto_released':
      return 'Grey6';
    default:
      return 'Grey6';
  }
};
