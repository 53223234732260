import dotenv from 'dotenv';
import { BKJ_J360_VERSION } from '../config/web-version';
dotenv.config();

const { CIRCLE_TAG, CIRCLE_BRANCH, CIRCLE_SHA1 } = process.env;

export const getWebVersion = (): string => {
  if (CIRCLE_TAG) return CIRCLE_TAG;
  if (CIRCLE_BRANCH && CIRCLE_SHA1) return `${CIRCLE_BRANCH}-${CIRCLE_SHA1.substring(0, 7)}`;
  if (CIRCLE_BRANCH) return CIRCLE_BRANCH;
  return `${BKJ_J360_VERSION}`;
};
