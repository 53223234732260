import styled, { css } from 'styled-components';
import { elementDimensions } from 'constants/elementDimensions';
import { ContentWrapperProps } from './AuthPageContextWrapper.types';
import { MountTransition } from 'routes/MountTransition';
const { TabNavigationHeight, MobilePageHeaderHeight, SSFBottomBarHeight } = elementDimensions;

export const StyledAuthPageContentWrapper = styled(MountTransition)<ContentWrapperProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.White};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - ${({ headerOffset }) => headerOffset}px);
  max-height: calc(100vh - ${({ headerOffset }) => headerOffset}px);
  overflow-y: overlay;
  overflow-x: overlay;
  transition: all 150ms ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakpoints.Desktop}) {
    height: calc(100vh - ${MobilePageHeaderHeight}px - ${TabNavigationHeight}px);
    max-height: calc(100vh - ${MobilePageHeaderHeight}px - ${TabNavigationHeight}px);
  }
  ${({ isSSFBottomBarOpen, hasSSFBars, headerOffset }) =>
    isSSFBottomBarOpen &&
    hasSSFBars &&
    css`
      height: calc(100vh - ${headerOffset}px - ${SSFBottomBarHeight}px);
      max-height: calc(100vh - ${headerOffset}px - ${SSFBottomBarHeight}px);
    `};
`;
