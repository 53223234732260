import { StyledBKJAvatar } from 'components/TeamMemberJaneAvatar/TeamMemberJaneAvatar.styled';
import { IJaneAvatarProps } from './TeamMemberJaneAvatar.types';

export const TeamMemberJaneAvatar = (props: IJaneAvatarProps): JSX.Element => {
  const { status, url, size = 24, pendingSignup, ...rest } = props;

  return (
    <StyledBKJAvatar
      src={url}
      fallbackAvatarColor={'Grey5'}
      height={`${size}px`}
      width={`${size}px`}
      size={size}
      fontSize={size}
      status={status}
      showPendingSignUp={pendingSignup}
      {...rest}
    />
  );
};
