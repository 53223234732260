import { OffsetElementType } from 'components/AuthPageContentWrapper/AuthPageContextWrapper.types';
import { ILocationFilterProps } from 'components/LocationFilterInput/LocationFilterInput.types';
import { ISSFBehaviorsOptions } from 'hooks/useSSFBehaviors/useSSFBehaviors.types';
import { TeamMemberTablePageSize } from 'pages/TeamMemberPage/components/TeamMemberTable/TeamMemberTable.constants';
import { QUERY_teamMemberPage } from 'queries';
import { SessionService } from 'services';
import { UserEmploymentStatusEnum } from 'types/graphql-types';
import { castEnumToDropdownItems } from 'utils/castEnumsToDropdownItems';

export const teamMemberPageOffsetElements: OffsetElementType[] = [
  'DesktopPageHeaderHeight',
  'SSFTopBarHeight',
  'SSFBottomBarHeight',
];

export const teamMemberPageInternalJaneTypeOptions = castEnumToDropdownItems(
  UserEmploymentStatusEnum,
  'internalJaneType',
);

function payloadTransformer(
  variables: Record<
    string,
    string &
      string[] &
      number[] &
      Record<
        string,
        string | string[] | number[] | Record<string, string | string[] | number[] | boolean>
      >
  >,
  isUserAgencyAndAgencyLocationFilterForcedDisabled: boolean,
) {
  const payload = {
    ...variables,
    filter: {
      byFullName: variables.byFullName,
      byPrimaryCommunity: [] as number[],
      byAgencyCommunity: [] as number[],
      byEmploymentStatus: variables.internalJaneType,
      byStatus: variables.teamMemberStatus,
      byPosition: variables.position_id,
      byPendingSignup: variables?.teamMemberMobileStatus,
    },
    // sort: [
    //   {
    //     field: 'accessStatus',
    //     direction: 'asc',
    //   },
    // ],
  };
  if (!variables.viewAllFacilities) {
    if (SessionService.assertUserType('B2B'))
      payload.filter.byPrimaryCommunity = [
        Number.parseInt(variables.location.value as string),
      ] as number[];

    if (
      SessionService.assertUserType('Agency') &&
      !isUserAgencyAndAgencyLocationFilterForcedDisabled
    )
      payload.filter.byAgencyCommunity = [
        Number.parseInt(variables.location.value as string),
      ] as number[];
  }
  const teamMemberMobileStatus = variables?.teamMemberMobileStatus;

  if (Array.isArray(teamMemberMobileStatus) && teamMemberMobileStatus.length > 0) {
    payload.filter.byPendingSignup = JSON.parse(teamMemberMobileStatus[0]);
  } else {
    payload.filter.byPendingSignup = teamMemberMobileStatus;
  }

  return payload;
}

export const teamMemberPageConfig = ({
  location,
  isUserAgencyAndAgencyLocationFilterForcedDisabled,
}: {
  location: ILocationFilterProps['value'];
  isUserAgencyAndAgencyLocationFilterForcedDisabled: boolean;
}): ISSFBehaviorsOptions => {
  return {
    key: 'TEAM_MEMBER_PAGE',
    pageSize: TeamMemberTablePageSize,
    paginationType: 'PagedPagination',
    query: QUERY_teamMemberPage,
    payloadTransformer: (variables) =>
      payloadTransformer(variables, isUserAgencyAndAgencyLocationFilterForcedDisabled),
    schema: {
      location: {
        initialValue: location,
        isGlobalCached: true,
        isPreservedOnReset: true,
      },
      teamMemberStatus: {
        initialValue: [],
        isGlobalCached: true,
      },
      teamMemberMobileStatus: {
        initialValue: null,
        isSessionCached: true,
        isGlobalCached: true,
        isPreservedOnReset: true,
      },
      position_id: {
        initialValue: [],
        isGlobalCached: true,
      },
      viewAllFacilities: {
        initialValue: false,
        isSessionCached: true,
        isGlobalCached: true,
        isPreservedOnReset: true,
      },
      byFullName: {
        initialValue: '',
        isGlobalCached: true,
        isPreservedOnReset: true,
      },
      internalJaneType: {
        initialValue: [],
        isGlobalCached: true,
      },
      sort: {
        initialValue: [
          {
            field: 'accessStatus',
            direction: 'asc',
          },
        ],
      },
    },
  };
};
