import { useFormBehaviors } from 'hooks';
import { UseFormBehaviors } from 'hooks/useFormBehaviors/useFormBehaviors.types';
import { useUserTypeSwitch } from 'hooks/useUserTypeSwitch';
import {
  AdministratorSettingsLocationFormFields as LASFFields,
  AdministratorSettingsGeneralFormApiError as GASFApiError,
  CommunitiesForCheckbox,
} from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.types';
import { createContext, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toastService } from 'services';
import { useAPIActions } from 'store/reducers/api/apiSlice';
import { API_PUT_updateCustomerSecondaryDetailsLocation } from 'requests/PUT_updateCustomerSecondaryDetailsLocation';
import { useAdministratorSettingsDataContext } from 'pages/SettingsPage/AdministratorSettingsPage/AdministratorSettings.data.context';
import { API_PUT_updateAgencySecondaryDetailsLocation } from 'requests/PUT_updateAgencySecondaryDetailsLocation';
import { useTranslation } from 'hooks/useTranslation';
const LocationPageFormAdministratorSettingsContext = createContext<
  UseFormBehaviors<LASFFields, GASFApiError>
>({} as UseFormBehaviors<LASFFields, GASFApiError>);

export const LocationPageFromAdministratorSettingsProvider: FC = ({ children }) => {
  const { secondary_users, communities } = useAdministratorSettingsDataContext();
  const { id } = useParams<{ id: string }>();
  const userData = secondary_users?.find((item) => item.id === id);
  const { t } = useTranslation();

  let userCommunitiesData = userData?.communities?.map((community) => {
    return {
      id: community.id,
      shift_emails: community.shift_emails,
      isIncluded: true, // isIncluded is just for front-end logic
    };
  });

  let communitiesAllData = communities?.map((community) => {
    let shift_emails = community.shift_emails ? community.shift_emails : false;
    let isIncluded = false;
    let name = community.name;
    userCommunitiesData?.forEach((item) => {
      if (item.id === community.id) {
        isIncluded = true;
        shift_emails = item.shift_emails;
      }
    });
    var abc: CommunitiesForCheckbox = {
      id: community.id,
      shift_emails: shift_emails,
      isIncluded: isIncluded,
      name: name,
    };
    return abc;
  });
  let communitiesAllFormData: LASFFields = {
    communities: communitiesAllData,
    first_name: userData?.first_name ? userData?.first_name : '',
    last_name: userData?.last_name ? userData?.last_name : '',
  };
  const { apiRequest } = useAPIActions();
  const form = useFormBehaviors<LASFFields, GASFApiError>({
    initialState: communitiesAllFormData,
    onSubmit: useUserTypeSwitch({
      Agency: (formValues: LASFFields) => {
        return apiRequest(API_PUT_updateAgencySecondaryDetailsLocation(id, formValues), {
          onError: () => {
            toastService.error(t('settings:Something_went_wrong'));
          },
          onSuccess: () => {
            toastService.success(t('settings:Successfully_updated'));
          },
        });
      },
      B2B: (formValues: LASFFields) => {
        return apiRequest(API_PUT_updateCustomerSecondaryDetailsLocation(id, formValues), {
          onError: () => {
            toastService.error(t('settings:Something_went_wrong'));
          },
          onSuccess: () => {
            toastService.success(t('settings:Successfully_updated'));
          },
        });
      },
    }),
    validations: useUserTypeSwitch({
      Agency: {
        // title: VALIDATION_required,
      },
      B2B: {
        // title: VALIDATION_required,
      },
    }),
  });
  return (
    <LocationPageFormAdministratorSettingsContext.Provider value={form}>
      {children}
    </LocationPageFormAdministratorSettingsContext.Provider>
  );
};

export const useLocationPageFormAdministratorSettingsContext = () =>
  useContext<UseFormBehaviors<LASFFields, GASFApiError>>(
    LocationPageFormAdministratorSettingsContext,
  );
