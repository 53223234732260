import { FC } from 'react';
import { ShiftModal } from 'components/ShiftModal';
import { InviteTeamMembersModal } from 'components/InviteTeamMembersModal';
import { withMemo } from 'utils/withMemo';
import { AuthGuard } from 'guards/AuthGuard';
import { CustomFieldEditModal } from 'components/CustomFieldEditModal';
import { CustomFieldCreateModal } from 'components/CustomFieldCreateModal';
import { TimeOffModal } from 'components/TimeOffModal';
import { JaneModal } from 'components/JaneModal';
import { InviteTeamMembersByCSVModal } from 'components/InviteTeamMembersByCSVModal';

const GlobalModalsComponent: FC = (): JSX.Element => {
  return (
    <AuthGuard>
      <CustomFieldEditModal />
      <CustomFieldCreateModal />
      <TimeOffModal />
      <ShiftModal />
      <JaneModal />
      <InviteTeamMembersModal />
      <InviteTeamMembersByCSVModal />
    </AuthGuard>
  );
};

export const GlobalModals = withMemo(GlobalModalsComponent);
