import dayjs from 'utils/dayjs';
import { monthsArray } from './YearView.constants';
import { IMonthCellConfig } from './YearView.types';
import {
  getCalendarMonthRange,
  getFirstDayOfMonthFromDate,
} from '../../../../../BKJDatePicker.utils';
import { ISO8601TupleType } from 'types/common.types';
import {
  BKJDatePickerOnChangeType,
  BKJDatePickerValueType,
} from '../../../../../BKJDatePicker.types';
import { MonthNameType } from '../../BKJCalendar.types';
import { useTranslation } from 'hooks/useTranslation';

export const getMonthCellsConfigArray = ({
  value,
  name,
  year,
  onChange,
}: {
  value: BKJDatePickerValueType;
  name: string;
  year: number;
  onChange: BKJDatePickerOnChangeType;
}): Array<IMonthCellConfig> =>
  monthsArray.map((monthName: MonthNameType) => {
    const { t } = useTranslation();
    const firstDayOfMonth = dayjs(`${monthName}, ${year}`).date(1).format();
    const firstDayOfSelectedDateRange = getFirstDayOfMonthFromDate(value[0]);
    const _value = getCalendarMonthRange(firstDayOfMonth);
    const isMonthThisMonth = dayjs(firstDayOfMonth).isSame(dayjs(), 'month');
    const isSelected = dayjs(firstDayOfMonth).isSame(firstDayOfSelectedDateRange);
    const handleMonthClick = (): void => {
      const e = {
        target: {
          name,
          value: _value,
        },
      };
      return onChange(e);
    };

    return {
      label: t(monthName.toUpperCase()),
      onClick: handleMonthClick,
      isMonthThisMonth: isMonthThisMonth,
      isSelected: isSelected,
    };
  });

export const getYearInView = (dateRangeInMonthView: ISO8601TupleType): number =>
  dayjs(dateRangeInMonthView[0]).year();
