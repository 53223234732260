import { FC, Fragment } from 'react';
import {
  DedicatedBKJBadge,
  BadgeText,
} from 'components/ShiftCardSkeleton/ShiftCardSkeleton.styled';
import { ITagsProps } from 'components/Tags/Tags.types';
import { UserGuard } from 'guards/UserGuard';
import { useTranslation } from 'hooks/useTranslation';
import { UserPermissionEnum } from 'guards/UserGuard.types';

export const Tags: FC<ITagsProps> = ({
  isRecurring: isRecurringTagVisible,
  isDedicated: isDedicatedTagVisible,
  isSentToAgencies,
  hasJane,
  isAgencyJane,
  isAcceptedBySharedTeamMember,
}) => {
  const { t } = useTranslation();
  const isAgencyTagVisible =
    isSentToAgencies &&
    ((hasJane && isAgencyJane) || !hasJane) &&
    !isDedicatedTagVisible &&
    !isAcceptedBySharedTeamMember;

  return (
    <Fragment>
      {isRecurringTagVisible && (
        <Fragment>
          <DedicatedBKJBadge variant="Grey">
            <BadgeText>{t('create_shifts:RECURRING_TAG')}</BadgeText>
          </DedicatedBKJBadge>
          &nbsp; &nbsp;
        </Fragment>
      )}

      {isDedicatedTagVisible ? (
        <Fragment>
          <DedicatedBKJBadge variant="Grey">
            <BadgeText>{t('create_shifts:DEDICATED')}</BadgeText>
          </DedicatedBKJBadge>
          &nbsp; &nbsp;
        </Fragment>
      ) : (
        isAgencyTagVisible && (
          <UserGuard
            userType={[
              UserPermissionEnum.USER_TYPE_B2B,
              UserPermissionEnum.USER_TYPE_ESSENTIAL_SECONDARY,
              UserPermissionEnum.USER_TYPE_PREMIUM_SECONDARY,
            ]}
          >
            <Fragment>
              <DedicatedBKJBadge variant="Grey">
                <BadgeText>{t('dashboard:AGENCY')}</BadgeText>
              </DedicatedBKJBadge>
              &nbsp; &nbsp;
            </Fragment>
          </UserGuard>
        )
      )}

      {isAcceptedBySharedTeamMember && (
        <Fragment>
          <DedicatedBKJBadge variant="Grey">
            <BadgeText>{t('dashboard:SHARED')}</BadgeText>
          </DedicatedBKJBadge>
          &nbsp; &nbsp;
        </Fragment>
      )}
    </Fragment>
  );
};
