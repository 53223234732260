import { FC, Fragment } from 'react';
import { BKJIcon } from '@bookjane2/bookjane-design-library';
import { Flex } from 'components/Flex';
import { ShiftStatusBadge } from 'components/ShiftStatusBadge';
import { JaneAvatar } from 'components/BKJJaneAvatar';
import { formatJaneNameByStatus } from 'utils/formatJaneNameByStatus';
import { parseVerboseDate } from 'utils';
import {
  ShiftsTableData,
  Text,
  Label,
  DateText,
  FacilityName,
  JaneName,
  Recurring,
} from './ShiftsTableRow.styled';
import {
  calculateBreakTime,
  calculateDuration,
  getHoursAndMinutesFromDate,
  formatDateForShiftTableRow,
} from 'pages/ShiftsListPage/ShiftsTableRow/ShiftsTableRow.utils';
import { shiftModalRoutingService } from 'components/ShiftModal/ShiftModal.ui.context';
import { BKJTableRowWrapper } from 'components/BKJTable/BKJTableRow/BKJTableRow.styled';
import { OrderStatusEnum, shiftsListPage_fetchOrders_edges_node } from 'types/graphql-types';
import { DedicatedBKJBadge } from 'components/ShiftCardSkeleton/ShiftCardSkeleton.styled';
import { getTranslatedResponse } from 'utils/getTranslatedResponse';
import { useTranslation } from 'hooks/useTranslation';

export const ShiftsAgencyTableRow: FC<shiftsListPage_fetchOrders_edges_node> = ({
  id,
  status,
  mainJane,
  position,
  startAt,
  endAt,
  createdAt,
  dedicated,
  community,
  recurrence,
  rate,
  orderSchedule,
  estimatedTotalCharge,
  totalDuration,
}) => {
  const { i18n, t } = useTranslation();
  function onShiftModal() {
    shiftModalRoutingService.open({ shiftModalShiftId: id });
  }

  const showRate =
    (!!mainJane?.agencyCommunity && status.value === OrderStatusEnum.completed) ||
    status.value !== OrderStatusEnum.completed;
  return (
    <BKJTableRowWrapper key={id} onClick={onShiftModal}>
      <ShiftsTableData>
        <Label>Shift Date</Label>
        <Flex flexDirection="column">
          <Text>{formatDateForShiftTableRow(startAt, true, false)}</Text>
          {recurrence && (
            <Recurring>
              <BKJIcon iconName={'Recurring'} />
              &nbsp;Recurring Shift
            </Recurring>
          )}
        </Flex>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Customer</Label>
        <Text>{community.name}</Text>
      </ShiftsTableData>

      <ShiftsTableData>
        <Label>City</Label>
        <Text>
          {community.location?.city},&nbsp;{community.location?.state}
        </Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Team Member</Label>
        <Flex alignItems="center">
          <JaneAvatar
            size={30}
            status={status.value}
            url={mainJane?.avatarUrls.small}
            pendingSignup={mainJane?.pendingSignup ?? false}
          />
          <Flex flexDirection="column" margin="0 0 0 13px">
            <JaneName>{formatJaneNameByStatus({ status: status.name, mainJane })}</JaneName>
            {mainJane?.agencyCommunity?.name && (
              <FacilityName>{mainJane?.agencyCommunity?.name}</FacilityName>
            )}
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            {dedicated && (
              <Fragment>
                &nbsp; &nbsp;
                <DedicatedBKJBadge variant="Grey">{t('status:DEDICATED')}</DedicatedBKJBadge>
              </Fragment>
            )}
          </Flex>
        </Flex>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Position</Label>
        <Text>{position.name}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Status</Label>
        <ShiftStatusBadge status={status.value}>
          {getTranslatedResponse(status.name, 'status', i18n, t)}
        </ShiftStatusBadge>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Start Time</Label>
        <Text>{getHoursAndMinutesFromDate(startAt)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>End Time</Label>
        <Text>{getHoursAndMinutesFromDate(endAt)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Unpaid Break Time</Label>
        <Text>{calculateBreakTime(orderSchedule)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Total Duration</Label>
        <Text>{calculateDuration(totalDuration)}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Hourly Pay Rate </Label>
        {showRate && rate && <Text>{rate.rateLabel}</Text>}
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Estimated Total Pay </Label>
        {showRate && estimatedTotalCharge && <Text>{estimatedTotalCharge.rateLabel}</Text>}
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Shift No. </Label>
        <Text>{orderSchedule.id}</Text>
      </ShiftsTableData>
      <ShiftsTableData>
        <Label>Created At </Label>
        <DateText>{parseVerboseDate(createdAt)}</DateText>
      </ShiftsTableData>
    </BKJTableRowWrapper>
  );
};
