import { CustomFieldInfoFormSchema } from 'components/CustomFieldModal/CustomFieldModal.types';
import { ICustomField } from 'pages/SettingsPage/CustomFieldsSettingsPage/CustomFieldsSettings.types';

export const VALIDATION_fieldName =
  (customFieldList: ICustomField[]) =>
  (value: CustomFieldInfoFormSchema[keyof CustomFieldInfoFormSchema]): string => {
    const fieldName = value as string;
    if (fieldName.trim() === '') return 'Please enter a field name';
    if (fieldName.length > 50) return 'You’ve reached max of 50 characters';
    if (
      customFieldList
        .map((customField) => customField.name.toLowerCase())
        .includes(fieldName.toLowerCase())
    )
      return 'Field name already exists';
    return '';
  };
