import { ISO8601Type } from 'types/common.types';
import dayjs from 'utils/dayjs';
import { DeleteShiftReasonType } from './DeleteView.types';

export const deleteViewReasonLabel = (deleteViewReason: DeleteShiftReasonType): string => {
  return deleteViewReason
    .split('_')
    .reduce((accum, curr) => `${accum} ${curr.charAt(0).toUpperCase() + curr.slice(1)}`, '');
};

export const isTimeWithinTwelveHours = (date: ISO8601Type): boolean => {
  const startDate = dayjs().toISOString();
  const endDate = dayjs(startDate).endOf('minute').add(12, 'hour').format();
  return dayjs(date).isBetween(startDate, endDate);
};
