import { TeamMemberCSVView } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/TeamMemberCSVView';
import { TeamMemberEditRankingView } from 'components/InviteTeamMembersByCSVModal/views/EditRanking/TeamMemberEditRankingView';
import { UnSuccessView } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/UnSuccessView';
import { SuccessView } from 'components/InviteTeamMembersByCSVModal/views/ImportViaSpreadsheet/views/SuccessView';
import { InviteTeamMemberView } from 'components/InviteTeamMembersByCSVModal/views/InviteTeamMemberView/InviteTeamMemberView';
export const InviteTeamMembersByCSVModalComponentMap = {
  InviteByCSV: TeamMemberCSVView,
  EditRanking: TeamMemberEditRankingView,
  UnSuccessFul: UnSuccessView,
  SuccessFul: SuccessView,
  InviteTeamMemberView: InviteTeamMemberView,
};

export type InviteTeamMembersByCSVModalViewType =
  keyof typeof InviteTeamMembersByCSVModalComponentMap;
