import { useSSFBehaviors, useURLParameters } from 'hooks';
import { FC, useContext, useMemo } from 'react';
import { createGQLDataContext } from 'utils/createDataContext';
import {
  CalloutListData,
  calloutListQueryConfig,
} from 'components/ShiftModal/src/views/AuditTrailView/AuditTrailView.constants';
import {
  shiftModalOrderDetailsCalloutList,
  shiftModalOrderDetailsCalloutList_node_CalloutList,
} from 'types/graphql-types';
import { calculateEstimatedTimeRemaining } from 'components/ShiftModal/src/views/DetailView/GeneralTab/CallOut';

export const AuditTrailDataContext = createGQLDataContext({} as CalloutListData);

export const AuditTrailDataProvider: FC = ({ children }) => {
  const [{ calloutListId }] = useURLParameters<{ calloutListId: string }>();

  const [values, useQueryOptions] = useSSFBehaviors<shiftModalOrderDetailsCalloutList>(
    calloutListQueryConfig(calloutListId),
  );

  const value = useMemo(() => {
    const nodeData = useQueryOptions?.data
      ?.node as shiftModalOrderDetailsCalloutList_node_CalloutList;

    return {
      estimatedTimeRemaining: nodeData?.estimatedCompletedAt
        ? calculateEstimatedTimeRemaining(nodeData.estimatedCompletedAt)
        : { mins: 0, hours: 0, days: 0 },
      values,
      ...useQueryOptions,
    };
  }, [useQueryOptions, values]);

  return <AuditTrailDataContext.Provider value={value}>{children}</AuditTrailDataContext.Provider>;
};

export const useAuditTrailDataContext = (): CalloutListData => useContext(AuditTrailDataContext);
