export const TeamMemberTableColumnCount = {
  B2B: 7,
  Agency: 6,
};

export const TeamMemberTableGridColumnWidth = {
  B2B: 'minmax(240px, 1fr) minmax(232px, 1fr) minmax(216px, 1fr) minmax(180px, 1fr) minmax(200px, 1fr) minmax(216px, 1fr) minmax(248px, 1fr)',
  Agency:
    'minmax(240px, 1fr) minmax(232px, 1fr) minmax(216px, 1fr) minmax(180px, 1fr) minmax(200px, 1fr) minmax(216px, 1fr)',
};

export const TeamMemberTablePageSize = 20;
