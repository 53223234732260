import { IExpandableMenuLink } from 'components/SettingsNavigation/ExpandableMenuLink/ExpandableMenuLink.types';
import {
  SettingsInternalMenuLink,
  SettingsNavItemWrapper,
  SettingsNavItem,
} from 'components/SettingsNavigation/SettingsNavigation.styled';
import { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

export const ExpandableMenuLink: FC<IExpandableMenuLink> = ({
  children,
  component: Component,
  to,
  isOpen,
  handleExpanded,
}) => {
  const { pathname } = useLocation();
  const isExpanded = pathname.includes(to);

  return (
    <Fragment>
      <SettingsInternalMenuLink
        to={to}
        onClick={() => {
          if (handleExpanded) handleExpanded();
        }}
      >
        <SettingsNavItemWrapper $isOpen={isOpen} $isAccordionOpen={isExpanded}>
          <SettingsNavItem>{children}</SettingsNavItem>
        </SettingsNavItemWrapper>
      </SettingsInternalMenuLink>
      <Component isOpen={isOpen} isExpanded={isExpanded} />
    </Fragment>
  );
};
