import { IJaneNameProps } from 'pages/DashboardPage/JaneName/JaneName.types';
import i18next from 'i18next';
import { transformValueToKey } from 'utils/getTranslatedResponse';
import { getLocalizedDateFormat } from 'utils/getLocalizedDateFormat';
const statusesThatImplyMainJaneIsDefined = [
  'In Progress',
  'Completed',
  'Requested',
  'Accepted',
  'Assigned',
  'Cancelled',
  'Swapped',
];

export const formatJaneNameByStatus = (props: IJaneNameProps): string => {
  const { frenchFormat } = getLocalizedDateFormat();
  const { status, mainJane } = props;

  if (status === 'Cancelled' && !mainJane)
    if (frenchFormat) {
      return `${i18next.t('dashboard:SHIFT')} ${i18next.t(
        'status:' + transformValueToKey(status),
      )}`;
    } else {
      return `${i18next.t('status:' + transformValueToKey(status))} ${i18next.t(
        'dashboard:SHIFT',
      )}`;
    }

  if (mainJane && statusesThatImplyMainJaneIsDefined.includes(status)) {
    return `${mainJane.firstName} ${mainJane.lastName}`;
  }

  switch (status) {
    case 'Unfulfilled':
    case 'Open':
    default:
      if (frenchFormat) {
        return `${i18next.t('dashboard:SHIFT')} ${i18next.t(
          'status:' + transformValueToKey(status),
        )}`;
      } else {
        return `${i18next.t('status:' + transformValueToKey(status))} ${i18next.t(
          'dashboard:SHIFT',
        )}`;
      }
  }
};
